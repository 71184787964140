import React, { useState } from "react"
import { Row, Col, Label } from "reactstrap"
import Select from "react-select"
import { PropTypes } from 'prop-types';

FilterSelectMultipleItems.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func
}

function FilterSelectMultipleItems({ label, items, onChange }) {

    const [selectedItems, setSelectedItems] = useState();

    const handleChange = (selectedItems) => {
        let listItem = []
        selectedItems?.map(item => {
            listItem.push(item.value)
        })
        onChange(listItem.join())
    }

    return (
        <Row xl={12} className="mb-2">
            <Label md={12} xl={2} className="col-form-label">{label}</Label>
            <Col md={12} xl={4}>
                <Select
                    value={selectedItems}
                    isMulti={true}
                    onChange={handleChange}
                    options={items}
                    classNamePrefix="select2-selection"
                />
            </Col>
        </Row>
    );
}

export default FilterSelectMultipleItems;