
const validate = (input,regStr) => {

    const regex = new RegExp(regStr);
    if (regex.test(input)) return true;
    return false;
}
export const validatePass = (input) => {
    let regStr = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*])[A-Za-z\\d$@$!#%^*?&]{5,}$'
    return validate(input,regStr)
}
