import { observable, action, makeObservable } from 'mobx';

class AuthStore {
    constructor() {
        makeObservable(this, {
            data: observable
        });
    }
    data = [];
}
const AuthStoreContext = new AuthStore();

export default AuthStoreContext;
