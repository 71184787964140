import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import ListProductSetItem from './ListProductSetItem';
import ProductSetModal from './ProductSetModal';
import { ProductSetContext } from '../ProductSetContext';
import { deleteSetItem } from "../../../apis/productApi";
import { message } from 'antd';
import PropTypes from 'prop-types';

function ListProductSet(props) {
  const { itemsObject } = useContext(ProductSetContext); 
  const [listItem, setListItem] = itemsObject;

  const [visibleModal, SetVisibleModal] = useState(false);
  const [selectedItem, SetSelectedItem] = useState(null);
  const [creatingMode, SetCreatingMode] = useState(false);

  const handleEditItemClick = (item) => {
    SetSelectedItem(item);
    SetVisibleModal(true);
  }

  const handleClose = (processedItem) => {
    SetVisibleModal(false);
    SetSelectedItem(null);

    if (processedItem.id == null) {
      SetCreatingMode(false);
      return;
    }
    
    let newListItem;
    if (creatingMode) {
      newListItem = [...listItem, processedItem];
      SetCreatingMode(false);
    } else {
        newListItem= listItem.map(prevItem => {
        if (prevItem.id === processedItem.id) {
          return {
            ...prevItem,
            ...processedItem,
          };
        }
        return prevItem;
      });
    }
    setListItem(newListItem);
  }

  const handleDeleteSetItem = (itemId) => {
    deleteSetItem(itemId, handleSuccess, handleError);
  }

  const handleSuccess = res => {
    message.success(res.data.message);
    const newListItem = listItem.filter(item => item.id !== parseInt(res.data.id));
    setListItem(newListItem);
  };

  const handleError = res => {
    message.error(res.message)
  };

  const handleAddSetItem = () => {
    const newItem = {
      id: null,
      product_id: null,
      set_id: props.setId,
      product_code: "",
      product_name: "",
      unit_code: "",
      base_qty: 1,
      qty_rate: 1,
    }
    SetSelectedItem(newItem);
    SetVisibleModal(true);
    SetCreatingMode(true);
  }

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">
        Bộ sản phẩm 
      </CardHeader>
      <CardBody>
        <Button color='success' style={{cursor: "pointer", marginBottom: "2px"}} onClick={() => handleAddSetItem()}>
            <i className="fas fa-plus"></i> Thêm sản phẩm
        </Button>
        <Table className="table table-bordered mytable">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Mã sản phẩm</Th>
              <Th>Tên sản phẩm</Th>
              <Th>ĐVT</Th>
              <Th>Số lượng</Th>
              <Th>Hành động</Th>
            </Tr>
          </Thead>
          <Tbody>
            <ListProductSetItem onEdit={handleEditItemClick} onDelete={handleDeleteSetItem} />
          </Tbody>
        </Table>
      </CardBody>      
      <ProductSetModal show={visibleModal} data={selectedItem} onClose={handleClose} selectedShop={props.selectedShop} />
    </Card>
  );
}

ListProductSet.propTypes = {
  setId: PropTypes.number,
  selectedShop: PropTypes.any,
}

export default ListProductSet;
