import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getList = (param, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, param, callback, callbackErr);
}
export const get = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);
}
export const create = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns';

    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)
}
export const update = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}
export const destroy = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}
export const restore = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id + '/restore';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr)
}
export const start = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id + '/start';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr)
}
export const stop = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + id + '/stop';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr)
}
export const find = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/sale-campaigns/find-products`;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, data, callback, callbackErr);
}