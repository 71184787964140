
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, } from "reactstrap"
import PropTypes from 'prop-types'
import { API_HOST_NAME } from '../../constants/SystemConstant';
import { utils } from '../../Utils/utils';
import { Popconfirm } from 'antd';
import * as productApi from "apis/productApi";
import { isUserInGroup, isWarehouseManager } from 'Utils/SecurityUtils';
import { fixImagePath, showMessage } from 'Utils/global';
import { Td, Th, Tr } from 'react-super-responsive-table';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useSelector } from 'react-redux';

ListProductItem.propTypes = {
  products: PropTypes.array,
  deleteCallback: PropTypes.func,
}

const canEdit = (user, product, selectedShop) => {
  if ((user.is_admin == 1) || isUserInGroup(['Admin', 'Kho', 'Content'])) return true;
  const productWarehouses = product.original_warehouses ? product.original_warehouses.split(',') : [];
  const userWarehouses = user.warehouses[selectedShop];
  return userWarehouses?.some(item => productWarehouses.includes(item))
}

function ListProductItem(props) {
  const { products } = props;

  const [showOriginPrice, setShowOriginPrice] = useState(false);
  const [user, setUser] = useState({});
  const selectedShop = useSelector(state => state.SelectedShop)

  useEffect(() => {
      const canShow = (isUserInGroup(['Admin', 'Kho']) || isWarehouseManager());
      setShowOriginPrice(canShow);
      const authUser = JSON.parse(localStorage.getItem('authUser'))
      setUser(authUser);
  }, [])

  const onDelete = id => {
    productApi.deleteProduct(id, deleteSuccess, showMessage);
  };
  const deleteSuccess = res => {
    alert('Xóa thành công');
  };

  const renderOriginPrice = (data) => {
    return (showOriginPrice) ? (
      <Td>{data.original_price ? utils.formatNumber(data.original_price) : ''}</Td>
    ) : ''
  }

  return (
    <>
      { products?.length === 0
        ? <Tr></Tr>
        : products?.map((data, i) => {
          return (
          <Tr key={i}>
            <Td>
              <a href={fixImagePath(data.first_image?.slide_file)} 
                rel="noreferrer" target="_blank">
                <img className="product-image" src={fixImagePath(data.first_image?.slide_file)} alt={data.product_code} />
              </a>
            </Td>
            <Td>{data.product_code}</Td>
            <Td>{data.code_anh ? data.code_anh : ''}</Td>
            <Td>{data.product_name}</Td>
            <Td>{data.unit_code}</Td>
            <Td>{data.category?.category_name}</Td>
            {renderOriginPrice(data)}
            <Td>{data.price ? utils.formatNumber(data.price) : ''}</Td>
            <Td>{data.sale_price ? utils.formatNumber(data.sale_price) : '0'}</Td>
            <Td>
              <Row className="d-flex align-items-center m-0">
                <Col xs="auto" sm="auto">
                  <Link className="text-success" to={"/products/view/" + data.product_id}>
                    <i className="fas fa-eye"></i>
                  </Link>
                </Col>
                {canEdit(user, data, selectedShop) &&
                <Col xs="auto" sm="auto">
                  <Link className='text-alert' to={"/products/" + data.product_id}>
                    <i className="fas fa-pen"></i>
                  </Link>
                </Col>
                }
                {canEdit(user, data, selectedShop) &&
                <Col xs="auto" sm="auto">
                  <Popconfirm
                    placement="top"
                    title={"Bạn có muốn xóa"}
                    onConfirm={() => onDelete(data.product_id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a className="text-danger">
                      <i className="fas fa-trash"></i>
                    </a>
                  </Popconfirm>
                </Col>
                }
              </Row>
            </Td>
          </Tr>
          )
        })
      }
    </>

  )
}

export default ListProductItem;
