import React, { useState } from "react"
import { Row, Col, Label, Input } from "reactstrap"
import { PropTypes } from 'prop-types';

FilterText.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
}

function FilterText({ label, onChange }) {
    const [text, setText] = useState();

    return (
        <Row xl={12} className="mb-2">
            <Label md={12} xl={2} className="col-form-label">{label}</Label>
            <Col md={12} xl={4}>
                <Input 
                    type='text'
                    value={text}
                    onChange={(e) => onChange(e.target.value)}
                    className="form-control" />
            </Col>
        </Row>
    );
}

export default FilterText;