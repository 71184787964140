import React from 'react';

function SaleReport(props) {
    return (
        <div className="page-content">
            report sale
        </div>
    )
}

export default SaleReport;
