import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import { limitString } from "constants/function";
import { API_HOST_NAME } from "constants/SystemConstant";

function ContactTableItem(props) {
  const { data, count, deleteCallback, restoreCallback } = props;
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  const onDelete = () => {
    deleteCallback(data.id);
  };

  const onRestore = () => {
    restoreCallback(data.id);
  };

  const renderStatus = (status) => {
    return status === 1 ? "Hiển thị" : "Ẩn"
  }
  return (
    <tr>
      <td>{count}</td>
      <td>{limitString(data.name, 30)}</td>
      <td>{limitString(data.address, 50)}</td>
      <td>{limitString(data.phone, 50)}</td>
      <td>{limitString(data.map_link, 50)}</td>
      <td>{renderStatus(data.status)}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/contact/edit/${data.id}`}>
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
ContactTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default ContactTableItem;
