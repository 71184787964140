import { selectedShopRoot } from "../../store/selectedShop/selector";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import React, { Component, useCallback, useEffect, useState } from 'react';
import AttributeList from "./List";
import { Card, CardBody, Col, Row } from "reactstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import "./attributes.scss";
import TextFieldEditorUpdate from "./TextFieldEditorUpdate";
import { debounce } from "../../Utils/global";
import {
  filterCategories,
  filterProducts,
  getAllAttributes,
  getAttributes,
  getGCategories
} from "../../store/wareHouseReceipt/actions";
import { groupsCheckedSelector, searchCategoriesSelector } from "../../store/wareHouseReceipt/selector";
import PropTypes from "prop-types";
import { Collapse } from 'antd';
import GroupRow from "./GroupRow";
import AttributeValues from "./Values";
import { Scrollbar } from "smooth-scrollbar-react";
import MetaTags from 'react-meta-tags';


const AttributeGroupsV2 = ({ searchCategories, selectedShop, groupsChecked }) => {

  const onUpdateRowVal = ({ value, rowIndex }) => {
    dispatch(getGCategories({ company_id: selectedShop }))

  }

  const columns = [{
    dataField: 'category_name',
    text: 'Tên',
    sort: true,
    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      <TextFieldEditorUpdate {...editorProps} value={value} rowIndex={rowIndex} onUpdateServer={onUpdateRowVal} />
    )
  }];
  const defaultSorted = [{
    dataField: 'category_id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 }
  ];

  const selectRow = {
    mode: 'checkbox'
  }
  const dispatch = useDispatch();
  const { SearchBar } = Search;
  useEffect(() => {
    if (selectedShop > 0) {
      dispatch(getGCategories({ company_id: selectedShop }));
      dispatch(getAllAttributes({ company_id: selectedShop, category_id: 0 }))
    }

  }, [dispatch, selectedShop]);
  useEffect(() => {
    if (groupsChecked > 0 && selectedShop > 0) {
      dispatch(getAttributes({ company_id: selectedShop, category_id: groupsChecked }))
    }

  }, [dispatch, selectedShop, groupsChecked]);

  const onSearchGroups = useCallback(
    debounce((text) => searchFuncCategories(text), 1000),
    [dispatch]
  );
  function searchFuncCategories(keyword) {
    dispatch(filterCategories({ keyword }))
  }
  const [searchCategoryKey, setSearchCategoryKey] = useState("")

  return (<div className="page-content">
    <MetaTags>
      <title>Thuộc tính sản phẩm</title>
    </MetaTags>

    <Card>
      <CardBody>
        <Row>
          <Col lg={4} md={6} className={"mb-4"}>
            <div className={"bordercol"}>
              <Scrollbar
                className="custom-class"
                onScroll={() => {

                }}
                alwaysShowTracks={false}
                renderByPixels={false}
                damping={0.1}
                plugins={{
                  overscroll: {
                    effect: "glow"
                  }
                }}
              >
                <div className={" limitheight"} style={{ overflowX: "hidden" }}>
                  <h4 className='mb-4 text-center'>
                    {"Nhóm sản phẩm"}
                  </h4>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">Search this table</span>
                        <input type="text" aria-labelledby="search-bar-0-label"
                          className="form-control" placeholder="Search" value={searchCategoryKey}
                          onChange={e => {
                            setSearchCategoryKey(e.target.value);
                            onSearchGroups(e.target.value);
                          }}
                        />
                      </label><i className="bx bx-search-alt search-icon"></i>
                    </div>

                  </div>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        {searchCategories && searchCategories?.map(cat => {
                          if (!cat?.category_parent_id) {
                            let childs = []
                            searchCategories?.map(citem => {
                              if (citem?.category_parent_id == cat?.category_id) {
                                childs?.push({ id: cat?.category_id, value: citem?.category_name, childs: [] })
                              }
                            })
                            return <GroupRow groupsChecked={groupsChecked} key={cat?.category_id} childs={childs} id={cat?.category_id} value={cat?.category_name} />
                          }
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Scrollbar>
            </div>

          </Col>
          <Col lg={4} md={6} className={"mb-4"}>
            <div className={"bordercol"}>
              <AttributeList />
            </div>
          </Col>
          <Col lg={4} md={6} className={"mb-4"}>
            <div className={"bordercol"}>
              <AttributeValues />
            </div>
          </Col>
        </Row>

      </CardBody>
    </Card>
  </div>
  );
}
AttributeGroupsV2.defaultProps = {
  searchCategories: {},
  selectedShop: 0,
  groupsChecked: 0
}

AttributeGroupsV2.propTypes = {
  searchCategories: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedShop: PropTypes.number,
  groupsChecked: PropTypes.number,
}
function mapStateToProps(state) {
  return {
    selectedShop: selectedShopRoot(state),
    searchCategories: searchCategoriesSelector(state),
    groupsChecked: groupsCheckedSelector(state),
  };
}
const withReduce = connect(mapStateToProps);

export default compose(
  withReduce,
)(AttributeGroupsV2);
