import React from 'react';

function ShopReport(props) {
    return (
        <div className="page-content">
            report shop
        </div>
    )
}

export default ShopReport;
