import React, { useState } from "react";
import MyPagination from "../../components/Common/MyPagination";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ListProductFilter from "./ListProductFilter";
import ListProductButton from "./ListProductButton";
import ListProductItem from "./ListProductItem";
import { isRoleIn, isUserInGroup, isWarehouseManager } from "Utils/SecurityUtils";
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"


function ListProduct(props) {

  let history = useHistory();

  const [products, setProducts] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [queryString, setQueryString] = useState('');
  const showOriginPrice = (isUserInGroup(['Admin', 'KeToan', 'Kho']) || isWarehouseManager());


  const updateQS = (value) => {
    setQueryString(value);
  }

  const onChangePage = (page) => {
    let queryStr = props.location.search.replaceAll('?', '');
    let queryArr = queryStr.split('&');
    let newQueryArr = queryArr.filter(query => !query.startsWith('page='));
    queryStr = newQueryArr.join('&')
    let url = props.location.pathname + "?page=" + page + '&' + queryStr;
    history.push(url);
  }

  const populateProducts = data => {
    setProducts(data.data.data.data);
    setAdditionalData(data.data.data);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách sản phẩm</title>
      </MetaTags>
      <Card>
        <CardHeader className="h5 bg-transparent border-bottom text-uppercase">Danh sách sản phẩm</CardHeader>
        <CardBody>
          <Card>
            <CardHeader className="bg-transparent border-bottom text-uppercase">
              <ListProductButton />
            </CardHeader>
            <CardBody>
              <ListProductFilter populateProducts={populateProducts} updateQueryString={updateQS} />
            </CardBody>
          </Card>
          <Table className="table mytable">
            <Thead>
              <Tr>
                <Th data-priority="1">Ảnh</Th>
                <Th data-priority="1">Mã</Th>
                <Th>Code ảnh</Th>
                <Th className="col-3">Tên</Th>
                <Th>Đơn vị</Th>
                <Th>Danh mục</Th>
                {(showOriginPrice) ? <Th data-priority="1">Giá gốc</Th> : ''}
                <Th data-priority="1">Giá bán</Th>
                <Th>Giá km</Th>
                <Th data-priority="1">Hành Động</Th>
              </Tr>
            </Thead>
            <Tbody>
              <ListProductItem products={products} />
            </Tbody>
          </Table>
          <MyPagination
            startPage={additionalData.current_page ? additionalData.current_page : 1}
            totalItemsCount={additionalData.total ? additionalData.total : 5}
            itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
            callback={onChangePage}
          />
        </CardBody>
      </Card>
    </div>
  );
}

ListProduct.propTypes = {
  location: PropTypes.object,
};
export default withRouter(ListProduct);
