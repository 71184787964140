import React from "react"
import { Row, Col, Tag, Image } from "antd"
import { Link } from "react-router-dom"
import baseColumnProps from 'components/Common/Table/Column/BaseColumnProps'
import { fixImagePath } from 'Utils/global';
import getColumnSearchProps from 'components/Common/Table/Filter/PartialLoadedTableFilter'
import DeleteButtonWithConfirm from "components/Common/Button/DeleteButtonWithConfirm"
import RestoreButtonWithConfirm from "components/Common/Button/RestoreButtonWithConfirm"

export const getBaseProductColumns = (filters, callbacks) => [
  {
    title: 'Sản phẩm cơ sở',
    dataIndex: 'product_code',
    align: 'left',
    hidden: false,
    responsive: ["xs"],
    render: (text, record) => <Col>
      <Row>
        <Col span={7}>Mã SP: </Col>
        <Col>{record.product_code}</Col>
      </Row>

      <Row>
        <Col span={7}>Tên SP: </Col>
        <Col>{record.product_web_name}</Col>
      </Row>

      <Row>
        <Col span={7}>ĐVT: </Col>
        <Col>{record.unit_code}</Col>
      </Row>

      <Row>
        <Col span={7}>Nhóm SP: </Col>
        <Col>{record.web_category?.category_name}</Col>
      </Row>

      <Row>
        <Col span={7}>Thương hiệu: </Col>
        <Col>{record.brand?.brand_name}</Col>
      </Row>

      <Row>
        <Link className='text-primary' to='#' onClick={() => callbacks.onShowVariantModal(record)} >
          <i className="fas fa-list"></i>
        </Link>
        <Link className='text-info' to={"/base-products/view/" + record.id} >
          <i className="fas fa-eye"></i>
        </Link>
        <Link className='text-success' to={"/base-products/" + record.id} >
          <i className="fas fa-pen"></i>
        </Link>
      </Row>
    </Col>,
  },
  {
    ...baseColumnProps('ID', 'product_id'),
    hidden: true,
  },
  {
    ...baseColumnProps('STT', 'stt'),
    width: 80,
  },
  {
    title: 'Ảnh',
    dataIndex: 'thumb_file',
    key: 'thumb_file',
    align: 'center',
    width: '10%',
    hidden: false,
    responsive: ["md"],
    render: (thumb_file, record) => {
      const thumbFile = fixImagePath(record.thumb_file);
      const previewFile = fixImagePath(record.slide_file);
      return (
        <Image width={64} src={thumbFile} preview={{ src: previewFile }} />
      );
    }
  },
  {
    ...baseColumnProps('Mã SP', 'product_code'),
    width: 150,
    filteredValue: filters.product_code || null,
    ...getColumnSearchProps('product_code', 'mã sp'),
  },
  {
    ...baseColumnProps('Tên SP', 'product_web_name'),
    width: 150,
    filteredValue: filters.product_web_name || null,
    ...getColumnSearchProps('product_web_name', 'tên sp'),
  },
  {
    ...baseColumnProps('ĐVT', 'unit_code'),
    width: 80,
    filteredValue: filters.unit_code || null,
    ...getColumnSearchProps('unit_code', 'đvt'),
  },
  {
    ...baseColumnProps('Nhóm SP', 'web_category_name'),
    width: 150,
    filteredValue: filters.web_category_name || null,
    ...getColumnSearchProps('web_category_name', 'nhóm sp'),
  },
  {
    ...baseColumnProps('Thương hiệu', 'brand_name'),
    width: 150,
    filteredValue: filters.brand_name || null,
    ...getColumnSearchProps('brand_name', 'thương hiệu'),
  },
  {
    title: '',
    align: 'center',
    width: 120,
    hidden: false,
    responsive: ["md"],
    render: (key, record) => {
      return (
        <>
          <Link className='text-primary mr-3' to='#' onClick={() => callbacks.onShowVariantModal(record)} >
            <i className="fas fa-list" title="Xem biến thể sản phẩm"></i>
          </Link>
          <Link className='text-info mr-3' to={"/base-products/view/" + record.product_id} >
            <i className="fas fa-eye" title="Xem sản phẩm"></i>
          </Link>
          <Link className='text-success' to={"/base-products/" + record.product_id} >
            <i className="fas fa-pen" title="Sửa sản phẩm"></i>
          </Link>
        </>
      );
    },
  },
].filter(e => e.hidden == false);
