
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
} from "reactstrap"
import PropTypes from 'prop-types'

function PaymentMethodTableItem(props) {
    const { data, count,callbackDelete } = props;
    const onDelete = () => {
        callbackDelete(data.payment_code)
    }
    return (
        <tr>
            <td>{data.payment_code}</td>
            <td>{data.payment_desc}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/payment-methods/"+data.payment_code} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <Link className='text-danger' onClick={onDelete} >
                            <i className="fas fa-trash"></i>
                        </Link>
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
PaymentMethodTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    callbackDelete: PropTypes.func
}

export default PaymentMethodTableItem;
