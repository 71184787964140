import UserTableItem from 'components/TableItem/UserTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as authApi from '../../apis/authApi'
import MetaTags from 'react-meta-tags';

import { Table, Card, CardBody, CardTitle, Row, Col, Button, Input } from "reactstrap"

import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from 'Utils/UseQuery';

function ListUser(props) {

  let history = useHistory();

  const [data, setData] = useState([]);
  const [additionalData, setAdditionalData] = useState({});

  const [isShowSearch, setIsShowSearch] = useState(false);

  const [filterUsername, setFilterUsername] = useState("");
  const [filterFullname, setFilterFullname] = useState("");
  const [filterPhone, setFilterPhone] = useState("");

  useEffect(() => {
    let query = useQuery(props.location.search)

    setFilterUsername(query.get('username') ?? '')
    setFilterFullname(query.get('fullname') ?? '')
    setFilterPhone(query.get('phone') ?? '')
  }, []);

  useEffect(() => {
    handleSearch();
  }, [props.location.search])

  const handleSearch = () => {
    const params = [];
    let queryParams = useQuery(props.location.search)
    let page = queryParams.get("page");
    if (page) {
      params.push({ name: "page", value: page });
    }
    params.push({ name: "username", value: queryParams.get("username") })
    params.push({ name: "fullname", value: queryParams.get("fullname") })
    params.push({ name: "phone", value: queryParams.get("phone") })

    authApi.getListUser(params, fetchDataSuccess, fetchDataFail)
  }


  const fetchDataSuccess = res => {
    setAdditionalData(res.data.data)
    setData(res.data.data.data);
  }
  const fetchDataFail = res => {
    alert(res.message)
  }
  const showUser = () => {
    if (data.length === 0) {
      return (<tr></tr>);
    }
    return data.map((v, i) => {
      return (<UserTableItem key={i} data={v} count={i + 1} setUserStatus={setUserStatus} />)
    })
  }

  const setUserStatus = (userStatus, index) => {
    let items = [...data];
    let item = {...items[index]};
    item.status = userStatus;
    items[index] = item;
    setData(items);
  }

  const filterToQueryString = () => {
    let queryParams = [];

    queryParams.push('username=' + encodeURIComponent(filterUsername));
    queryParams.push('fullname=' + encodeURIComponent(filterFullname));
    queryParams.push('phone=' + encodeURIComponent(filterPhone));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));

    let queryString = queryParams.join('&');
    return queryString;
  }

  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString
    history.push(url);
  }

  const handleResetFilter = () => {
    setFilterUsername("");
    setFilterFullname("");
    setFilterPhone("");
  }

  const onChangePage = (page) => {
    let url = props.location.pathname + "?page=" + page;
    let queryString = filterToQueryString()
    url = url + '&' + queryString
    history.push(url);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách người dùng</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="h4">Danh mục người dùng</CardTitle>
          <Row className="justify-content-between">
            <Col xs="auto" sm="auto">
            </Col>
            <Col xs="auto" sm="auto">
              <Link to="/users/create">
                <Button color='primary' className='px-3 rounded-pill'>
                  <i className="fas fa-plus mr-2"></i> Thêm mới
                </Button>
              </Link>
            </Col>
          </Row>
          {
            isShowSearch ?
              <Row className='align-items-end'>
                <Col xs={2}>
                  <label className='body-2 font-weight-bold'>
                    Tìm theo username
                  </label>
                  <Input
                    type='text'
                    className="form-control"
                    value={filterUsername}
                    onChange={(e) => setFilterUsername(e.target.value)} />
                </Col>
                <Col xs={2}>
                  <label className='body-2 font-weight-bold'>
                    Tìm theo tên
                  </label>
                  <Input
                    type='text'
                    className="form-control"
                    value={filterFullname}
                    onChange={(e) => setFilterFullname(e.target.value)} />
                </Col>
                <Col xs={2}>
                  <label className='body-2 font-weight-bold'>
                    Tìm theo số điện thoại
                  </label>
                  <Input
                    type='text'
                    className="form-control"
                    value={filterPhone}
                    onChange={(e) => setFilterPhone(e.target.value)} />
                </Col>
                <Col xs={12} lg="auto">
                  <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                    Tìm kiếm
                  </Button>
                  <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                    Reset tìm kiếm
                  </Button>
                </Col>
              </Row>
              : null
          }
          <div className='tag color-primary font-weight-bold mt-3'
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => setIsShowSearch(!isShowSearch)}>
            {
              isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
            }
            {
              isShowSearch ?
                <i className="bx bx bx-chevron-up ml-2" />
                : <i className="bx bx bx-chevron-down ml-2" />
            }
          </div>
          <div className="table-responsive">
            <Table className="table mb-3">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Username</th>
                  <th>Tên NV</th>
                  <th>Địa chỉ</th>
                  <th>Điện thoại</th>
                  <th>Email</th>
                  <th>Vai trò</th>
                  <th>Hành Động</th>
                </tr>
              </thead>
              <tbody>
                {showUser()}
              </tbody>
            </Table>
          </div>
          <MyPagination
            startPage={additionalData.current_page ? additionalData.current_page : 1}
            totalItemsCount={additionalData.total ? additionalData.total : 5}
            itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
            callback={onChangePage} />


        </CardBody>
      </Card>
    </div>
  )
}
ListUser.propTypes = {
  location: PropTypes.object,
}


export default withRouter(ListUser)
