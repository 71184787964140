import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import { limitString } from "constants/function";
import { API_HOST_NAME } from "constants/SystemConstant";
import { Popconfirm } from "antd";

function FooterTableItem(props) {
  const { data, count, deleteCallback, restoreCallback } = props;
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  const onDelete = () => {
    deleteCallback(data.id);
  };

  const onRestore = () => {
    restoreCallback(data.id);
  };
  return (
    <tr>
      <td>{count}</td>
      <td>{data.name}</td>
      <td>{data.menu_order}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/footer/edit/${data.id}`}>
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
            <Popconfirm
              placement="top"
              title={"Bạn có muốn xóa"}
              onConfirm={() => onDelete()}
              okText="Yes"
              cancelText="No"
            >
              <i className="fas fa-trash"></i>
            </Popconfirm>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
FooterTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default FooterTableItem;
