import React from 'react';
import ProductImage from '../../images/prod-example.png';
import PropTypes from 'prop-types';


function ImageItemMulti(props) {
    let {data,onDelete,disabled,onView} = props;
    
    return (
        <div className='image-holder' >
            <div className='ratio ratio-1x1'>
                <img src={data} onClick={onView}/>
                {disabled ? '' : (
                    <div className='delete-img position-absolute' onClick={onDelete}>
                        <i className="fas fa-times-circle"></i>
                    </div>
                )}

            </div>
        </div>
    );
}

ImageItemMulti.propTypes = {
    data: PropTypes.any,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
    onView:PropTypes.func
}

ImageItemMulti.defaultProps = {
    data: [],
    onDelete: null,
    disabled: false,
    onView:null
}
export default ImageItemMulti;
