import React from "react"
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from "antd";

export const layout = {
  labelCol: {
    xs: { span: 24 }, sm: { span: 3 }
  },
  wrapperCol: {
    xs: { span: 24 }, sm: { span: 21 }
  },
};

export const tailLayout = {
  wrapperCol: {
    xs: { span: 24 }, sm: { span: 21, offset: 3 }
  },
};

export const validateMessages = {
  required: 'Thiếu ${label}!',
  types: {
    email: '${label} không đúng định dạng!',
    number: '${label} không phải là số hợp lệ!',
  },
  number: {
    range: '${label} phải nằm trong khoảng ${min} và ${max}',
  },
};

export const uploadImageProps = {
  listType: "picture-card",
  beforeUpload: (file) => {
    const isOk = ((file.type === 'image/png') || (file.type === 'image/jpeg'));
    if (!isOk) {
      message.error(`${file.name} is not a png/jpg file`);
    }
    return isOk || Upload.LIST_IGNORE;
  },
};
