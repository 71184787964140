import React from 'react';
import { Col, Row } from 'reactstrap';
import ImageItem from './ImageItem';
import PropTypes from 'prop-types';
import { API_HOST_NAME } from './../../constants/SystemConstant';
import { utils } from 'Utils/utils';
import ImageItemMulti from './ImageItemMulti';

ImagesStoreMulti.propTypes = {
    data: PropTypes.array,
    addData: PropTypes.array,
    addImageCallback: PropTypes.func,
    deleteAddImg: PropTypes.func,
    deleteImg: PropTypes.func,
    disabled: PropTypes.bool,
    onView:PropTypes.func,
}

ImagesStoreMulti.defaultProps = {
    data:[],
    addData: [],
    addImageCallback: null,
    deleteAddImg: null,
    deleteImg: null,
    disabled: false,
    onView:null
}

function ImagesStoreMulti(props) {
    let { onView} = props;
    let data = props.data;
    let disabled = props.disabled;
    let addData = props.addData;
    let addImageCallback = props.addImageCallback;
    let deleteAddImgCallback = props.deleteAddImg;
    let deleteDataCallback = props.deleteImg;
  
    const showListImage = () => {
        if (data.length == 0) return;
        return data.map((v, i) => {
            return (
                <Col xs="auto" key={i}>
                    <ImageItemMulti
                        data={v.image_file.startsWith('http') ? v.image_file : API_HOST_NAME + v.image_file} 
                        disabled={disabled} 
                        onDelete={() => onDeleteImg(v.image_file)} 
                        onView={() =>{onView(v)}}
                    />
                </Col>
            )
        })
    }

    const onDeleteImg = (fileName) => {
        console.log("onDeleteImg:"+fileName);
        if(deleteDataCallback)deleteDataCallback(fileName)
    }

    const showListAddImage = () => {
        try{
            if (utils.isEmpty(addData) || addData.length == 0) return;
            return addData.map((v, i) => {
                let src = URL.createObjectURL(v)
                return (
                    <Col xs="auto" key={i}>
                        <ImageItemMulti  onView={() =>{onView(v)}} data={src} disabled={disabled} onDelete={() => deleteAddImg(v)} />
                    </Col>
                )
            })
        }catch(ex){
            
        }
        
    }

    const deleteAddImg = (addImg) => {
        if(deleteAddImgCallback)deleteAddImgCallback(addImg)
    }

    const onAddImage = (e) => {        
        if(addImageCallback)addImageCallback(e.target.files)
    }

    return (
        <div className='border-1 rounded border-grey border-solid'>
            <Row>
                <Col xs={12} md="auto">
                    {!disabled &&
                        <div className='p-3 py-5'>
                            <div className='text-center'>
                                <label htmlFor='upload-image-for-store' className='d-block image-holder m-auto border-1 rounded border-dashed mb-3 border-primary cursor-pointer color-primary'>
                                    <div className='ratio-1x1 ratio'>
                                        <div className='position-absolute center d-flex justify-content-center align-items-center'>+</div>
                                    </div>
                                </label>
                                <label htmlFor='upload-image-for-store' className='btn btn-primary '>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-upload mr-2"></i>Tải ảnh lên
                                    </div>
                                </label>
                            </div>
                            <input type="file" multiple="multiple" accept="image/*" id="upload-image-for-store" onChange={onAddImage} disabled={disabled} className='d-none' />
                        </div>
                    }                    
                </Col>
                <Col xs={12} md>
                    <div className='p-3'>
                        <Row>
                            {showListImage()}
                            {showListAddImage()}
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ImagesStoreMulti;
