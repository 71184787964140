import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import { limitString } from "constants/function";
import { API_HOST_NAME } from "constants/SystemConstant";

function FeedbackTableItem(props) {
  const { data, count, deleteCallback, restoreCallback } = props;
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  const onDelete = () => {
    deleteCallback(data.id);
  };

  const onRestore = () => {
    restoreCallback(data.id);
  };

  return (
    <tr>
      <td>{count}</td>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{limitString(data.feedback, 90)}</td>
      <td>{moment(data.created_at).format("DD/MM/YYYY")}</td>
      <td>
        <Col xs="auto" sm="auto">
          <Link className="text-alert" to={`/feedback/view/${data.id}`}>
            <i className="fas fa-eye"></i>
          </Link>
        </Col>
      </td>
    </tr>
  );
}
FeedbackTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default FeedbackTableItem;
