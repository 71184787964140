
const axios = require('axios');
import loadingProcessor from '../processor/LoadingProcessor';
import { API_HOST_NAME } from './../constants/SystemConstant';
import { isTokenExpired } from './../Utils/SecurityUtils';
import history from '../history';
function delay(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
}

// interceptors for request
// filter token before request
axios.interceptors.request.use(async config => {
    // Do something before request is sent
    loadingProcessor.addLoading()
    // await delay(2000)

    let url = config.url;
    if (url != API_HOST_NAME + "/api/auth/login" && url != API_HOST_NAME + "/api/auth/refresh") {
        if (isTokenExpired()) {
            // if (!localStorage.getItem('refresh-token')) {
            //     history.push("/logout")
            //     return;
            // }
            let headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            };
            let param = {
                refresh_token: localStorage.getItem('refresh-token')
            }
            let res = await axios({
                method: 'get',
                url: API_HOST_NAME + "/api/auth/refresh",
                headers: headers,
                params: param
            })
            localStorage.setItem("authUser", JSON.stringify(res.data.user))
            localStorage.setItem('token', res.data.access_token)
            localStorage.setItem('refresh-token', res.data.refresh_token)
            config.headers.Authorization = 'Bearer ' + res.data.access_token
        }
    }
    return config;
}, function (error) {
    // Do something with request error
    loadingProcessor.removeLoading()

    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    loadingProcessor.removeLoading()
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    loadingProcessor.removeLoading()
    // trường hợp refresh token trả về 401 vì đã đăng nhập ở thiết bị khác hoặc do refresh token có vấn đề
    if (error.response.status == 401) {
        // trường hợp nhập sai tên đăng nhập/mật khẩu
        if (error.response.config.url.includes('auth/login')) {
            return Promise.reject(error);
        } else {
            //place your reentry code
            localStorage.clear()
            history.push("/logout")
            return Promise.resolve(error)
        }
    }
    if (error.response.config.url == API_HOST_NAME + "/api/auth/refresh") {
        localStorage.clear()
        history.push("/logout")
        return Promise.resolve(error)
    }
    return Promise.reject(error);
})
/**
 *
 * @param {*} url
 * @param {*} token if token != '' then header has attibute Authorization = 'Bearer '+token
 * @param {*} params = [{name:'name',value:'value'}]
 * @param {*} callback = (res) => {}
 * @param {*} callbackError = (res) => {}
 */

export const GET = (url, token, params, callback=()=>{}, callbackError=()=>{}) => {

    // let isLoading = useSelector(state => state.isLoading)
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };
    if (token !== '') {
        headers.Authorization = 'Bearer ' + token;
    }
    let paramsObj = {}

    // params = [{name,value}]
    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
            // url += '?' + new URLSearchParams(paramsObj);
        }
    }
    axios({
        method: 'get',
        url: url,
        headers: headers,
        params: paramsObj
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
export const GETV2 = async (url, token, params) => {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
        };
        if (token !== '') {
            headers.Authorization = 'Bearer ' + token;
        }
        let paramsObj = {}

        // params = [{name,value}]
        if (params) {
            if (params.length > 0) {
                params.forEach((param, index) => {
                    paramsObj[param.name] = param.value;
                })
                // url += '?' + new URLSearchParams(paramsObj);
            }
        }
        axios({
            method: 'get',
            url: url,
            headers: headers,
            params: paramsObj
        }).then(responseData => {
            resolve(responseData);
        }).catch(error => {
            reject(error?.response?.data);
        });
    })
}
export const GET_ARR_BUFFER = (url, token, params, callback, callbackError) => {

    // let isLoading = useSelector(state => state.isLoading)
    let headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };
    if (token !== '') {
        headers.Authorization = 'Bearer ' + token;
    }
    let paramsObj = {}

    // params = [{name,value}]
    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
            // url += '?' + new URLSearchParams(paramsObj);
        }
    }
    axios({
        method: 'get',
        url: url,
        headers: headers,
        params: paramsObj,
        responseType: "arraybuffer",
        responseEndcoding: "binary"
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
export const DELETE = (url, body, token, params, callback, callbackError) => {

    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    if (token !== '') {
        headers.Authorization = 'Bearer ' + token;
    }
    let data = (body) ? body : {};
    let paramsObj = {}

    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
        }
    }
    axios({
        method: 'delete',
        url: url,
        headers: headers,
        params: paramsObj,
        data: data
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
export const DELETEV2 = (url, body, token, params) => {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
        if (token !== '') {
            headers.Authorization = 'Bearer ' + token;
        }
        let data = (body) ? body : {};
        let paramsObj = {}

        if (params) {
            if (params.length > 0) {
                params.forEach((param, index) => {
                    paramsObj[param.name] = param.value;
                })
            }
        }
        axios({
            method: 'delete',
            url: url,
            headers: headers,
            params: paramsObj,
            data: data
        }).then(responseData => {
            resolve(responseData);
        }).catch(error => {
            reject(error?.response?.data);
        });
    });
}
export const POSTV2 = (url, body, token, params, callback, callbackError) => {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
        if (token !== '') {
            headers.Authorization = 'Bearer ' + token;
        }
        let data = (body) ? body : {};
        let paramsObj = {}
        if (params) {
            if (params.length > 0) {
                params.forEach((param, index) => {
                    paramsObj[param.name] = param.value;
                })
            }
        }
        axios({
            method: 'post',
            url: url,
            headers: headers,
            params: paramsObj,
            data: data
        }).then(responseData => {
            resolve(responseData);
        }).catch(error => {
            reject(error?.response?.data);
        });
    });
}
export const POSTV3 = async (url, body, token, params) => {
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };
  if (token !== '') {
      headers.Authorization = 'Bearer ' + token;
  }
  let data = (body) ? body : {};
  let paramsObj = {}
  if (params) {
      if (params.length > 0) {
          params.forEach((param, index) => {
              paramsObj[param.name] = param.value;
          })
      }
  }
  return await axios({
      method: 'post',
      url: url,
      headers: headers,
      params: paramsObj,
      data: data
  })
  .then(responseData => responseData)
  .catch(error => error);
}

export const PUTV2 = (url, body, token, params, callback, callbackError) => {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
        if (token !== '') {
            headers.Authorization = 'Bearer ' + token;
        }
        let data = (body) ? body : [];
        let paramsObj = {}
        if (params) {
            if (params.length > 0) {
                params.forEach((param, index) => {
                    paramsObj[param.name] = param.value;
                })
            }
        }
        axios({
            method: 'put',
            url: url,
            headers: headers,
            params: paramsObj,
            data: data
        }).then(responseData => {
            resolve(responseData);
        }).catch(error => {
            reject(error?.response?.data);
        });
    });
}
export const POST = (url, body, token, params, callback, callbackError) => {

    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    if (token !== '') {

        headers.Authorization = 'Bearer ' + token;
    }
    let data = (body) ? body : {};
    let paramsObj = {}
    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
        }
    }
    axios({
        method: 'post',
        url: url,
        headers: headers,
        params: paramsObj,
        data: data
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
export const POST_FILE = (url, formData, token, params, callback, callbackError) => {
    let headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    };
    if (token !== '') {
        headers.Authorization = 'Bearer ' + token;
    }
    let paramsObj = {}
    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
        }
    }
    axios({
        method: 'post',
        url: url,
        headers,
        params: paramsObj,
        data: formData
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
export const PUT = (url, body, token, params, callback, callbackError) => {


    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    if (token !== '') {

        headers.Authorization = 'Bearer ' + token;
    }
    let data = (body) ? body : [];
    let paramsObj = {}
    if (params) {
        if (params.length > 0) {
            params.forEach((param, index) => {
                paramsObj[param.name] = param.value;
            })
        }
    }
    axios({
        method: 'put',
        url: url,
        headers: headers,
        params: paramsObj,
        data: data
    }).then(responseData => {
        callback(responseData);
    }).catch(error => {
        if (callbackError && error.response) {
            callbackError(error.response.data)
        }
    });
}
