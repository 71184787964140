
import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getList = (companyId, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/brands';
  let params = [{ name: 'company_id', value: companyId }]
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr);
}

export const create = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/brands';
  let token = localStorage.getItem("token")
  commonApis.POST(url, data, token, '', callback, callbackErr)
}

export const del = async (id) => {
  let url = API_HOST_NAME + '/api/brands/' + id;
  let token = localStorage.getItem("token")
  return commonApis.DELETEV2(url, '', token, [])
}

export const update = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/brands/' + id;
  let token = localStorage.getItem("token")
  commonApis.PUT(url, data, token, [], callback, callbackErr)
}