import React, { useState } from "react"
import { Row, Col, Label, Input, FormGroup } from "reactstrap"
import { PropTypes } from 'prop-types';

FilterCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
}

function FilterCheckbox({ label, onChange }) {

  return (
    <Row xl={12} className="mb-2">
      <Label md={0} xl={2} className="col-form-label"> </Label>
      <Col md={12} xl={10}>
        <FormGroup check>
          <Input
            type='checkbox'
            onChange={(e) => onChange(e.target.checked ? 1 : 0)}
            className="form-control" />
          {' '}
          <Label check>{label}</Label>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default FilterCheckbox;