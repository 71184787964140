
import React, { useEffect, useRef, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import {Table,Card,CardBody,CardTitle, Row,Col,Button} from "reactstrap"
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from 'Utils/UseQuery';
import UserPermissionItem from 'components/TableItem/UserPermissionItem';
import * as permissionApi from '../../apis/permissionApi';
import AlertSimple from 'components/Common/AlertSimple';
import MetaTags from 'react-meta-tags';


function permission(props) {
    const [data, setData] = useState([])
    const [additionalData, setAdditionalData] = useState({});
    const [listRole,setListRole] = useState([]);
    const [role,setRole] = useState([]);
    const [activeUser, setActiveUser] = useState(0);
    const userChange = useRef({});
    let history = useHistory();
    const [showMessage, setShowMessage] = useState({
        state:false,
        message:"",
        title:"",
        typeShow:'success'
    });

    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }, [props.location.search]);

    const fetchData = page => {
        permissionApi.getUsers(page, fetchDataSuccess, fetchDataFail)
    }
    
    const fetchDataSuccess = res => {
        let result = res.data.data;
        setAdditionalData(result)
        setData(result.data);
        
        getUserFirst(result.data[0].user_id)
    }

    const getUserFirst = async(id) =>{
        userChange.current = id;
        setActiveUser(id);

        permissionApi.getRolesUser(id, onUserFirstSuccess,fetchDataFail);
    }

    const onUserFirstSuccess = (res) =>{
        let all_role = res.data.all_roles;
        let role_us = res.data.data;
        let result = [];

        all_role.forEach(it =>{
            let index = role_us.findIndex(x => x.role_id == it.role_id);            
            it['checked'] = (index >= 0);   
            result.push(it);   
        });
        setRole(result);
        setListRole(all_role);

    }
    
    const fetchDataFail = res => {
        setShowMessage({state:true,message:res.message,title:"Thất bại",typeShow:'danger'}); 
    }

    const showUser = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        
        return data.map((v, i) => {
            let activeClass = (activeUser === v.user_id) ? 'active' : '';
            return (<UserPermissionItem 
                key={i} 
                data={v} 
                count={i + 1} 
                activeClass={activeClass}
                onUserChange={onUserChange} 
            />)
        })
    }

    const onUserChange = (e, item) =>{
        if(e)e.preventDefault();
        userChange.current = item;
        setActiveUser(item.user_id);

        permissionApi.getRolesUser(item.user_id, onRoleUSerSuccess,fetchDataFail);
    }

    const onRoleUSerSuccess = (res) =>{
        let all_role = [... listRole];
        let role_us = res.data.data;
        let result = [];

        all_role.forEach(it =>{
            let index = role_us.findIndex(x => x.role_id == it.role_id);            
            it['checked'] = index >= 0;   
            result.push(it);         
        });

        setRole(result)

    }

    const onCheckChange = (e,v) =>{
        let check = e.target.checked;
        let role_vl = [... role];
        role_vl.forEach(it =>{
            if(it.role_id == v.role_id)it.checked = check
        })

        setRole(role_vl);
    }
    const onChangePage = (page) => {
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }


    const renderUser = () =>{
        return(
            <>
                <div className="table-responsive">
                    <Table className="table mb-3">
                        <thead>
                            <tr>
                                <th>Tên người dùng</th>                           
                            </tr>
                        </thead>
                        <tbody>
                            {showUser()}
                        </tbody>
                    </Table>
                </div>

                <MyPagination 
                    startPage={additionalData.current_page ? additionalData.current_page : 1} 
                    totalItemsCount={additionalData.total ? additionalData.total : 5} 
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
                    callback={onChangePage}
                    />  
            </>

        )
    }

    const renderRoles = () =>{
        if (role.length === 0) return (<tr></tr>)
        return role.map((v, i) => {
            return (                
                <tr key={i}>
                    <th>{v.role_name}</th>
                    <td>
                        <input
                            type="checkbox"
                            value={v.role_id}    
                            checked={v.checked}                            
                            onChange={(e) =>{onCheckChange(e,v)}}
                        />
                    </td>
                </tr>
                
                                 
            )
        });
    }

    const onSubmit = (e) =>{
        e.preventDefault();
        
        let listRole =[];
        role.forEach(it =>{
            if(it.checked)listRole.push(it.role_id);
        })

        let params={
            role_ids:listRole
        }

        permissionApi.update(activeUser,params,onUpdateSuccess,fetchDataFail);
    }

    const onUpdateSuccess = (res) =>{
        setShowMessage({state:true, message:"",title:"Cập nhật thành công" ,typeShow:'success'});
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Phân quyền</title>
            </MetaTags>
            {showMessage.state ? 
                <AlertSimple 
                    message={showMessage.message}
                    type={showMessage.typeShow}
                    title={showMessage.title}
                    confirmCallback={() => {
                        setShowMessage({state:false});
                    }}
                />
                : null
            }
            <Card>
                <CardBody>
                    <CardTitle className="h4">Phân quyền cho người dùng</CardTitle>
                    
                    <div className='my-grid grid-2-column-minmax-300-400 d-grid' style={{ alignItems: "baseline" }}>
                    
                            <div>
                                <CardTitle className="mb-4"></CardTitle>
                                <Card outline color="grey" className='py-4' style={{ border: '1px solid' }}>
                                    {renderUser()}
                                </Card>
                            </div>
                            <div>
                                <Table className="table table-striped table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Nhóm quyền</th>
                                            <th className='text-center'>Cho phép</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderRoles()}
                                    </tbody>
                                </Table>
                                {activeUser != 0 ?
                                    <div className='mt-4'>
                                        <div className='d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right'>                                          
                                            <div className=''>                                                
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-primary w-md" 
                                                    onClick={(e) =>{onSubmit(e)}}
                                                >
                                                    Cập nhật
                                                </button>
                                            </div>                                    
                                        </div>
                                    </div>
                                    :null
                                }
                                
                                                                                               
                            </div>
                        </div>
                        


                </CardBody>
            </Card>
        </div>
    )
}
permission.propTypes = {
    location: PropTypes.object,
}


export default withRouter(permission)
