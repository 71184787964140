
import * as commonApis from './commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';


export const getAll = (selectedShop, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/all-attributes"
    if (!selectedShop) return;
    let body = {
        company_id: selectedShop
    }

    let token = localStorage.getItem("token")
    commonApis.POST(url,body, token, "", callback, callbackErr)
}

export const getAllByCategoryId = (catId, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/all-category-attributes"
    if (!catId) return;
    let body = {
        "category_id":catId
    };
    let token = localStorage.getItem("token")
    commonApis.POST(url,body, token, "", callback, callbackErr)
} 