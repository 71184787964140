import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import { limitString } from "constants/function";
import { API_HOST_NAME } from "constants/SystemConstant";

function SupportRequestTableItem(props) {
  const { data, count, deleteCallback, restoreCallback } = props;
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  const onDelete = () => {
    deleteCallback(data.id);
  };

  const onRestore = () => {
    restoreCallback(data.id);
  };

  return (
    <tr>
      <td>{count}</td>
      <td>{data.phone}</td>
      <td>{data.request_times ? moment(data.last_request).format("DD/MM/YYYY") : moment(data.created_at).format("DD/MM/YYYY")}</td>
      <td>{data.request_times}</td>
    </tr>
  );
}
SupportRequestTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default SupportRequestTableItem;
