import store from '../store/index';
import { addLoading,removeLoading } from './../store/loading/actions';
class LoadingProcessor {
    #loadingArr;
    constructor() {
        this.#loadingArr = []
    }
    addLoading = () => {
        this.#loadingArr.push(true);
        store.dispatch(addLoading())
    }
    removeLoading = () => {
        if (this.#loadingArr.length > 0) {
            this.#loadingArr.pop()
        }
        if (this.#loadingArr.length === 0) {
            store.dispatch(removeLoading())
        }
    }
    
}
export default new LoadingProcessor();