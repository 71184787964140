const INIT_STATE = 0
import { CHANGE_SELECTED_SHOP } from './actionTypes';

const SelectedShop = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_SHOP:
            return action.payload;
        default:
            return state
    }
}
export default SelectedShop;