import React, { useEffect, useState } from "react";
import MyPagination from "../../../components/Common/MyPagination";
import * as contactApi from "../../../apis/contactApi";
import { Table, Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ConfirmDelete from "components/Common/ConfirmDelete";
import ConfirmRestore from "components/Common/ConfirmRestore";
import "flatpickr/dist/themes/material_blue.css"
import ContactTableItem from "components/TableItem/ContactTableItem";
import MetaTags from 'react-meta-tags';


function ListShopAddress(props) {
  const [deleteId, setDeleteId] = useState(null);
  const [restoreId, setRestoreId] = useState(null);
  const selectedShop = useSelector(state => state.SelectedShop);
  const [dataContact, setDataContact] = useState({})
  let history = useHistory();
  const showProductCatItem = () => {
    if (dataContact) {
      if (dataContact?.total === 0) {
        return <tr></tr>;
      } else {
        return dataContact?.data && dataContact?.data.map((v, i) => {
          return (
            <ContactTableItem
              key={i}
              data={v}
              count={i + 1}
              deleteCallback={setDeleteId}
              restoreCallback={setRestoreId}
            />
          );
        });
      }
    }
  };

  const onChangePage = page => {
    let url = props.location.pathname + "?page=" + page;
    history.push(url);
  };
  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = page => {
    contactApi.getListContact(fetchDataSuccess, fetchDataFail);
  };
  const fetchDataSuccess = res => {
    const { data } = res.data;
    setDataContact(data)
  };
  const fetchDataFail = res => {
    alert(res.message);
  };

  const onDelete = id => {
  };

  return (
    <div className="page-content">
    <MetaTags>
        <title>CMS | Địa chỉ liên hệ</title>
    </MetaTags>
      {deleteId ? (
        <ConfirmDelete
          confirmCallback={() => {
            onDelete(deleteId);
          }}
          cancelCallback={() => {
            setDeleteId(null);
          }}
        />
      ) : (
        ""
      )}
      {restoreId ? (
        <ConfirmRestore
          confirmCallback={() => {
            onRestore(restoreId);
          }}
          cancelCallback={() => {
            setRestoreId(null);
          }}
        />
      ) : (
        ""
      )}
      <Card>
        <CardBody>
          <Card color='light'>
            <CardBody>
              <Row className="justify-content-between">
                <Col xs="auto" sm="auto"><CardTitle className="h4">Danh sách địa chỉ shop</CardTitle></Col>
                <Col xs="auto" sm="auto">
                  <Link to="/contact/create">
                    {" "}
                    <Button color="primary" className="px-3 rounded-pill">
                      <i className="fas fa-plus mr-2"></i> Thêm mới
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="table-responsive">
            <Table className="table mb-3 mytable">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên CH</th>
                  <th>Địa chỉ</th>
                  <th>Số liên hệ</th>
                  <th>Google map</th>
                  <th>Tình trạng</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>{showProductCatItem()}</tbody>
            </Table>
          </div>
          <MyPagination
            startPage={dataContact.current_page ? dataContact.current_page : 1}
            totalItemsCount={dataContact.total ? dataContact.total : 5}
            itemsCountPerPage={dataContact.per_page ? dataContact.per_page : 10}
            callback={onChangePage}
          />
        </CardBody>
      </Card>
    </div>
  );
}
ListShopAddress.propTypes = {
  location: PropTypes.object,
};

export default withRouter(ListShopAddress);
