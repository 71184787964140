import { Button, Col, Image, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from 'prop-types';
import { fixImagePath, showMessage } from "Utils/global";
import * as productApi from "../../apis/productApi";
import { Link } from "react-router-dom";

ListVariant.propTypes = {
  visible: PropTypes.bool,
  baseProduct: PropTypes.object,
  onClose: PropTypes.func,
};

const variantColumns = [
  {
    title: 'Sản phẩm',
    dataIndex: 'product_id',
    align: 'left',
    hidden: false,
    responsive: ["xs"],
    render: (text, record) => <Col>
      <Row>
        <Col span={7}>Mã SP: </Col>
        <Col>{record.product_code}</Col>
      </Row>

      <Row>
        <Col span={7}>Tên SP: </Col>
        <Col>{record.product_web_name}</Col>
      </Row>

      <Row>
        <Col span={7}>ĐVT: </Col>
        <Col>{record.unit_code}</Col>
      </Row>

      <Row>
        <Col span={7}>Nhóm kho: </Col>
        <Col>{record.category.category_name}</Col>
      </Row>

      <Row>
        <Col span={7}>Nhóm web: </Col>
        <Col>{record.web_category.category_name}</Col>
      </Row>

      <Row>
        <Col span={7}>Thương hiệu: </Col>
        <Col>{record.brand.brand_name}</Col>
      </Row>

      <Row>
        <Link className='text-success' to={"/products/" + record.product_id} >
          <i className="fas fa-pen"></i>
        </Link>
      </Row>
    </Col>,
  },
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 50,
    hidden: false,
    responsive: ["md"],
  },
  {
    title: 'Ảnh',
    dataIndex: 'thumb_file',
    key: 'thumb_file',
    align: 'center',
    width: 100,
    hidden: false,
    responsive: ["md"],
    render: (_, record) => {
      const thumbFile = fixImagePath(record.first_image?.thumb_file);
      const previewFile = fixImagePath(record.first_image?.slide_file);
      return (
        <Image
          width={64}
          src={thumbFile}
          preview={{ src: previewFile }}
        />
      );
    }
  },
  {
    title: 'Mã SP',
    dataIndex: 'product_code',
    key: 'product_code',
    align: 'center',
    width: 150,
    hidden: false,
    responsive: ["md"],
  },
  {
    title: 'Tên web',
    dataIndex: 'product_web_name',
    key: 'product_web_name',
    width: 150,
    hidden: false,
    responsive: ["md"],
  },
  {
    title: 'Tên kho',
    dataIndex: 'product_name',
    key: 'product_name',
    hidden: false,
    responsive: ["md"],
  },
  {
    title: 'ĐVT',
    dataIndex: 'unit_code',
    key: 'unit_code',
    align: 'center',
    width: 80,
    hidden: false,
    responsive: ["md"],
  },
  {
    title: 'Nhóm kho',
    dataIndex: 'category_id',
    key: 'category_id',
    align: 'left',
    width: 100,
    hidden: false,
    responsive: ["md"],
    render: (el, record) => record.category?.category_name,
  },
  {
    title: 'Nhóm web',
    dataIndex: 'web_category_id',
    key: 'web_category_id',
    align: 'left',
    width: 150,
    hidden: false,
    responsive: ["md"],
    render: (el, record) => record.web_category?.category_name,
  },
  {
    title: 'Thương hiệu',
    dataIndex: 'brand_name',
    key: 'brand_name',
    align: 'left',
    width: 160,
    hidden: false,
    responsive: ["md"],
    render: (el, record) => record.brand?.brand_name,
  },
  {
    title: '',
    align: 'center',
    width: 50,
    hidden: false,
    responsive: ["md"],
    render: (key, record) => {
      return (
        <>
          <Link className='text-success' to={"/products/" + record.product_id} >
            <i className="fas fa-pen" title="Sửa sản phẩm"></i>
          </Link>
        </>
      );
    },
  },
]

function ListVariant({ visible, baseProduct, onClose }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {    
    if (!visible) return;
    if (baseProduct?.product_code == '') return;
    productApi.getProductsByCodeAnh(baseProduct.product_code, onFetchProductsSuccess, showMessage);
  }, [visible]);

  const onFetchProductsSuccess = (res) => {
    let lp = res.data.data.map((item, index) => { 
      return { ...item, 
        key: item.product_id, 
        stt: index + 1 }
    })
    setProducts(lp);
  }

  const closeModal = () => {
    setProducts([]);
    onClose();
  }

  return (
    <Modal isOpen={visible} toggle={onClose} centered={true}>
      <ModalHeader>
        Danh sách biến thể sản phẩm
      </ModalHeader>
      <ModalBody>
        <Table className="mytable"
          size="small"
          dataSource={products}
          columns={variantColumns}
          pagination={false} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export default ListVariant;