import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

export const webOrderColumns = [
  {
    title: "Số HĐ",
    dataIndex: "order_number",
    key: "order_number",
  },
  {
    title: "Ngày",
    dataIndex: "order_date",
    key: "order_date",
  },
  {
    title: "Khách",
    dataIndex: "receipt_name",
    key: "receipt_name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Tổng SL",
    dataIndex: "total_product",
    key: "total_product",
  },
  {
    title: "Tổng tiền",
    dataIndex: "total_amount",
    key: "total_amount",
  },
  {
    title: "Tình trạng",
    dataIndex: "process_status",
    key: "process_status",
    render: (status) => {
      const statusText = (status) => {
        switch (status) {
          case 0:
            return 'Đã hủy';
          case 1:
            return 'Đang xử lý';
          case 0:
            return 'Đã xử lý';
          default:
            return 'Không xác định';
        }
      }
      return (<>{statusText(status)}</>);
    },
  },
  {
    title: 'Hành động',
    width: '8%',
    align: 'center',
    hidden: false,
    render: (key, record) => {
      return (
        <Row>
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/web-orders/view/${record.id}`}>
              <i className="fas fa-eye"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
            <Link className='text-success' to={"/web-orders/" + record.id} >
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
        </Row>
      );
    },
  },
];