import PaymentMethodTableItem from 'components/TableItem/PaymentMethodTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as paymentMethodApi from '../../apis/paymentMethodApi';
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap"

import { Link, useHistory, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from 'Utils/UseQuery';
import ConfirmDelete from './../../components/Common/ConfirmDelete';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';
import { showMessage } from 'Utils/global';


function ListPaymentMethod(props) {
  const [data, setData] = useState([])
  const [additionalData, setAdditionalData] = useState({})
  const [deleteCode, setDeleteCode] = useState(null);
  let history = useHistory();
  useEffect(() => {
    let page = useQuery(props.location.search).get('page');
    fetchData(page);
  }, [props.location.search])
  const fetchData = page => {
    paymentMethodApi.getList(page, fetchDataSuccess, showMessage)
  }
  const fetchDataSuccess = res => {
    setAdditionalData(res.data.data)
    setData(res.data.data.data);
  }
  const showPaymentMethods = () => {
    if (data.length === 0) {
      return (<tr></tr>);
    }
    return data.map((v, i) => {
      return (<PaymentMethodTableItem key={v.code} data={v} count={i + 1} callbackDelete={setDeleteCode} />)
    })
  }
  const onDelete = (code) => {
    paymentMethodApi.deletePaymentMethod(code, deleteSuccess, deleteFail)
  }
  const deleteSuccess = (res) => {
    alert("Xóa thành công")
    setDeleteCode(null)
    let page = useQuery(props.location.search).get('page');
    fetchData(page);
  }
  const deleteFail = (res) => {
    alert(getMessage(res.message))
    setDeleteCode(null)
  }
  const onChangePage = (page) => {
    let url = props.location.pathname + '?page=' + page;
    history.push(url);
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách phương thức thanh toán</title>
      </MetaTags>
      {deleteCode ?
        <ConfirmDelete
          confirmCallback={() => {
            onDelete(deleteCode)
          }}
          cancelCallback={() => {
            setDeleteCode(null)
          }}
        /> : ''}
      <Card>
        <CardBody>
          <CardTitle className="h4">Danh mục phương thức thanh toán</CardTitle>
          <Row className="justify-content-between">
            <Col xs="auto" sm="auto">

            </Col>
            <Col xs="auto" sm="auto">
              <Link to="/payment-methods/create">
                <Button color='primary' className='px-3 rounded-pill'>
                  <i className="fas fa-plus mr-2"></i> Thêm mới
                </Button>
              </Link>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table className="table mb-3">
              <thead>
                <tr>
                  <th>Mã phương thức thanh toán</th>
                  <th>Tên phương thức thanh toán</th>
                  <th>Hành Động</th>
                </tr>
              </thead>
              <tbody>
                {showPaymentMethods()}
              </tbody>
            </Table>
          </div>
          <MyPagination
            startPage={additionalData.current_page ? additionalData.current_page : 1}
            totalItemsCount={additionalData.total ? additionalData.total : 5}
            itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
            callback={onChangePage}
          />
        </CardBody>
      </Card>
    </div>
  )
}
ListPaymentMethod.propTypes = {
  location: PropTypes.object,
}


export default withRouter(ListPaymentMethod);
