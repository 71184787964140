import React, { useEffect, useState, useRef, useCallback } from "react";
import * as billApi from "../../apis/billApi";
import { CacheUtils } from 'Utils/CacheUtils';
import * as global from 'Utils/global';
import { AutoSuggest } from "react-autosuggestions";
import * as moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  Form,
} from "reactstrap";
import MetaTags from 'react-meta-tags';

import { inUserGroup } from "../../helpers/function";
import { isUserInGroup } from "Utils/SecurityUtils";

const initialProductState = {
  product_name: "",
  warehouse_code: "",
  product_id: "",
  product_code: "",
  unit_code: "",
  qty: 0,
  original_price: 0,
  price: 0,
  amount: 0,
  discount: 0,
  final_amount: 0,
  stock: 0,
  sale_detail_id: 0,
  web_order_detail_id: 0,
}

function Order(props) {
  let history = useHistory();
  const location = useLocation();
  const [orderId, setOrderId] = useState("");

  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  const selectedShop = useSelector(state => state.SelectedShop);

  const [dataPaymentMethods, setDataPaymentMethods] = useState([]);
  const [dataSaleMans, setDataSaleMans] = useState([]);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataProductFilter, setDataProductFilter] = useState([]);
  const [user, setUser] = useState();
  const [dataProductFilterRender, setDataProductFilterRender] = useState(initialProductState);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [productCodeFilter, setProductCodeFilter] = useState('');
  const [productCode, setProductCode] = useState();
  const [productAvailableWarehouser, setProductAvailableWarehouser] = useState([]);

  var today = new Date();
  const date = moment(new Date(today)).format("DD/MM/YYYY");
  const [form, setForm] = useState({
    customer_phone: "",
    customer_name: "",
    customer_address: "",
    bill_date: date,
    is_ship: false,
    payment_code: '',
    bill_note: "",
    row_count: 0,
    total_amount: 0,
    total_discount: 0,
    total_final_amount_after_pre_paid: 0,
    total_final_amount: 0,
    pre_paid: 0,
    company_id: 0,
    saleman_id: 0,
  });
  const popupRef = useRef();

  const showListProduct = () => {
    if (selectedProducts.length === 0) {
      return <tr></tr>;
    } 
    return selectedProducts.map((item, index) => {
      return (
        <tr key={index}>
          <th>{item.product_code}</th>
          <td>{item.product_name}</td>
          <td>{item.warehouse_code}</td>
          <td>{item.unit_code}</td>
          <td>{item.qty}</td>
          <td>{formatMoney(item.price)}</td>
          <td>{formatMoney(item.amount)}</td>
          <td>{formatMoney(item.discount)}</td>
          <td>{formatMoney(item.final_amount)}</td>
          <td>
            <span
              href="#"
              className="color-danger"
              onClick={() => handleDeleteProduct(index)}
            >
              <i className="fas fa-minus-circle"></i>
            </span>
          </td>
        </tr >
      );
    });
  };

  const totalArr = () => {
    console.log('selectedProducts = ', selectedProducts)
    let row_count = selectedProducts.length;
    let total_amount = 0;
    let total_discount = 0;
    let total_final_amount = 0;

    for (let i = 0; i < selectedProducts.length; i++) {
      total_amount += parseInt(selectedProducts[i].amount)
      total_discount += parseInt(selectedProducts[i].discount)
      total_final_amount += parseInt(selectedProducts[i].final_amount)
    }

    let total_final_amount_after_pre_paid = total_final_amount - parseInt(form.pre_paid)

    setForm({
      ...form,
      row_count: row_count,
      total_amount: total_amount,
      total_discount: total_discount,
      total_final_amount: total_final_amount,
      total_final_amount_after_pre_paid: total_final_amount_after_pre_paid,
    })
  }

  const handleSelectProduct = () => {
    if (!dataProductFilterRender.product_code) {
      alert("Vui lòng điển mã sản phẩm")
    } else if (!dataProductFilterRender.warehouse_code) {
      alert("Vui lòng chọn kho")
    } else if (dataProductFilterRender.qty < 1) {
      alert("Vui lòng nhập số lượng")
    } else if (dataProductFilterRender.qty.toString() === "NaN") {
      alert("Trường số lượng không được để trống")
    }
    else if (dataProductFilterRender.discount.toString() === "NaN") {
      alert("Trường chiết khấu không được để trống, vui lòng điền 0 nếu sản phẩm không có chiết khấu")
    }
    else {
      let arr = [...selectedProducts];
      arr.push(dataProductFilterRender);
      setSelectedProducts(arr);
      setDataProductFilterRender(initialProductState)
      setProductAvailableWarehouser([])
      setProductCode("")
    }

  };
  const handleDeleteProduct = value => {
    let arr = [...selectedProducts];
    arr.splice(value, 1);
    let newArr = arr;
    setSelectedProducts(arr);
    let totalQty = 0
    let stockItem = productAvailableWarehouser.filter(function isEven(item) {
      return item.warehouse_code === dataProductFilterRender.warehouse_code;
    });
    let stockProductSelected = newArr.filter(function isEven(item) {
      return (item.warehouse_code === dataProductFilterRender.warehouse_code) 
        && (item.product_code === dataProductFilterRender.product_code);
    });
    if (stockProductSelected.length > 0) {
      for (let i = 0; i < stockProductSelected.length; i++) {
        totalQty = totalQty + stockProductSelected[i].qty
      }
      setDataProductFilterRender({
        ...dataProductFilterRender,
        qty: 0,
        stock: stockItem[0].qty - totalQty,
      });
    }
  };

  const getProductList = useCallback(
    global.debounce(val => {
      if (val) {
        billApi.getProductFilter(
          selectedShop,
          val,
          fetchDataProductSuccess,
          fetchDataProductFail
        );
      }
    }, 1000),
    []
  );

  const onSearchProductCode = (val) => {
    setDataProduct([]);
    setDataProductFilter([]);
    let indexString = val.indexOf(" ")
    setDataProductFilterRender({
      ...dataProductFilter,
      warehouse_code: ""
    })
    if (indexString === -1) {
      setProductCodeFilter(val)
      getProductList(val)
    } else {
      const pcode = val.slice(0, indexString);
      setProductCode(pcode);
      setProductCodeFilter(pcode)
      if (dataProduct.length > 0 && pcode) {
        let newArr = [...dataProduct];
        var arrFilter = newArr.filter(function (item) {
          return item.product_code === pcode;
        });
        if (arrFilter.length > 0) {
          setProductAvailableWarehouser([])
          setDataProductFilterRender({
            product_name: arrFilter[0].product_name,
            product_id: arrFilter[0].product_id,
            product_code: arrFilter[0].product_code,
            warehouse_code: "",
            unit_code: arrFilter[0].unit_code,
            qty: 0,
            original_price: arrFilter[0].price,
            price: arrFilter[0].sale_price ? arrFilter[0].sale_price : arrFilter[0].price,
            amount: 0,
            discount: 0,
            final_amount: 0,
            stock: 0,
            sale_detail_id: arrFilter[0].sale_detail_id,
          });
        }
      }

      if (pcode) {
        billApi.getProductAvailableWarehouse(
          selectedShop,
          pcode,
          fetchDataProductAvailableWarehouserSuccess,
          fetchDataProductAvailableWarehouserFail
        );
      }
    }
  }

  const fetchDataProductSuccess = (res) => {
    const { data } = res.data;
    let modifiedArr = data.map(function (element) {
      return `${element.product_code} - ${element.product_name}`;
    });
    setDataProduct(data);
    setDataProductFilter(modifiedArr);
  }

  const fetchDataProductFail = () => {
    setDataProduct([]);
    setDataProductFilter([]);
  }

  const showOptionProductAvailableWarehouser = () => {
    if (productAvailableWarehouser.length === 0) {
      return null;
    } else {
      return productAvailableWarehouser.map((item, index) => {
        return (
          <option key={index} value={item.warehouse_code}>
            {item.warehouse_code}
          </option>
        );
      });
    }
  };
  const fetchDataProductAvailableWarehouserSuccess = res => {
    const { data } = res.data;
    setProductAvailableWarehouser(data);
  };
  const fetchDataProductAvailableWarehouserFail = res => {
    alert("Không có dữ liệu");
  };

  const showOptionPaymentMethod = () => {
    if (dataPaymentMethods.length === 0) {
      return null;
    }
    return dataPaymentMethods.map((item, index) => {
      return (
        <option key={index} value={item.payment_code}>
          {item.payment_desc}
        </option>
      );
    });
  };

  const showSalemans = () => {
    
    <Input required type="select" className="form-control" value={form.payment_code}
    onChange={(e) => setForm({
      ...form,
      payment_code: e.target.value,
    })}>
    <option value="" disabled>Chọn phương thức thanh toán</option>
    {showOptionPaymentMethod()}
  </Input>

    if (!user) return null;
    return (isUserInGroup(['sale']))
    ? <Input readOnly type="select" value={user.user_id} className='form-control'>
        <option key={user.user_id} value={user.user_id}>
          {user.fullname} - {user.username}
        </option>
      </Input>
    : <Input required type="select" value={form.saleman_id}
        className="form-control"
        onChange={(e) => setForm({
          ...form,
          saleman_id: e.target.value,
        })}
      >
      <option value="">Chọn nhân viên bán hàng</option>
      {showOptionSaleMans()}
    </Input>    
  }

  const showOptionSaleMans = () => {
    if (dataSaleMans.length === 0) {
        return;
    }
    return dataSaleMans.map((item, index) => {
      return (
        <option key={index} value={item.user_id}>
          {item.fullname} - {item.username}
        </option>
      );
    });
  };

  const getCustomerList = useCallback(
    global.debounce(phone => {
      if (phone) {
        billApi.getAllCustomer(
          phone,
          fetchDataAllCustomersSuccess,
          fetchDataAllCustomerMethodsFail
        );
      }
    }, 1200),
    []
  );

  const fetchDataAllCustomer = phone => {
    setForm({
      ...form,
      customer_phone: phone,
    });
    getCustomerList(phone)
  };
  const fetchDataAllCustomersSuccess = res => {
    setDataCustomer([]);
    const { data } = res.data;
    setIsToggle(true);
    setDataCustomer(data);
  };
  const fetchDataAllCustomerMethodsFail = res => {
    alert("Không có dữ liệu");
  };

  const showOptionCustom = () => {
    if (dataCustomer.length === 0) {
      return null;
    } else {
      return dataCustomer.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => handleSelectCustomer(item)}
            className="order-phone-customer-item"
          >
            {item.customer_name} - {item.customer_phone}
          </div>
        );
      });
    }
  };

  const handleSelectCustomer = item => {
    setForm({
      ...form,
      customer_phone: item.customer_phone,
      customer_name: item.customer_name,
      customer_address: item.customer_address,
    });
    setIsToggle(false);
  };

  const handleClickOutside = e => {
    if (popupRef.current && popupRef.current.contains(e.target)) {
      return;
    }
    setIsToggle(false);
    setDataCustomer([]);
  };

  useEffect(() => {
    if (isToggle) {
      document.addEventListener("click", handleClickOutside, false);
    } else {
      document.removeEventListener("click", handleClickOutside, false);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [isToggle]);

  const handleChangeWarehouser = value => {
    let totalQty = 0
    let stockItem = productAvailableWarehouser.filter(function isEven(item) {
      return item.warehouse_code === value;
    });
    let stockProductSelected = selectedProducts.filter(function isEven(item) {
      return item.warehouse_code === value && item.product_code === dataProductFilterRender.product_code;
    });
    if (stockProductSelected.length > 0) {
      for (let i = 0; i < stockProductSelected.length; i++) {
        totalQty = totalQty + stockProductSelected[i].qty
      }
    }
    setDataProductFilterRender({
      ...dataProductFilterRender,
      warehouse_code: value,
      qty: 0,
      stock: stockItem[0].qty - totalQty,
    });
  };

  const handleChangeUnit = value => {
    let id = productAvailableWarehouser.filter(function isEven(item) {
      return item.warehouse_code === dataProductFilterRender.warehouse_code;
    });
    if (!dataProductFilterRender.warehouse_code) {
      alert("Vui lòng chọn kho")
    } else {
      if (value <= dataProductFilterRender.stock) {
        let total = value * dataProductFilterRender.price;
        let totalFinalAmount = (total ? total : 0) - (dataProductFilterRender.discount ? dataProductFilterRender.discount : 0);
        setDataProductFilterRender({
          ...dataProductFilterRender,
          qty: parseInt(value),
          amount: total,
          final_amount: totalFinalAmount,
        });
      }
      else {
        alert("Không đủ số lượng")
      }
    }
  };

  const handleChangePrice = value => {
    let amount = parseInt(dataProductFilterRender.qty ? dataProductFilterRender.qty : 0) * (value ? value : 0);
    let total = amount - parseInt(dataProductFilterRender.discount ? dataProductFilterRender.discount : 0);
    setDataProductFilterRender({
      ...dataProductFilterRender,
      price: value,
      amount: amount,
      final_amount: total,
    });
  };

  const handleChangePrePaid = value => {
    const pre_paid = parseInt(value)
    let total_final_amount_after_pre_paid = form.total_final_amount - pre_paid
    setForm({
      ...form,
      pre_paid: pre_paid,
      total_final_amount_after_pre_paid: total_final_amount_after_pre_paid
    })
  }

  const handleChangeDiscount = value => {
    let total = parseInt(dataProductFilterRender.amount ? dataProductFilterRender.amount : 0) - (value ? value : 0);
    setDataProductFilterRender({
      ...dataProductFilterRender,
      discount: value,
      final_amount: total,
    });
  };

  useEffect(() => {
    if (selectedShop <= 0) return;
    setDataProductFilterRender(initialProductState)
    setProductAvailableWarehouser([])
    setProductCode("");
    const cartProducts = CacheUtils.getCachedCart()
    if (cartProducts) {
      const newCartProducts= cartProducts.filter(item => item.company_id == selectedShop);
      setSelectedProducts(newCartProducts);
      CacheUtils.setCachedCart([])
    }
  }, [selectedShop]);

  useEffect(() => {
    totalArr()
  }, [selectedProducts]);

  useEffect(() => {
    if (location?.state?.detail) {
      const { id, customer_phone, customer_name, customer_address } = location.state.detail
      setOrderId(id);
      const data = {
        ...form,
        customer_phone: customer_phone,
        customer_name: customer_name,
        customer_address: customer_address
      }
      setForm({ ...data })
    }
  }, [location])

  const submitForm = (e) => {
    e.preventDefault();
    let dataSubmit = {
      ...form,
      company_id: selectedShop,
      saleman_id: isUserInGroup(['Sale']) ? user.user_id : parseInt(form.saleman_id),
      bill_date: moment(new Date(today)).format("YYYY-MM-DD"),
      details: selectedProducts,
      is_ship: form.is_ship === true ? 1 : 0,
      order_id: orderId !== "" ? orderId : ""
    }
    if (selectedProducts.length == 0) {
      alert("Danh sách sản phẩm chưa có")
    } else {
      billApi.create(
        dataSubmit,
        createBillSuccess,
        createBillFail,
      );
    }
  }

  const createBillSuccess = res => {
    alert("Tạo hoá đơn thành công");
    localStorage.setItem('cart', JSON.stringify([]))
    history.push("/orders")
  };
  const createBillFail = res => {
    alert(res.message);
  };

  const fetchSalemans = () => {
    billApi.getSaleMan(fetchSalemansSuccess, fetchSalemansFail);
  };
  
  const fetchSalemansSuccess = res => {
    const { data } = res.data;
    setDataSaleMans(data);
    CacheUtils.setCachedSalemans(JSON.stringify(data))
  };

  const fetchSalemansFail = () => {
    alert("Không có dữ liệu");
  };

  const initSalemans = () => {
    const salemans = CacheUtils.getCachedSalemans()
    if (salemans) {
      setDataSaleMans(salemans)
    } else {
      fetchSalemans();
    }
  }

  const fetchPaymentMethods = () => {
    billApi.getAllPaymentMethods(
      fetchPaymentMethodsSuccess,
      fetchPaymentMethodsFail
    );
  };
  const fetchPaymentMethodsSuccess = res => {
    const { data } = res.data;
    setDataPaymentMethods(data);
    CacheUtils.setCachedPaymentMethods(data)
  };
  const fetchPaymentMethodsFail = () => {
    alert("Không có dữ liệu");
  };

  const initPaymentMethods = () => {
    const paymentMethods = CacheUtils.getCachedPaymentMethods();
    if (paymentMethods) {
      setDataPaymentMethods(paymentMethods)
    } else {
      fetchPaymentMethods();
    }
  }

  const initUser = () => {
    const userInfo = CacheUtils.getCachedUser();
    if (userInfo) {
      setUser(userInfo)
    }
  }

  useEffect(() => {
    initUser();
    initPaymentMethods();    
    initSalemans();
    
    const cartCustomer = CacheUtils.getCachedCartCustomer();
    if (cartCustomer) {
      const data = {
        ...form,
        customer_phone: cartCustomer.customer_phone,
        customer_name: cartCustomer.customer_name,
        customer_address: cartCustomer.customer_address
      }
      setForm(data)      
      CacheUtils.setCachedCartCustomer({})
    }
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết đơn hàng</title>
      </MetaTags>
      <Form className="form" onSubmit={(e) => submitForm(e)}>
        <Card>
          <CardBody>
            <CardTitle className="h4">Tạo Hóa Đơn </CardTitle>
            <Card color="light">
              <CardBody>
                <div className="color-primary tag mb-3">THÔNG TIN HÓA ĐƠN </div>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div>Ngày</div>
                      <div>
                        <Input required type="text" className="form-control" value={form.bill_date} readOnly />
                      </div>
                      <div>Số điện thoại:</div>
                      <div className="order-phone-customer">
                        <Input
                          required
                          type="text"
                          className="form-control"
                          value={form.customer_phone}
                          onChange={e => fetchDataAllCustomer(e.target.value)}
                        />
                        {isToggle && (
                          <div
                            className="order-popup-phone-customer"
                            ref={popupRef}
                          >
                            {showOptionCustom()}
                          </div>
                        )}
                      </div>
                      <div>Tên khách:</div>
                      <div>
                        <Input
                          required
                          type="text"
                          value={form.customer_name}
                          className="form-control"
                          onChange={(e) => setForm({
                            ...form,
                            customer_name: e.target.value,
                          })}
                        />
                      </div>
                      <div>Địa chỉ:</div>
                      <div>
                        <Input
                          required
                          type="text"
                          value={form.customer_address}
                          className="form-control"
                          onChange={(e) => setForm({
                            ...form,
                            customer_address: e.target.value,
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div>Cần đặt ship:</div>
                      <div>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch1"
                            value={form.is_ship}
                            onChange={(e) => setForm({
                              ...form,
                              is_ship: !form.is_ship,
                            })}
                          />
                        </div>
                      </div>
                      <div>Thanh toán:</div>
                      <div>
                        <Input required type="select" className="form-control" value={form.payment_code}
                          onChange={(e) => setForm({
                            ...form,
                            payment_code: e.target.value,
                          })}>
                          <option value="" disabled>Chọn phương thức thanh toán</option>
                          {showOptionPaymentMethod()}
                        </Input>
                      </div>
                      <div>Nhân viên:</div>
                      <div>
                        {showSalemans()}
                      </div>
                      <div>Ghi chú:</div>
                      <div>
                        <Input type="textarea" className="form-control" value={form.bill_note}
                          onChange={(e) => setForm({
                            ...form,
                            bill_note: e.target.value,
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="table-responsive">
              <Table className="table-striped table-bill mb-0 nowrap-table ">
                <thead>
                  <tr>
                    <th>Mã sản phẩm</th>
                    <th>Tên sản phẩm</th>
                    <th style={{ minWidth: '100px' }}>Kho</th>
                    <th>Đơn vị</th>
                    <th>Số lượng</th>
                    <th>Đơn giá (vnđ)</th>
                    <th>Thành tiền (vnđ)</th>
                    <th>Chiết khấu</th>
                    <th>Tổng</th>
                    <th>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {showArrProductFilter()} */}
                  {showListProduct()}
                  {selectedProducts.length > 0 && (
                    <tr>
                      <td>
                      </td>
                      <td></td>
                      <td>

                      </td>
                      <th></th>
                      <td>

                      </td>
                      <td></td>
                      <td><div style={{ color: "#f46a6a" }}>{formatMoney(form.total_amount)}</div></td>
                      <td>
                        <div style={{ color: "#f46a6a" }}>{formatMoney(form.total_discount)}</div>
                      </td>
                      <td>
                        <div style={{ color: "#f46a6a" }}>{formatMoney(form.total_final_amount)}</div>
                      </td>
                      <td>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <div className="auto-suggest">
                        <AutoSuggest
                          name="product_code"
                          options={dataProductFilter}
                          handleChange={(e) => onSearchProductCode(e)}
                          value={productCodeFilter}
                        />
                      </div>
                    </td>
                    <td><div>{dataProductFilterRender.product_name}</div></td>
                    <td>
                      <div>
                        <Input
                          value={dataProductFilterRender.warehouse_code}
                          required
                          disabled={productAvailableWarehouser.length === 0}
                          style={{ maxWidth: '120px' }}
                          type="select"
                          className="form-control"
                          onChange={e => handleChangeWarehouser(e.target.value)}
                        >
                          <option value="" disabled>Chọn kho</option>
                          {showOptionProductAvailableWarehouser()}
                        </Input>
                        {dataProductFilterRender.warehouse_code && <div style={{ marginTop: "10px" }}>Còn {dataProductFilterRender.stock}</div>}
                      </div>
                    </td>
                    <th>{dataProductFilterRender.unit_code}</th>
                    <td>
                      <Input
                        disabled={!dataProductFilterRender.warehouse_code}
                        min="0"
                        style={{ maxWidth: '100px' }}
                        value={dataProductFilterRender.qty}
                        type="number"
                        onChange={e => handleChangeUnit(e.target.value)}
                      />
                    </td>
                    {/*<td>{dataProductFilterRender.price && formatMoney(dataProductFilterRender.price)}</td>*/}
                    <td>
                      <Input
                        style={{ maxWidth: '100px' }}
                        value={dataProductFilterRender.price}
                        type="number"
                        onChange={e => handleChangePrice(parseInt(e.target.value))}
                      />
                    </td>
                    <td>{dataProductFilterRender.amount && formatMoney(dataProductFilterRender.amount)}</td>
                    <td>
                      <Input
                        style={{ maxWidth: '100px' }}
                        value={dataProductFilterRender.discount}
                        type="number"
                        onChange={e => handleChangeDiscount(parseInt(e.target.value))}
                      />

                    </td>
                    <td>{dataProductFilterRender.final_amount ? formatMoney(dataProductFilterRender.final_amount) : 0}</td>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        className="color-success"
                        onClick={() => handleSelectProduct()}
                      >
                        <i className="fas fa-plus-square"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="mt-4">
              <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
                <div className="h4 mb-0">Tổng tiền:</div>
                <div className="body-1">{formatMoney(form.total_amount)}</div>
                <div className="h4 mb-0">Tổng chiết khấu:</div>
                <div className="body-1">{formatMoney(form.total_discount)}</div>
                <div className="h4 mb-0">Tổng sau chiết khấu:</div>
                <div className="body-1">{formatMoney(form.total_final_amount)}</div>
                <div className="h4 mb-0">Đã cọc:</div>
                <div className="body-1">
                  <Input
                    type="number"
                    style={{ width: "120px" }}
                    onChange={(e) => handleChangePrePaid(e.target.value)}
                    value={form.pre_paid}
                  />
                </div>
                <div className="h4 mb-0">Tiền cần thu:</div>
                <div className="h2 mb-0 color-primary">{formatMoney(form.total_final_amount_after_pre_paid)}</div>
                <div className="">
                  <Button
                    color="success"
                    className="px-4 mr-2"
                    style={{ width: "120px" }}
                  >
                    Lưu lại
                  </Button>
                </div>
                <div className="">
                  <Link to="/orders">
                    <Button
                      color="danger"
                      className="px-4"
                      style={{ width: "120px" }}
                    >
                      Hủy
                    </Button>
                  </Link>

                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default Order;
