import React, { useEffect, useState, useCallback } from "react";
import * as billApi from "../../apis/billApi";
import { CacheUtils } from 'Utils/CacheUtils';
import { convertNumberToCurrency, debounce, fixImagePath, showMessage } from 'Utils/global';
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Table, Row, Col, Form, Input, Checkbox, Select, InputNumber, Image, Button, message, AutoComplete, Dropdown, Space, Menu } from 'antd';
import MetaTags from 'react-meta-tags';
import { inUserGroup } from "../../helpers/function";
import { PropTypes } from 'prop-types'
import AddProductModal from "./AddProductModal";
import { DownOutlined, FileExcelOutlined, PlusOutlined, PrinterOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import { isUserInGroup } from "Utils/SecurityUtils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { downloadFile } from "constants/function";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import UploadButton from "components/Common/UploadButton";

OrderSummerizeItem.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
}

function OrderSummerizeItem(props) {
  return (
    <Form.Item label={props.title}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      className="mb-1"
      labelAlign="right">
      {props.children}
    </Form.Item>
  );
}

OrderInfoItem.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  rules: PropTypes.any,
}

function OrderInfoItem(props) {
  return (
    <Form.Item {...props}>
      {props.children}
    </Form.Item>
  );
}

OrderV2.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

function OrderV2(props) {
  let isView = props.match.url.includes("view")
    || (!(props.match.url.includes("create")));
  const orderId = props.match.params?.id;
  const [orderForm] = Form.useForm();
  let history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [dataPaymentMethods, setDataPaymentMethods] = useState([]);
  const [dataSaleMans, setDataSaleMans] = useState([]);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [user, setUser] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [addProductModelVisible, setAddProductModelVisible] = useState(false);
  const [processingWeborderId, setProcessingWebOrderId] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false);

  const downloadBillPrint = () => {
    billApi.downloadBillPrint(orderId, downloadExcel, downloadBillPrintFail)
  }

  const downloadBillPrintWithPrice = () => {
    billApi.downloadBillPrint(`${orderId}?with_price=1`, downloadExcel, downloadBillPrintFail)
  }

  const downloadExcel = (res) => {
    downloadFile(res.data.data);
  }

  const downloadBillPrintFail = err => {
    showMessage(err.message);
  };

  var today = new Date();
  const date = moment(new Date(today)).format("DD/MM/YYYY");
  const [form, setForm] = useState({
    customer_phone: "",
    customer_name: "",
    customer_address: "",
    bill_date: date,
    is_ship: false,
    payment_code: '',
    bill_note: "",
    row_count: 0,
    total_amount: 0,
    total_discount: 0,
    total_final_amount_after_pre_paid: 0,
    total_final_amount: 0,
    pre_paid: 0,
    company_id: 0,
    saleman_id: 0,
  });

  useEffect(() => {
    initUser();
    initPaymentMethods();
    initSalemans();

    if ((orderId != 'create') && (orderId > 0)) {
      fetchDetailBill(orderId);
    }

    if ((orderId == 'create') || (!isView && ((orderId == false) || (orderId == 0)))) {
      const cartCustomer = CacheUtils.getCachedCartCustomer();
      if (cartCustomer) {
        setForm(prevState => ({
          ...prevState,
          customer_phone: cartCustomer.customer_phone,
          customer_name: cartCustomer.customer_name,
          customer_address: cartCustomer.customer_address
        }));
        setProcessingWebOrderId(cartCustomer.web_order_id);
        CacheUtils.setCachedCartCustomer({});
      }
    }
  }, []);

  useEffect(() => {
    if (selectedShop <= 0) return;
    const cartProducts = CacheUtils.getCachedCart()
    if (cartProducts) {
      const newCartProducts = cartProducts.filter(item => item.company_id == selectedShop);
      setSelectedProducts(newCartProducts);
      CacheUtils.setCachedCart([])
    }
  }, [selectedShop]);

  useEffect(() => {
    totalArr()
  }, [selectedProducts]);

  const fetchDetailBill = id => {
    billApi.getBillDetail(id, fetchDetailBillSuccess, fetchDetailBillFailure);
  };

  const fetchDetailBillSuccess = res => {
    const { data } = res.data;
    setSelectedProducts(data[0].details.map(item => { return { ...item, key: item.id } }));
    setForm({
      ...data[0],
      total_final_amount_after_pre_paid: parseInt(data[0].total_final_amount) - parseInt(data[0].pre_paid)
    });
    orderForm.setFieldsValue({
      payment_method: data[0].payment_code,
      saleman_id: data[0].saleman_id
    });
  };

  const fetchDetailBillFailure = err => {
    showMessage(err.message);
  };

  const totalArr = () => {
    let row_count = selectedProducts.length;
    let total_amount = 0;
    let total_discount = 0;
    let total_final_amount = 0;

    for (let i = 0; i < selectedProducts.length; i++) {
      total_amount += parseInt(selectedProducts[i].amount)
      total_discount += parseInt(selectedProducts[i].discount)
      total_final_amount += parseInt(selectedProducts[i].final_amount)
    }

    let total_final_amount_after_pre_paid = total_final_amount - parseInt(form.pre_paid)

    setForm(prevState => ({
      ...prevState,
      row_count: row_count,
      total_amount: total_amount,
      total_discount: total_discount,
      total_final_amount: total_final_amount,
      total_final_amount_after_pre_paid: total_final_amount_after_pre_paid
    }))
  }

  const handleSelectProduct = (product) => {
    let arr = [...selectedProducts];
    arr.push(product);
    setSelectedProducts(arr);
    setAddProductModelVisible(false);
  };

  const handleRemoveProduct = removedProduct => {
    let arr = selectedProducts.filter(item => item.product_id != removedProduct.product_id);
    setSelectedProducts(arr);
  };

  const fetchDataAllCustomer = phone => {
    setForm({
      ...form,
      customer_phone: phone,
    });
    getCustomerList(phone)
  };

  const getCustomerList = useCallback(
    debounce(phone => {
      setForm(prevState => ({
        ...prevState,
        ...{
          customer_name: '',
          customer_address: ''
        }
      }));

      if (phone) {
        billApi.getAllCustomer(
          phone,
          fetchDataAllCustomersSuccess,
          fetchDataAllCustomerMethodsFail
        );
      }
    }, 1200),
    []
  );

  const fetchDataAllCustomersSuccess = res => {
    setDataCustomer([]);
    const { data } = res.data;
    setDataCustomer(data);
  };

  const fetchDataAllCustomerMethodsFail = res => {
    alert("Không có dữ liệu");
  };

  const handleChangePrePaid = value => {
    const pre_paid = parseInt(value.toString().replace(/\D/g, ''))
    let total_final_amount_after_pre_paid = form.total_final_amount - pre_paid
    setForm({
      ...form,
      pre_paid: pre_paid,
      total_final_amount_after_pre_paid: total_final_amount_after_pre_paid
    })
  }

  const submitForm = (e) => {
    let dataSubmit = {
      ...form,
      company_id: selectedShop,
      saleman_id: isUserInGroup(['Sale']) ? user.user_id : parseInt(form.saleman_id),
      bill_date: moment(new Date(today)).format("YYYY-MM-DD"),
      details: selectedProducts,
      is_ship: form.is_ship === true ? 1 : 0,
      order_id: ""
    }
    if (selectedProducts.length == 0) {
      alert("Đơn không có sản phẩm")
    } else {
      billApi.create(
        dataSubmit,
        createBillSuccess,
        createBillFail,
      );
    }
  }

  const createBillSuccess = res => {
    alert("Tạo hoá đơn thành công");
    localStorage.setItem('cart', JSON.stringify([]))
    history.push("/orders")
    if (processingWeborderId > 0) {
      history.push("/web-orders/" + processingWeborderId)
    }
  };
  const createBillFail = res => {
    alert(res.message);
  };

  const fetchSalemans = () => {
    billApi.getSaleMan(fetchSalemansSuccess, fetchSalemansFail);
  };

  const fetchSalemansSuccess = res => {
    const { data } = res.data;
    setDataSaleMans(data);
    CacheUtils.setCachedSalemans(JSON.stringify(data))
  };

  const fetchSalemansFail = () => {
    alert("Không có dữ liệu nhân viên bán hàng");
  };

  const initSalemans = () => {
    const salemans = CacheUtils.getCachedSalemans()
    if (salemans) {
      setDataSaleMans(salemans)
    } else {
      fetchSalemans();
    }
  }

  const fetchPaymentMethods = () => {
    billApi.getAllPaymentMethods(
      fetchPaymentMethodsSuccess,
      fetchPaymentMethodsFail
    );
  };

  const fetchPaymentMethodsSuccess = res => {
    const { data } = res.data;
    setDataPaymentMethods(data);
    CacheUtils.setCachedPaymentMethods(data)
  };

  const fetchPaymentMethodsFail = () => {
    alert("Không có dữ liệu phương thức thanh toán");
  };

  const initPaymentMethods = () => {
    const paymentMethods = CacheUtils.getCachedPaymentMethods();
    if (paymentMethods) {
      setDataPaymentMethods(paymentMethods)
    } else {
      fetchPaymentMethods();
    }
  }

  const initUser = () => {
    const userInfo = CacheUtils.getCachedUser();
    if (userInfo) {
      setUser(userInfo)
    }
  }

  const onChangePhone = (value) => {
    const item = {
      customer_phone: value,
    };
    setForm(prevState => ({
      ...prevState,
      ...item
    }));
  };

  const onSelectPhone = (key, values) => {
    const item = {
      customer_phone: values.value,
      customer_name: values.cname,
      customer_address: values.caddr,
    };
    setForm(prevState => ({
      ...prevState,
      ...item
    }));
  };

  const onSearchPhone = val => {
    fetchDataAllCustomer(val);
  };

  const showPayments = () => {
    return dataPaymentMethods.map((el) => {
      return { value: el.payment_code, label: el.payment_desc }
    })
  }

  const paymentOptions = dataPaymentMethods.map(d => (
    <Select.Option key={d.payment_code} value={d.payment_code}>
      {d.payment_desc}
    </Select.Option>
  ));

  const showSales = () => {
    return isUserInGroup(['Sale'])
      ? [{ value: user?.user_id, label: user?.fullname + ' - ' + user?.username }]
      : dataSaleMans?.map((el) => {
        return { value: el.user_id, label: el.fullname + ' - ' + el.username }
      })
  }

  const copyPhone = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(form.customer_phone);
    message.info('Đã copy số điện thoại');
  }

  const productColumns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'product_id',
      align: 'left',
      hidden: false,
      responsive: ["xs"],
      render: (text, record) => <Col>
        <Row>
          <Col span={7}>Mã SP: </Col>
          <Col>{record.product_code}</Col>
        </Row>

        <Row>
          <Col span={7}>Tên SP: </Col>
          <Col>{record.product_name}</Col>
        </Row>

        <Row>
          <Col span={7}>ĐVT: </Col>
          <Col>{record.unit_code}</Col>
        </Row>

        <Row>
          <Col span={7}>Kho: </Col>
          <Col>{record.warehouse_code}</Col>
        </Row>

        <Row>
          <Col span={7}>Giá thường: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.regular_price))}</Col>
        </Row>

        <Row>
          <Col span={7}>Giá bán: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.price))}</Col>
        </Row>

        <Row>
          <Col span={7}>Số lượng: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.qty))}</Col>
        </Row>

        <Row>
          <Col span={7}>Thành tiền: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.amount))}</Col>
        </Row>

        <Row>
          <Col span={7}>Chiết khấu: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.discount))}</Col>
        </Row>

        <Row>
          <Col span={7}>Tổng: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.final_amount))}</Col>
        </Row>

        <Row hidden={isView}>
          <a className='text-success mr-5' onClick={() => onEditProduct(record)}>
            <i className="fas fa-pen"></i> Sửa
          </a>
          <a className='text-danger' onClick={() => handleRemoveProduct(record)}>
            <i className="fas fa-trash"></i> Xóa
          </a>
        </Row>
      </Col>,
    },
    {
      title: 'Ảnh',
      dataIndex: 'thumb_file',
      key: 'thumb_file',
      align: 'center',
      width: '7%',
      hidden: false,
      responsive: ["md"],
      render: (thumb_file, record) => {
        const thumbFile = fixImagePath(record.thumb_file);
        const previewFile = fixImagePath(record.slide_file);
        return (
          <Image
            width={64}
            src={thumbFile}
            preview={{ src: previewFile }}
          />
        );
      }
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      align: 'center',
      width: 110,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
      key: 'product_name',
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit_code',
      key: 'unit_code',
      align: 'center',
      width: 70,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Kho',
      dataIndex: 'warehouse_code',
      key: 'warehouse_code',
      align: 'center',
      width: 70,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'regular_price',
      key: 'regular_price',
      width: 110,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      key: 'price',
      width: 110,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'SL',
      dataIndex: 'qty',
      key: 'qty',
      width: 80,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'amount',
      key: 'amount',
      width: 110,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'CK',
      dataIndex: 'discount',
      key: 'discount',
      width: 100,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Tổng',
      dataIndex: 'final_amount',
      key: 'final_amount',
      width: 110,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: '',
      align: 'center',
      fixed: 'right',
      hidden: isView,
      responsive: ["md"],
      render: (key, record) => {
        return (
          <Row>
            <a className='text-danger' onClick={() => handleRemoveProduct(record)}>
              <i className="fas fa-minus-circle"></i>
            </a>
          </Row>
        );
      },
    },
  ].filter(e => e.hidden == false);

  const showOrderInfo = () => {
    return (
      <Row>
        <Col xs={24} md={12}>
          <OrderInfoItem label='Ngày'>
            <Input required value={form.bill_date} readOnly />
          </OrderInfoItem>
          <OrderInfoItem label='Số điện thoại'>
            <Row>
              <Col flex="auto" className="mr-2">
                <AutoComplete
                  showSearch={true}
                  onSelect={onSelectPhone}
                  onChange={onChangePhone}
                  onSearch={onSearchPhone}
                  filterOption={false}
                  value={form.customer_phone}
                  disabled={isView}
                  readOnly={isView}
                >
                  {dataCustomer.map(item => (
                    <Select.Option
                      key={item.customer_id}
                      caddr={item.customer_address}
                      cname={item.customer_name}
                      value={item.customer_phone}
                    >
                      {item.customer_phone} - {item.customer_name}
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Col>
              <Col flex="30px">
                <a className='btn btn-sm btn-primary' onClick={copyPhone}>copy</a>
              </Col>
            </Row>
          </OrderInfoItem>
          <OrderInfoItem label='Tên khách'>
            <Input required value={form.customer_name} readOnly={isView}
              onChange={(e) => setForm({ ...form, customer_name: e.target.value })} />
          </OrderInfoItem>
          <OrderInfoItem label='Địa chỉ'>
            <Input.TextArea required value={form.customer_address} readOnly={isView}
              onChange={(e) => setForm({ ...form, customer_address: e.target.value })} />
          </OrderInfoItem>
        </Col>
        <Col xs={24} md={12}>
          <OrderInfoItem label='Cần đặt ship'>
            <Checkbox checked={form.is_ship} disabled={isView}
              onChange={(e) => setForm({ ...form, is_ship: !form.is_ship })} />
          </OrderInfoItem>
          <OrderInfoItem label='Cách thanh toán' name='payment_method'
            rules={[{ required: true, message: 'Chưa chọn phương thức thanh toán' }]}>
            <Select required value={form.payment_code} disabled={isView}
              options={showPayments()}
              onChange={(value) => setForm(prevState => ({ ...prevState, payment_code: value }))} />
          </OrderInfoItem>
          <OrderInfoItem label='Nhân viên bán hàng' name='saleman_id'
            rules={[{ required: true, message: 'Chưa chọn nhân viên bán hàng' }]}>
            <Select required value={form.saleman_id} disabled={isView}
              options={showSales()}
              onChange={(value) => setForm({ ...form, saleman_id: value })} />
          </OrderInfoItem>
          <OrderInfoItem label='Ghi chú'>
            <Input.TextArea value={form.bill_note} readOnly={isView}
              onChange={(e) => setForm({ ...form, bill_note: e.target.value })} />
          </OrderInfoItem>
        </Col>
      </Row>
    );
  }

  const showOrderSummary = () => {
    return (
      <Row justify="end">
        <Col>
          <OrderSummerizeItem title='Tổng tiền'>
            <Input required value={convertNumberToCurrency(form.total_amount)} readOnly={true} />
          </OrderSummerizeItem>
          <OrderSummerizeItem title='Tổng chiết khấu'>
            <Input required value={convertNumberToCurrency(form.total_discount)} readOnly={true} />
          </OrderSummerizeItem>
          <OrderSummerizeItem title='Tổng tiền sau chiết khấu'>
            <Input required value={convertNumberToCurrency(form.total_final_amount)} readOnly={true} />
          </OrderSummerizeItem>
          <OrderSummerizeItem title='Tiền cọc'>
            <InputNumber required value={convertNumberToCurrency(form.pre_paid)} readOnly={isView}
              onChange={(value) => handleChangePrePaid(value)}
              style={{ width: 180 }} />
          </OrderSummerizeItem>
          <OrderSummerizeItem title='Tiền cần thu'>
            <Input value={convertNumberToCurrency(form.total_final_amount_after_pre_paid)} readOnly={true} />
          </OrderSummerizeItem>
        </Col>
      </Row>
    );
  }


  const handleMenuClick = (e) => {
    if (e.key == 1) downloadBillPrint();
    else if (e.key == 2) downloadBillPrintWithPrice();
  };

  const menuPrint = (
    <Menu>
      <Menu.Item key={4}><Link to={`/orders2/print-season/${orderId}`}>Season không giá</Link></Menu.Item>
      <Menu.Item key={3}><Link to={`/orders2/print-season-price/${orderId}`}>Season Có giá</Link></Menu.Item>
      <Menu.Item key={2}><Link to={`/orders2/print/${orderId}`}>Không giá</Link></Menu.Item>
      <Menu.Item key={1}><Link to={`/orders2/print-price/${orderId}`}>Có giá</Link></Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={1}>Excel không giá</Menu.Item>
      <Menu.Item key={2}>Excel có giá</Menu.Item>
    </Menu>
  );

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết đơn hàng</title>
      </MetaTags>
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        labelAlign="left"
        layout="horizontal"
        scrollToFirstError={true}
        form={orderForm}
        onFinish={(e) => submitForm(e)} >
        <Card title='TẠO HÓA ĐƠN'>
          <Card type="inner" title="THÔNG TIN HÓA ĐƠN">
            {showOrderInfo()}
          </Card>
          <Table className="mytable mb-2 mt-2"
            dataSource={selectedProducts}
            columns={productColumns}
            pagination={false} />
          <Button type="primary" hidden={isView}
            icon={<PlusOutlined className="ant-fix-button-icon" />}
            onClick={() => setAddProductModelVisible(true)}
          >
            Thêm sản phẩm
          </Button>
          <Card type="inner" className="mt-2">
            {showOrderSummary()}
          </Card>
          <Row justify="end">
            <Button type="primary" htmlType="submit" hidden={isView}
              icon={<SaveOutlined className="ant-fix-button-icon" />}
              style={{ width: "120px", marginTop: 10, marginRight: 25 }}
            >
              Lưu lại
            </Button>
            <Button type="ghost"
              icon={<RollbackOutlined className="ant-fix-button-icon" />}
              style={{ width: "120px", marginTop: 10, marginRight: 25 }}
              onClick={() => history.goBack()}
            >
              Quay lại
            </Button>
            <Dropdown overlay={menuPrint} placement="top">
              <Button type="primary"
                icon={<PrinterOutlined className="ant-fix-button-icon" />}
                style={{ width: "120px", marginTop: 10, marginRight: 25 }}
                hidden={orderId == 'create'}
              >
                In hóa đơn
              </Button>
            </Dropdown>
            <Dropdown overlay={menu} placement="top">
              <Button 
                icon={<FileExcelOutlined className="ant-fix-button-icon" />}
                style={{ width: "130px", marginTop: 10, marginRight: 25, backgroundColor: '#33C481', color: 'white' }}
                hidden={orderId == 'create'}
              >
                Tải hóa đơn
              </Button>
              {/* <Button style={{ width: "120px", marginTop: 10, marginRight: 25, backgroundColor: '#33C481', color: 'white' }}>
                <Space>
                  <FileExcelOutlined className="ant-fix-button-icon" />
                  Tải hóa đơn
                </Space>
              </Button> */}
            </Dropdown>
          </Row>
        </Card>
      </Form>
      <AddProductModal
        companyId={selectedShop}
        visible={addProductModelVisible}
        onSelect={handleSelectProduct}
        onClose={() => setAddProductModelVisible(false)} />
    </div>
  );
}

export default OrderV2;
