import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { withRouter, useHistory, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import * as wareHouseApi from '../../apis/wareHouseApi'
import { useSelector } from 'react-redux';
import { getMessage } from 'Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function Warehouse(props) {
    const code = props.match.params?.code;
    let history = useHistory();
    const [data, setData] = useState({})
    const selectedShop = useSelector(state => state.SelectedShop);
    useEffect(() => {
        if (code) {
            fetchData(code);
        }
    }, [code, selectedShop])
    const fetchData = (code) => {
        wareHouseApi.getWareHouse(code, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
    }
    const onChangeInput = (value, attr) => {
        data[attr] = value;
        setData({ ...data })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (code) {
            wareHouseApi.updateWareHouse(code, data, selectedShop, onSubmitSuccess, onSubmitFail)
        }
        else {
            wareHouseApi.createWareHouse(data, selectedShop, onSubmitSuccess, onSubmitFail)
        }
    }
    const onSubmitSuccess = (res) => {
        history.push("/warehouses")
    }
    const onSubmitFail = (res) => {
        alert(getMessage(res.message))
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Chi tiết kho hàng</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">Danh mục kho hàng</CardTitle>
                    <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <Label htmlFor="warehouse-code-input">Mã kho hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="warehouse-code-input"
                                placeholder="Nhập mã kho hàng"
                                value={data.warehouse_code}
                                onChange={e => onChangeInput(e.target.value, 'warehouse_code')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="warehouse-name-Input">Tên kho hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="warehouse-name-Input"
                                placeholder="Nhập tên kho hàng"
                                value={data.warehouse_name}
                                onChange={e => onChangeInput(e.target.value, 'warehouse_name')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="warehouse-address-Input">Địa chỉ kho hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="warehouse-address-Input"
                                placeholder="Nhập địa chỉ kho hàng"
                                value={data.warehouse_address}
                                onChange={e => onChangeInput(e.target.value, 'warehouse_address')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="warehouse-phone-Input">Số điện thoại kho hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="warehouse-phone-Input"
                                placeholder="Nhập số điện thoại kho hàng"
                                value={data.warehouse_phone}
                                onChange={e => onChangeInput(e.target.value, 'warehouse_phone')}
                            />
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} sm="auto">
                                    <button type="submit" className="btn btn-primary w-md">
                                        Lưu
                                    </button>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Link to="/warehouses" className="btn btn-secondary w-md">
                                        Hủy
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
Warehouse.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
}

export default withRouter(Warehouse)
