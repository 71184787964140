import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { PropTypes } from 'prop-types';
import * as categoryApi from '../../apis/categoryApi'
import * as attributeApi from '../../apis/attributeApi'
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function ProductCategory(props) {
  let id = props.match.params?.id
  let history = useHistory()
  const [data, setData] = useState({})
  const [allCat, setAllCat] = useState([])
  const [selectedCat, setSelectedCat] = useState({})
  const [attributes, setAttributes] = useState([
    {
      attribute_id: 1,
      attribute_name: 'Màu'
    },
    {
      attribute_id: 2,
      attribute_name: 'Size'
    },
    {
      attribute_id: 3,
      attribute_name: 'Loại'
    },
  ])
  const [selectedAttr, setSelectedAttr] = useState([])
  const selectedShop = useSelector(state => state.SelectedShop);

  useEffect(() => {
    fetchAllCat()
    fetchAllAttr()
  }, [id, selectedShop])
  useEffect(() => {

    if (allCat.length > 0) {
      allCat.forEach((v, i) => {
        if (data.category_parent_id == v.value) {
          setSelectedCat(v);
        }
      })
      if (allCat.some((v, i) => v.value == data.category_id)) {
        setAllCat(allCat.filter((v1, i1) =>
          v1.value != data.category_id
        ))
      }
    }
  }, [data, allCat])
  const fetchAllAttr = () => {
    attributeApi.getAll(selectedShop, fetchAttrSuccess, fetchAllCatFail)
  }
  const fetchAttrSuccess = (res) => {
    setAttributes(res.data.data)
  }
  const fetchAllCat = () => {
    categoryApi.getAllCategory(selectedShop, fetchAllCatSuccess, fetchAllCatFail)
  }
  const fetchAllCatSuccess = (res) => {
    if (res.data.data?.length > 0) {
      res.data.data.unshift({ category_id: "", category_name: "Không danh mục" })
      setAllCat(res.data.data.map((v, i) => {
        if (v.category_id == data.category_id) {
          return
        }
        return { value: v.category_id, label: v.category_name }
      }))
    }
    fetchData()
  }
  const fetchAllCatFail = (res) => {
    alert("Lỗi load dữ liệu")
  }
  const fetchData = () => {
    if (id) {
      categoryApi.getDetailCategory(id, fetchDataSuccess, fetchDataFail)
    }
  }
  const fetchDataSuccess = (res) => {
    setData(res.data.data[0])
    setSelectedAttr(res.data.data[0].attributes?.map((v, i) => {
      return v.attribute_id
    }))
  }
  const fetchDataFail = (res) => {
    alert(getMessage(res.message))
  }
  const onChangeName = (e) => {
    let name = e.target.value;
    setData({ ...data, "category_name": name });
  }
  const onChangeParent = (e) => {
    let value = e.value
    setData({ ...data, "category_parent_id": value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (id) {
      categoryApi.updateCategory(selectedShop, data.category_id, data.category_name, data.category_parent_id, selectedAttr.join(), onSubmitSuccess, onSubmitFail)
    }
    else {
      categoryApi.createCategory(selectedShop, data.category_name, data.category_parent_id, selectedAttr.join(), onSubmitSuccess, onSubmitFail)
    }
  }
  const onSubmitSuccess = (res) => {
    history.push('/product-categories')
  }
  const onSubmitFail = (res) => {
    alert(res?.message ? getMessage(res.message) : "Lưu thất bại")
  }
  const showAttr = () => {
    if (attributes.length === 0) return;
    return attributes.map((v, i) => {
      return (
        <div className="form-check mb-2" key={i}>
          <input
            className="form-check-input"
            type="checkbox"
            onClick={e => onCheckAttr(e, v.attribute_id)}
            checked={selectedAttr.includes(v.attribute_id)}
          />
          <label
            className="form-check-label"
            htmlFor="defaultCheck1"
          >
            {v.attribute_name}
          </label>
        </div>
      )
    })
  }
  const onCheckAttr = (event, value) => {
    if (event.target.checked) {
      setSelectedAttr([...selectedAttr, value])
    }
    else {
      setSelectedAttr(selectedAttr.filter((v, i) => v != value))
    }
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết nhóm sản phẩm kho</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Danh mục sản phẩm</CardTitle>
          <Form onSubmit={onSubmit}>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Tên Danh mục sản phẩm</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Tên Danh mục sản phẩm"
                value={data.category_name}
                onChange={onChangeName}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Danh mục cha</Label>
              {/* <Input type='select'
                                className="form-control" onChange={onChangeParent} value={data.category_parent_id}>
                                {renderAllCat()}
                            </Input> */}
              <Select
                value={selectedCat}
                options={allCat}
                onChange={onChangeParent} />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Thuộc tính</Label>
              {showAttr()}
            </div>

            <div>
              <Row>
                <Col xs={12} sm="auto">
                  <button type="submit" className="btn btn-primary w-md">
                    Lưu
                  </button>
                </Col>
                <Col xs={12} sm="auto">
                  <Link to="/product-categories" className="btn btn-secondary w-md">
                    Hủy
                  </Link>
                </Col>
              </Row>

            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

ProductCategory.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(ProductCategory)
