import React from "react"
import PropTypes from "prop-types"
import { Alert as AntdAlert, Popconfirm } from "antd"
import { Link } from "react-router-dom"

const IconButtonWithConfirm = ({title, type, handleConfirm, id}) => {
  return (
    <Popconfirm
      placement="top"
      title={`Bạn có muốn ${type == 'RESTORE' ? 'khôi phục' : 'xóa'} ${title}`}
      onConfirm={() => handleConfirm(id)}
      okText={`Đồng ý ${type == 'RESTORE' ? 'khôi phục' : 'xóa'} ${title}`}
      cancelText="Không"
    >
      <span className="text-danger" style={{cursor: "pointer"}}>
        <i className={`fas fa-trash${type == 'RESTORE' ? '-restore' : ''}`} 
          title={`Bạn có muốn ${type == 'RESTORE' ? 'khôi phục' : 'xóa'} ${title}`}></i>
      </span>
    </Popconfirm>
  )
}

IconButtonWithConfirm.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  handleConfirm: PropTypes.func,
  id: PropTypes.number,
}

export default IconButtonWithConfirm
