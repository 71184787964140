import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label,
} from 'reactstrap';
import { Col, message, Popconfirm, Row, Select, Table } from 'antd';
import { convertNumberToCurrency, debounce, showMessage } from 'Utils/global';
import { utils } from 'Utils/utils';
import PropTypes from 'prop-types';
import * as wareHouseApi from "apis/wareHouseExportApi";
import { CacheUtils } from 'Utils/CacheUtils';
import authStore from 'store/authStore';
import { useStore } from 'store/hooks';
import { SelectWebOrderProductContext } from '../WebOrder';

ProducSelectionModal.propTypes = {
  companyId: PropTypes.number,
  baseProductId: PropTypes.number,
  products: PropTypes.array,
  qty: PropTypes.number,
  visible: PropTypes.bool,
  onSelectProduct: PropTypes.func,
  onCancel: PropTypes.func,
};

function ProducSelectionModal({
  companyId,
  baseProductId,
  products,
  qty,
  visible,
  onSelectProduct,
  onCancel,
}) {

  const selectWebOrderProductContext = useContext(SelectWebOrderProductContext)
  const { listCart, setListCart } = selectWebOrderProductContext

  const AuthStore = useStore(authStore);

  const handleClose = () => {
    onCancel();
  }

  const handleAddToCart = (value) => {
    const item = {
      ...value,
      base_product_id: baseProductId,
      company_id: companyId,
      regular_price: value.price,
      discount: 0,
      price: value.sale_price > 0 ? value.sale_price : value.price,
    }
    const item1 = {
      ...item,
      qty: qty,
      amount: item.price * qty,
      final_amount: item.price * qty,
    }
    const data = [...listCart, item1]
    setListCart(data);
    CacheUtils.setCachedCart(data)
    AuthStore.data = data
    message.success('Thêm sản phẩm vào giỏ hàng thành công');
    onSelectProduct(item1);
    onCancel();
  }

  const productSelectionColumns = [
    {
      title: 'Sản phẩm vừa chọn',
      dataIndex: 'product_id',
      align: 'left',
      render: (text, record) => <Col>
        <Row>
          <Col span={7}>ID: </Col>
          <Col>{record.product_id}</Col>
        </Row>        
        
        <Row>
          <Col span={7}>Mã sản phẩm: </Col>
          <Col>{record.product_code}</Col>
        </Row>
        
        <Row>
          <Col span={7}>Tên sản phẩm: </Col>
          <Col span={17}>{record.product_name}</Col>
        </Row>
        
        <Row>
          <Col span={7}>Kho: </Col>
          <Col>{record.warehouse_code}</Col>
        </Row>
        
        <Row>
          <Col span={7}>Giá thường: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.price))}</Col>
        </Row>
        
        <Row>
          <Col span={7}>Giá bán: </Col>
          <Col>{convertNumberToCurrency(parseInt(record.sale_price))}</Col>
        </Row>
        
        <Row>
          <a className='text-alert' onClick={() => handleAddToCart(record)}>
            Chọn<i className="fas fa-cart-plus"></i>
          </a>
        </Row>
      </Col>,
      responsive: ["xs"],
    },
    {
      title: 'ID',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
      width: 100,
      render: (text, record) => record.product_id,
      responsive: ["md"],
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      width: 150,
      render: (text, record) => record.product_code,
      responsive: ["md"],
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 250,
      render: (text, record) => record.product_name,
      responsive: ["md"],
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit_code',
      key: 'unit_code',
      width: 100,
      render: (text, record) => record.unit_code,
      responsive: ["md"],
    },
    {
      title: 'Kho',
      dataIndex: 'warehouse_code',
      key: 'warehouse_code',
      width: 120,
      render: (text, record) => record.warehouse_code,
      responsive: ["md"],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      key: 'price',
      width: 120,
      align: 'right',
      render: (text) => convertNumberToCurrency(parseInt(text)),
      responsive: ["md"],
    },
    {
      title: 'Giá bán',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: 120,
      align: 'right',
      render: (text) => convertNumberToCurrency(parseInt(text)),
      responsive: ["md"],
    },
    {
      title: 'Hành động',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (key, record) => {
        return (
          <a className='text-alert' onClick={() => handleAddToCart(record)}>
          Chọn<i className="fas fa-cart-plus"></i>
          </a>
        );
      },
      responsive: ["md"],
    },
  ];

  return (
    <Modal 
      // size='xl' 
      // style={{ minWidth: '680px' }} 
      isOpen={visible} 
      toggle={handleClose}>
      <ModalHeader>
        Chọn sản phẩm
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Danh sách sản phẩm</Label>
            <Table
              className='mytable'
              size='small'
              pagination={{hideOnSinglePage: true}}
              columns={productSelectionColumns}
              dataSource={products}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ProducSelectionModal;
