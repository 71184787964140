import React from "react";
import * as productApi from "../../apis/productApi";
import { Button } from "reactstrap";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isUserInGroup, isWarehouseManager } from '../../Utils/SecurityUtils';
import UploadButton from "components/Common/UploadButton";
import ShowHideComponent from "components/Common/ShowHideComponent";
import LinkButton from "components/Common/LinkButton";


function ListProductButton() {

  const selectedShop = useSelector(state => state.SelectedShop);

  const isPhoto = isUserInGroup(['Admin', 'Photo']);
  const isContent = isUserInGroup(['Admin', 'Content']);
  const isKho = (isUserInGroup(['Admin', 'Kho']) || isWarehouseManager());

  const uploadFileSucces = (res) => {
    alert(res.data.message);
  }

  return (
    <>
      <UploadButton
        buttonText="Up mới"
        buttonClass="px-3 mr-2 mb-1 mt-1"
        color="primary"
        fileTypes=".xlsx, .xls"
        iconClass="fas fa-file-excel mr-2"
        inputId="create-products"
        show={isKho}
        uploadKey="import"
        additionalParams={{ company_id: selectedShop }}
        uploadCallback={productApi.uploadFileExcel}
        succesCallback={uploadFileSucces} />

      <UploadButton
        buttonText="Up sửa"
        buttonClass="px-3 mr-2 mb-1 mt-1"
        color="primary"
        fileTypes=".xlsx, .xls"
        iconClass="fas fa-pencil-alt mr-2"
        inputId="update-products"
        show={isKho}
        uploadKey="import"
        additionalParams={{ company_id: selectedShop }}
        uploadCallback={productApi.uploadUpdateProductExcel}
        succesCallback={uploadFileSucces} />

      <UploadButton
        buttonText="Up content"
        buttonClass="px-3 mr-2 mb-1 mt-1"
        color="primary"
        fileTypes=".xlsx, .xls"
        iconClass="fas fa-list mr-2"
        inputId="update-content"
        show={isContent}
        uploadKey="import"
        additionalParams={{ company_id: selectedShop }}
        uploadCallback={productApi.uploadContent}
        succesCallback={uploadFileSucces} />

      <UploadButton
        buttonText="Up ảnh"
        buttonClass="px-3 mr-2 mb-1 mt-1"
        color="primary"
        fileTypes=".jpg, .jpeg, .png, .webp"
        iconClass="fas fa-images mr-2"
        inputId="upload-image"
        show={isPhoto || isKho || isContent}
        uploadKey="images[]"
        additionalParams={{ company_id: selectedShop }}
        uploadCallback={productApi.uploadMultipleImg}
        succesCallback={uploadFileSucces} />

      <LinkButton show={isKho} link='/products/create' buttonText="Thêm mới" buttonClass="px-3 mb-1 mt-1" />

    </>
  );
}

export default withRouter(ListProductButton);
