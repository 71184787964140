
export const validatePrices = (priceFrom, priceTo) => {
    if (priceFrom != "" && priceTo == "") {
        return false
    }
    if (priceFrom == "" && priceTo != "") {
        return false
    }
    if (priceFrom != "" && priceTo != "") {
        if (priceFrom == 0 || priceTo == 0) {
            return false
        }
        if (priceFrom >= priceTo) {
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }

}
export const getUser = () => {
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        return obj
    } 
    return null
}
export const formatMoney = (number) => {
    if (number) {
        return new Intl.NumberFormat('vi-VN').format(number);
    }
};

export const checkPermision = (isAdmin, listRole, role) => {
    if (isAdmin)
        return true
    if (listRole && role) {
        const result = listRole.find(({ module_path }) => module_path === role);
        let finalResult = !(typeof result === 'undefined' || result === null)
        return finalResult
    } else {
        return false
    }
}

export const checkCMSPermision = (isAdmin, currentUserGroups) => {
    if (isAdmin)
        return true
    if (currentUserGroups) {
        const groups = currentUserGroups.split(',');        
        return groups.includes('CMS');
    } else {
        return false
    }
}

export const downloadFile = (file) => {
    const element = document.createElement('a');
    element.setAttribute('href', file);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export const limitString = (txt, number) => {
    if (txt) {
        return txt.length > number ? txt.substring(0, number) + "..." : txt;
    }
}