import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Row, Col, Input, Table, Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { utils } from 'Utils/utils';
import ImagesStoreMulti from 'components/ImagesStore/ImageStoreMulti';
import Select from 'react-select';
import * as preOrderApi from '../../apis/preOrderApi';
import * as utilsApi from '../../apis/utilsApi';
import * as billApi from "../../apis/billApi";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AlertSimple from 'components/Common/AlertSimple';
import Lightbox from 'react-image-lightbox';
import { imageOverlay } from 'leaflet';
import { orderStatusSale, productDetailStatusSale, productDetailStatusOrder, productDetailStatusAdmin } from "./const";
import { API_HOST_NAME } from 'constants/SystemConstant';
import "./style.scss"
import MetaTags from 'react-meta-tags';
import { CacheUtils } from 'Utils/CacheUtils';
import { inUserGroup } from 'helpers/function';
import { isUserInGroup } from 'Utils/SecurityUtils';


function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

PreOrder.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

PreOrder.defaultProps = {
  location: {},
  match: {}
}

function PreOrder(props) {
  const popupRef = useRef();
  let history = useHistory();
  const id = props.match.params?.id;
  let isView = props.match.url.includes("view");
  const selectedShop = useSelector(state => state.SelectedShop);
  const [user, setUser] = useState({});
  
  useEffect(() => {
    const cachedUser = CacheUtils.getCachedUser();
    if (cachedUser) {
      setUser(cachedUser);
    }
  }, [])
  const [addImage, setAddImage] = useState([]);
  const [data, setData] = useState(() => {
    return {
      order_date: utils.dateDefault(),
      pre_paid: 0
    }
  });
  const [listStatusDetail, setListStatusDetail] = useState([]);
  const [listSale, setListSale] = useState([]);
  const [selectedSale, setSelectedSale] = useState({});
  const [listPayment, setListPayment] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [listManOrder, setListManOrder] = useState([]);
  const [selectedManOrder, setSelectedManOrder] = useState({});
  const [selectedProcess, setSelectedProcess] = useState({});
  const [listProcess, setListProcess] = useState([]);
  const [viewImage, setViewImage] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [listWareHouse, setListWareHouse] = useState([]);
  const itemDelete = useRef();
  const [selectedUnit, setSelectedUnit] = useState({});
  const [listUnit, setListUnit] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [showMessage, setShowMessage] = useState({
    state: false,
    message: "",
    title: "",
    typeShow: 'success'
  });

  const dfTemp = {
    product_name: "",
    color: "",
    size: "",
    unit_code: "",
    qty: 0,
    price: 0,
    amount: 0
  }
  const [dataTmp, setDataTmp] = useState(dfTemp);
  const fileDelete = useRef();

  const addImageCallback = (file) => {
    try {
      let curent_img = [...addImage];
      let images = Array.from(file);
      images.forEach(item => {
        if (utils.checkValidImage(item)) {
          let check = curent_img.findIndex(x => x.name === item.name);
          if (check < 0) curent_img.push(item);
        }
      })
      setAddImage(curent_img);
    } catch (ex) { }
  }

  useEffect(() => {
    initSelect();
    fetchData(id);
    if (isUserInGroup(['Sale'])) {
      setListStatusDetail(productDetailStatusSale);
    } else if (isUserInGroup(['Order'])) {
      setListStatusDetail(productDetailStatusOrder)
    } else if (isUserInGroup(['Admin'])) {
      setListStatusDetail(productDetailStatusAdmin)
    }
  }, [id]);

  const fetchSalemans = () => {
    billApi.getSaleMan(fetchSalemansSuccess, fetchSalemansFail);
  };
  
  const fetchSalemansSuccess = res => {
    const { data } = res.data;
    setListSale(data.map((v, i) => { return { value: v.user_id, label: v.fullname } }));
    CacheUtils.setCachedSalemans(data);
  };

  const fetchSalemansFail = () => {
    alert("Không có dữ liệu");
  };

  const initSalemans = () => {

    if (isUserInGroup(['Sale'])) {
        setListSale([{ value: userInfo.user_id, label: userInfo.username }]);
        return;
    }

    const salemans = CacheUtils.getCachedSalemans()
    if (salemans) {
      setListSale(salemans.map((v, i) => { return { value: v.user_id, label: v.fullname } }));
    } else {
      fetchSalemans();
    }
  }

  const initOrdermans = () => {

    const ordermans = CacheUtils.getCachedOrdermans()
    if (ordermans) {
      setListManOrder(salemans.map((v, i) => { return { value: v.user_id, label: v.fullname } }));
    } else {
      utilsApi.getOrderMan(onOrderManSuccess, onSubmitFail);
    }
  }

  const fetchPaymentMethods = () => {
    billApi.getAllPaymentMethods(
      onPaymentSuccess,
      fetchPaymentMethodsFail
    );
  };
  const fetchPaymentMethodsSuccess = res => {
    const { data } = res.data;
    setDataPaymentMethods(data);
    CacheUtils.setCachedPaymentMethods(data)
  };
  const fetchPaymentMethodsFail = () => {
    alert("Không có dữ liệu");
  };

  const initPaymentMethods = () => {
    const paymentMethods = CacheUtils.getCachedPaymentMethods();
    if (paymentMethods) {
      setListPayment(paymentMethods.map((v, i) => { return { value: v.payment_code, label: v.payment_desc } }));
    } else {
      fetchPaymentMethods();
    }
  }
  
  const initSelect = () => {
    if (!user) return;
    try {
      initSalemans();
      initOrdermans();
      initPaymentMethods();

      // to do: optimize init units
      utilsApi.getAllUnits(onUnitSuccess, onSubmitFail);

      isUserInGroup(['Sale'])
        ? setListProcess(orderStatusSale) 
        : utilsApi.getAllProcess(onProcessSuccess, onSubmitFail);
    } catch (ex) {
      alert("ex:" + ex)
    }
  }

  const fetchData = (id) => {
    if (id) {
      preOrderApi.get(selectedShop, id, fetchDataSuccess, fetchDataFail)
    }
  }
  const fetchDataSuccess = (res) => {
    let result = res.data.data[0];
    setData(result);
    setListWareHouse(result.details);

    setSelectedSale({ value: result.saleman.user_id, label: result.saleman.fullname });
    setSelectedManOrder({ value: result.orderman.user_id, label: result.orderman.fullname });
    setSelectedPayment({ value: result.payment.payment_code, label: result.payment.payment_desc });
    setSelectedProcess({ value: result.process_status.status_id, label: result.process_status.status_text });

    reloadTotalMaount(result.details);
  }
  const fetchDataFail = (res) => {
    setShowMessage({
      state: true,
      message: res.message,
      title: "Thất bại",
      typeShow: 'danger'
    });
  }

  const onSaleManSuccess = (res) => {
    setListSale(res.data.data.map((v, i) => { return { value: v.user_id, label: v.fullname } }));
  }
  const onPaymentSuccess = (res) => {
    setListPayment(res.data.data.map((v, i) => { return { value: v.payment_code, label: v.payment_desc } }));
    CacheUtils.setCachedPaymentMethods(res.data.data);
  }
  const onOrderManSuccess = (res) => {
    setListManOrder(res.data.data.map((v, i) => { return { value: v.user_id, label: v.fullname } }));
  }
  const onUnitSuccess = (res) => {
    setListUnit(res.data.data.map((v, i) => { return { value: v.unit_code, label: v.unit_name } }));
  }
  const onProcessSuccess = (res) => {
    setListProcess(res.data.data.map((v, i) => { return { value: v.status_id, label: v.status_text } }));
  }

  const deleteAddImg = (img) => {
    setAddImage(addImage.filter((v, i) => { return v != img }))
  }

  const onDeleteImg = (filename) => {
    fileDelete.current = filename;
    preOrderApi.deleteImg(id, filename, onDeleteImgSuccess, onDeleteImgFail)
  }

  const onDeleteImgSuccess = (res) => {
    let imgs = data.images;
    let index = imgs.findIndex(x => x.image_file == fileDelete.current);
    if (index >= 0) imgs.splice(index, 1);
    data['images'] = imgs;
    setData({ ...data });
    fileDelete.current = "";
  }

  const onDeleteImgFail = (res) => {
    setShowMessage({ state: true, message: res.message, title: "Thất bại", typeShow: 'danger' });
  }

  const onChangeInput = (value, attr) => {
    data[attr] = value;
    setData({ ...data })
  }

  const onChangeInputTmp = (value, attr) => {
    dataTmp[attr] = value;
    setDataTmp({ ...dataTmp })
  }

  const onChangeSelectedSale = (e) => {
    setSelectedSale(e);
  }
  const onChangeSelectedPayment = (e) => {
    setSelectedPayment(e);
  }
  const onChangeSelectedManOrder = (e) => {
    setSelectedManOrder(e);
  }
  const onChangeSelectedUnit = (e) => {
    setSelectedUnit(e);
    dataTmp['unit_code'] = e.label;
  }
  const onChangeSelectedProcess = (e) => {
    const params = {
      order_id: props.match.params?.id,
      process_status: e.value
    }
    if (isUserInGroup(['Admin', 'Sale'])) {
      preOrderApi.changeOrderStatus(params, changeOrderStatusSuccess, fetchDataFail)
    }
    setSelectedProcess(e)
  }

  const handleChangeStatusDetail = (order_detail_id, e) => {
    const params = {
      order_detail_id: order_detail_id,
      process_status: e.value
    }
    if (isUserInGroup(['Admin', 'Sale', 'Order'])) {
      preOrderApi.changeOrderDetailStatus(params, changeOrderStatusSuccess, fetchDataFail)
    }
  }
  const changeOrderStatusSuccess = (res) => {
    fetchData(props.match.params?.id)
  }
  const dataBuilder = () => {
    let result = new FormData();

    result.append('company_id', selectedShop);
    result.append('order_date', data.order_date);
    result.append('customer_phone', data.customer_phone);
    result.append('customer_name', data.customer_name);
    result.append('customer_address', data.customer_address);
    result.append('order_note', data.order_note);
    result.append('expire_date', data.expire_date);

    result.append('saleman_id', selectedSale.value);
    result.append('orderman_id', selectedManOrder.value);
    result.append('payment_code', selectedPayment.value);

    result.append('total_amount', totalAmount)
    result.append('pre_paid', parseFloat(data.pre_paid));
    result.append('row_count', listWareHouse.length);

    if (id) {
      let newListWH = [];
      listWareHouse.forEach(it => {
        if (typeof (it.id) == 'string' && (it.id).startsWith('TMP_ID')) {
          newListWH.push({
            amount: it.amount,
            color: it.color,
            price: it.price,
            product_name: it.product_name,
            qty: it.qty,
            size: it.size,
            unit_code: it.unit_code
          })
        } else {
          newListWH.push(it);
        }
      })
      result.append('details', JSON.stringify(newListWH))
    } else {
      result.append('details', JSON.stringify(listWareHouse))
    }

    if (id) {
      result.append('process_status', selectedProcess.value);
    }
    if (addImage.length != 0) {
      addImage.forEach((v, i) => {
        result.append('images[]', v)
      })
    }
    return result;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (listWareHouse && listWareHouse.length == 0) {
      setShowMessage({ state: true, message: "Vui lòng thêm sản phẩm trước khi thực hiện !", title: "Thất bại", typeShow: 'warning' });
      return;
    }
    let data = dataBuilder();
    if (!id) {
      preOrderApi.create(data, onSubmitSuccess, onSubmitFail);
    } else {
      preOrderApi.update(id, data, onSubmitSuccess, onSubmitFail)
    }
  }
  const onSubmitSuccess = (res) => {
    alert(res.data.message);
    history.push("/preorders");
  }
  const onSubmitFail = (res) => {
    setShowMessage({ state: true, message: res.message, title: "Thất bại", typeShow: 'danger' });
  }
  const returnDefaultValue = (id) => {
    const item = productDetailStatusAdmin.filter(x => x.value === id);
    return item[0]
  }
  const showListProduct = () => {
    try {
      if (!listWareHouse || listWareHouse.length === 0) {
        return (<tr></tr>)
      }
      return listWareHouse.map((v, i) => {
        return (
          <tr key={i}>
            <td>{v.product_name}</td>
            <td>{v.color}</td>
            <td>{v.size}</td>
            <td>{v.unit_code}</td>
            <td>{utils.formatNumber(parseFloat(v.qty))}</td>
            <td>{utils.formatNumber(parseFloat(v.price))}</td>
            <td>{utils.formatNumber(v.amount)}</td>
            {
              isUserInGroup(['Admin', 'Sale', 'Order'])
              ? <td>
                  <Select
                    value={returnDefaultValue(v.process_status)}
                    isMulti={false}
                    onChange={e => handleChangeStatusDetail(v.id, e)}
                    options={listStatusDetail}
                    classNamePrefix="select2-selection"
                    className="fix-width"
                    isLoading={false}
                    placeholder="Thay đổi trạng thái"
                  />
                </td>
                : null
            }
            {!isView &&
              <td>
                <a href="#" className='color-danger' onClick={(e) => { onDeleteItem(e, v) }} >
                  <i className="fas fa-minus-circle"></i>
                </a>
              </td>
            }
          </tr>
        )
      })
    } catch (ex) {
      return (<tr></tr>)
    }

  }

  const removeDetailItem = (item) => {
    itemDelete.current = "";
    let old = [...listWareHouse];
    let index = old.findIndex(it => it.id == item.id);
    old.splice(index, 1);

    setListWareHouse(old);
    reloadTotalMaount(old);
  }


  const onDeleteItem = (e, item) => {
    e.preventDefault();
    if (!id) {
      removeDetailItem(item);
    } else {
      if (typeof (item.id) == 'string' && (item.id).startsWith('TMP_ID')) {
        removeDetailItem(item);
      } else {
        itemDelete.current = item;
        preOrderApi.deleteOrderDetail(item.id, onDeleteItemSuccess, onDeleteItemFail);
      }

    }
  }

  const onDeleteItemSuccess = (res) => {
    removeDetailItem(itemDelete.current);
  }

  const onDeleteItemFail = (res) => {
    setShowMessage({ state: true, message: res.message, title: "Thất bại", typeShow: 'warning' });
  }



  const onAddItem = (e) => {
    e.preventDefault();
    if (
      utils.isEmpty(dataTmp.product_name) 
      || utils.isEmpty(dataTmp.size) 
      || utils.isEmpty(dataTmp.color) 
      || utils.isEmpty(dataTmp.unit_code) 
      || parseInt(dataTmp.qty) == 0 
      || parseInt(dataTmp.price) == 0
    ) {
      setShowMessage({ state: true, message: "Vui lòng điền đầy đủ thông tin", title: "Chú ý", typeShow: 'warning' });
      return;
    }
    dataTmp['id'] = utils.createUUID();
    dataTmp['amount'] = dataTmp.qty * dataTmp.price;
    let value = [...listWareHouse, dataTmp];
    setListWareHouse(value);
    dfTemp['unit_code'] = dataTmp.unit_code;
    setDataTmp(dfTemp);

    reloadTotalMaount(value);
  }

  const reloadTotalMaount = (list) => {
    if (list.size == 0) { setTotalAmount(0); }
    let total = list.reduce((sum, it) => sum + it.amount, 0);
    setTotalAmount(total);
  }

  const imgView = useRef({})
  const onView = (data) => {
    imgView.current = data;
    setViewImage(true)
  }
  const fetchDataAllCustomer = useCallback(
    debounce(phone => {
      onChangeInput(phone, 'customer_phone')
      billApi.getAllCustomer(
        phone,
        fetchDataAllCustomersSuccess,
        fetchDataAllCustomerMethodsFail
      );
    }, 1200),
    []
  );

  const fetchDataAllCustomersSuccess = res => {
    setDataCustomer([]);
    const { data } = res.data;
    setIsToggle(true);
    setDataCustomer(data);
  };
  const fetchDataAllCustomerMethodsFail = res => {
    alert("Không có dữ liệu");
  };
  const showOptionCustom = () => {
    if (dataCustomer.length === 0) {
      return null;
    } else {
      return dataCustomer.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => handleSelectCustomer(item)}
            className="order-phone-customer-item"
          >
            {item.customer_name} - {item.customer_phone}
          </div>
        );
      });
    }
  };
  const handleSelectCustomer = item => {
    setData({
      ...data,
      customer_phone: item.customer_phone,
      customer_name: item.customer_name,
      customer_address: item.customer_address,
    });
    setIsToggle(false);
  };
  const handleClickOutside = e => {
    if (popupRef.current && popupRef.current.contains(e.target)) {
      return;
    }
    setIsToggle(false);
    setDataCustomer([]);
  };
  useEffect(() => {
    if (isToggle) {
      document.addEventListener("click", handleClickOutside, false);
    } else {
      document.removeEventListener("click", handleClickOutside, false);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [isToggle]);
  return (
    <>

      <div className="page-content">
        <MetaTags>
          <title>Chi tiết lệnh đặt hàng</title>
        </MetaTags>
        {viewImage ?
          <Lightbox
            mainSrc={
              (imgView.current).image_file ? API_HOST_NAME + (imgView.current).image_file :
                URL.createObjectURL(imgView.current)
            }
            enableZoom={true}
            onCloseRequest={() => {
              imgView.current
              setViewImage(false)
            }}
          />
          : null
        }
        {showMessage.state ?
          <AlertSimple
            message={showMessage.message}
            type={showMessage.typeShow}
            title={showMessage.title}
            confirmCallback={() => {
              setShowMessage({ state: false });
            }}
          />
          : null
        }
        <Card>
          <CardBody>
            <Card color='light'>
              <CardBody>
                <div className='tag color-primary font-weight-bold mb-3'>
                  THÔNG TIN LỆNH ĐẶT HÀNG
                </div>

                <Row>
                  <Col xs={12} md>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='d-grid my-grid grid-fit-content-auto'>
                          <div>Ngày đặt:</div>
                          <div>
                            <Input
                              type='date'
                              className="form-control"
                              defaultValue={utils.dateDefault()}
                              min={utils.dateDefault()}
                              disabled={true}
                              value={data.order_date}
                              onChange={e => onChangeInput(e.target.value, 'order_date')}
                            />
                          </div>
                          <div>Số order:</div>
                          <div>
                            <Input
                              type='text'
                              className="form-control"
                              value={data?.id}
                              disabled={true}
                            />
                          </div>
                          <div>Số điện thoại:</div>
                          <div className="order-phone-customer">
                            <Input
                              required
                              type="text"
                              disabled={isView}
                              className="form-control"
                              value={data?.customer_phone}
                              onChange={e => fetchDataAllCustomer(e.target.value)}
                            />
                            {isToggle && (
                              <div
                                className="order-popup-phone-customer"
                                ref={popupRef}
                              >
                                {showOptionCustom()}
                              </div>
                            )}
                          </div>
                          <div>Tên khách:</div>
                          <div>
                            <Input
                              type='text'
                              className="form-control"
                              value={data?.customer_name}
                              disabled={isView}
                              onChange={e => onChangeInput(e.target.value, 'customer_name')}
                            />
                          </div>
                          <div>Địa chỉ:</div>
                          <div>
                            <Input
                              type='textarea'
                              className="form-control"
                              value={data?.customer_address}
                              disabled={isView}
                              onChange={e => onChangeInput(e.target.value, 'customer_address')}
                            />
                          </div>
                          <div>Nhân viên sale:</div>
                          <div>
                            <Select
                              value={selectedSale}
                              options={listSale}
                              isDisabled={isView}
                              onChange={onChangeSelectedSale}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='d-grid my-grid grid-fit-content-auto'>
                          <div>Thanh toán:</div>
                          <div>
                            <Select
                              value={selectedPayment}
                              options={listPayment}
                              isDisabled={isView}
                              onChange={onChangeSelectedPayment}
                            />
                          </div>
                          <div>Nhân viên đặt hàng:</div>
                          <div>
                            <Select
                              value={selectedManOrder}
                              options={listManOrder}
                              isDisabled={isView}
                              onChange={onChangeSelectedManOrder}
                            />
                          </div>
                          <div>Ngày hết hạn: </div>
                          <div>
                            <Input
                              type='date'
                              className="form-control"
                              min={utils.dateDefault()}
                              value={data.expire_date}
                              disabled={isView}
                              onChange={e => onChangeInput(e.target.value, 'expire_date')}
                            />
                          </div>
                          <div>Ghi chú:</div>
                          <div>
                            <Input type='textarea'
                              className="form-control"
                              value={data.order_note}
                              disabled={isView}
                              onChange={e => onChangeInput(e.target.value, 'order_note')}
                            />
                          </div>
                          {id && <div>Trạng thái xử lý:</div>}
                          {id && (isUserInGroup(['Admin', 'Sale'])) ?
                            <Select
                              value={selectedProcess}
                              options={listProcess}
                              isDisabled={isView}
                              onChange={onChangeSelectedProcess}
                            />
                            : <Select
                              value={selectedProcess}
                              options={listProcess}
                              isDisabled={true}
                            />
                          }


                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card color='light'>
              <CardBody>
                <div className='tag color-primary font-weight-bold mb-3'>
                  Hình ảnh
                </div>

                <Row>
                  <Col xs={12} lg={12}>
                    <ImagesStoreMulti
                      data={data.images ? data.images : []}
                      addImageCallback={addImageCallback}
                      addData={addImage}
                      deleteAddImg={deleteAddImg}
                      deleteImg={onDeleteImg}
                      disabled={isView}
                      onView={onView}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Table className="table-striped mb-0 nowrap-table ">
              <thead>
                <tr>
                  <th>Tên sản phẩm</th>
                  <th>Màu</th>
                  <th>Size</th>
                  <th>ĐVT</th>
                  <th>Số lượng</th>
                  <th>Đơn giá (vnđ)</th>
                  <th>Tạm tính (vnđ)</th>
                  {
                    isUserInGroup(['Admin', 'Sale', 'Order']) ?
                      <th>
                        Trạng thái
                      </th>
                      : null
                  }
                  {!isView && <th>Thao tác</th>}
                </tr>

              </thead>
              <tbody>
                {showListProduct()}
                {!isView &&
                  <tr>
                    <td>
                      <Input
                        type='text'
                        className="form-control"
                        value={dataTmp.product_name}
                        onChange={e => onChangeInputTmp(e.target.value, 'product_name')}
                      />
                    </td>
                    <td style={{ width: 100 }}>
                      <Input
                        type='text'
                        className="form-control"
                        value={dataTmp.color}
                        onChange={e => onChangeInputTmp(e.target.value, 'color')}
                      />
                    </td>
                    <td style={{ width: 100 }}>
                      <Input
                        type='text'
                        className="form-control"
                        value={dataTmp.size}
                        onChange={e => onChangeInputTmp(e.target.value, 'size')}
                      />
                    </td>
                    <td style={{ width: 180 }}>
                      <Select
                        value={selectedUnit}
                        options={listUnit}
                        onChange={onChangeSelectedUnit}
                      />
                    </td>
                    <td style={{ width: 100 }}>
                      <Input
                        type='text'
                        className="form-control"
                        value={dataTmp.qty}
                        onChange={e => onChangeInputTmp(e.target.value, 'qty')}
                      />
                    </td>
                    <td style={{ width: 200 }}>
                      <Input
                        type='text'
                        className="form-control"
                        value={dataTmp.price}
                        onChange={e => onChangeInputTmp(e.target.value, 'price')}
                      />
                    </td>
                    <td>
                      <div>
                        {utils.formatNumber(parseFloat(dataTmp.qty ? dataTmp.qty : 0) * parseFloat(dataTmp.price ? dataTmp.price : 0))}
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <a href="#" className='color-success' onClick={onAddItem}>
                        <i className="fas fa-plus-square"></i>
                      </a>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>

            <div className='mt-4'>
              <div className='d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right'>
                <div className='h4 mb-0'>Tổng tiền:</div>
                <div className='h2 mb-0 color-primary'>{utils.formatMoney(totalAmount)}</div>
                <div className='h4 mb-0'>Đã cọc:</div>
                {isView ?
                  <div className='h2 mb-0'>{utils.formatMoney(data.pre_paid)}</div> :
                  <div className='body-1'>
                    <Input
                      type='text'
                      style={{ width: '120px' }}
                      value={utils.formatNumber(data.pre_paid)}
                      onChange={e => onChangeInput(e.target.value, 'pre_paid')}
                    />
                  </div>
                }

              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              {!isView ?
                <div className=''>
                  <Button
                    color="success"
                    className='px-4 mr-2'
                    style={{ width: '120px' }}
                    onClick={onSubmit}
                  >Lưu lại</Button>
                </div> :
                <div></div>
              }
              {
                data?.process_status?.status_id == 103 ?
                  <div className=''>
                    <Link className='color-success' to={{
                      pathname: '/orders/create',
                      state: { detail: data }
                    }} >
                      <Button
                        style={{ margin: '0 10px' }}
                        color="primary"
                        className='px-4'
                      >Tạo hóa đơn</Button>
                    </Link>
                  </div> :
                  null
              }
              <div className=''>
                <Button
                  color="success"
                  className="px-4"
                  style={{ width: "120px" }}
                  onClick={() => history.goBack()}
                >
                  Quay lại
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default PreOrder;
