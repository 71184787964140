import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import { limitString } from "constants/function";
import { API_HOST_NAME } from "constants/SystemConstant";

function CategoryMenuTableItem(props) {
  const { data, count, deleteCallback, restoreCallback } = props;
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  const onDelete = () => {
    deleteCallback(data.id);
  };

  const onRestore = () => {
    restoreCallback(data.id);
  };

  return (
    <tr>
      <td>{count}</td>
      <td>{limitString(data.category_name, 30)}</td>
      <td><img 
        src={data.image?.startsWith('http') ? data.image : API_HOST_NAME + data.image} 
        style={{ width: "200px", height: '60px', objectFit:'cover' }}
        /></td>
      <td>{data.display_order}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/category-menu/edit/${data.category_id}`}>
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
CategoryMenuTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default CategoryMenuTableItem;
