import React, {useState, useEffect} from "react"
import { Row, Col, Label } from "reactstrap"
import Select from "react-select"
import { PropTypes } from 'prop-types';

import * as shopApi from './../../../apis/shopApi';

FilterCompany.propTypes = {
    onChange: PropTypes.func,
}

function FilterCompany({onChange}) {
    const [company, setCompany] = useState();
    const [companies, setCompanies] = useState();

    const handleChange = (selectedCompany) => {
        onChange(selectedCompany.value);
        setCompany(selectedCompany);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = () => {
        shopApi.getAllShop(parseCompanies, showErr);
    }

    const parseCompanies = (res) => {

        let listCompany = res.data.data.map(item => {
            return {
                label: item.company_name,
                value: item.company_id
            }
        })

        setCompanies(listCompany);

        if ((listCompany !== undefined) && (listCompany.length > 0)) {
            onChange(listCompany[0].value);
            setCompany(listCompany[0]);
        }
    }

    const showErr = (res) => {
        alert(res.message);
    }

    return (
        <Row xl={12} className="mb-2">
            <Label md={12} xl={2} className="col-form-label">Hệ thống cửa hàng</Label>
            <Col md={12} xl={4}>
                <Select
                    value={company}
                    isMulti={false}
                    onChange={handleChange}
                    options={companies}
                    classNamePrefix="select2-selection"
                />
            </Col>
        </Row>
    );
}

export default FilterCompany;