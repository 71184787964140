
import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getList = (params, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);    
}

export const deleteOrder = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr);
}

export const restoreOrder = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/restore/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr);
}

export const create = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders';
    let token = localStorage.getItem("token")

    commonApis.POST_FILE(url, data, token, '', callback, callbackErr)
}

export const get = (selectedShop, id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/' + id;
    let params = [{
        name: 'company_id',
        value: selectedShop
    }]
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}

export const update = (id,data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/update/'+id;
    let token = localStorage.getItem("token")

    commonApis.POST_FILE(url, data, token, '', callback, callbackErr)
}

export const deleteImg = (id,filename, callback, callbackErr) => {

    let url = API_HOST_NAME + `/api/orders/${id}/image`;
    if (!filename || !id) return
    let params = [{
        name: 'image_file',
        value: filename
    }]
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, params, callback, callbackErr);
}

export const deleteOrderDetail = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/detail/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr);
}

export const changeOrderStatus = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/status';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr);
}

export const changeOrderDetailStatus = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/detail/status';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr);
}

export const setPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/orders/" + id + "/set-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const setNotPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/orders/" + id + "/set-not-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};



