import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { PropTypes } from 'prop-types';
import { Link, useHistory, withRouter } from 'react-router-dom';
import * as shopApi from '../../apis/shopApi'
import { getMessage } from 'Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function Shop(props) {
  const id = props.match.params?.id;
  const [data, setData] = useState({})
  let history = useHistory();
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [props.match.params])
  const fetchData = (id) => {
    shopApi.getShop(id, fetchDataSuccess, fetchDataFail)
  }
  const fetchDataSuccess = (res) => {
    setData(res.data.data)
  }
  const fetchDataFail = (res) => {
    alert("Không có dữ liệu")
  }
  const onChangeData = (value, attrName) => {
    data[attrName] = value;
    setData({ ...data })
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (id) {
      shopApi.updateShop(id, data, onSubmitSuccess, onSubmitFail)
    }
    else {
      shopApi.createShop(data, onSubmitSuccess, onSubmitFail)
    }
  }
  const onSubmitSuccess = (res) => {
    history.push("/shops")
  }
  const onSubmitFail = (res) => {
    alert(res?.message ? getMessage(res.message) : "Lưu thất bại")
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết công ty</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Danh mục cửa hàng</CardTitle>
          <Form onSubmit={onSubmit}>
            <div className="mb-3">
              <Label htmlFor="Shop-name-Input">Tên cửa hàng</Label>
              <Input
                type="text"
                className="form-control"
                id="Shop-name-Input"
                placeholder="Nhập tên cửa hàng"
                value={data.company_name}
                onChange={e => onChangeData(e.target.value, 'company_name')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="Shop-address-Input">Địa chỉ cửa hàng</Label>
              <Input
                type="text"
                className="form-control"
                id="Shop-address-Input"
                placeholder="Nhập địa chỉ cửa hàng"
                value={data.company_address}
                onChange={e => onChangeData(e.target.value, 'company_address')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="Shop-phone-Input">Số điện thoại cửa hàng</Label>
              <Input
                type="text"
                className="form-control"
                id="Shop-phone-Input"
                placeholder="Nhập số điện thoại cửa hàng"
                value={data.company_phone}
                onChange={e => onChangeData(e.target.value, 'company_phone')}
              />
            </div>
            <div>
              <Row>
                <Col xs={12} sm="auto">
                  <button type="submit" className="btn btn-primary w-md">
                    Lưu
                  </button>
                </Col>
                <Col xs={12} sm="auto">
                  <Link to="/shops" className="btn btn-secondary w-md">
                    Hủy
                  </Link>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
Shop.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(Shop)
