import CurrencyTableItem from 'components/TableItem/CurrencyTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as currencyApi from '../../apis/currencyApi';


import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap"

import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'Utils/UseQuery';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import ConfirmDelete from './../../components/Common/ConfirmDelete';
import { getMessage } from 'Utils/ErrMessageUtils';

function ListCurrency(props) {
    const [data, setData] = useState([])
    const [additionalData, setAdditionalData] = useState({})
    const [deleteCode, setDeleteCode] = useState(null);
    let history = useHistory();
    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }, [props.location.search])
    const fetchData = page => {
        currencyApi.getList(page, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = res => {
        setAdditionalData(res.data.data)
        setData(res.data.data.data);
    }
    const fetchDataFail = res => {
        alert("Lỗi xảy ra")
    }
    const showCurrencies = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<CurrencyTableItem key={v.code} data={v} count={i + 1} deleteCallback={setDeleteCode} />)
        })
    }
    const onDelete = (code) => {
        currencyApi.deleteCurrency(code, deleteSuccess, deleteFail)
    }
    const deleteSuccess = (res) => {
        setDeleteCode(null)
        alert("Xóa thành công")
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }
    const deleteFail = (res) => {
        alert(getMessage(res.message))
        setDeleteCode(null)
    }

    const onChangePage = (page) => {
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }
    return (
        <div className="page-content">
            {deleteCode ?
                <ConfirmDelete confirmCallback={() => { onDelete(deleteCode) }}
                    cancelCallback={() => {
                        setDeleteCode(null)
                    }}
                />
                : ''}
            <Card>
                <CardBody>
                    <CardTitle className="h4">Danh mục đơn vị tiền tệ</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">

                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/currencies/create">
                                <Button color='primary' className='px-3 rounded-pill'>
                                    <i className="fas fa-plus mr-2"></i> Thêm mới
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>Mã đơn vị tiền tệ</th>
                                    <th>Tên đơn vị tiền tệ</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showCurrencies()}
                            </tbody>
                        </Table>
                    </div>
                    <MyPagination 
                        startPage={additionalData.current_page ? additionalData.current_page : 1} 
                        totalItemsCount={additionalData.total ? additionalData.total : 5} 
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
                        callback={onChangePage}
                        />
                </CardBody>
            </Card>
        </div>
    )
}
ListCurrency.propTypes = {
    location: PropTypes.object,
}


export default withRouter(ListCurrency);
