import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { Switch } from 'antd';
import * as bannerApi from "../../../apis/bannerApi";
import PreviewImg from "../../../constants/component/PreviewImg";
import "./style.css"
import { API_HOST_NAME } from "constants/SystemConstant";
import MetaTags from 'react-meta-tags';


function OrderView(props) {
  let params = useParams();
  let history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const popupRef = useRef();
  const [form, setForm] = useState({});
  const [selectedTypeBanner, setSelectedTypeBanner] = useState({})
  const [isShowBanner, setIsShowBanner] = useState()
  const selectedShop = useSelector(state => state.SelectedShop);
  useEffect(() => {
    if (params.id) {
      fetchDetail(params.id);
    }
  }, [params.id]);

  const fetchDetail = id => {
    bannerApi.getDetail(id, fetchDetailBannerlSuccess, fetchDetailBannerFailure);
  };
  const renderSelectedType = (type) => {
    if (type === "image") {
      return {
        "label": "Ảnh",
        "value": "image"
      }
    } else {
      return {
        "label": "Video",
        "value": "video"
      }
    }
  }
  const routeBack = () => {
    history.push("/banner")
  }
  const fetchDetailBannerlSuccess = res => {
    const { data } = res.data;
    setForm(data)
    setIsShowBanner(data?.status === 1 ? true : false)
    setSelectedTypeBanner(renderSelectedType(data?.banner_media_type))
  };
  const fetchDetailBannerFailure = err => {
  };

  const submitForm = e => {
    e.preventDefault();
    const params = {

    }
  };
  const optionGroup = [
    { label: "Ảnh", value: "image" },
    { label: "Video", value: "video" }
  ]

  const handleSelectGroup = (selectedGroup) => {
    setSelectedTypeBanner(selectedGroup)
  }
  const onChange = (checked) => {
    setIsShowBanner(checked)
  };
  return (
    <div className="page-content">
    <MetaTags>
        <title>CMS | Chi tiết banner</title>
    </MetaTags>
      <Form className="form" onSubmit={e => submitForm(e)}>
        <Card>
          <CardBody>
            <Card color="light">
              <CardBody>
                <div className="color-primary tag mb-3">THÔNG TIN BANNER </div>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Header</div>
                      <div>
                        <Input
                          disabled
                          name="header"
                          type="text"
                          className="form-control"
                          value={form?.header}
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Tiêu đề</div>
                      <div>
                        <Input
                          disabled
                          name="title"
                          type="text"
                          className="form-control"
                          value={form?.title}
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Nội dung</div>
                      <div>
                        <textarea
                          disabled
                          name="content"
                          className="form-control"
                          rows="8"
                          value={form?.content}
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Link banner</div>
                      <div>
                        <Input
                          disabled
                          name="banner_link"
                          type="text"
                          className="form-control"
                          value={form?.banner_link}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Loại banner</div>
                      <div>
                        <Select
                          isDisabled={true}
                          value={selectedTypeBanner}
                          onChange={handleSelectGroup}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Link ảnh/Video</div>
                      <div>
                        {
                          form?.banner_media_type === "image" &&
                          <img src={form?.banner_media_link} style={{ width: '100px', height: 'auto' }} />
                        }
                        {
                          form?.banner_media_type === "video" &&
                          <video
                            muted
                            autoPlay
                            loop className="video" style={{ width: '200px' }}>
                            <source src={form?.banner_media_link} type="video/mp4" />
                          </video>
                        }
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Tên nút</div>
                      <div>
                        <Input
                          disabled
                          name="button_title"
                          type="text"
                          className="form-control"
                          value={form?.button_title}
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Link nút</div>
                      <div>
                        <Input
                          disabled
                          name="button_link"
                          type="text"
                          className="form-control"
                          value={form?.button_link}
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Thứ tự hiển thị</div>
                      <div>
                        <Input
                          disabled
                          name="banner_order"
                          type="text"
                          className="form-control"
                          value={form?.banner_order}
                        />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Hiển thị/Ẩn</div>
                      <div>
                        <Switch checked={isShowBanner} onChange={onChange} disabled />
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form"></div>
                      <div>
                        <Button color="secondary" onClick={routeBack}>
                          Quay lại
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default OrderView;
