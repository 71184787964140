
import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getListShop = (page, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/companies';
    let params = []
    if (page) {
        params.push({
            name: 'page',
            value: page
        })
    }
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}
export const getShop = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/companies/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}
export const getAllShop = (callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-companies';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}
export const createShop = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/companies';
    let token = localStorage.getItem("token")

    commonApis.POST(url, data, token, '', callback, callbackErr)

}
export const updateShop = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/companies/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}