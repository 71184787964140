import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { Form, Input, message, Select } from 'antd';
import * as billApi from "../../../apis/billApi";
import { addSetItem, updateSetItem } from "../../../apis/productApi";
import { debounce } from 'Utils/global';
const { Option } = Select;

function ProductSetModal(props) {

  const [item, setItem] = useState({
    id: null,
    product_id: null,
    set_id: null,
    product_code: "",
    product_name: "",
    unit_code: "",
    base_qty: 1,
    qty_rate: 1,
  });
  const [productCodes, setProductCodes] = useState([]);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (props.data) {
      setItem({...item, ...props.data});
    }
  }, [props.data])

  useEffect(() => {
    if (closing) {
      handleClose();
    }
  }, [closing])

  const handleClose = () => {
    props.onClose(item);
  }

  const handleSave = () => {
    setClosing(false);
    if (item.id) {
      updateSetItem(item, handleSuccess, handleError);
    } else {
      addSetItem(item, handleSuccess, handleError);
    }
  }

  const handleSuccess = res => {
    setItem({...item, ...res.data.item});
    setClosing(true);
    message.success(res.data.message);
  };

  const handleError = res => {
    message.error(res.message)
  };

  const getProductList = useCallback(
    debounce(val => {
      if (val && (props.selectedShop > 0)) {        
        billApi.getProductFilter(
          props.selectedShop,
          val,
          fetchProductSuccess,
          fetchProductFail
        );
      }
    }, 500),
    []
  );

  const fetchProductSuccess = res => {
    setProductCodes(res.data.data);
  };

  const fetchProductFail = res => {
    setProductCodes([]);
  };

  const onSearchProductCode = val => {
    getProductList(val);
  };

  const onChangeProductCode = (value) => {
    const selectItem = productCodes.find(i => i.product_id == value);
    const itemProduct = {
      product_code: selectItem?.product_code,
      product_name: selectItem?.product_name,
      unit_code: selectItem?.unit_code,
      product_id: selectItem?.product_id,
    };
    setItem({ ...item, ...itemProduct });
  };

  return (
    <Modal isOpen={props.show} toggle={handleClose}>
      <ModalHeader>
        Chi tiết sản phẩm trong bộ
      </ModalHeader>
      <ModalBody>
        <Form layout='vertical'>
          <Form.Item label="Mã sản phẩm">
            <Select
              showSearch
              placeholder="Nhập mã sản phẩm"
              onChange={onChangeProductCode}
              onSearch={onSearchProductCode}
              filterOption={false}
              optionLabelProp="value"
              value={item?.product_code}
            >
              {productCodes.map(i => (
                <Option key={i.product_id} value={i.product_id}>
                  {i.product_code} - {i.product_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tên sản phẩm">
            <Input.TextArea
              readOnly={true}
              disabled={true}
              value={item?.product_name}
            />
          </Form.Item>
          <Form.Item label="Đơn vị tính">
            <Input
              type="text"
              readOnly={true}
              disabled={true}
              value={item?.unit_code}
            />
          </Form.Item>
          <Form.Item label="Số lượng">
            <Input
              value={item?.base_qty}
              onChange={e => setItem({ ...item, ...{ base_qty: e.target.value } })}
            />
          </Form.Item>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button color="primary" onClick={handleSave}>
          Lưu lại
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ProductSetModal.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  selectedShop: PropTypes.any,
}
export default ProductSetModal;
