import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Label, Row } from 'reactstrap'
import { withRouter, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import * as authApi from '../../apis/authApi'
import { GENDER } from '../../common/data/gender';
import { InputGroup, Button } from 'reactstrap';
import { validatePass } from './../../Utils/Validate';
import { getMessage } from 'Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import { showMessage } from 'Utils/global';

const initialUserState = {
  user_id: 0,
  username: "",
  fullname: "",
  phone: "",
  email: "",
  address: "",
  birthday: "",
  gender: 0,
  can_list_sale: 0,
  is_admin: 0,
  is_warehouse_manager: 0,
  role: "",
  roles: [],
}

function User(props) {
  const id = props.match.params?.id;
  const isAddMode = (id == 'new');
  const [data, setData] = useState(initialUserState);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  let history = useHistory();

  useEffect(() => {
    fetchUserGroups();
    if (id && parseInt(id) > 0) {
      fetchData(id);
    }
  }, [])

  const fetchData = (id) => {
    authApi.get(id, fetchDataSuccess, showMessage)
  }

  const fetchDataSuccess = (res) => {
    const { data } = res.data;
    console.log('user data = ', data);
    setData(data);

    const tmpGroups = data.role.split(',');
    const groups = tmpGroups.map((item) => ({ value: item, label: item }));
    setSelectedUserGroups(groups);
  }
  
  const fetchUserGroups = () => {
    authApi.getGroups(populateUserGroups, showMessage)
  }
  const populateUserGroups = (res) => {
    const { data } = res.data;
    const groups = data.map((item) => ({ value: item, label: item }));
    setUserGroups(groups);
  }

  const onChangeData = (value, attrName) => {
    data[attrName] = value;
    setData({ ...data })
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (validatePassword()) {
      if (id) {
        authApi.update(id, data, onSubmitSuccess, onSubmitFail)
      }
      else {
        authApi.create(data, onSubmitSuccess, onSubmitFail)
      }
    }
  }
  const validatePassword = () => {
    if (id && !data.password) {
      delete data.password
      return true
    }
    if (!validatePass(data.password)) {
      alert("Mật khẩu phải tối thiểu 6 ký tự và bao gồm số, chữ thường, chữ hoa và ký tự đặc biệt");
      return false;
    }
    if (data.password != data.confirmPassword) {
      alert("Xác nhận mật khẩu không trùng với mật khẩu");
      return false;
    }
    return true;
  }
  const onSubmitSuccess = (res) => {
    history.push("/users")
  }
  const onSubmitFail = (res) => {
    alert(getMessage(res.message))
  }
  const renderGender = () => {
    return GENDER.map((v, i) => {
      return (
        <option key={i} value={v.value}>{v.label}</option>
      );
    })
  }
  
  const toggleShowPasswordInput = (id) => {
    if (document.getElementById(id).type == 'password') {
      document.getElementById(id).type = 'text'
    }
    else {
      document.getElementById(id).type = 'password'
    }
  }

  const onUserGroupsChanged = (groups) => {
    setSelectedUserGroups(groups);
    const simpleGroups = groups.map(item => (item.value));
    const groupStr = simpleGroups.join(',');
    data['role'] = groupStr;
    setData({ ...data })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết người dùng</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Danh mục người dùng</CardTitle>
          <Form onSubmit={onSubmit}>
            <div className="mb-3">
              <Label htmlFor="user-name-input">Username</Label>
              <input
                type="text"
                className="form-control"
                id="user-code-input"
                placeholder="Nhập tên tài khoản"
                value={data.username}
                onChange={e => onChangeData(e.target.value, 'username')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-name-input">Mật khẩu</Label>
              <InputGroup >
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={data.password}
                  onChange={e => onChangeData(e.target.value, 'password')}
                />
                <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={e => toggleShowPasswordInput('password')}>
                  <span className="input-group-text"><i className="far fa-eye" style={{ fontSize: '20px' }}></i></span>
                </span>
              </InputGroup>
            </div>
            <div className="mb-3">
              <Label htmlFor="user-name-input">Nhập lại mật khẩu</Label>
              <InputGroup >
                <input
                  type="password"
                  className="form-control"
                  id="confirm-password"
                  value={data.confirmPassword}
                  onChange={e => onChangeData(e.target.value, 'confirmPassword')}
                />
                <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={e => toggleShowPasswordInput('confirm-password')}>
                  <span className="input-group-text"><i className="far fa-eye" style={{ fontSize: '20px' }}></i></span>
                </span>
              </InputGroup>
            </div>
            <div className="mb-3">
              <Label htmlFor="user-name-input">Nhóm người dùng</Label>
              <Select
                style={{ width: "500px" }}
                isMulti={true}
                value={selectedUserGroups}
                options={userGroups}
                onChange={onUserGroupsChanged} />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-name-input">Tên người dùng</Label>
              <input
                type="text"
                className="form-control"
                id="user-name-input"
                placeholder="Nhập tên người dùng"
                value={data.fullname}
                onChange={e => onChangeData(e.target.value, 'fullname')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-address-input">Địa chỉ người dùng</Label>
              <input
                type="text"
                className="form-control"
                id="user-address-input"
                placeholder="Nhập địa chỉ người dùng"
                value={data.address}
                onChange={e => onChangeData(e.target.value, 'address')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-phone-input">Số điện thoại người dùng</Label>
              <input
                type="text"
                className="form-control"
                id="user-phone-input"
                placeholder="Nhập số điện thoại người dùng"
                value={data.phone}
                onChange={e => onChangeData(e.target.value, 'phone')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-email-input">Email người dùng</Label>
              <input
                type="text"
                className="form-control"
                id="user-email-input"
                placeholder="Nhập email người dùng"
                value={data.email}
                onChange={e => onChangeData(e.target.value, 'email')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-birthday-input">Ngày sinh</Label>
              <input
                type="date"
                className="form-control"
                id="user-birthday-input"
                value={data.birthday}
                onChange={e => onChangeData(e.target.value, 'birthday')}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="user-gender-input">Giới tính</Label>
              <select className="form-control" value={data.gender}
                id="user-gender-input"
                onChange={e => onChangeData(e.target.value, 'gender')}>
                {renderGender()}
              </select>
            </div>
            {/* <div className="mb-3">
                            <Label htmlFor="user-role-input">Vai trò người dùng</Label>
                            <input
                                type="text"
                                className="form-control"
                                id="user-role-input"
                                placeholder="Nhập Vai trò người dùng"
                                value={data.fullname}
                                onChange={e => onChangeData(e.target.value, 'fullname')}
                            />
                        </div> */}
            <Row>
              <Col xs={12} sm="auto">
                <Label>
                  Quản lý sale
                </Label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="list-sale"
                    switch="none"
                    checked={data.can_list_sale}
                    onChange={() =>
                      setData({ ...data, can_list_sale: (data.can_list_sale ? 0 : 1) })
                    }
                  />
                  <label
                    htmlFor="list-sale"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </Col>
              <Col xs={12} sm="auto">
                <Label for='is-admin'>
                  Có quyền admin hệ thống
                </Label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="is-admin"
                    switch="none"
                    checked={data.is_admin}
                    onChange={() =>
                      setData({ ...data, is_admin: (data.is_admin ? 0 : 1) })
                    }
                  />
                  <label
                    htmlFor="is-admin"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </Col>
              <Col xs={12} sm="auto">
                <Label for='is-warehouse-manager'>
                  Có quyền admin kho
                </Label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="is-warehouse-manager"
                    switch="none"
                    checked={data.is_warehouse_manager}
                    onChange={() =>
                      setData({ ...data, is_warehouse_manager: (data.is_warehouse_manager ? 0 : 1) })
                    }
                  />
                  <label
                    htmlFor="is-warehouse-manager"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </Col>

            </Row>
            <div>
              <Row>
                <Col xs={12} sm="auto">
                  <button type="submit" className="btn btn-primary w-md">
                    Lưu
                  </button>
                </Col>
                <Col xs={12} sm="auto">
                  <Button
                    className="btn btn-secondary w-md"
                    style={{ width: "120px" }}
                    onClick={() => history.goBack()}
                  >
                    Quay lại
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

User.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(User)
