
import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';
import axios from 'axios';

export const getUsers = (page, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/users"
    let params = [
        {
            name: 'page',
            value: page ? page : 1
        },
    ]
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}


export const getAllRole = ( callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/roles/all"
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);
}

export const getRolesUser = (id,callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/users/${id}/roles`;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);
}
export const update = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/users/${id}/roles`;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}

export const getListModules = (id,callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/users/modules/` + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);
}