import React, { useCallback, useEffect, useState } from 'react';

import { CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import { Card } from 'reactstrap';
import { Row } from 'reactstrap';
import { Col } from 'reactstrap';
import { Input } from 'reactstrap';

import { Button } from 'reactstrap';
import PropTypes from "prop-types";
import 'antd/dist/antd.css';
import "./receipt.scss";
import {
  filterProductsSelector,
  wareHouseReceiptDetailSelector,
  wareHouseReceiptsSelector, wareHouseReceiptTypesSelector,
  wareHousesSelector
} from "../../store/wareHouseReceipt/selector";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import {
  confirmHouseReceipt,
  filterProducts, getListWareHouseReceiptTypes,
  getWareHouseReceiptDetail,
  getWareHouseReceipts,
  getWareHouses, saveWareHouseReceipt
} from "../../store/wareHouseReceipt/actions";
import { selectedShopRoot } from "../../store/selectedShop/selector";
import { debounce, showMessage } from "../../Utils/global";
import moment from "moment";
import { Redirect, useHistory } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;

function WareHouseReceipt({ wareHouseReceiptDetail, selectedShop, wareHouses, filroducts, match, wareHouseReceiptTypes }) {
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("authUser"));
  useEffect(() => {
    dispatch(getListWareHouseReceiptTypes())
    dispatch(getWareHouses({ selectedShop, page: 1 }))
  }, [dispatch, selectedShop]);

  let history = useHistory();
  const [listItems, setListItems] = useState([]);
  const [qty, setQty] = useState(0);
  const [rcDate, setRcDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [rcNote, setRcNote] = useState("");
  const [rcType, setRcType] = useState(1);
  const [rcTotalQty, setRcTotalQty] = useState(0);
  const [rcUserInfo, setRcUserInfo] = useState({
    user_id: userInfo.user_id,
    username: userInfo.username,
    fullname: userInfo.fullname
  });
  const [whId, setWhId] = useState(0);
  const [itemProduct, setItemProduct] = useState({
    product_code: "",
    product_name: "",
    qty: "",
    warehouse_code: "",
    unit_code: "",
    product_id: ""
  });

  useEffect(() => {
    const { params } = match;
    if (params && parseInt(params?.id) > 0) {
      setWhId(parseInt(params?.id))
      dispatch(getWareHouseReceiptDetail({ id: params?.id }));
    }
  }, []);

  useEffect(() => {
    if (whId > 0 && wareHouseReceiptDetail && wareHouseReceiptDetail?.data?.length > 0) {
      const rcData = wareHouseReceiptDetail.data[0]
      setRcDate(moment(new Date(rcData.receipt_date)).format("YYYY-MM-DD"));
      setRcNote(rcData.receipt_note);
      setRcType(rcData.receipt_type);
      setRcTotalQty(rcData.total_qty);
      setRcUserInfo(rcData.userinfo);
      if (rcData?.details?.length > 0) {
        setListItems(rcData?.details)
      }
    }
  }, [wareHouseReceiptDetail]);

  const resetItemProduct = () => {
    setItemProduct({
      product_code: "",
      product_name: "",
      qty: 0,
      warehouse_code: "",
      unit_code: ""
    })
    setQty(0)
  }

  /*const showListProduct = () => {
      if (data.length === 0) {
          return (<tr></tr>)
      }
      return data.map((v, i) => {
          return (
              <tr key={i}>
                  <td>{v.code}</td>
                  <td>{v.name}</td>
                  <td>{v.unit}</td>
                  <td>{v.storage}</td>
                  <td>{v.quantity}</td>
                  <td>
                      <a href="#" className='color-danger'>
                          <i className="fas fa-minus-circle"></i>
                      </a>
                  </td>
              </tr>
          )
      })
  }*/
  /*const updateListProduct = ({order,field,value}) => {
      if(field == "qty" && (!value && value > listProducts[order].qty)){
          alert(`Số lượng ko được vượt quá ${listProducts[order].qty}`);
      }else if((field == "code" || field == "warehouse_code" ) && !value) {
          alert("Vui lòng nhập đầy đủ thông tin");
      }else{
          setListProducts(oldProducts => {
              return oldProducts?.map((item,iIndex)=> {
                  if(iIndex == order){
                      let newItem = item;
                      newItem[field] = value;
                      return newItem;
                  }else{
                      return item;
                  }
              })
          })
      }
  }*/
  const onAddItem = () => {
    if (qty <= 0 || itemProduct.product_code == "" || itemProduct.warehouse_code == "") {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else {
      const item = {
        ...itemProduct,
        qty: qty
      }
      setListItems(oldItems => {
        return [...oldItems, item]
      });
      resetItemProduct();
    }
  }
  const onSaveListProducts = useCallback(() => {
    if (parseInt(rcType) <= 0) {
      alert("Vui lòng chọn loại phiếu nhập");
    } else {
      dispatch(saveWareHouseReceipt({
        postData: {
          receipt_date: rcDate,
          receipt_type: rcType,
          receipt_note: rcNote,
          row_count: listItems?.length ?? 0,
          company_id: selectedShop,
          details: listItems
        }, cb: ({ success, error }) => {
          showMessage(error)
          if (success) {
            history.push("/warehouse-receipts")
          }
        }
      }))
    }

  }, [rcType, rcNote, rcDate, listItems])

  const onSearchProductCode = useCallback(
    debounce((text) => fetchDropdownOptions(text), 1000),
    [selectedShop]
  );
  function fetchDropdownOptions(keyword) {
    try {
      if (selectedShop > 0 && keyword) {
        dispatch(filterProducts({ product_code: keyword, company_id: selectedShop }))
      }
    } catch {

    }
  }
  const onChangeProductCode = (key, values) => {
    const item = {
      product_code: values?.value,
      product_name: values?.productName,
      unit_code: values?.unitCode,
      product_id: values?.productId
    }
    setItemProduct(oldItem => {
      return { ...oldItem, ...item }
    })
  }
  const handleDelete = (order) => {
    setListItems(oldItems => oldItems.filter((item, lIndex) => lIndex !== order));
  }

  const onVerifyReceipt = () => {
    if (whId > 0 && wareHouseReceiptDetail?.data?.length > 0 && wareHouseReceiptDetail?.data[0]?.status == 2) {
      dispatch(confirmHouseReceipt({
        id: whId, cb: ({ status, error }) => {
          showMessage({ message: (status) ? "Xác nhận thành công" : error?.message })
        }
      }))
    }
  }

  return (

    <div className="page-content">
      <Card>
        <CardBody>
          <Card color='light'>
            <CardBody>
              <div className='tag color-primary font-weight-bold mb-3'>
                THÔNG TIN PHIẾU NHẬP
              </div>
              <Row>
                <Col xs={12} lg={8}>
                  <div className='grid-info-container'>
                    <div className='body-2 font-weight-bold grid-info-item'>Ngày</div>
                    <div className=' grid-info-item'>
                      <Input type='date' className="form-control" disabled={true} value={rcDate} onChange={e => setRcDate(e.target.value)} />
                    </div>
                    <div className='body-2 font-weight-bold grid-info-item'>Số PN</div>
                    <div className=' grid-info-item'>
                      <Input type='text' className="form-control" disabled={true} value={whId ? whId : ''} />
                    </div>
                    <div className='body-2 font-weight-bold grid-info-item'>Loại:</div>
                    <div className=' grid-info-item'>
                      <Input type='select' disabled={whId > 0}
                        className="form-control" value={rcType} onChange={e => setRcType(e.target.value)} >
                        {whId <= 0 && <option value={""}>Loại phiếu</option>}
                        {
                          wareHouseReceiptTypes?.length > 0 && wareHouseReceiptTypes?.map((type, tIndex) =>
                            <option key={tIndex}
                              selected={(rcType == type?.warehouse_receipt_type_id)}
                              value={type?.warehouse_receipt_type_id}>{type?.warehouse_receipt_type_name}
                            </option>)
                        }
                      </Input>
                    </div>

                    <div className='body-2 font-weight-bold grid-info-item'>Nhân viên:</div>
                    <div className=' grid-info-item'>
                      <Input type='text'
                        disabled={true}
                        className="form-control" value={rcUserInfo?.fullname} >
                      </Input>
                    </div>
                    <div className='body-2 font-weight-bold grid-info-item'>
                      Ghi chú:
                    </div>
                    <div className=' grid-info-item'>
                      <Input type='textarea' disabled={whId > 0} className="form-control" value={rcNote} onChange={e => setRcNote(e.target.value)} />
                    </div>
                  </div>

                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className='table-responsive'>
            <Table className="table-striped mb-0 nowrap-table ">
              <thead>
                <tr>
                  <th>Mã sản phẩm</th>
                  <th>Tên sản phẩm</th>
                  <th>Đơn vị tính</th>
                  <th>Kho</th>
                  <th>Số lượng</th>
                  <th>Thao tác</th>
                </tr>

              </thead>
              <tbody>
                {listItems?.length > 0 && listItems?.map((product, order) => {
                  return (<tr key={order}>
                    <td>{product?.product_code}</td>
                    <td><div className={"breakword max200"}>{product?.product_name}</div></td>
                    <td>{product?.unit_code}</td>
                    <td>{product?.warehouse_code}</td>
                    <td>{product?.qty}</td>
                    <td>{whId <= 0 && <a href="#" className='color-danger' onClick={() => handleDelete(order)}>
                      <i className="fas fa-minus-circle"></i>
                    </a>}</td>
                  </tr>)

                })}
                {whId <= 0 && <tr>
                  <td>
                    <Select
                      showSearch
                      placeholder="Nhập mã sản phẩm"
                      onChange={onChangeProductCode}
                      onSearch={onSearchProductCode}
                      filterOption={false}
                      value={itemProduct.product_code}
                      style={{ width: '300px' }}
                    >
                      {filroducts?.length > 0 &&
                        filroducts.map(item => (
                          <Option
                            key={item.product_id}
                            price={item.price}
                            productName={item.product_name}
                            value={item.product_code}
                            unitCode={item.unit_code}
                            productId={item.product_id}
                          >
                            {item.product_code}
                          </Option>
                        ))
                      }
                    </Select>
                  </td>
                  <td >
                    <div className={"breakword max200"}>{itemProduct?.product_name}</div>
                  </td>
                  <td>{itemProduct?.unit_code}</td>
                  <td><Input type='select' onChange={e => {
                    setItemProduct(oldItem => {
                      return {
                        ...oldItem,
                        warehouse_code: e.target.value
                      }
                    })
                  }} value={itemProduct?.warehouse_code}>
                    {whId <= 0 && <option value={""}>Kho nhập</option>}
                    {wareHouses && wareHouses?.data?.length > 0 && wareHouses?.data?.map((wHouse, index) => <option key={index} selected={(itemProduct && itemProduct?.warehouse_code == wHouse?.warehouse_code)} value={wHouse?.warehouse_code}>{wHouse?.warehouse_name}</option>)}
                  </Input></td>
                  <td><Input type='number' value={qty} onChange={e => {
                    setQty(e.target.value)
                  }} /></td>
                  <td>
                    <a className='color-success' onClick={onAddItem}>
                      <i className="fas fa-plus-square"></i>
                    </a>
                  </td>
                </tr>}
                {whId > 0 ?
                <tr>
                  <td colSpan={4} style={{ textAlign: "right" }}>Tổng số lượng sản phẩm</td>
                  <td>{rcTotalQty}</td>
                  <td></td>
                </tr>: null}
              </tbody>
            </Table>
          </div>
          <div className='mt-4'>
            <div className='d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right'>
              {whId > 0 && wareHouseReceiptDetail?.data?.length > 0 && wareHouseReceiptDetail?.data[0]?.status == 2 && <Button color="primary" className='px-4' onClick={onVerifyReceipt} >Xác nhận nhập hàng</Button>}
              {whId <= 0 && <div className=''>
                <Button color="success" className='px-4' style={{ width: '120px' }} onClick={onSaveListProducts} >Lưu lại</Button>
              </div>}
              <div className=''>
                <Button color="secondary" className='px-4' style={{ width: '120px' }} onClick={() => { history.goBack() }} >Quay lại</Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

WareHouseReceipt.defaultProps = {
  wareHouseReceiptDetail: {},
  id: 0,
  selectedShop: 0,
  wareHouses: {},
  filroducts: {},
  match: {},
  wareHouseReceiptTypes: []
}
WareHouseReceipt.propTypes = {
  wareHouseReceiptDetail: PropTypes.object,
  id: PropTypes.number,
  selectedShop: PropTypes.number,
  wareHouses: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  filroducts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  match: PropTypes.object,
  wareHouseReceiptTypes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
}
function mapStateToProps(state) {
  return {
    wareHouseReceiptDetail: wareHouseReceiptDetailSelector(state),
    selectedShop: selectedShopRoot(state),
    wareHouses: wareHousesSelector(state),
    filroducts: filterProductsSelector(state),
    wareHouseReceiptTypes: wareHouseReceiptTypesSelector(state),
  };
}

const withReduce = connect(mapStateToProps);
export default compose(
  withReduce,
)(WareHouseReceipt);
