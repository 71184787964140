import WarehouseReceiptTableItem from 'components/TableItem/WarehouseReceiptTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import { Table, Card, CardBody, CardTitle, Row, Col, Button, Input } from "reactstrap"
import { Link, useHistory, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { wareHouseReceiptsSelector } from "../../store/wareHouseReceipt/selector";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { useSelector } from 'react-redux';
import { selectedShopRoot } from "../../store/selectedShop/selector";
import { useQuery } from "../../Utils/UseQuery";
import "flatpickr/dist/themes/material_blue.css"
import * as warehouseReceiptApi from "../../apis/warehouseReceipts";
import moment from "moment";
import * as authApi from "../../apis/authApi";
import * as warehouseReceiptTypeApi from "../../apis/warehouseReceiptTypeApi";
import MetaTags from 'react-meta-tags';


function ListWarehouseReceipt(props) {
  const history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [data, setData] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [listReceiptType, setListReceiptType] = useState([]);

  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [receipt_number, setReceipt_number] = useState("");
  const [receipt_type, setReceipt_type] = useState("");
  const [user, setUser] = useState("");
  const [status, setStatus] = useState("");


  const filterToQueryString = () => {
    let queryParams = [];

    let fromDate = moment(from_date, "YYYY-MM-DD", true);
    if (fromDate.isValid())
      queryParams.push('from_date=' + fromDate.format("YYYY-MM-DD"));

    let toDate = moment(to_date, "YYYY-MM-DD", true);
    if (toDate.isValid())
      queryParams.push('to_date=' + toDate.format("YYYY-MM-DD"));

    if (Boolean(receipt_number) && (!isNaN(receipt_number)))
      queryParams.push('receipt_number=' + encodeURIComponent(receipt_number));

    queryParams.push('receipt_type=' + encodeURIComponent(receipt_type));
    queryParams.push('user=' + encodeURIComponent(user));
    queryParams.push('status=' + encodeURIComponent(status));
    queryParams.push('company_id=' + encodeURIComponent(selectedShop));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));

    let queryString = queryParams.join('&');
    return queryString;
  }

  useEffect(() => {
    let query = useQuery(props.location.search)
    let fromDate = moment(query.get('from_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setFrom_date(fromDate)

    let toDate = moment(query.get('to_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setTo_date(toDate)

    setReceipt_number(query.get('receipt_number') ?? '')
    setReceipt_type(query.get('receipt_type') ?? '')
    setStatus(query.get('status') ?? '')
    setUser(query.get('user') ?? '')
  }, []);

  useEffect(() => {
    handleSearch();
    getAllStaff();
    getAllListReceiptType();
  }, [props.location.search, selectedShop]);

  const showWareHouseReceiptItem = () => {
    if (data.length === 0) {
      return (<tr></tr>);
    }
    return data.map((v, i) => {
      return <WarehouseReceiptTableItem key={v.id} onReload={handleSearch} data={v} />
    })
  }
  const getAllListReceiptType = () => {
    warehouseReceiptTypeApi.getAllListReceiptType(getDataTypeSuccess, alertFail);
  }
  const getDataTypeSuccess = (res) => {
    setListReceiptType(res.data.data);
  }
  const handleResetFilter = () => {
    setDateRange("");
    setReceipt_number("");
    setReceipt_type("");
    setUser("");
    setStatus("");
  }

  const getAllStaff = () => {
    authApi.getListStaffThuKho(getDataSuccess, alertFail);
  }
  const getDataSuccess = (res) => {
    setListUser(res.data.data);
  }
  const alertFail = (res) => {
    alert(res.message);
  }

  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString
    history.push(url);
  }

  const handleSearch = () => {
    if (selectedShop === 0) return
    const params = [];
    let queryParams = useQuery(props.location.search)
    let page = queryParams.get("page");
    if (page) {
      params.push({ name: "page", value: page });
    }
    params.push({ name: "user", value: queryParams.get("user") })
    params.push({ name: "from_date", value: queryParams.get("from_date") })
    params.push({ name: "to_date", value: queryParams.get("to_date") })
    params.push({ name: "receipt_number", value: queryParams.get("receipt_number") })
    params.push({ name: "receipt_type", value: queryParams.get("receipt_type") })
    params.push({ name: "status", value: queryParams.get("status") })
    params.push({ name: "company_id", value: queryParams.get("selectedShop") ?? selectedShop })

    warehouseReceiptApi.getList(params, populateData, errorHandler);
  }
  const populateData = (data) => {
    setCurrentPage(data.data.data);
    setData(data.data.data.data);
  }

  const errorHandler = (res) => {
    alert(res.message);
  }

  const onChangePage = (page) => {
    let url = props.location.pathname + "?page=" + page;
    let queryString = filterToQueryString()
    url = url + '&' + queryString
    history.push(url);
  }

  const uploadWarehouseReceipt = async (e) => {
    const file = e.target.files[0];
    let result = new FormData();
    result.append('company_id', selectedShop)
    result.append('import', file);
    if (file) {
      warehouseReceiptApi.uploadWarehouseReceiptFile(result, uploadFileSucces, uploadFail)
    }
  }

  const uploadFileSucces = (res) => {
    alert(res.data.message);
  }

  const uploadFail = (res) => {
    alert(res.message);
  }

  const onImportFile = () => {
    document.getElementById("fileExcel").click();
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách phiếu nhập</title>
      </MetaTags>
      <Card>
        <CardBody>
          {/* <CardTitle className="h4">Danh sách phiếu nhập</CardTitle>
                        <Row className="justify-content-between">
                            <Col xs="auto" sm="auto">
                            </Col>
                            <Col xs="auto" sm="auto">
                                <Link to="/warehouse-receipts/create"><Button color='primary' className='px-3 rounded-pill'><i className="fas fa-plus mr-2"></i> Thêm mới</Button></Link>
                            </Col>
                        </Row> */}
          <Card color='light'>
            <CardBody>
              <Row className="justify-content-between mb-3">
                <Col xs="auto" sm="auto"><CardTitle className="h4">Danh sách phiếu nhập</CardTitle></Col>
                <Col xs="auto" sm="auto">
                  <Button color="primary" className="px-3 rounded-pill mr-2" onClick={onImportFile}>
                    <i className="fas fa-upload mr-2"></i> Import phiếu nhập
                  </Button>
                  <input
                    type="file"
                    id="fileExcel"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={uploadWarehouseReceipt}
                  />
                  <Link to="/warehouse-receipts/create">
                    {" "}
                    <Button color="primary" className="px-3 rounded-pill">
                      <i className="fas fa-plus mr-2"></i> Thêm mới
                    </Button>
                  </Link>
                </Col>
              </Row>
              {
                isShowSearch ?
                  <Row className='align-items-end'>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Từ ngày
                      </label>
                      <Input
                        type='date'
                        className="form-control"
                        value={from_date}
                        onChange={(e) => setFrom_date(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Đến ngày
                      </label>
                      <Input
                        type='date'
                        className="form-control"
                        value={to_date}
                        onChange={(e) => setTo_date(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo số PN
                      </label>
                      <Input
                        type='text'
                        className="form-control"
                        value={receipt_number}
                        onChange={(e) => setReceipt_number(e.target.value)}
                      >
                      </Input>
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Loại phiếu nhập
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={receipt_type}
                        onChange={(e) => setReceipt_type(e.target.value)}
                      >
                        <option value={""}>Chọn loại phiếu nhập</option>
                        {
                          listReceiptType.map((item, i) => (
                            <option value={item.warehouse_receipt_type_id} key={i}>{item.warehouse_receipt_type_name}</option>
                          ))
                        }
                      </Input>
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo NV
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                      >
                        <option value={""}>Chọn nhân viên</option>
                        {
                          listUser.map((item, i) => (
                            <option value={item.user_id} key={i}>{item.username}</option>
                          ))
                        }
                      </Input>
                    </Col>

                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo trạng thái
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value={""}>Chọn trạng thái</option>
                        <option value={0}>Đã xóa</option>
                        <option value={1}>Bình thường</option>
                      </Input>
                    </Col>
                    <Col xs={12} lg="auto">
                      <Button color="primary" className="mr-3 mt-3"
                        onClick={handleOnSearch}>
                        Tìm kiếm
                      </Button>
                      <Button color="secondary" className="mr-3 mt-3"
                        onClick={handleResetFilter}>
                        Reset tìm kiếm
                      </Button>
                    </Col>
                  </Row>
                  : null
              }
              <div className='tag color-primary font-weight-bold mt-3'
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={() => setIsShowSearch(!isShowSearch)}>
                {
                  isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
                }
                {
                  isShowSearch ?
                    <i className="bx bx bx-chevron-up ml-2" />
                    : <i className="bx bx bx-chevron-down ml-2" />
                }
              </div>
            </CardBody>
          </Card>
          <div className="table-responsive">
            <Table className="table mb-3">
              <thead>
                <tr>
                  <th>Ngày</th>
                  <th>Số</th>
                  <th>Loại PN</th>
                  <th>NV</th>
                  <th>Tình trạng</th>
                  <th>Hành Động</th>
                </tr>
              </thead>
              <tbody>
                {showWareHouseReceiptItem()}
              </tbody>
            </Table>
          </div>
          <MyPagination
            startPage={currentPage.current_page ? currentPage.current_page : 1}
            totalItemsCount={currentPage.total ? currentPage.total : 5}
            itemsCountPerPage={currentPage.per_page ? currentPage.per_page : 10}
            callback={onChangePage}
          />
        </CardBody>
      </Card>
    </div>
  )
}

ListWarehouseReceipt.defaultProps = {
  wareHouseReceipts: {}
}

ListWarehouseReceipt.propTypes = {
  wareHouseReceipts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  location: PropTypes.object,
}
function mapStateToProps(state) {
  return {
    wareHouseReceipts: wareHouseReceiptsSelector(state),
    selectedShop: selectedShopRoot(state),
  };
}
const withReduce = connect(mapStateToProps);

export default compose(
  withReduce, withRouter
)(ListWarehouseReceipt);
