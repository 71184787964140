import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { PropTypes } from 'prop-types';
import * as paymentMethodApi from '../../apis/paymentMethodApi';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function PaymentMethod(props) {
    const code = props.match.params?.code;
    const [data, setData] = useState({})
    let history = useHistory();
    useEffect(() => {
        if (code) {
            fetchData(code);
        }
    }, [props.match.params])
    const fetchData = (code) => {
        paymentMethodApi.get(code, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
    }
    const onChangeData = (value, attrName) => {
        data[attrName] = value;
        setData({ ...data })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (code) {
            paymentMethodApi.update(code, data, onSubmitSuccess, onSubmitFail)
        }
        else {
            paymentMethodApi.create(data, onSubmitSuccess, onSubmitFail)
        }
    }
    const onSubmitSuccess = (res) => {
        history.push("/payment-methods")
    }
    const onSubmitFail = (res) => {
        alert(getMessage(res.message))
    }
    return (
        <div className="page-content">
        <MetaTags>
            <title>Chi tiết phương thức thanh toán</title>
        </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">Danh mục phương thức thanh toán</CardTitle>
                    <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <Label htmlFor="Shop-code-input">Mã phương thức thanh toán</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="payment-code-input"
                                placeholder="Nhập mã phương thức thanh toán"
                                value={data.payment_code}
                                onChange={e => onChangeData(e.target.value, 'payment_code')}
                                disabled={code ? true : false}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="Shop-name-Input">Tên phương thức thanh toán</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="payment-name-input"
                                placeholder="Nhập tên phương thức thanh toán"
                                value={data.payment_desc}
                                onChange={e => onChangeData(e.target.value, 'payment_desc')}
                            />
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} sm="auto">
                                    <button type="submit" className="btn btn-primary w-md">
                                        Lưu
                                    </button>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Link to="/payment-methods" className="btn btn-secondary w-md">
                                        Hủy
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}


PaymentMethod.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
}


export default PaymentMethod;
