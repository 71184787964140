import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Switch } from 'antd';
import * as contactApi from "../../../apis/contactApi";
import PreviewImg from "../../../constants/component/PreviewImg";
import "./style.css"
import { API_HOST_NAME } from "constants/SystemConstant";
import MetaTags from 'react-meta-tags';


function AddShopAddress(props) {
  let params = useParams();
  let history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const popupRef = useRef();
  const [form, setForm] = useState({});
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [map_link, setMap_link] = useState("")
  const [display_order, setDisplay_order] = useState("");
  const [isShowBanner, setIsShowBanner] = useState()
  const selectedShop = useSelector(state => state.SelectedShop);
  const routeBack = () => {
    history.push("/contact")
  }
  const handleSubmitReservations = (values) => {
    const params = {
      ...values,
      status: isShowBanner ? 1 : 0
    }
    contactApi.addContact(params, addSuccess, addFailure);
  };
  const addSuccess = res => {
    alert(res.data.message);
    routeBack()
  };
  const addFailure = err => {
    alert(err.message);
  };

  const onChange = (checked) => {
    setIsShowBanner(checked)
  };

  return (
    <div className="page-content">
    <MetaTags>
        <title>CMS | Thêm địa chỉ liên hệ</title>
    </MetaTags>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: (name) || "",
          address: (address) || "",
          phone: (phone) || "",
          map_link: (map_link) || "",
          display_order: (display_order) || ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            "Tên không được để trống"
          ),
          address: Yup.string().required(
            "Địa chỉ không được để trống"
          ),
          phone: Yup.string().required(
            "Số điện thoại không được để trống"
          ),
          map_link: Yup.string().required(
            "Địa chỉ map không được để trống"
          ),
          display_order : Yup.string().required(
            "Số thứ tự không được để trống"
          ),
        })}
        onSubmit={values => {
          handleSubmitReservations(values);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Thêm mới banner </CardTitle> */}
                <Card color="light">
                  <CardBody>
                    <div className="color-primary tag mb-3">CHỈNH SỬA LIÊN HỆ </div>
                    <Row>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Tên CH</div>
                          <div>
                            <Field
                              name="name"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Địa chỉ</div>
                          <div>
                            <Field
                              name="address"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.address && touched.address
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Số liên hệ</div>
                          <div>
                            <Field
                              name="phone"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Link map</div>
                          <div>
                            <Field
                              name="map_link"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.map_link && touched.map_link
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="map_link"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Thứ tự hiển thị</div>
                          <div>
                            <Field
                              name="display_order"
                              label=""
                              type="number"
                              className={
                                "form-control" +
                                (errors.display_order && touched.display_order
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="display_order"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Hiển thị/Ẩn</div>
                          <div>
                            <Switch checked={isShowBanner} onChange={onChange} />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form"></div>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              style={{ width: "120px" }}
                            >
                              Lưu lại
                            </Button>{" "}
                            <Button color="secondary" onClick={routeBack}>
                              Quay lại
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default AddShopAddress;
