import React, { useState, useEffect, useReducer } from "react"
import { Alert, Table, message } from "antd"
import { PER_PAGE } from "constants/SystemConstant"
import { useHistory, useLocation } from "react-router-dom"
import { filtersToAPIQueryParams, urlToFilters, filtersToUrl, urlToCurrentPage } from "helpers/QueryHelper"
import { isEmptyObject } from "helpers/CommonHelper"
import { PropTypes } from 'prop-types'
import { useSelector } from "react-redux";


const reducerDataSource = (dataSource, action) => {
  switch (action.type) {
    case 'POPULATE':
      return action.payload;
    case 'SOFT_DELETE':
      const unDeletedItems = dataSource.map((el) => {
        if (el.id == action.payload) {
          return { ...el, ...{ 
            'is_deleted': 1,
            'status_text': 'Đã xóa'
          }}
        }
        return el;
      })
      return unDeletedItems;
    case 'RESTORE':
      const normalItems = dataSource.map((el) => {
        if (el.id == action.payload) {
          return { ...el, ...{ 
            'is_deleted': 0,
            'status_text': 'Bình thường'
          }}
        }
        return el;
      })
      return normalItems;
    case 'DELETE':
      const notDeletedItems = dataSource.filter(el => el.id != action.payload);
      notDeletedItems.forEach(function (row, index) {
        row.stt = index + 1;
      });
      return notDeletedItems;
    default:
      return dataSource;
  }
}

function PartialLoadedTable({ getColumns, paginationTitle, perPage, callbacks }) { //getData, deleteData, restoreData, canRestore }) {

  const [tablePagination, setTablePagination] = useState({
    defaultCurrent: 1,
    pageSize: perPage,
    total: 0,
    hideOnSinglePage: true,
    locale: { items_per_page: paginationTitle },
    showTotal: (total) => `Tổng ${total} ${paginationTitle}`,
  })
  const [columns, setColumns] = useState([]);
  const [dataSource, dispatchDataSource] = useReducer(reducerDataSource, []);
  const [errorMessage, setErrorMessage] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const history = useHistory();
  const location = useLocation();
  const selectedShop = useSelector(state => state.SelectedShop);

  const fetchData = (page, pageSize, filters) => {
    setTableLoading(true);
    const params = [];
    params.push({ name: "page", value: page });
    params.push({ name: "per_page", value: pageSize });
    console.log('callbacks?.companyId = ', callbacks?.companyId);
    if (callbacks?.needCompanyId && selectedShop > 0) {
      params.push({ name: 'company_id', value: selectedShop });
    }
    filters.forEach(filter => params.push(filter));
    callbacks.getData(params, fetchDataSuccess, fetchDataFail);
  }

  const fetchDataSuccess = (resp) => {
    console.log('fetchDataSuccess resp = ', resp);
    let responseData = resp.data.data;
    let dataArray = [];
    let fromIndex = 1;
    let total = 0;
    if (
      responseData.hasOwnProperty('links')
      && responseData.hasOwnProperty('data')
      && responseData.hasOwnProperty('meta')
    ) {
      dataArray = responseData.data;
      fromIndex = responseData.meta.from;
      total = responseData.meta.total;
    } else {
      dataArray = responseData.data;
      fromIndex = responseData.from;
      total = responseData.total;
    }
    let alist = responseData.data.map((item, index) => {
      return {
        ...item,
        key: item[callbacks.idField ?? 'id'],
        id: item[callbacks.idField ?? 'id'],
        stt: fromIndex + index,
      }
    });
    dispatchDataSource({type: 'POPULATE', payload: alist});
    setTablePagination(prev => ({ ...prev, total: total }));
    setErrorMessage("");
    setTableLoading(false);
  }

  const fetchDataFail = (error) => {
    setErrorMessage(error.message ? error.message : error);
    setTableLoading(false);
  }

  useEffect(() => {
    if (selectedShop && selectedShop > 0) {
      const url = window.location.href;
      const filters = urlToFilters(url);
      setFilteredInfo(filters);
      const currentPage = urlToCurrentPage(url);
      setTablePagination(prev => ({ ...prev, current: currentPage }));
      const queryParams = filtersToAPIQueryParams(filters);
      fetchData(1, tablePagination.pageSize, queryParams);
    }
  }, [location, selectedShop])

  const handleRestore = (id) => {
    callbacks.restoreData(id, restoreSucess, restoreError);
  }

  const restoreSucess = resp => {
    message.success(resp.message);
    dispatchDataSource({type: 'RESTORE', payload: id});
  }

  const restoreError = error => {
    message.error(error);
  }

  const handleDelete = (id) => {
    callbacks.deleteData(id, deleteSuccess, deleteError);
  }

  const deleteSuccess = resp => {
    message.success(resp.message);
    if (callbacks?.canRestore) {
      dispatchDataSource({type: 'SOFT_DELETE', payload: id});
    } else {
      dispatchDataSource({type: 'DELETE', payload: id});
    }
  }

  const deleteError = error => {
    message.error(error);
  }

  const initColumns = () => {
    const funcs = {
      ...callbacks,
      handleDelete: handleDelete,
      handleRestore: handleRestore,      
    };
    const tableColumns = getColumns(filteredInfo, funcs); // handleDelete, handleRestore
    setColumns(tableColumns);
  }

  useEffect(() => {
    initColumns();
  }, [])

  useEffect(() => {
    if (!isEmptyObject(filteredInfo)) {
      initColumns();
    }
  }, [filteredInfo])

  return (
    <>
      {errorMessage ? <Alert message={errorMessage} type="error" className="mb-2" /> : null}
      <Table
        className="mytable"
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => record[callbacks.idField ?? 'id']}
        locale={{ emptyText: 'Không có dữ liệu' }}
        //loading={tableLoading}
        pagination={tablePagination}
        onChange={(pagination, filters, sorter, extra) => {
          let currentPage = (extra.action === 'paginate') ? pagination.current : 1;
          let perPage = pagination.pageSize;
          let search = filtersToUrl(filters);
          let url = `${location.pathname}?${search}&page=${currentPage}&per_page=${perPage}`;
          history.push(url);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      />
    </>
  )
}

PartialLoadedTable.propTypes = {
  getColumns: PropTypes.func,
  paginationTitle: PropTypes.string,
  perPage: PropTypes.number,
  callbacks: PropTypes.object,
  getData: PropTypes.func,
  deleteData: PropTypes.func,
  restoreData: PropTypes.func,
  canRestore: PropTypes.bool,
}
export default PartialLoadedTable