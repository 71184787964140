import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as categoryApi from '../../apis/categoryApi';

const useFetchCategories = (selectedShop) => {
  const [categories, setCategories] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      categoryApi.getAllCategory(selectedShop, fetchCatSuccess, showMessage)
    }
  }, [selectedShop])
  
  const fetchCatSuccess = (res) => {
    setCategories(res.data.data.map(v => { return { value: v.category_id, label: v.category_name } }));
  }

  return categories;
};

export default useFetchCategories;
