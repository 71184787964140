import UnitTableItem from 'components/TableItem/UnitTableItem';
import React, { useState, useEffect } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as unitApi from '../../apis/unitApi';

import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap"

import { Link, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery } from './../../Utils/UseQuery';
import SweetAlert from 'react-bootstrap-sweetalert';
import ConfirmDelete from 'components/Common/ConfirmDelete';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function ListUnit(props) {
    const [data, setData] = useState([])
    const [additionalData, setAdditionalData] = useState({})
    const [deleteCode, setDeleteCode] = useState(null)
    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }, [props.location.search])
    let history = useHistory();


    const fetchData = page => {
        unitApi.getListUnit(page, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = res => {
        setAdditionalData(res.data.data)
        setData(res.data.data.data);
    }
    const fetchDataFail = res => {
        alert(getMessage(res.message))
    }

    const showUnits = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<UnitTableItem key={i} data={v} count={i + 1} callbackDelete={setDeleteCode} />)
        })
    }
    const renderDeleteModal = () => {
        if (deleteCode) {

            return (
                <ConfirmDelete
                    confirmCallback={() => {
                        onDeleteUnit(deleteCode)
                    }}
                    cancelCallback={() => {
                        setDeleteCode(null)
                    }}
                />
            );
        }
        return;
    }
    const onChangePage = (page) => {
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }
    const onDeleteUnit = (code) => {
        unitApi.deleteUnit(code, onDeleteSuccess, onDeleteFail)
    }
    const onDeleteSuccess = res => {
        alert("Xóa thành công")
        setDeleteCode(null)
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }
    const onDeleteFail = res => {
        alert(getMessage(res.message))
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Danh sách đơn vị tính</title>
            </MetaTags>
            {renderDeleteModal()}
            <Card>
                <CardBody>
                    <CardTitle className="h4">Danh mục đơn vị tính</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">

                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/units/create">
                                <Button color='primary' className='px-3 rounded-pill'>
                                    <i className="fas fa-plus mr-2"></i> Thêm mới
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>Mã đơn vị tính</th>
                                    <th>Tên đơn vị tính</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showUnits()}
                            </tbody>
                        </Table>
                    </div>
                    <MyPagination 
                        startPage={additionalData.current_page ? additionalData.current_page : 1} 
                        totalItemsCount={additionalData.total ? additionalData.total : 5} 
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
                        callback={onChangePage} />

                </CardBody>
            </Card>
        </div>
    )
}
ListUnit.propTypes = {
    location: PropTypes.object,
}

export default withRouter(ListUnit);
