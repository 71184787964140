import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getAllBill = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/bills";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const getAllPaymentMethods = (callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/all-payment-methods";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const getSaleMan = (callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/auth/role/sale";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const deleteBill = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/bills/${id}`;
  if (!id) return;
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, "", callback, callbackErr);
};

export const restoreBill = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/bills/restore/" + id;
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const getAllCustomer = (phone, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/customers/filter-phone";
  let params = [];
  if (phone) {
    params.push({
      name: "phone",
      value: phone,
    });
  }
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};
export const getProductFilter = (
  selectedShop,
  filer,
  callback,
  callbackErr
) => {
  let url = API_HOST_NAME + "/api/products/filter-code";

  let params = [
    {
      name: "company_id",
      value: selectedShop,
    },
  ];

  if (filer) {
    params.push({
      name: "product_code",
      value: filer,
    });
  }
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const getProductAvailableWarehouse = (
  selectedShop,
  filer,
  callback,
  callbackErr
) => {
  let url = API_HOST_NAME + "/api/inventories/get-available-warehouse";
  let params = [
    {
      name: "company_id",
      value: selectedShop,
    },
  ];
  if (filer) {
    params.push({
      name: "product_code",
      value: filer,
    });
  }
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const create = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/bills';
  let token = localStorage.getItem("token")
  commonApis.POST(url, data, token, '', callback, callbackErr)

}

export const getBillDetail = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/bills/${id}`;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

// filter search
export const filterBills = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/bills/filters`;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const setPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/bills/" + id + "/set-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const setNotPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/bills/" + id + "/set-not-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const downloadBillPrint = (id, callback,callbackErr) => {
  let url = API_HOST_NAME + '/api/bills/excel/' + id;
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);
}