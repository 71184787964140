import React, { useState, useEffect } from "react"
import { Row, Col, Label } from "reactstrap"
import Select from "react-select"
import { PropTypes } from 'prop-types';

import * as shopApi from './../../../apis/shopApi';

FilterCompanies.propTypes = {
    onChange: PropTypes.func,
}

function FilterCompanies({ onChange }) {
    const [companyIds, setCompanyIds] = useState();
    const [companies, setCompanies] = useState();

    const handleChange = (selectedCompanies) => {
        let listCompany = []
        selectedCompanies?.map(item => {
            listCompany.push(item.value)
        })
        onChange(listCompany.join())
    }

    useEffect(() => {
        getDataCompany();
    }, []);

    const getDataCompany = () => {
        shopApi.getAllShop(getDataShop, showErr);
    }

    const getDataShop = (res) => {
        let listCompany = res.data.data.map(item => {
            return {
                label: item.company_name,
                value: item.company_id
            }
        })
        setCompanies(listCompany);
    }

    const showErr = (res) => {
        alert(getMessage(res.message));
    }

    return (
        <Row xl={12} className="mb-2">
            <Label md={12} xl={2} className="col-form-label">Hệ thống cửa hàng</Label>
            <Col md={12} xl={4}>
                <Select
                    value={companyIds}
                    isMulti={true}
                    onChange={handleChange}
                    options={companies}
                    classNamePrefix="select2-selection"
                />
            </Col>
        </Row>
    );
}

export default FilterCompanies;