import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
  Input, Form, FormGroup, Label
} from 'reactstrap';
import { message } from 'antd';
import * as saleCampaignDetailApi from "../../apis/saleCampaignDetailApi";
import { showMessage } from 'Utils/global';
import PropTypes from 'prop-types';
import { SaleProductContext } from './SaleCampaign';
import { nullSaleProduct } from 'UsePages/Product/const';

EditSaleProductModal.propTypes = {
  campaignId: PropTypes.number,
  saleProduct: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

function EditSaleProductModal({ campaignId, saleProduct, visible, onClose }) {

  const saleProductContext = useContext(SaleProductContext)
  const { productsDispatch } = saleProductContext
  
  const [product, setProduct] = useState(nullSaleProduct)

  useEffect(() => {
    if (saleProduct.id > 0) {
      setProduct({...product, ...saleProduct});
    }
  }, [saleProduct])

  const handleSave = () => {
    saleCampaignDetailApi.update(campaignId, saleProduct.id, {sale_price: product.sale_price}, onUpdateSuccess, showMessage);
  }

  const handleChange = (salePrice) => {
    setProduct({...product, ...{sale_price: salePrice}});
  }

  const onUpdateSuccess = (res) => {
    productsDispatch({ type: 'UPDATE', payload: product });
    onClose();
    message.success(res.data.message);
  }

  return (
    <Modal isOpen={visible} toggle={onClose}>
      <ModalHeader>
        SẢN PHẨM KHUYẾN MẠI
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Mã sản phẩm</Label>
            <Input type='text'
              value={product.product_code}
              readOnly={true}
            />
          </FormGroup>
          <FormGroup>
            <Label>Tên sản phẩm</Label>
            <Input type='textarea'
              value={product.product_name}
              readOnly={true}
            />
          </FormGroup>
          <FormGroup>
            <Label>Giá khuyến mại</Label>
            <Input type='text'
              value={product.sale_price}
              onChange={e => handleChange(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Đóng</Button>
        <Button color="primary" onClick={handleSave}>Lưu lại</Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditSaleProductModal;
