import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getListWareHouseExport = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-exports';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}

export const getAvailableWareHouse = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/get-available-warehouse';
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,params,callback,callbackErr)
}
export const getProductByCode = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/products/filter-code';
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,params,callback,callbackErr)
}
export const getProductInStockByCode = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/products/filter-code-in-stock';
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,params,callback,callbackErr)
}
export const getWarehouseExportType = (callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/all-warehouse-export-types';
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,'',callback,callbackErr)
}

export const getAllWarehouses = (selectedShop,callback,callbackErr) => {
    const params = [
        {
            name:'company_id',
            value: selectedShop
        }
    ]
    let url = API_HOST_NAME + '/api/all-warehouses';
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,params,callback,callbackErr)
}

export const warehouseExports = (data,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-exports';
    let token = localStorage.getItem("token")
    commonApis.POST(url,data,token,'',callback,callbackErr)
}

export const warehouseExportsTmp = (data,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-exports/export-tmp';
    let token = localStorage.getItem("token")
    commonApis.POST(url,data,token,'',callback,callbackErr)
}


export const getDetailWare = (id,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-exports/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url,token,'',callback,callbackErr)
}
export const deleteWare = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/warehouse-exports/" + id
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}
export const unDoWare = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/warehouse-exports/restore/" + id
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr)

}

export const changeStatus = (id, status, callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/warehouse-exports/details?id=${id}&status=${status}`
    let token = localStorage.getItem("token")
    commonApis.PUT(url, '', token, '', callback, callbackErr)
}


export const deleteDetailProduct = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/warehouse-exports/delete-product?detail_id=" + id
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}
export const warehouseExportsAddProd = (data,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-exports/add-product';
    let token = localStorage.getItem("token")
    commonApis.POST(url,data,token,'',callback,callbackErr)
}

export const uploadWarehouseExportFile = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/warehouse-exports/import";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};
// warehouse-exports/add-product

