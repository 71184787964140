const INIT_STATE = false
import { ADD_LOADING, REMOVE_LOADING } from './actionTypes';

const Loading = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_LOADING:
            return true;
        case REMOVE_LOADING:
            return false;
        default:
            return state
    }
}
export default Loading;