import React from 'react';
import ProductImage from '../../images/prod-example.png';
import PropTypes from 'prop-types';


function ImageItem(props) {
    let data = props.data
    let onDelete = props.onDelete
    let disabled = props.disabled
    let lightboxCallback = props.lightboxCallback
    const onShowLightBox = () => {
        lightboxCallback(data)
    }
    return (
        <div className='image-holder' >
            <div className='ratio ratio-1x1'>
                <img src={data}  onClick={onShowLightBox} />
                {disabled ? '' : (
                    <div className='delete-img position-absolute' onClick={onDelete}>
                        <i className="fas fa-times-circle"></i>
                    </div>
                )}

            </div>
        </div>
    );
}

ImageItem.propTypes = {
    data: PropTypes.any,
    onDelete: PropTypes.func,
    lightboxCallback: PropTypes.func,
    disabled: PropTypes.bool,
}
export default ImageItem;
