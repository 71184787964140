import React from "react";
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import ListSaleCampaignTableItem from "./ListSaleCampaignTableItem";

function ListSaleCampaignTable() {

  return (
    <Table className="table mytable">
      <Thead>
        <Tr>
          <Th>STT</Th>
          <Th>Tên</Th>
          <Th>Từ ngày</Th>
          <Th>Đến ngày</Th>
          <Th>Trạng thái</Th>
          <Th>Hành Động</Th>
        </Tr>
      </Thead>
      <Tbody>
        <ListSaleCampaignTableItem />
      </Tbody>
    </Table>
  )
}

export default ListSaleCampaignTable;