import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { PropTypes } from 'prop-types';

function ConfirmDelete({confirmCallback,cancelCallback}) {
    return (
        <SweetAlert
            title="Bạn chắc chắn muốn xóa"
            warning
            showCancel
            confirmButtonText="Đồng ý"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            cancelBtnText="Hủy"
            onConfirm={confirmCallback}
            onCancel={cancelCallback}
        >
            Bạn sẽ không thu hồi được quyết định này
        </SweetAlert>
    )
}

ConfirmDelete.propTypes = {
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
}
export default ConfirmDelete