import {API_HOST_NAME} from "../constants/SystemConstant";
import * as commonApis from "./commonApis";
export const getListWareHouse = (company, page) => {
	let url = API_HOST_NAME + '/api/warehouses';
	if (!company) return;
	let params = [
		{
			name: 'company_id',
			value: company
		}
	]
	if (page) {
		params.push({
			name: 'page',
			value: page
		})
	}
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token, params);
}
export const getListWareHouseReceiptTypes = () => {
	let url = API_HOST_NAME + '/api/all-warehouse-receipt-types';
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token, null);
}
export const getWareHouseReceiptTypes = () => {
	let url = API_HOST_NAME + '/api/warehouse-receipt-types/list';
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token, null);
}
export const filterProductByCode = (product_code, company) => {
	let url = API_HOST_NAME + '/api/products/filter-code';
	let params = [
		{
			name: 'company_id',
			value: company
		},
		{
			name: 'product_code',
			value: product_code
		}
	]
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token, params);
}
export const saveWarehouseReceipts = ({postData}) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts';
	let token = localStorage.getItem("token")
	return commonApis.POSTV2(url,postData, token, "")
}
export const saveAttribute = ({postData}) => {
	let url = API_HOST_NAME + '/api/attributes';
	let token = localStorage.getItem("token")
	return commonApis.POSTV2(url,postData, token, "")
}
export const saveAttributeVal = (postData) => {
	let url = API_HOST_NAME + '/api/attribute-values';
	let token = localStorage.getItem("token")
	return commonApis.POSTV2(url,postData, token, "")
}
export const updateAttribute = ({postData,id}) => {
	let url = API_HOST_NAME + '/api/attributes/'+id;
	let token = localStorage.getItem("token")
	return commonApis.PUTV2(url,postData, token, "")
}
export const updateAttributeVal = ({value,id}) => {
	let url = API_HOST_NAME + '/api/attribute-values/'+id;
	let token = localStorage.getItem("token")
	return commonApis.PUTV2(url,{value}, token, "")
}

export const saveCheckedAttributes = ({category_id,attribute_ids}) => {
	let url = API_HOST_NAME + '/api/categories/'+category_id+'/attributes';
	let token = localStorage.getItem("token")
	return commonApis.PUTV2(url,{attribute_ids}, token, "")
}
export const delWarehouseReceipts = ({id}) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts/'+id;
	let token = localStorage.getItem("token")
	return commonApis.DELETEV2(url,null, token, "")
}
export const restoreWarehouseReceipts = ({id}) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts/restore/'+id;
	let token = localStorage.getItem("token")
	return commonApis.PUTV2(url,null, token, "")
}
export const confirmWarehouseReceipts = ({id}) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts/confirm/'+id;
	let token = localStorage.getItem("token")
	return commonApis.PUTV2(url,null, token, "")
}
export const getList = (params, callback, callbackErr) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts';
	let token = localStorage.getItem("token")
	commonApis.GET(url, token, params, callback, callbackErr);    
}
export const getDetail = ({id}) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts/' + id;
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token, '');
}
export const createWarehouse = (data, callback, callbackErr) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts';
	let token = localStorage.getItem("token")
	commonApis.POST(url, data, token, '', callback, callbackErr)
}
export const updateWarehouse = (id, data, callback, callbackErr) => {
	let url = API_HOST_NAME + '/api/warehouse-receipts/' + id;
	let token = localStorage.getItem("token")
	commonApis.PUT(url, data, token, '', callback, callbackErr)
}

export const uploadWarehouseReceiptFile = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/warehouse-receipts/import";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const getListCategories = ({company_id}) => {
	let url = API_HOST_NAME + '/api/categories/tree';
	let params = []
	params.push({
		name: 'company_id',
		value: company_id
	})
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token,params);
}

export const getListAttributes = ({company_id,category_id}) => {
	let url = API_HOST_NAME ;
	let params = []
	if(company_id){
		params.push({
			name: 'company_id',
			value: company_id
		})
	}
	if(category_id){
		params.push({
			name: 'category_id',
			value: category_id
		})
		url+= '/api/all-category-attributes'

	}else{
		url += '/api/all-attributes';
	}
	let token = localStorage.getItem("token")
	return commonApis.POSTV2(url, "",token,params);
}

export const getListAttributeValues = ({attribute_id}) => {
	let url = API_HOST_NAME ;
	url += '/api/attribute-values/?attribute_id='+attribute_id;
	let token = localStorage.getItem("token")
	return commonApis.GETV2(url, token);
}

export const deleteWarehouseReceiptProduct = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/warehouse-receipts/delete-product?detail_id=" + id
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}

export const addWarehouseReceiptProduct = (data,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-receipts/add-product';
    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)
}
