import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { GENDER } from '../../../common/data/gender'

import { connect } from "react-redux";
import classnames from "classnames"
import { validatePass } from "Utils/Validate";
import * as authApi from '../../../apis/authApi';
import { getMessage } from './../../../Utils/ErrMessageUtils';

import './ProfileMenu.css';
import { showMessage } from "Utils/global";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}

const setAuthuser = (authUser) => {
  localStorage.setItem("authUser", JSON.stringify(authUser))
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "Admin",
      showModalProfile: false,
      userData: {},
      activeTabProfile: "profile",
      newPassword: "",
      confirmNewPassword: ""
    }
    this.toggle = this.toggle.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.showGender = this.showGender.bind(this)
    this.changeTabProfile = this.changeTabProfile.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.changeUserData = this.changeUserData.bind(this)

    this.onSubmitChangePass = this.onSubmitChangePass.bind(this)
    this.submitChangePassSuccess = this.submitChangePassSuccess.bind(this)
    this.submitChangePassFail = this.submitChangePassFail.bind(this)
    
    this.onSubmitProfile = this.onSubmitProfile.bind(this)
    this.submitProfileSuccess = this.submitProfileSuccess.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username, userData: userData })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username })
      }
    }
  }
  closeModal() {
    this.setState({ showModalProfile: false })
  }
  openModal(e) {
    e.preventDefault();
    this.setState({ showModalProfile: true })
  }
  showGender() {
    return GENDER.map((v, i) => {
      return <option key={i} value={v.value}>{v.label}</option>
    })
  }
  changeTabProfile(idTab) {
    this.setState({ activeTabProfile: idTab })
  }
  changeInput(value, attr) {
    this.setState({ [attr]: value })
  }
  changeUserData(value, attr) {
    let userData = {...this.state.userData};
    userData[attr] = value;
    this.setState({userData})
  }
  onSubmitChangePass(e) {
    e.preventDefault();
    if (!validatePass(this.state.newPassword)) {
      alert("Mật khẩu mới phải tối thiểu 6 ký tự và bao gồm số, chữ thường, chữ hoa và ký tự đặc biệt");
      return;
    }
    if (this.state.newPassword != this.state.confirmNewPassword) {
      alert("Xác nhận mật khẩu không trùng với mật khẩu mới");
      return;
    }
    authApi.changePassword(this.state.newPassword, this.submitChangePassSuccess, this.submitChangePassFail)
  }
  submitChangePassSuccess(res) {
    alert("Thay đổi mật khẩu thành công");
    this.setState({ showModalProfile: false });
  }
  submitChangePassFail(res) {
    alert(getMessage(res.message))
  }
  toggleShowPasswordInput(id) {
    if (document.getElementById(id).type == 'password') {
      document.getElementById(id).type = 'text';
      document.getElementById("new-password-input-ico").className = "fas fa-eye-slash";
    }
    else {
      document.getElementById(id).type = 'password';
      document.getElementById("new-password-input-ico").className = "fas fa-eye";
    }
  }
  toggleShowPasswordInput2(id) {
    if (document.getElementById(id).type == 'password') {
      document.getElementById(id).type = 'text';
      document.getElementById("confirm-new-password-input-ico").className = "fas fa-eye-slash";
    }
    else {
      document.getElementById(id).type = 'password';
      document.getElementById("confirm-new-password-input-ico").className = "fas fa-eye";
    }
  }

  onSubmitProfile(e) {
    e.preventDefault();
    
    let params = {
      email: this.state.userData.email,
      fullname: this.state.userData.fullname,
      phone: this.state.userData.phone,
      birthday: this.state.userData.birthday,
      gender: this.state.userData.gender
    }
    
    authApi.updateProfile(params, this.submitProfileSuccess, showMessage)
  }
  submitProfileSuccess(res) {
    alert(res.data.message);
    let authUser = getUserName();
    authUser.email = this.state.userData.email;
    authUser.fullname = this.state.userData.fullname;
    authUser.phone = this.state.userData.phone;
    authUser.birthday = this.state.userData.birthday;
    authUser.gender = this.state.userData.gender;
    setAuthuser(authUser)
    this.setState({ showModalProfile: false });
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" onClick={e => this.openModal(e)}>
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
        <Modal isOpen={this.state.showModalProfile} size="lg" toggle={e => this.closeModal()}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="">
              Thông tin tài khoản
            </h5>
            <button
              type="button"
              onClick={this.closeModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTabProfile === "profile",
                  })}
                  onClick={() => {
                    this.changeTabProfile("profile")
                  }}
                >
                  Thông tin tài khoản
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTabProfile === "password",
                  })}
                  onClick={() => {
                    this.changeTabProfile("password")
                  }}
                >
                  Đổi mật khẩu
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTabProfile} className="mt-3 text-muted">
              <TabPane tabId="profile">
                <Form onSubmit={this.onSubmitProfile} id="profileFrm">
                  <Row xl={12}>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Username</Label>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.userData.username}
                        disabled
                      />
                    </Col>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Email</Label>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.userData.email}
                        onChange={e => this.changeUserData(e.target.value, 'email')}
                      />
                    </Col>
                  </Row>

                  <Row xl={12}>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Họ và tên</Label>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.userData.fullname}
                        onChange={e => this.changeUserData(e.target.value, 'fullname')}
                      />
                    </Col>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Số điện thoại</Label>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.userData.phone}
                        onChange={e => this.changeUserData(e.target.value, 'phone')}
                      />
                    </Col>
                  </Row>

                  <Row xl={12}>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Ngày sinh</Label>
                      <Input
                        type="date"
                        className="form-control"
                        value={this.state.userData.birthday}
                        onChange={e => this.changeUserData(e.target.value, 'birthday')}
                      />
                    </Col>
                    <Col xl={6} sm={12} className="mb-2">
                      <Label>Giới tính</Label>
                      <Input
                        type="select"
                        className="form-control"
                        value={this.state.userData.gender}
                        onChange={e => this.changeUserData(e.target.value, 'gender')}
                      >
                        {this.showGender()}
                      </Input>
                    </Col>
                  </Row>

                  <div>
                    <button type="submit" className="btn btn-primary w-md">
                      Lưu
                    </button>
                  </div>
                </Form>
              </TabPane>
              <TabPane tabId="password">
                <Form onSubmit={this.onSubmitChangePass}>
                  <div>
                    <Label>Mật khẩu</Label>
                    <div ></div>
                    <InputGroup >
                      <Input
                        type="password"
                        className="form-control"
                        id="new-password-input"
                        value={this.state.newPassword}
                        onChange={e => this.changeInput(e.target.value, 'newPassword')}
                      />
                      <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={e => this.toggleShowPasswordInput('new-password-input')}>
                        <span className="input-group-text"><i id="new-password-input-ico" className="far fa-eye" style={{ fontSize: '20px' }}></i></span>
                      </span>
                    </InputGroup>
                  </div>
                  <div className="my-3">
                    <Label>Xác nhận mật khẩu</Label>
                    <InputGroup >
                      <Input
                        type="password"
                        className="form-control"
                        id="confirm-new-password-input"
                        value={this.state.confirmNewPassword}
                        onChange={e => this.changeInput(e.target.value, 'confirmNewPassword')}
                      />
                      <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={e => this.toggleShowPasswordInput2('confirm-new-password-input')}>
                        <span className="input-group-text"><i id="confirm-new-password-input-ico" className="far fa-eye" style={{ fontSize: '20px' }}></i></span>
                      </span>
                    </InputGroup>
                  </div>
                  <button type="submit" className="btn btn-primary w-md">
                    Lưu
                  </button>
                </Form>

              </TabPane>
            </TabContent>

          </div>
          {/* <div className="modal-footer">
            <button
              type="button"
              onClick={this.closeModal}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
            >
              Save changes
            </button>
          </div> */}
        </Modal>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
