import React, { useState, useEffect, useReducer } from "react"
import { Alert, Table, message } from "antd"
import { PropTypes } from 'prop-types'

const reducerDataSource = (dataSource, action) => {
  switch (action.type) {
    case 'POPULATE':
      return action.payload;
    case 'SOFT_DELETE':
      const unDeletedItems = dataSource.map((el) => {
        if (el.id == action.payload) {
          return { ...el, ...{ 
            'is_deleted': 1,
            'status_text': 'Đã xóa'
          }}
        }
        return el;
      })
      return unDeletedItems;
    case 'RESTORE':
      const normalItems = dataSource.map((el) => {
        if (el.id == action.payload) {
          return { ...el, ...{ 
            'is_deleted': 0,
            'status_text': 'Bình thường'
          }}
        }
        return el;
      })
      return normalItems;
    case 'DELETE':
      const notDeletedItems = dataSource.filter(el => el.id != action.payload);
      notDeletedItems.forEach(function (row, index) {
        row.stt = index + 1;
      });
      return notDeletedItems;
    default:
      return dataSource;
  }
}

function FullLoadedTableWithData({ getColumns, data, deleteData, restoreData, canRestore, paginationTitle, perPage }) {

  const [columns, setColumns] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [dataSource, dispatchDataSource] = useReducer(reducerDataSource, []);
  const [errorMessage, setErrorMessage] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  const handleRestore = (id) => {
    return restoreData(id)
      .then(resp => {
        message.success(resp.message);
        dispatchDataSource({type: 'RESTORE', payload: id});
      })
      .catch(error => {
        message.error(error);
      })
  }

  const handleDelete = (id) => {
    return deleteData(id)
      .then(resp => {
        message.success(resp.message);
        if (canRestore) {
          dispatchDataSource({type: 'SOFT_DELETE', payload: id});
        } else {
          dispatchDataSource({type: 'DELETE', payload: id});
        }
      })
      .catch(error => {
        message.error(error);
      })
  }

  const initColumns = () => {
    const tableColumns = getColumns(handleDelete, handleRestore);
    console.log('tableColumns = ', tableColumns);
    setColumns(tableColumns);
  }

  useEffect(() => {
    initColumns();
  }, [])

  useEffect(() => {
    dispatchDataSource({type: 'POPULATE', payload: data});
  }, [data])

  return (
    <>
      {errorMessage ? <Alert message={errorMessage} type="error" className="mb-2" /> : null}
      <Table
        tableLayout="fixed"
        className="mytable"
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Không có dữ liệu' }}
        loading={tableLoading}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: perPage,
          total: totalItem,
          hideOnSinglePage: true,
          locale: { items_per_page: paginationTitle },
          showTotal: (total) => `Tổng ${total} ${paginationTitle}`,
        }}
      />
    </>
  )
}

FullLoadedTableWithData.propTypes = {
  getColumns: PropTypes.func,
  data: PropTypes.array,
  deleteData: PropTypes.func,
  restoreData: PropTypes.func,
  canRestore: PropTypes.bool,
  paginationTitle: PropTypes.string,
  perPage: PropTypes.number,
}
export default FullLoadedTableWithData