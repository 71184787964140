import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Space } from 'antd';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import * as saleCampaignApi from "apis/saleCampaignApi";
import { CSVLink } from 'react-csv';
import { getFindSaleProductColumns } from './FindSaleProductColumns';
import FullLoadedTableWithData from 'components/Common/Table/FullLoadedTableWithData';
import { validateMessages } from 'components/Common/Form/CommonProps';
import { MetaTags } from 'react-meta-tags';
import { showMessage } from 'Utils/global';
import { useSelector } from 'react-redux';

FindSaleProducts.propTypes = {
  campaignId: PropTypes.number,
  isView: PropTypes.bool,
  saleProducts: PropTypes.array,
  productTableVisible: PropTypes.bool,
};

function FindSaleProducts() {
  const selectedShop = useSelector(state => state.SelectedShop)
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    updateFilteredProducts(data);
  }, [data])

  const updateFilteredProducts = (filteredSource) => {
    const filteredData = filteredSource.map((currentValue, index) => {
      return {
        "STT": currentValue.stt,
        "Mã SP": currentValue.product_code,
        "Code ảnh": currentValue.code_anh,
        "Tên SP": currentValue.product_name,
        "Tên chiến dịch": currentValue.name,
        "Từ ngày": currentValue.from_date,
        "Đến ngày": currentValue.to_date,
        "Giá gốc": currentValue.product_original_price,
        "Giá bán": currentValue.product_price,
        "Giá sale": currentValue.product_sale_price,
        "Giá gốc trong sale": currentValue.campaign_retail_price,
        "Giá bán trong sale": currentValue.campaign_sale_price,
        "Tình trạng": currentValue.status_text,
      }
    });
    setFilteredProducts(filteredData);
  }

  const handleFinish = (values) => {
    const data = [];
    if (values.product_code && values.product_code.length > 0) {
      data.push({'name': 'product_code', 'value': values.product_code});
    }
    if (values.product_name && values.product_name.length > 0) {
      data.push({'name': 'product_name', 'value': values.product_name});
    }
    if (values.code_anh && values.code_anh.length > 0) {
      data.push({'name': 'code_anh', 'value': values.code_anh});
    }
    data.push({'name': 'company_id', 'value': selectedShop});
    saleCampaignApi.find(data, onFindSuccess, showMessage);
  }

  const onFindSuccess = (res) => {
    let alist = res.data.data.map((item, index) => {
      return {
        ...item,
        id: 1 + index,
        key: 1 + index,
        stt: 1 + index,
      }
    });
    console.log('alist = ', alist)
    setData(alist);
  }

  const handleFinishFailed = (values, errorFields, outOfDate) => {
    console.log('handleFinishFailed values = ', values);
    console.log('handleFinishFailed errorFields = ', errorFields);
    console.log('handleFinishFailed outOfDate = ', outOfDate);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Tìm sản phẩm sale</title>
      </MetaTags>
      <Card>
        <CardHeader className="h3 bg-transparent border-bottom">
          Tìm sản phẩm khuyến mại
        </CardHeader>
        <CardBody className='pt-1 pb-1'>
          <Form
            form={form}
            layout='inline'
            name="find-sale-product-form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateMessages={validateMessages}
            autoComplete="off"
            style={{
              maxWidth: 'none',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Form.Item
              name="product_code"
              label="Mã sản phẩm" >
              <Input placeholder='Nhập mã sản phẩm để tìm kiếm' />
            </Form.Item>
            <Form.Item
              name="code_anh"
              label="Code ảnh" >
              <Input placeholder='Nhập code ảnh để tìm kiếm' />
            </Form.Item>
            <Form.Item
              name="product_name"
              label="Tên sản phẩm" >
              <Input placeholder='Nhập tên sản phẩm để tìm kiếm' />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Tìm (Bấm Enter)
                </Button>
                <Button type="primary" className="px-3 mr-2">
                  <CSVLink style={{ color: "white" }} data={filteredProducts} filename={"san-pham-sale.csv"}>
                    Download
                  </CSVLink>
                </Button>
              </Space>
            </Form.Item>
          </Form>

          <FullLoadedTableWithData
            getColumns={getFindSaleProductColumns}
            data={data}
            paginationTitle="sản phẩm sale"
            perPage={30} />
        </CardBody>
      </Card>
    </div>
  );
}

export default FindSaleProducts;