import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getListUnit = (page, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/units';
    let params = []
    if (page) {
        params.push({
            name: 'page',
            value: page
        })
    }
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}

export const getAll = (callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-units';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, "", callback, callbackErr);
}

export const deleteUnit = (code, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/units/' + code;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)

}
export const getUnit = (code, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/units/' + code;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}
export const createUnit = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/units';

    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)

}
export const updateUnit = (code, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/units/' + code;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}