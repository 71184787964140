import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
  Input, Form, FormGroup, Label, TabContent, TabPane, Row, Col, InputGroup, InputGroupText,
  NavItem, Nav, NavLink
} from 'reactstrap';
import { message, Popconfirm, Select, Table } from 'antd';
import * as saleCampaignDetailApi from "../../apis/saleCampaignDetailApi";
import * as productApi from "../../apis/productApi";
import * as inventoryApi from "../../apis/inventoriesApi";
import { convertNumberToCurrency, debounce, showMessage } from 'Utils/global';
import { utils } from 'Utils/utils';
import PropTypes from 'prop-types';
import getColumnSearchProps from 'components/Common/TableFilter';
import { SaleProductContext } from './SaleCampaign';
import * as wareHouseApi from "../../apis/wareHouseExportApi";
import classnames from "classnames"

AddSaleProductsModal.propTypes = {
  campaignId: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

const parseRawData = (rawData) => {
  var clipRows = rawData.split('\n');

  for (let i = 0; i < clipRows.length; i++) {
    clipRows[i] = clipRows[i].split('\t');
  }

  jsonObj.length = 0;
  for (let i = 0; i < clipRows.length; i++) {
    var item = {};
    for (let j = 0; j < clipRows[i].length; j++) {
      if (clipRows[i][j] != '\r') {
        if (clipRows[i][j].length !== 0) {
          item[j] = clipRows[i][j];
        }
      }
    }
    jsonObj.push(item);
  }

  let rows = [];
  for (let i = 0; i < jsonObj.length; i++) {
    let obj = jsonObj[i];
    if ((Object.keys(obj).length === 3) && ('' !== obj['0'])) {
      let row = {
        product_code: obj['0'].trim(),
        retail_price: obj['1'].trim().replaceAll('.', ''),
        sale_price: obj['2'].trim().replaceAll('.', '')
      };
      rows.push(row);
    }
  }

  return rows;
}

const findDuplicates = (rows) => {
  const lookup = rows.reduce((a, e) => {
    a[e.product_code] = ++a[e.product_code] || 0;
    return a;
  }, {});
  const duplicates = rows.filter(e => lookup[e.product_code]);
  return duplicates;
}

const getProductDetails = async (rows) => {
  const codes = rows.map(obj => obj.product_code);
  const res = await productApi.getProductsByCodes({ codes: codes }, () => { }, showMessage);
  const products = res.data.data;
  return products;
}

const getProductsOutOfStock = async (ids, errors) => {
  const res = await inventoryApi.getProductsOutOfStock({ ids: ids }, () => { }, showMessage);
  const products = res.data.data;
  products.map(p => {
    p.error = 'Sản phẩm hết tồn';
    errors.push(p);
  });
  return products;
}

var jsonObj = [];

function AddSaleProductsModal({ campaignId, visible, onClose }) {

  const saleProductContext = useContext(SaleProductContext)
  const { productsDispatch, companyId } = saleProductContext

  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState('');
  const [errorProducts, setErrorProducts] = useState([]);
  const [addingProductErrors, setAddingProductErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const [productCodes, setProductCodes] = useState([]);
  const [salePricePercent, setSalePricePercent] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({
    productCode: "",
    productName: "",
    productId: 0,
    originalPrice: 0,
    price: 0,
    retailPrice: 0,
    salePrice: 0
  });

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '5%',
      align: 'center',
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '14%',
      ...getColumnSearchProps('product_code', 'mã sản phẩm'),
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '33%',
      ...getColumnSearchProps('product_name', 'tên sản phẩm'),
    },
    {
      title: 'Giá gốc',
      dataIndex: 'display_original_price',
      key: 'display_original_price',
      width: '11%',
      align: 'right',
      ...getColumnSearchProps('original_price', 'giá gốc'),
    },
    {
      title: 'Giá bán',
      dataIndex: 'display_price',
      key: 'display_price',
      width: '11%',
      align: 'right',
      ...getColumnSearchProps('price', 'giá bán'),
    },
    {
      title: 'Giá retail',
      dataIndex: 'display_retail_price',
      key: 'display_retail_price',
      width: '11%',
      align: 'right',
      ...getColumnSearchProps('retail_price', 'giá retail'),
    },
    {
      title: 'Giá km',
      dataIndex: 'display_sale_price',
      key: 'display_sale_price',
      width: '11%',
      align: 'right',
      ...getColumnSearchProps('sale_price', 'giá khuyến mại'),
    },
    {
      title: 'Xóa',
      width: '4%',
      align: 'center',
      render: (key, record) => {
        return (
          <Popconfirm
            placement="top"
            title={"Bạn có muốn xóa sản phẩm khuyến mại?"}
            onConfirm={() => onRemove(record)}
            okText="Đồng ý xóa"
            cancelText="Không"
          >
            <a className="text-danger"><i className="fas fa-trash"></i></a>
          </Popconfirm>);
      },
    },
  ];

  const columnErrors = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '30%',
      ...getColumnSearchProps('product_code', 'mã sản phẩm'),
    },
    {
      title: 'Giá',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: '30%',
    },
    {
      title: 'Lỗi',
      dataIndex: 'error',
      key: 'error',
      width: '30%',
    },
  ];

  const columnAddingProductErrors = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
    },
    {
      title: 'Lỗi',
      dataIndex: 'error',
      key: 'error',
      width: '90%',
    },
  ];

  const onRemove = (record) => {
    const notDeletedProducts = data.filter(el => el.product_code != record.product_code);
    notDeletedProducts.forEach(function (row, index) {
      row.stt = index + 1;
    });
    setData(notDeletedProducts);

    var rows = rawData.split('\n');
    const notDeletedRows = rows.filter(el => el.indexOf(record.product_code) == -1);
    const updatedRawData = notDeletedRows.join('\n');
    setRawData(updatedRawData);
  }

  const processDuplicates = (duplicates, errors) => {
    if (duplicates.length === 0) return false;

    duplicates.sort(function (a, b) {
      var x = a['product_code'];
      var y = b['product_code'];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    duplicates.forEach((p) => {
      p.error = 'Mã sản phẩm xuất hiện nhiều lần';
      errors.push(p);
    });
    return true;
  }

  const processNotExistProducts = (rows, productDetails, errors) => {
    let notExistsProducts;
    if (productDetails.length == 0) {
      notExistsProducts = rows.map(p => p);
    } else {
      let existsProductCodes = productDetails.map(p => p.product_code);
      notExistsProducts = rows.filter(p => !existsProductCodes.includes(p.product_code));
    }
    notExistsProducts.map(p => {
      p.error = 'Sản phẩm không có trên hệ thống';
      let pushed = errors.find(errorProduct =>
      ((errorProduct.product_code === p.product_code)
        && (errorProduct.error === p.error)));
      if (typeof pushed === 'undefined') {
        errors.push(p);
      }
    });
    return (notExistsProducts.length > 0);
  }

  const processAddDetail = (rows, productDetails) => {
    const updatedProducts = rows.reduce((filtered, row) => {
      let found = productDetails.find(p => p.product_code === row.product_code);
      if (found) {
        row.product_id = found.product_id;
        row.product_name = found.product_name;
  
        row.original_price = parseInt(found.original_price);
        row.display_original_price = convertNumberToCurrency(row.original_price);
  
        row.price = parseInt(found.price);
        row.display_price = convertNumberToCurrency(row.price);
  
        row.retail_price = parseInt(row.retail_price);
        row.display_retail_price = convertNumberToCurrency(row.retail_price);
  
        row.sale_price = parseInt(row.sale_price);
        row.display_sale_price = convertNumberToCurrency(row.sale_price);

        row.stt = filtered.length + 1;
        row.key = row.stt;
        filtered.push(row);
      }
      return filtered;
    }, [])
    setData(updatedProducts);
  }

  const validateProducts = async () => {
    setLoading(true);
    setHasError(false);
    setData([]);
    setErrorProducts([]);
    let hasProductError = false;

    if (rawData != '') {
      let errors = [];
      const rows = parseRawData(rawData);
      const duplicates = findDuplicates(rows);
      hasProductError = processDuplicates(duplicates, errors);
      const productDetails = await getProductDetails(rows);
      const hasNotExistProducts = processNotExistProducts(rows, productDetails, errors);
      let existsProductIds = productDetails.map(p => p.product_id);
      const productsOutOfStock = await getProductsOutOfStock(existsProductIds, errors);
      hasProductError = (hasNotExistProducts || (productsOutOfStock.length > 0) || hasProductError);      
      errors.map((p, index) => { p.key = index + 1; return p; });
      setErrorProducts(errors);
      setHasError(hasProductError);
      processAddDetail(rows, productDetails);
    }
    setLoading(false);
  }

  const addToList = () => {
    const original_price = utils.moneyToNumber(selectedProduct.originalPrice);
    const display_original_price = convertNumberToCurrency(original_price);

    const price = utils.moneyToNumber(selectedProduct.price);
    const display_price = convertNumberToCurrency(price);

    const retail_price = parseInt(selectedProduct.retailPrice);
    const display_retail_price = convertNumberToCurrency(retail_price);

    const sale_price = parseInt(selectedProduct.salePrice);
    const display_sale_price = convertNumberToCurrency(sale_price);

    const newData = [...data, {
      product_code: selectedProduct.productCode,
      product_name: selectedProduct.productName,
      product_id: selectedProduct.productId,     
  
      original_price: original_price,
      display_original_price: display_original_price,
      price: price,
      display_price: display_price,
      retail_price: retail_price,
      display_retail_price: display_retail_price,
      sale_price: sale_price,
      display_sale_price: display_sale_price,
    }];
    newData.map((p, index) => { p.stt = index + 1, p.key = p.stt; return p; });
    setData(newData);
    setSelectedProduct({
      productCode: "",
      productName: "",
      productId: 0,
      originalPrice: 0,
      price: 0,
      retailPrice: 0,
      salePrice: 0
    })
    setSalePricePercent(0);
  }

  const handleChange = (value) => {
    setRawData(value);
  }

  const handleSave = () => {
    const postData = data.map(({product_id, retail_price, sale_price}) => ({product_id, retail_price, sale_price}))
    const products = {saleProducts: postData};
    saleCampaignDetailApi.add(campaignId, products, onAddSuccess, showMessage);
  }

  const onChangeRetailPrice = (value) => {
    value = utils.moneyToNumber(value);
    setSelectedProduct({ ...selectedProduct, retailPrice: value });
  }

  const onChangeSalePrice = (value) => {
    value = utils.moneyToNumber(value);
    setSelectedProduct({ ...selectedProduct, salePrice: value });
    let retailPrice = selectedProduct.retailPrice;
    const price = utils.moneyToNumber(selectedProduct.price);
    if (selectedProduct.retailPrice == 0) retailPrice = price;
    const percent = parseInt((retailPrice - value) / retailPrice * 100);
    setSalePricePercent(percent);
  }

  const onChangeSalePricePercent = (value) => {
    if (value == '') return;
    setSalePricePercent(value);
    let retailPrice = utils.moneyToNumber(selectedProduct.retailPrice);
    const price = utils.moneyToNumber(selectedProduct.price);
    if (retailPrice == 0) retailPrice = price;
    let salePrice = (value * retailPrice) / 100;
    salePrice = parseInt(salePrice / 100000) * 100000; // round up to 100K
    setSelectedProduct({ ...selectedProduct, salePrice: salePrice });
  }

  const handleClose = () => {
    setRawData('');
    setErrorProducts([]);
    setHasError(false);
    setData([]);
    onClose();
    setSelectedProduct({
      productCode: "",
      productName: "",
      productId: 0,
      originalPrice: 0,
      price: 0,
      retailPrice: 0,
      salePrice: 0
    });
  }

  const onAddSuccess = (res) => {
    let products = res.data.products;
    products.forEach(function (row, index) {
      row.key = row.product_id;
      row.stt = index + 1;
      row.sale_price = parseInt(row.sale_price);
      row.display_price = convertNumberToCurrency(row.sale_price);
    });
    productsDispatch({ type: 'POPULATE', payload: products });
    handleClose();
    setAddingProductErrors([]);
    message.success(res.data.message);
  }

  const onChangeProductCodes = (key, values) => {
    setSelectedProduct({
      ...selectedProduct,
      productId: values.product_id,
      productCode: values.product_code,
      productName: values.product_name,
      originalPrice: convertNumberToCurrency(values.original_price),
      price: convertNumberToCurrency(values.price),
    })
  };

  const onSearchProductCodes = val => {
    getProductList(val, companyId);
  };

  const getProductList = useCallback(
    debounce((val, companyId) => {
      if (val) {
        const params = [
          { name: "product_code", value: val },
          { name: "company_id", value: companyId },
        ];
        wareHouseApi.getProductInStockByCode(
          params,
          fetchDataProductSuccess,
          fetchDataProductFail
        );
      }
    }, 1000),
    []
  );

  const fetchDataProductSuccess = res => {
    setProductCodes(res.data.data);
  };

  const fetchDataProductFail = res => {
    setProductCodes([]);
  };


  const renderFormAddMultiProducts = () => {
    return (
      <>
        <FormGroup>
          <Label>Copy nội dung file chương trình khuyến mại và dán vào đây</Label>
          <Input
            type='textarea'
            value={rawData}
            onChange={e => handleChange(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Button color="primary" className='mr-2' onClick={validateProducts}>
            Thêm
          </Button>
        </FormGroup>
      </>
    );
  }

  const renderFormAddSingleProduct = () => {
    return (
      <>
        <FormGroup row>
          <Label className="col-2 mt-2" style={{ maxWidth: "130px" }}>Thêm mã lẻ</Label>
          <Select
            showSearch
            placeholder="Nhập mã sản phẩm"
            onChange={onChangeProductCodes}
            onSearch={onSearchProductCodes}
            value={selectedProduct.productCode}
            filterOption={false}
            bordered={false}
            style={{ borderRadius: "0.25rem", border: "1px solid #ced4da" }}
            className="col-10"
          >
            {productCodes.map(item => (
              <Select.Option
                key={item.product_id}
                product_code={item.product_code}
                original_price={item.original_price}
                price={item.price}
                product_name={item.product_name}
                unit_code={item.unit_code}
                product_id={item.product_id}
                value={item.product_code}
              >
                {item.product_code} - {item.product_name}
              </Select.Option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2" style={{ maxWidth: "130px" }}>Mã sản phẩm: </Label>
          <Label className="col-8">{selectedProduct.productCode}</Label>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2" style={{ maxWidth: "130px" }}>Tên sản phẩm: </Label>
          <Label className="col-8">{selectedProduct.productName}</Label>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2" style={{ maxWidth: "130px" }}>Giá gốc:</Label>
          <InputGroup style={{ maxWidth: '250px', minWidth: '250px' }} >
            <Input type="text"
              value={selectedProduct.originalPrice ? utils.formatNumber(selectedProduct.originalPrice) : 0}
              readOnly={true}
              style={{ textAlign: "right" }}
            />
            <InputGroupText>đ</InputGroupText>
          </InputGroup>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2" style={{ maxWidth: "130px" }}>Giá bán:</Label>
          <InputGroup style={{ maxWidth: '250px', minWidth: '250px' }} >
            <Input type="text"
              value={selectedProduct.price ? utils.formatNumber(selectedProduct.price) : 0}
              readOnly={true}
              style={{ textAlign: "right" }}
            />
            <InputGroupText>đ</InputGroupText>
          </InputGroup>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2 mt-2" style={{ maxWidth: "130px" }}>Giá retail:</Label>
          <InputGroup style={{ maxWidth: '250px', minWidth: '250px' }} >
            <Input type="text"
              value={selectedProduct.retailPrice ? utils.formatNumber(selectedProduct.retailPrice) : 0}
              onChange={e => onChangeRetailPrice(e.target.value)}
              style={{ textAlign: "right" }}
            />
            <InputGroupText>đ</InputGroupText>
          </InputGroup>
        </FormGroup>

        <FormGroup row>
          <Label className="col-2 mt-2" style={{ maxWidth: "130px" }}>Giá khuyến mại:</Label>
          <Row className="col-8">
            <Col style={{ maxWidth: '250px', minWidth: '250px' }} className="mb-2">
              <InputGroup>
                <Input type="text" placeholder='Nhập giá giảm'
                  value={selectedProduct.salePrice ? utils.formatNumber(selectedProduct.salePrice) : 0}
                  onChange={e => onChangeSalePrice(e.target.value)}
                  style={{ textAlign: "right" }}
                />
                <InputGroupText>đ</InputGroupText>
              </InputGroup>
            </Col>
            <Col style={{ maxWidth: '120px', minWidth: '120px' }}>
              <InputGroup>
                <Input type="text" placeholder='Nhập % giảm' value={salePricePercent}
                  onChange={e => onChangeSalePricePercent(e.target.value)}
                  style={{ textAlign: "right" }}
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Button color="primary" onClick={addToList}>
            Thêm
          </Button>
        </FormGroup>
      </>
    );
  }

  return (
    <Modal size='xl' style={{ minWidth: '680px' }} isOpen={visible} toggle={handleClose}>
      <ModalHeader>
        Chiến dịch khuyến mại
      </ModalHeader>
      <ModalBody>
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab == "1" })}
              onClick={() => { setActiveTab('1') }}
            >
              <span className="d-block d-sm-none"><i className="fas fa-list-ol"></i></span>
              <span className="d-none d-sm-block">Thêm nhiều sản phẩm</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab == "2" })}
              onClick={() => { setActiveTab('2') }}
            >
              <span className="d-block d-sm-none"><i className="fas fa-tshirt"></i></span>
              <span className="d-none d-sm-block">Thêm 1 sản phẩm</span>
            </NavLink>
          </NavItem>
        </Nav>

        <Form>
          <TabContent activeTab={activeTab} className="text-muted mt-3">
            <TabPane tabId='1'>
              {renderFormAddMultiProducts()}
            </TabPane>
            <TabPane tabId='2'>
              {renderFormAddSingleProduct()}
            </TabPane>
          </TabContent>

          {hasError
            ? <FormGroup>
              <Label className='error'>Các lỗi xảy ra khi phân tích danh sách sản phẩm khuyến mại</Label>
              <Table loading={loading}
                className='mytable'
                size='small'
                pagination={{
                  hideOnSinglePage: true,
                }}
                columns={columnErrors}
                dataSource={errorProducts}
              />
            </FormGroup>
            : null
          }

          {addingProductErrors.length > 0
            ? <FormGroup>
              <Label className='error'>Các lỗi xảy ra khi thêm sản phẩm khuyến mại</Label>
              <Table loading={loading}
                className='mytable'
                size='small'
                pagination={{
                  hideOnSinglePage: true,
                }}
                columns={columnAddingProductErrors}
                dataSource={addingProductErrors}
              />
            </FormGroup>
            : null
          }

          {!hasError
            ? <FormGroup>
              <Label>Danh sách các mã sản khuyến mại</Label>
              <Table loading={loading}
                className='mytable'
                size='small'
                pagination={{
                  hideOnSinglePage: true,
                }}
                columns={columns}
                dataSource={data}
              />
            </FormGroup>
            : null
          }
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Đóng
        </Button>
        {!hasError
          ? <Button color="primary" onClick={handleSave}>
            Lưu lại
          </Button>
          : null
        }
      </ModalFooter>
    </Modal>
  );
}

export default AddSaleProductsModal;
