import RoleTableItem from 'components/TableItem/RoleTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as roleApi from '../../apis/roleApi';
import PropTypes from 'prop-types';
import {Table, Card, CardBody,CardTitle,Row,Col,Button} from "reactstrap"

import { Link, useHistory } from 'react-router-dom';
import AlertSimple from 'components/Common/AlertSimple';
import { useSelector } from 'react-redux';
import { useQuery } from 'Utils/UseQuery';
import Confirm from 'components/Common/Confirm';
import MetaTags from 'react-meta-tags';


ListRole.propTypes = {
    location: PropTypes.object,
}

ListRole.defaultProps = {
    location:{}
}

function ListRole(props) {
    const [data,setData] = useState([]);
    const [additionalData, setAdditionalData] = useState({})
    let history = useHistory();
    const selectedShop = useSelector(state => state.SelectedShop);    
    const [showMessage, setShowMessage] = useState({state:false, message:"",title:"",typeShow:'success'});
    const [deleteId, setDeleteId] = useState(null);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page)
    }, [props.location.search, selectedShop,reload]);

    const fetchData = (page) => {
        roleApi.getList(selectedShop, page, roleSucess, errorHandler)
    }

    const roleSucess = (res) =>{
        setData(res.data.data.data);
        setAdditionalData(res.data.data);
    }

    const errorHandler = (res) =>{
        setShowMessage({state:true,message:res.message, title:"Thất bại",typeShow:'danger'});

    }

    const onDelete = (e,id) =>{
        e.preventDefault();
        setDeleteId(id);
    }

    
    const showRoles = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<RoleTableItem key={i} data={v} count={i+1} onDelete={onDelete}/>)
        })
    }

    const onChangePage = (page) =>{
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }

    const onConfirm = () =>{
        setDeleteId(null);
        roleApi.deleteRole(deleteId, confirmSuccess, errorHandler) ;
    } 

    const confirmSuccess = (res) => {
        setReload(!reload);
        setShowMessage({state:true, message:"",title:"Xóa thành công" ,typeShow:'success'});
        
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Danh sách nhóm quyền</title>
            </MetaTags>
            {deleteId ?
                <Confirm
                    title={"Bạn chắc chắn muốn xóa ?" }
                    message="Bạn sẽ không thu hồi được quyết định này"
                    confirmCallback={() => {
                        onConfirm();
                    }}
                    cancelCallback={() => {
                        setDeleteId(null)
                    }}
                />
                : null
            }
             {showMessage.state ? 
                <AlertSimple 
                    message={showMessage.message}
                    type={showMessage.typeShow}
                    title={showMessage.title}
                    confirmCallback={() => {
                        setShowMessage({state:false});
                    }}
                />
                : null
            }

            <Card>
                <CardBody>
                    <CardTitle className="h4">Nhóm người dùng</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">                            
                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/roles/create"> 
                                <Button color='primary' className='px-3 rounded-pill'>
                                    <i className="fas fa-plus mr-2"></i> Thêm mới
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>Chức vụ</th>
                                    <th>Hành động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showRoles()}
                            </tbody>
                        </Table>
                    </div>
                    <MyPagination 
                        startPage={additionalData.current_page ? additionalData.current_page : 1} 
                        totalItemsCount={additionalData.total ? additionalData.total : 5} 
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
                        callback={onChangePage}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default ListRole;
