import React from 'react';
import * as brandApi from 'apis/brandApi';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import PageContent from 'components/Common/PageContent';
import LinkButton from 'components/Common/LinkButton';
import FullLoadedTableWithData from 'components/Common/Table/FullLoadedTableWithData';
import DeleteButtonWithConfirm from 'components/Common/Button/DeleteButtonWithConfirm';
import { useSelector } from 'react-redux';
import { useFetchBrandsForTable } from 'components/Hook/useFetchBrands';
import getColumnSearchProps from 'components/Common/TableFilter';

const getBrandTableColumns = (funcs) => [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 100,
    align: 'center',
  },
  {
    title: 'Tên thương hiệu',
    dataIndex: 'brand_name',
    key: 'brand_name',
    ...getColumnSearchProps('brand_name', 'thương hiệu'),
  },
  {
    title: 'Hành động',
    width: 100,
    align: 'center',
    key: 'action',
    render: (key, record) => {
      return (
        <Row justify={"center"}>
          <Col className="mr-2">
            <Link className='text-success'
              to={{
                pathname: `brands/${record.id}`,
                state: record,
              }} >
              <i className='fas fa-pen' title='Sửa thương hiệu'></i>
            </Link>
          </Col>
          <Col className="mr-2">
            {
              <DeleteButtonWithConfirm title="thương hiệu" handleConfirm={funcs.handleDelete} id={record.id} />
            }
          </Col>
        </Row>
      )
    },
  },
];

function BrandListPage() {
  const selectedShop = useSelector(state => state.SelectedShop);
  const { brands } = useFetchBrandsForTable(selectedShop);
    return (
      <PageContent title='Danh sách thương hiệu'>
        <LinkButton
          buttonClass="mb-2"
          buttonText="Tạo thương hiệu mới"
          iconClass="fas fa-plus mr-2"
          link="/brands/create"
          show={true}
        />
        <FullLoadedTableWithData
          getColumns={getBrandTableColumns}
          data={brands}
          canRestore={false}
          paginationTitle='thương hiệu'
          perPage={10}
          deleteData={brandApi.del}
        />
      </PageContent>
    )
}

export default BrandListPage;
