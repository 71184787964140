import { Field, Formik } from "formik";
import moment from "moment";
import { PropTypes } from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import * as repayApi from "../../apis/repayApi";
import * as wareHouseApi from "../../apis/wareHouseApi";
import MetaTags from 'react-meta-tags';


function Return(props) {
  const id = props.match.params?.id;
  let history = useHistory();
  let location = useLocation();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [data, setData] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [wareHouseList, setWareHouseList] = useState([]);
  const [dataWareHouseEachProduct, setDataWareHouseEachProduct] = useState({});
  const [dataToggleRepayItem, setDataToggleRepayItem] = useState({});
  const formRef = useRef();
  const [reduce_amount, setReduce_amount] = useState("");

  const formatMoney = number => {
    if(number) {
      return new Intl.NumberFormat('vi-VN').format(number);
    }
  };

  useEffect(() => {
    if (selectedShop === 0) return;
    fetchDataWareHouses(selectedShop);
  }, [selectedShop]);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  const handleRowCount = () => {
    let count = 0;
    const arr = Object.keys(dataToggleRepayItem);

    arr.map(item => {
      if (dataToggleRepayItem[item]) {
        count++;
      }
    });

    return count;
  }

  const handleTotalAmount = () => {
    let total = 0;
    for(let i = 0; i < dataTable.length; i++) {
      if(dataTable[i].checked) {
        total += parseFloat(dataTable[i].amount) 
      }
    }
    return total
  }

  const handleTotalDiscount = () => {
    let total = 0;
    for(let i = 0; i < dataTable.length; i++) {
      if(dataTable[i].checked) {
        total += parseFloat(dataTable[i].discount) 
      }
    }
    return total
  }
  const handleTotalReduceAmount = () => {
    let total = 0;
    for(let i = 0; i < dataTable.length; i++) {
      if(dataTable[i].checked) {
        total += parseFloat(dataTable[i].reduce_amount) 
      }
    }
    return total
  }
  const handleTotalFinalAmount = () => {
    let total = 0;
    for(let i = 0; i < dataTable.length; i++) {
      if(dataTable[i].checked) {
        total += parseFloat(dataTable[i].final_amount) 
      }
    }
    return total
  }

  const arrAfterProductRepay = () => {
    if (dataTable) {
      const result = dataTable.filter(item => item.checked == true);
      if(result.length > 0) {
        return result;
      }else {
        return false
      }
    }
  }

  const handleCreateRepay = values => {
    const data = {
      bill_id: id,
      company_id: selectedShop,
      repay_date: moment(new Date()).format("YYYY-MM-DD"),
      repay_note: values.repay_note,
      row_count: handleRowCount(),
      total_amount: handleTotalAmount(),
      total_discount: handleTotalDiscount(),
      total_final_amount: handleTotalFinalAmount(),
      total_reduce_amount: handleTotalReduceAmount(),
      details: arrAfterProductRepay(),
    };
    if (!arrAfterProductRepay()) {
      alert("Vui lòng chọn danh sách sản phẩm trả lại")
    } else {
      console.log('return data = ', data.details);
      repayApi.postCreateNewRepay(data, submitCreateSuccess, submitCreateFail);
    }
  }
  const submitCreateSuccess = () => {
    alert("Trả lại thành công");
    history.push("/returns");
  };
  const submitCreateFail = res => {
    alert(res.message);
  };
  const fetchDataWareHouses = selectedShop => {
    wareHouseApi.getAll(
      selectedShop,
      fetchDataWareHousesSuccess,
      fetchDataWareHousesFail
    );
  };

  const fetchDataWareHousesSuccess = res => {
    const { data } = res.data;
    setWareHouseList(data);
  };
  const fetchDataWareHousesFail = res => {
    alert(res.message);
  };

  const fetchData = id => {
    repayApi.getDetailRepayForCreate(id, fetchDataSuccess, fetchDataFail);
  };

  const fetchDataSuccess = res => {
    const { data } = res.data;
    if (data.details && data.details.length > 0) {
      const newDataTable = data.details.map(item => {
        return {
          ...item,
          checked: false
        }
      })
      setDataTable([...newDataTable]);
      setData(res.data.data);
    } else {
      alert("Danh sách sản phẩm đã được trả hết")
      history.push('/orders')
    }
  };

  const fetchDataFail = res => {
    alert(res.message);
    history.push('/orders')
  };

  const getUserName = useMemo(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      return obj;
    }
    return null;
  }, []);

  const setDataWareHouse = (e, item, i) => {
    const newArr = dataTable;
    const newItem = {
      ...item,
      warehouse_code: e
    }
    newArr.splice(i, 1, newItem);
    setDataTable([...newArr]);
  }
  const setReduceAmount = (e, item, i) => {
    const newArr = dataTable;
    const newItem = {
      ...item,
      reduce_amount: parseFloat(e),
      final_amount: parseFloat(item.amount) - parseFloat(item.discount) - parseFloat(e)
    }
    newArr.splice(i, 1, newItem);
    setDataTable([...newArr]);
  }

  const setIsCheckedItem = (e, item, i) => {
    const newArr = dataTable;
    const newItem = {
      ...item,
      checked: e
    }
    newArr.splice(i, 1, newItem);
    setDataTable([...newArr]);
  }
  const showListProduct = () => {
    if (!dataTable) return <tr></tr>;

    if (dataTable.length > 0) {
      return dataTable.map((v, i) => {
        return (
          <tr key={i}>
            <th>{v.product_code}</th>
            <td style={{ maxWidth: '200px', whiteSpace: 'normal' }}>{v.product_name || "N/A"}</td>
            <td>
              <Input
                type="select"
                className="form-control"
                value={v.warehouse_code}
                onChange={e => {
                  setDataWareHouse(e.target.value, v, i);
                }}
              >
                {wareHouseList.length > 0
                  ? wareHouseList.map((_item, index) => (
                    <option key={index} value={_item.warehouse_code}>
                      {_item.warehouse_name}
                    </option>
                  ))
                  : ""}
              </Input>
            </td>
            <td>{v.qty}</td>
            <td>{formatMoney(v.amount)}</td>
            <td>{formatMoney(v.discount)}</td>
            <td>
              <Input
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setReduceAmount(e.target.value, v, i);
                  }}
                >
                </Input>
            </td>
            <td>{formatMoney(v.final_amount)}</td>
            <td>
              <div className="form-check form-switch">
                <input
                  value={i}
                  onChange={e => {
                    setIsCheckedItem(e.target.checked, v, i)
                  }}
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch1"
                  checked={v.checked}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <div className="page-content">
    <MetaTags>
      <title>Chi tiết trả lại</title>
    </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="h4">TẠO TRẢ LẠI </CardTitle>

          <Card color="light">
            <CardBody>
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={{ repay_note: "" }}
                onSubmit={values => {
                  handleCreateRepay(values);
                }}
              >
                <Form>
                  <div className="color-primary tag mb-3">
                    THÔNG TIN HÓA ĐƠN{" "}
                  </div>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <div className="d-grid my-grid grid-fit-content-auto">
                        <div>Ngày</div>
                        <div>
                          {data
                            ? moment(data.bill_date).format("DD/MM/YYYY")
                            : ""}
                        </div>
                        <div>Số điện thoại:</div>
                        <div>{data ? data.customer_phone : ""}</div>
                        <div>Tên khách:</div>
                        <div>{data ? data.customer_name : ""}</div>
                        <div>Địa chỉ:</div>
                        <div>{data ? data.customer_address : ""}</div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <div className="d-grid my-grid grid-fit-content-auto">
                        <div>Ngày TL:</div>
                        <div>{moment(new Date()).format("DD/MM/YYYY")}</div>
                        <div>Người tạo phiếu:</div>
                        <div>{getUserName ? getUserName.fullname : ""}</div>
                        <div>Ghi chú:</div>
                        <div>
                          <Field name="repay_note" className="form-control">
                            {({ field }) => (
                              <Input
                                type="textarea"
                                className="form-control"
                                {...field}
                              />
                            )}
                          </Field>
                          {/* */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </CardBody>
          </Card>

          <div className="table-responsive">
            <Table className="table-striped mb-0 nowrap-table ">
              <thead>
                <tr>
                  <th>Mã sản phẩm</th>
                  <th>Tên sản phẩm</th>
                  <th>Kho</th>
                  <th>Số lượng</th>
                  <th>Thành tiền (vnđ)</th>
                  <th>Chiết khấu (vnđ)</th>
                  <th>Giảm trừ(vnđ)</th>
                  <th>Tổng (vnđ)</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>{showListProduct()}</tbody>
            </Table>
          </div>

          <div className="mt-4">
            <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
              <div className="h4 mb-0">Tổng:</div>
              <div className="body-1">{formatMoney(handleTotalAmount())} đ</div>
              <div className="h4 mb-0">Tổng chiết khấu:</div>
              <div className="body-1">{formatMoney(handleTotalDiscount())} đ</div>
              <div className="h4 mb-0">Tổng giảm trừ:</div>
              <div className="body-1">{formatMoney(handleTotalReduceAmount())} đ</div>
              <div className="h4 mb-0">Tổng sau chiết khấu:</div>
              <div className="h2 mb-0 color-primary">{formatMoney(handleTotalFinalAmount())} đ</div>
              <div className="">
                <Button
                  onClick={() => handleSubmit()}
                  color="success"
                  className="px-4"
                  style={{ width: "120px" }}
                >
                  Lưu lại
                </Button>
              </div>
              <div className="">
                <Button
                  color="danger"
                  className="px-4"
                  style={{ width: "120px" }}
                  onClick={() => history.push("/returns")}
                >
                  Hủy
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

Return.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(Return);
