import * as commonApis from './commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getWebOrderList = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/web-orders';
  let token = localStorage.getItem('token');
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const updateWebOrderProcessStatus = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/web-orders/${id}`;
  let token = localStorage.getItem('token');
  commonApis.PUT(url, data, token, '', callback, callbackErr);
}

export const getWebOrder = (id, callback, callbackErr) => {
  if (!id) return;
  let url = API_HOST_NAME + `/api/web-orders/${id}/inventories`;
  let token = localStorage.getItem('token');
  commonApis.GET(url, token, '', callback, callbackErr);
};
