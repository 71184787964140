import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const add = (campainId, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + campainId + '/details';
    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)
}
export const update = (campainId, campainDetailId, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + campainId + '/details/' + campainDetailId;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}
export const destroy = (campainId, campainDetailId, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/sale-campaigns/' + campainId + '/details/' + campainDetailId;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}