import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { PropTypes } from 'prop-types';

function ConfirmRestore({ confirmCallback, cancelCallback }) {
    return (
        <SweetAlert
            title="Bạn có muốn khôi phục"
            warning
            showCancel
            confirmButtonText="Đồng ý"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            cancelBtnText="Hủy"
            onConfirm={confirmCallback}
            onCancel={cancelCallback}
        >

        </SweetAlert>
    )
}

ConfirmRestore.propTypes = {
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
}
export default ConfirmRestore