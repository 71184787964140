import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { withRouter, useHistory, Link } from 'react-router-dom';
import * as unitApi from '../../apis/unitApi';
import { PropTypes } from 'prop-types';
import { getMessage } from 'Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function Unit(props) {
    const code = props.match.params?.code;
    const [data, setData] = useState({})
    let history = useHistory();
    useEffect(() => {
        if (code) {
            fetchData(code);
        }
    }, [code])
    const fetchData = (code) => {
        unitApi.getUnit(code, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
    }
    const onChangeData = (value, attrName) => {
        data[attrName] = value;
        setData({ ...data })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (code) {
            unitApi.updateUnit(code, data, onSubmitSuccess, onSubmitFail)
        }
        else {
            unitApi.createUnit(data, onSubmitSuccess, onSubmitFail)
        }
    }
    const onSubmitSuccess = (res) => {
        history.push("/units")
    }
    const onSubmitFail = (res) => {
        alert(res?.message ? getMessage(res.message) : "Lưu thất bại")
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Chi tiết đơn vị tính</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">Danh mục đơn vị tính</CardTitle>
                    <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <Label htmlFor="Shop-code-input">Mã đơn vị tính</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="unit-code-input"
                                placeholder="Nhập mã đơn vị tính"
                                value={data.unit_code}
                                onChange={e => onChangeData(e.target.value, 'unit_code')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="Shop-name-Input">Tên đơn vị tính</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="unit-name-input"
                                placeholder="Nhập tên đơn vị tính"
                                value={data.unit_name}
                                onChange={e => onChangeData(e.target.value, 'unit_name')}
                            />
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} sm="auto">
                                    <button type="submit" className="btn btn-primary w-md">
                                        Lưu
                                    </button>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Link to="/units" className="btn btn-secondary w-md">
                                        Hủy
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
Unit.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
}

export default withRouter(Unit);
