import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";
import * as repayApi from "../../apis/repayApi";
import { isRoleIn, isUserInGroup } from "Utils/SecurityUtils";
import { showMessage } from "Utils/global";

function ReturnTableItem(props) {
  const { data, deleteCallback, restoreCallback, successCallback, isKeToan } = props;
  const onDelete = () => {
    deleteCallback(data.id);
  };
  const onRestore = () => {
    restoreCallback(data.id);
  };

  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };
  
  const onSuccess = res => {
    alert(res.data.message);
    successCallback();
  };

  const onUnmarkProcessedInAccounting = () => {    
    repayApi.setNotPrcessedAccountingStatus(data.id, onSuccess, showMessage);
  }

  const onMarkProcessedInAccounting = () => {
    repayApi.setPrcessedAccountingStatus(data.id, onSuccess, showMessage);
  }

  return (
    <tr>
      <td>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</td>
      <td>{data.id}</td>
      <td>{data.bill_id}</td>
      <td>{data.customer_name}</td>
      <td>{data.customer_phone}</td>
      <td>{data.username}</td>
      <td>{formatMoney(data.total_amount)}</td>
      <td>{formatMoney(data.total_discount)}</td>
      <td>{formatMoney(data.total_final_amount)}</td>
      <td className={(isKeToan && (data?.accounting_status === 1)) ? 'accountant-checked' : ''}>{data.status_text}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link
              className="text-success"
              to={{ pathname: `/returns/views/${data.id}` }}
            >
              <i className="fas fa-eye"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
            {data.status === 0 ? <a className="text-warning" onClick={onRestore}>
              <i className="fas fa-trash-restore"></i>
            </a> : <a className="text-danger" onClick={onDelete}>
              <i className="fas fa-trash"></i>
            </a>}
          </Col>
          {isUserInGroup(['KeToan'])
            ? <Col xs="auto" sm="auto">
              {data.accounting_status === 1
                ? <a className="text-success" onClick={onUnmarkProcessedInAccounting}>
                  <i className="fas fa-unlink"></i>
                </a>
                : <a className="text-success" onClick={onMarkProcessedInAccounting}>
                  <i className="fas fa-link"></i>
                </a>
              }
            </Col>
            : null
          }
        </Row>
      </td>
    </tr>
  );
}
ReturnTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
  successCallback: PropTypes.func,
  isKeToan: PropTypes.bool,
};

export default ReturnTableItem;
