import wareHouseReceipts from "./reducer";
import {createSelector} from 'reselect';
export const wareHouseReceiptsRoot = state => state.wareHouseReceipts;
export const  wareHouseReceiptsSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.wareHouseReceipts
});
export const  wareHouseReceiptDetailSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.wareHouseReceiptDetail
});
export const  wareHousesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.wareHouses
});
export const  filterProductsSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.filterProducts
});
export const  wareHouseReceiptTypesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.wareHouseReceiptTypes
});
export const  categoriesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.categories
});
export const  searchCategoriesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.searchCategories
});
export const  groupsCheckedSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.groupsChecked
});
export const  attributesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.attributes
});
export const  allAttributesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.allAttributes
});

export const  attributeValuesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.attributeValues
});
export const  selectedAttributeSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.selectedAttribute
});
export const  checkedAttributesSelector = createSelector(wareHouseReceiptsRoot, data => {
	return data?.checkedAttributes
});
