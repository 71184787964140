import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { Td, Tr } from 'react-super-responsive-table';
import { ProductSetContext } from '../ProductSetContext';

function ListProductSetItem(props) {  

  const { itemsObject } = useContext(ProductSetContext); 
  const [listItem, setListItem] = itemsObject;

  const handleEditSetItem = (itemId) => {
    const item = listItem.find(item => item.id == itemId);    
    props.onEdit(item);
  }

  if (listItem.length === 0) return null;
  return listItem.map((v, i) => {
    return (
      <Tr key={i}>
        <Td>{v.id}</Td>
        <Td>{v.product_code}</Td>
        <Td>{v.product_name}</Td>
        <Td>{v.unit_code}</Td>
        <Td>{v.base_qty}</Td>
        <Td>
          <Row className="d-flex align-items-center m-0">              
            <Col xs="auto" sm="auto">
              <span style={{cursor: "pointer"}} onClick={() => handleEditSetItem(v.id)}>
                <i className="fas fa-pen"></i>
              </span>
            </Col>
            <Col xs="auto" sm="auto">
              <Popconfirm
                placement="top"
                title={"Bạn có muốn xóa"}
                onConfirm={() => props.onDelete(v.id)}
                okText="Yes"
                cancelText="No"
              >
                <a className="text-danger">
                  <i className="fas fa-trash"></i>
                </a>
              </Popconfirm>
            </Col>
          </Row>
        </Td>
      </Tr>
    )
  })
}

ListProductSetItem.propTypes = {
  items: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}
export default ListProductSetItem;
