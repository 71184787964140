import PropTypes from 'prop-types';
import React, { createContext, useState, useEffect } from 'react';

export const ProductSetContext = createContext();

export const ProductSetProvider = (props) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    setItems(props.items)
  }, [props.items])
  return (
    <ProductSetContext.Provider value={{itemsObject: [items, setItems]}}>
      {props.children}
    </ProductSetContext.Provider>
  );
};

ProductSetProvider.propTypes = {
  children: PropTypes.any,
  items: PropTypes.array,
}