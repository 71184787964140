
const _storage_key = {
  PAYMENT_METHODS: 'payment-methods',
  SALE_MANS: 'salemans',
  ORDER_MANS: 'ordermans',
  ROLES: 'roles_',
  USER: 'authUser',
  CART: 'cart',
  CART_CUSTOMER: 'cart_customer',
}

export { _storage_key as storage_key }
