import WarehouseTableItem from 'components/TableItem/WarehouseTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as wareHouseApi from '../../apis/wareHouseApi';


import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap"

import { Link, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from './../../Utils/UseQuery';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmDelete from 'components/Common/ConfirmDelete';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function ListWarehouse(props) {
    const [warehouseData, setWarehouseData] = useState([])
    const [additionalData, setAdditionalData] = useState({})
    const [deleteCode, setDeleteCode] = useState(null)
    const selectedShop = useSelector(state => state.SelectedShop);
    let history = useHistory()

    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page)
    }, [props.location.search, selectedShop]);
    const fetchData = (page) => {
        wareHouseApi.getListWareHouse(selectedShop, page, populateData, errorHandler)
    }
    const populateData = (data) => {
        setWarehouseData(data.data.data.data);
        setAdditionalData(data.data.data);
    }
    const errorHandler = (res) => {
        alert(getMessage(res.message));
    }
    const showWarehouses = () => {
        if (warehouseData.length === 0) {
            return (<tr></tr>);
        }
        return warehouseData.map((v, i) => {
            return (<WarehouseTableItem key={i} data={v} count={i + 1} deleteCallback={setDeleteCode} />)
        })
    }
    const showDeleteModal = () => {

        if (deleteCode) {
            return (
                <ConfirmDelete
                    confirmCallback={() => {
                        onDeleteWareHouse(deleteCode)
                    }}
                    cancelCallback={() => {
                        setDeleteCode(null)
                    }}
                />
            )
        }
        return;
    }
    const onChangePage = (page) => {
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }
    const onDeleteWareHouse = (code) => {
        wareHouseApi.deleteWarehouse(code, deleteWarehouseSuccess, deleteWarehouseFail)
    }
    const deleteWarehouseSuccess = (res) => {
        alert("Xóa thành công")
        setDeleteCode(null)
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }
    const deleteWarehouseFail = (res) => {
        alert(getMessage(res.message));
        setDeleteCode(null)
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Danh sách kho hàng</title>
            </MetaTags>
            {showDeleteModal()}
            <Card>
                <CardBody>
                    <CardTitle className="h4">Danh mục Kho hàng</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">

                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/warehouses/create">
                                <Button color='primary' className='px-3 rounded-pill'>
                                    <i className="fas fa-plus mr-2"></i> Thêm mới
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>Mã kho</th>
                                    <th>Tên kho</th>
                                    <th>Địa chỉ</th>
                                    <th>Số điện thoại</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showWarehouses()}
                            </tbody>
                        </Table>
                    </div>
                    <MyPagination  
                        startPage={additionalData.current_page ? additionalData.current_page : 1} 
                        totalItemsCount={additionalData.total ? additionalData.total : 5}
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10} 
                        callback={onChangePage} />

                </CardBody>
            </Card>
        </div>
    )
}
ListWarehouse.propTypes = {
    location: PropTypes.object,
}
export default withRouter(ListWarehouse);
