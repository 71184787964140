import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as brandApi from 'apis/brandApi';

export const useFetchBrands = (selectedShop) => {
  const [brands, setBrands] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      brandApi.getList(selectedShop, fetchBrandsSuccess, showMessage);
    }
  }, [selectedShop])

  const fetchBrandsSuccess = (res) => {
    setBrands(res.data.data.map(item => { return { value: item.id, title: item.brand_name } }));
  }

  return { brands, setBrands };
};

export const useFetchBrandsForTable = (selectedShop) => {
  const [brands, setBrands] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      brandApi.getList(selectedShop, fetchBrandsSuccess, showMessage);
    }
  }, [selectedShop])

  const fetchBrandsSuccess = (res) => {
    const { data } = res.data;
    const brandList = data.map((item, index) => ({...item, stt: index+1}));
    setBrands(brandList);
  }

  return { brands, setBrands };
};

