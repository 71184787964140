import React from 'react'
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'

import { PropTypes } from 'prop-types';
import WebOrderLabel from './WebOrderLabel';

WebOrderCustomerPart.propTypes = {
  webOrder: PropTypes.object,
}

function WebOrderCustomerPart({webOrder}) {  
  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">Thông tin khách hàng</CardHeader>
      <CardBody>                  
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Tên khách</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="text"
              className="form-control"
              value={webOrder.receipt_name}
              disabled={true}
            />
          </Col>
        </Row>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Số điện thoại</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="text"
              className="form-control"
              value={webOrder.phone}
              disabled={true}
            />
          </Col>
        </Row>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Địa chỉ</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="textarea"
              className="form-control"
              value={webOrder.receipt_address}
              disabled={true}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default WebOrderCustomerPart