import React, {useEffect} from "react"
import { Row, Col, Input, Label } from "reactstrap"
import { PropTypes } from 'prop-types';
import moment from 'moment';

import "./filter.css"

FilterFromToDate.propTypes = {
    onChangeFromDate: PropTypes.func,
    onChangeToDate: PropTypes.func,
}

function FilterFromToDate({ onChangeFromDate, onChangeToDate }) {
    let curr = new Date();
    let toDay = curr.toISOString().substring(0,10);
    curr.setMonth(curr.getMonth() - 1);
    let aMonthBefore = curr.toISOString().substring(0,10);

    const handleChangeFromDate = (date) => {
        onChangeFromDate(date ? moment(date).format("YYYY-MM-DD") : "" );
    }

    const handleChangeToDate = (date) => {
        onChangeToDate(date ? moment(date).format("YYYY-MM-DD") : "" );
    }

    useEffect(() => {
        onChangeFromDate(aMonthBefore);
        onChangeToDate(toDay);
    }, []);

    return (
        <>
            <Row xl={12} className="mb-2">
                <Label md={12} xl={2} className="col-form-label">Từ ngày - Đến ngày</Label>
                <Col xs={6} xl={10}>
                    <Input 
                        type="date"
                        className="filter-date"
                        onChange={(e) => handleChangeFromDate(e.target.value)}
                        defaultValue={aMonthBefore} />  
                    <label className="arr"><>&rarr;</></label>
                    <Input 
                        type="date"
                        className="filter-date filter-date-to"
                        onChange={(e) => handleChangeToDate(e.target.value)}
                        defaultValue={toDay} />
                </Col>
            </Row>
        </>
    );
}

export default FilterFromToDate;