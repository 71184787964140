import React, { useState } from "react"
import { Row, Col, Label } from "reactstrap"
import { PropTypes } from 'prop-types';
import { TreeSelect } from 'antd';

FilterSelectTreeMulti.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func
}

function FilterSelectTreeMulti({ label, items, onChange }) {

  const handleChange = (selectedItems) => {
    // console.log('selectedItems', selectedItems);
    // let listItem = []
    // selectedItems?.map(item => {
    //   listItem.push(item)
    // })
    // console.log('listItem', listItem);
    onChange(selectedItems.join())
  }

  return (
    <Row xl={12} className="mb-2">
      <Label md={12} xl={2} className="col-form-label">{label}</Label>
      <Col md={12} xl={4}>
        <TreeSelect
          xl={12}
          showSearch
          treeNodeFilterProp='title'
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={items}
          allowClear
          multiple
          treeDefaultExpandAll
          onChange={(value) => handleChange(value)}
        />
      </Col>
    </Row>
  );
}

export default FilterSelectTreeMulti;