
import React from 'react';
import { Link } from 'react-router-dom';
import {Row,Col} from "reactstrap"
import PropTypes from 'prop-types'

function UserPermissionItem(props) {
    const { data, count,onUserChange,activeClass} = props;
    const onChange = (e) =>{
        console.log("onChange")
        onUserChange(e,data)
    }
    return (
        <tr>
            <td 
                className={'role-permission-item font-weight-bold '+activeClass}  
                onClick={onChange}
            >
                {data.username}
            </td>
            
        </tr>
    )

}
UserPermissionItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    onUserChange:PropTypes.func,
    activeClass:PropTypes.string
}

export default UserPermissionItem;
