import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getListWareHouse = (company, page, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouses';
    if (!company) return;
    let params = [
        {
            name: 'company_id',
            value: company
        }
    ]
    if (page) {
        params.push({
            name: 'page',
            value: page
        })
    }
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}
export const deleteWarehouse = (code, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouses/' + code;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}
export const getWareHouse = (code, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouses/' + code;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr)
}
export const updateWareHouse = (code, data, selectedShop, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouses/' + code;
    data.company_id = selectedShop;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}
export const createWareHouse = (data, selectedShop, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouses';
    data.company_id = selectedShop;
    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)
}
export const getAll = (shopId, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-warehouses';
    let token = localStorage.getItem("token")
    if (!shopId) return;
    let params = [{
        name: 'company_id',
        value: shopId
    }]
    commonApis.GET(url, token, params, callback, callbackErr)
}
