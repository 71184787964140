import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Row
} from "reactstrap";
import * as contactApi from "../../../apis/contactApi";
import MetaTags from 'react-meta-tags';


function FeedbackView(props) {
  let params = useParams();
  let history = useHistory();
  const [form, setForm] = useState({});
  useEffect(() => {
    if (params.id) {
      fetchDetail(params.id);
    }
  }, [params.id]);

  const fetchDetail = id => {
    contactApi.getDetailFeedback(id, fetchDetailSuccess, fetchDetailFailure);
  };
  const routeBack = () => {
    history.push("/feedback")
  }
  const fetchDetailSuccess = res => {
    const { data } = res.data;
    setForm(data)
  };
  const fetchDetailFailure = err => {
  };

  return (
    <div className="page-content">
      <MetaTags>
          <title>CMS | Chi tiết phản hồi</title>
      </MetaTags>
      <Form className="form" onSubmit={e => submitForm(e)}>
        <Card>
          <CardBody>
            <Card color="light">
              <CardBody>
                <div className="color-primary tag mb-3">THÔNG TIN PHẢN HỒI</div>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Tên khách</div>
                      <div>
                        {form?.name}
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Liên hệ</div>
                      <div>
                        {form?.email}
                      </div>
                    </div>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Thời gian</div>
                      <div>
                        {form?.created_at}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div className="title-form">Nội dung</div>
                      <div>
                        {form?.feedback}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={20}>
                  </Col>
                  <Col xs={4}>
                    <Button color="secondary" onClick={routeBack}>
                      Quay lại
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default FeedbackView;
