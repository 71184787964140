export const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === "{}";
};

export const isInt = (value) => {
  return !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10));
}

export const getSaleProductStatusColor = (status) => {
  switch (status) {
    case 1:
      return "green";
    case 2:
      return "gold";
    case 3:
      return "cyan";
    default:
      return "red";
  }
}