import React from "react"
import PropTypes from "prop-types"
import { Alert as AntdAlert } from "antd"

const Alert = props => {
  return (
    <>
      {props.message ? <AntdAlert message={props.message} type={props.isError ? "error" : "success"} showIcon className="mb-2" /> : null}
    </>
  )
}

Alert.propTypes = {
  isError: PropTypes.bool,
  message: PropTypes.string,
}

export default Alert
