import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Switch } from 'antd';
import * as contactApi from "../../../apis/contactApi";
import * as categoryApi from "../../../apis/categoryApi";
import PreviewImg from "../../../constants/component/PreviewImg";
import "./style.css"
import { API_HOST_NAME } from "constants/SystemConstant";
import MetaTags from 'react-meta-tags';


function EditCategoryMenu(props) {
  let params = useParams();
  let history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const popupRef = useRef();
  const [form, setForm] = useState({});
  const [category_name, setCategory_name] = useState("");
  const [display_order, setDisplay_order] = useState("");
  const [imgCategory, setImgCategory] = useState("");
  const selectedShop = useSelector(state => state.SelectedShop);
  useEffect(() => {
    if (params.id) {
      fetchDetail(params.id);
    }
  }, [params.id]);

  const fetchDetail = id => {
    categoryApi.getWebCategory(id, fetchDetailSuccess, fetchDetailFailure);
  };
  const fetchDetailSuccess = res => {
    const { data } = res.data;
    setForm(data)
    setImgCategory(data?.image);
    setCategory_name(data?.category_name)
    setDisplay_order(data?.display_order)
  };

  const routeBack = () => {
    history.push("/category-menu")
  }
  const fetchDetailFailure = err => {
  };
  const handleSubmit = (values) => {
    const params = {
      display_order : values.display_order
    }
    categoryApi.updateWebCategory(form.category_id, params, updateSuccess, updateFailure);
  };
  const updateSuccess = res => {
    alert(res.data.data);
    routeBack()
  };
  const updateFailure = err => {
    alert(err.data);
  };

  const uploadImgSuccess = (res) => {
    const { data } = res.data
    fetchDetail(params.id)
  }
  const uploadFail = (res) => {
    alert(res.data);
  }
  const onUpload = (e) => {
    let result = new FormData();
    result.append('image', e.target.files[0]);
    categoryApi.updateWebCategoryImage(form.category_id, result, uploadImgSuccess, uploadFail)
  }
  return (
    <div className="page-content">
      <MetaTags>
          <title>CMS | Sửa menu danh mục</title>
      </MetaTags>
      <Formik
        enableReinitialize={true}
        initialValues={{
          category_name: (category_name) || "",
          display_order: (display_order) || ""
        }}
        validationSchema={Yup.object().shape({
          category_name: Yup.string().required(
            "Tên không được để trống"
          ),
          display_order: Yup.string().required(
            "Số thứ tự không được để trống"
          ),
        })}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Thêm mới banner </CardTitle> */}
                <Card color="light">
                  <CardBody>
                    <div className="color-primary tag mb-3">CHỈNH SỬA DANH MỤC</div>
                    <Row>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Tên danh mục</div>
                          <div>
                            <Field
                              name="category_name"
                              label=""
                              type="text"
                              disabled={true}
                              className={
                                "form-control" +
                                (errors.category_name && touched.category_name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="category_name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Số thứ tự</div>
                          <div>
                            <Field
                              name="display_order"
                              label=""
                              type="number"
                              className={
                                "form-control" +
                                (errors.display_order && touched.display_order
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="display_order"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form"></div>
                          <div className="box-upload">
                            <input type="file" id="upload_img" onChange={(e) => onUpload(e)}></input>
                            {
                              imgCategory && 
                                <img 
                                  src={imgCategory.startsWith('http') ? imgCategory : API_HOST_NAME + imgCategory} 
                                  id="img_preview"></img>
                            }
                            <div className="box-icon-upload">
                              <i className="bx bx-upload"></i>
                            </div>
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto" style={{marginTop : "20px"}}>
                          <div className="title-form"></div>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              style={{ width: "120px" }}
                            >
                              Lưu lại
                            </Button>{" "}
                            <Button color="secondary" onClick={routeBack}>
                              Quay lại
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default EditCategoryMenu;
