export const GET_LIST = "wareHouseReceipt/GET_LIST"
export const GET_LIST_SUCCESS = "wareHouseReceipt/GET_LIST_SUCCESS"

export const GET_DETAIL = "wareHouseReceipt/GET_DETAIL"
export const GET_DETAIL_SUCCESS = "wareHouseReceipt/GET_DETAIL_SUCCESS"

export const DELETE_ITEM = "wareHouseReceipt/DELETE_ITEM"
export const RESTORE_ITEM = "wareHouseReceipt/RESTORE_ITEM"

export const SAVE_ITEM = "wareHouseReceipt/SAVE_ITEM"
export const GET_LIST_WARE_HOUSE = "wareHouseReceipt/GET_LIST_WARE_HOUSE"
export const GET_LIST_WARE_HOUSE_SUCCESS = "wareHouseReceipt/GET_LIST_WARE_HOUSE_SUCCESS"

export const GET_LIST_WARE_HOUSE_RECEIPT_TYPE = "wareHouseReceipt/GET_LIST_WARE_HOUSE_RECEIPT_TYPE"
export const GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS = "wareHouseReceipt/GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS"

export const FILTER_PRODUCTS = "wareHouseReceipt/FILTER_PRODUCTS"
export const FILTER_PRODUCTS_SUCCESS = "wareHouseReceipt/FILTER_PRODUCTS_SUCCESS"

export const FILTER_CATEGORIES = "wareHouseReceipt/FILTER_CATEGORIES"
export const GET_CATEGORIES = "wareHouseReceipt/GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "wareHouseReceipt/GET_CATEGORIES_SUCCESS"
export const VERIFY_RECEIPT = "wareHouseReceipt/VERIFY_RECEIPT";

export const GROUP_CHECKED_CHANGE = "wareHouseReceipt/GROUP_CHECKED_CHANGE";


export const GET_ALL_ATTRIBUTES = "wareHouseReceipt/GET_ALL_ATTRIBUTES";
export const GET_ALL_ATTRIBUTES_SUCCESS = "wareHouseReceipt/GET_ALL_ATTRIBUTES_SUCCESS";


export const GET_ATTRIBUTES = "wareHouseReceipt/GET_ATTRIBUTES";
export const GET_ATTRIBUTES_SUCCESS = "wareHouseReceipt/GET_ATTRIBUTES_SUCCESS";
export const SAVE_ADD_NEW_ATTRIBUTE = "wareHouseReceipt/SAVE_ADD_NEW_ATTRIBUTE";
export const SAVE_UPDATE_NEW_ATTRIBUTE = "wareHouseReceipt/SAVE_UPDATE_NEW_ATTRIBUTE";
export const GET_ATTRIBUTE_VALUES = "wareHouseReceipt/GET_ATTRIBUTE_VALUES";
export const GET_ATTRIBUTE_VALUES_SUCCESS = "wareHouseReceipt/GET_ATTRIBUTE_VALUES_SUCCESS";

export const SAVE_ADD_NEW_ATTRIBUTE_VAL = "wareHouseReceipt/SAVE_ADD_NEW_ATTRIBUTE_VAL";
export const SAVE_UPDATE_NEW_ATTRIBUTE_VAL = "wareHouseReceipt/SAVE_UPDATE_NEW_ATTRIBUTE_VAL";

export const SET_SELECTED_ATTRIBUTE = "wareHouseReceipt/SET_SELECTED_ATTRIBUTE";

export const ON_CHECKED_ATTRIBUTE = "wareHouseReceipt/ON_CHECKED_ATTRIBUTE";
export const ON_SAVE_CHECKED_ATTRIBUTES = "wareHouseReceipt/ON_SAVE_CHECKED_ATTRIBUTES";
export const ON_RESET_SAVED_CHECKED_ATTRIBUTES = "wareHouseReceipt/ON_RESET_SAVED_CHECKED_ATTRIBUTES";
export const onResetSavedCheckedAttributes = (payload) => ({
	type: ON_RESET_SAVED_CHECKED_ATTRIBUTES,
	payload
})
export const onSaveCheckedAttributes = (payload) => ({
	type: ON_SAVE_CHECKED_ATTRIBUTES,
	payload
})

export const onCheckedAttribute = (payload) => ({
	type: ON_CHECKED_ATTRIBUTE,
	payload
})

export const setSelectedAttribute = (payload) => ({
	type: SET_SELECTED_ATTRIBUTE,
	payload
})

export const saveUpdateAttributeVal = (payload) => ({
	type: SAVE_UPDATE_NEW_ATTRIBUTE_VAL,
	payload
})
export const saveAddNewAttributeVal = (payload) => ({
	type: SAVE_ADD_NEW_ATTRIBUTE_VAL,
	payload
})

export const saveUpdateAttribute = (payload) => ({
	type: SAVE_UPDATE_NEW_ATTRIBUTE,
	payload
})
export const saveAddNewAttribute = (payload) => ({
	type: SAVE_ADD_NEW_ATTRIBUTE,
	payload
})
export const groupCheckedChange = (payload) => ({
	type: GROUP_CHECKED_CHANGE,
	payload
})
export const getAllAttributes = (payload) => ({
	type: GET_ALL_ATTRIBUTES,
	payload
})
export const getAttributes = (payload) => ({
	type: GET_ATTRIBUTES,
	payload
})
export const getAttributeValues = (payload) => ({
	type: GET_ATTRIBUTE_VALUES,
	payload
})
export const confirmHouseReceipt = (payload) => ({
	type: VERIFY_RECEIPT,
	payload
})
export const getGCategories = (payload) => ({/*{keyword}*/
	type: GET_CATEGORIES,
	payload
})
export const filterCategories = (payload) => ({/*{keyword}*/
	type: FILTER_CATEGORIES,
	payload
})

export const getListWareHouseReceiptTypes = (payload) => ({
	type: GET_LIST_WARE_HOUSE_RECEIPT_TYPE,
	payload
})
export const filterProducts = (payload) => ({
	type: FILTER_PRODUCTS,
	payload
})
export const getWareHouses = (payload) => ({
	type: GET_LIST_WARE_HOUSE,
	payload
})
export const getWareHouseReceipts = (payload) => ({
	type: GET_LIST,
	payload
})
export const getWareHouseReceiptDetail = (payload) => ({
	type: GET_DETAIL,
	payload
})
export const delWareHouseReceipt = (payload) => ({
	type: DELETE_ITEM,
	payload
})
export const restoreWareHouseReceipt = (payload) => ({
	type: RESTORE_ITEM,
	payload
})
export const saveWareHouseReceipt = (payload) => ({
	type: SAVE_ITEM,
	payload
})

