import WarehouseExportTableItem from 'components/TableItem/WarehouseExportTableItem';
import React, { useState, useEffect } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Input,    
} from "reactstrap";
import { getMessage } from 'Utils/ErrMessageUtils';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from 'Utils/UseQuery';
import * as wareHouseApi from './../../apis/wareHouseExportApi';
import * as warehouseExportTypeApi from '../../apis/warehouseExportTypeApi';
import moment from "moment";
import * as authApi from "../../apis/authApi";
import { API_HOST_NAME } from 'constants/SystemConstant';
import MetaTags from 'react-meta-tags';


function ListWarehouseExport(props) {
    const selectedShop = useSelector(state => state.SelectedShop);
    const [data, setData] = useState([])
    const [isShowSearch, setIsShowSearch] = useState(false);
    let history = useHistory();

    // data search
    const [export_number, setExport_number] = useState("");
    const [export_type, setExport_type] = useState("");
    const [user, setUser] = useState("");
    const [status, setStatus] = useState("");
    const [listUser, setListUser] = useState([]);
    const [listExportType, setListExportType] = useState([]);
    const [from_date, setFrom_date] = useState("");
    const [to_date, setTo_date] = useState("");
    const [currentPage, setCurrentPage] = useState([]);


    const filterToQueryString = () => {
      let queryParams = [];
  
      let fromDate = moment(from_date, "YYYY-MM-DD", true);
      if (fromDate.isValid())
        queryParams.push('from_date=' + fromDate.format("YYYY-MM-DD"));
  
      let toDate = moment(to_date, "YYYY-MM-DD", true);
      if (toDate.isValid())
        queryParams.push('to_date=' + toDate.format("YYYY-MM-DD"));
  
        if (Boolean(export_number) && (!isNaN(export_number)))
          queryParams.push('export_number=' + encodeURIComponent(export_number));
  
      queryParams.push('export_type=' + encodeURIComponent(export_type));
      queryParams.push('user=' + encodeURIComponent(user));
      queryParams.push('status=' + encodeURIComponent(status));
      queryParams.push('company_id=' + encodeURIComponent(selectedShop));

      let rdn = Math.floor(Math.random() * 10) + 1;
      queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));
  
      let queryString = queryParams.join('&');
      return queryString;
    }

    useEffect(() => {
      let query = useQuery(props.location.search)
      let fromDate = moment(query.get('from_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
      setFrom_date(fromDate)
      
      let toDate = moment(query.get('to_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
      setTo_date(toDate)
      
      setExport_number(query.get('export_number') ?? '')
      setExport_type(query.get('export_type') ?? '')
      setStatus(query.get('status') ?? '')
      setUser(query.get('user') ?? '')
    }, []);

    useEffect(() => {
        handleSearch();
        getAllStaff();
        getAllListExportType();
    }, [props.location.search, selectedShop])

    const handleOnSearch = () => {
        let url = props.location.pathname;
        let queryString = filterToQueryString()
        url = url + '?' + queryString
        history.push(url);
    }

    const handleSearch = () => {
        if (selectedShop === 0) return
        const params = [];
        let queryParams = useQuery(props.location.search)
        let page = queryParams.get("page");
        if (page) {
            params.push({name: "page", value: page});
        } 
        params.push({name: "user", value: queryParams.get("user")})
        params.push({name: "from_date", value: queryParams.get("from_date")})
        params.push({name: "to_date", value: queryParams.get("to_date")})
        params.push({name: "export_number", value: queryParams.get("export_number")})
        params.push({name: "export_type", value: queryParams.get("export_type")})
        params.push({name: "status", value: queryParams.get("status")})
        params.push({name: "company_id", value: queryParams.get("selectedShop")  ?? selectedShop})
    
        
        wareHouseApi.getListWareHouseExport(params, fetchDataSuccess, fetchDataFail)
    }
    
    const handleResetFilter = () => {
        setFrom_date("");
        setTo_date("");
        setExport_number("");
        setExport_type("");
        setUser("");
        setStatus("");
    }
    
    const getAllStaff = () => {
        authApi.getListStaffThuKho(getDataSuccess, alertFail);
    }
    const getDataSuccess = (res) => {
        setListUser(res.data.data);
    }
    const alertFail = (res) => {
        alert(res.message);
    }
    const getAllListExportType = () => {
        warehouseExportTypeApi.getAllListExportType(getDataTypeSuccess, alertFail);
    }
    const getDataTypeSuccess = (res) => {
        setListExportType(res.data.data);
    }

    const fetchDataSuccess = (res) => {
        setCurrentPage(res.data.data);
        setData(res.data.data.data);
    }

    const fetchDataFail = (res) => {
        alert(getMessage(res.message));
        setData([]);
    }

    const onChangePage = (page) => {
        let url = props.location.pathname + "?page=" + page;
        let queryString = filterToQueryString()
        url = url + '&' + queryString
        history.push(url);
    }
    const onDelete = (id) => {
        wareHouseApi.deleteWare(id, deleteSuccess, DeleteFail)
    }

    const deleteSuccess = (res) => {
        alert(getMessage(res.data.message));
        fetchData();
    }
    const DeleteFail = (res) => {
        alert(getMessage(res.data.message));
    }
    const onUndo = (id) => {
        wareHouseApi.unDoWare(id, onUndoSuccess, onUndoFail)
    }

    const onUndoSuccess = (res) => {
        fetchData();
        alert(getMessage(res.data.message));
    }
    const onUndoFail = (res) => {
        alert(getMessage(res.data.message));
    }
    const showWareHouseExportItem = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<WarehouseExportTableItem key={v.id} data={v} onDelete={onDelete} onUndo={onUndo} />)
        })
    }
    
    

    const uploadWarehouseExport = async (e) => {
        const file = e.target.files[0];
        let result = new FormData();
        result.append('company_id', selectedShop)
        result.append('import', file);
        if (file) {
            wareHouseApi.uploadWarehouseExportFile(result, uploadFileSucces, uploadFail)
        }
    }

    const uploadFileSucces = (res) => {
        alert(res.data.message);
    }

    const uploadFail = (res) => {
        alert(res.message);
    }

    const onImportFile = () => {
        document.getElementById("fileExcel").click();
    };

    return (
        <div className="page-content">
        <MetaTags>
          <title>Danh sách phiếu xuất</title>
        </MetaTags>
            <Card>
                <CardBody>
                    {/* <CardTitle className="h4">Danh sách hóa đơn</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">
                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/warehouse-exports/create"> <Button color='primary' className='px-3 rounded-pill'><i className="fas fa-plus mr-2"></i> Thêm mới</Button></Link>
                        </Col>
                    </Row> */}
                    <Card color='light'>
                        <CardBody>
                            <Row className="justify-content-between mb-3">
                                <Col xs="auto" sm="auto"><CardTitle className="h4">Danh sách phiếu xuất</CardTitle></Col>
                                <Col xs="auto" sm="auto">                                 
                                    <Button color="primary" className="px-3 rounded-pill mr-2" onClick={onImportFile}>
                                        <i className="fas fa-upload mr-2"></i> Import PX chuyển kho
                                    </Button>
                                    <input 
                                        type="file" 
                                        id="fileExcel" 
                                        style={{display: 'none'}}
                                        accept=".xlsx, .xls"
                                        onChange={uploadWarehouseExport}
                                    />
                                    <Link to="/warehouse-exports/create">
                                        {" "}
                                        <Button color="primary" className="px-3 rounded-pill">
                                            <i className="fas fa-plus mr-2"></i> Thêm mới
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className="justify-content-between mb-3"> 
                                <Col xs="auto" sm="auto"></Col>
                                <Col xs="auto" sm="auto">                                             
                                <a href={API_HOST_NAME + "/storage/templates/tpl_import_phieu_xuat_kho.xlsx"}>
                                    Download import template
                                </a>
                                </Col>
                            </Row>
                            {
                                isShowSearch ?
                                    <Row className='align-items-end'>
                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                            Từ ngày
                                            </label>
                                            <Input 
                                                type='date'
                                                className="form-control"
                                                value={from_date}
                                                onChange={(e) => setFrom_date(e.target.value)} />
                                        </Col>
                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                            Đến ngày
                                            </label>
                                            <Input 
                                                type='date'
                                                className="form-control"
                                                value={to_date}
                                                onChange={(e) => setTo_date(e.target.value)} />
                                        </Col>
                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                                Tìm theo số PX
                                            </label>
                                            <Input 
                                                type='text'
                                                className="form-control"
                                                value={export_number}
                                                onChange={(e) => setExport_number(e.target.value)} />
                                        </Col>
                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                                Loại phiếu xuất
                                            </label>
                                            <Input 
                                                type='select'
                                                className="form-control"
                                                value={export_type}
                                                onChange={(e) => setExport_type(e.target.value)}
                                            >
                                                <option value={""}>Chọn loại phiếu xuất</option>
                                                {
                                                    listExportType.map((item, i) => (
                                                        <option value={item.warehouse_export_type_id} key={i}>{item.warehouse_export_type_name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                                Tìm theo NV
                                            </label>
                                            <Input 
                                                type='select'
                                                className="form-control"
                                                value={user}
                                                onChange={(e) => setUser(e.target.value)}
                                            >
                                                <option value={""}>Chọn nhân viên</option>
                                                {
                                                    listUser.map((item, i) => (
                                                        <option value={item.user_id} key={i}>{item.fullname} - {item.username}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>

                                        <Col xs={2}>
                                            <label className='body-2 font-weight-bold'>
                                                Tìm theo trạng thái
                                            </label>
                                            <Input type='select'
                                                className="form-control"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value={""}>Chọn trạng thái</option>
                                                <option value={0}>Đã xóa</option>
                                                <option value={1}>Bình thường</option>
                                            </Input>
                                        </Col>
                                        <Col xs={12} lg="auto">
                                            <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                                                Tìm kiếm
                                            </Button>
                                            <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                                                Reset tìm kiếm
                                            </Button>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <div className='tag color-primary font-weight-bold mt-3' 
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                                onClick={() => setIsShowSearch(!isShowSearch)}>
                                {
                                    isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
                                }
                                {
                                    isShowSearch ?
                                        <i className="bx bx bx-chevron-up ml-2" />
                                        : <i className="bx bx bx-chevron-down ml-2" />
                                }
                            </div>
                        </CardBody>
                    </Card>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>Ngày</th>
                                    <th>Số</th>
                                    <th>Loại PX</th>
                                    <th>NV</th>
                                    <th>Trạng thái</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    showWareHouseExportItem()
                                }
                            </tbody>
                        </Table>
                    </div>
                    <MyPagination
                        startPage={currentPage.current_page ? currentPage.current_page : 1}
                        totalItemsCount={currentPage.total ? currentPage.total : 5}
                        itemsCountPerPage={currentPage.per_page ? currentPage.per_page : 10}
                        callback={onChangePage}
                    />

                </CardBody>
            </Card>
        </div>
    )
}
ListWarehouseExport.propTypes = {
    location: PropTypes.object,
}
export default withRouter(ListWarehouseExport);
