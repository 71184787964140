import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { PropTypes } from 'prop-types';

AlertSimple.propTypes = {
    confirmCallback: PropTypes.func,
    title:PropTypes.string,
    message:PropTypes.string,
    type:PropTypes.string,
}

AlertSimple.propTypes = {
    confirmCallback: null,
    title:"",
    message:"",
    type:""
}

function AlertSimple({confirmCallback,title,message,type}) {
    return (
        <SweetAlert
            title={title}
            type={type}
            confirmButtonText="OK"
            confirmBtnBsStyle="success"
            onConfirm={confirmCallback}
        >
            {message}
        </SweetAlert>
    )
}


export default AlertSimple