
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {
    Row,
    Col, Button,
} from "reactstrap"
import PropTypes from 'prop-types'
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useDispatch} from "react-redux";
import {delWareHouseReceipt, restoreWareHouseReceipt} from "../../store/wareHouseReceipt/actions";
import {showMessage} from "../../Utils/global"; // Import css
function WarehouseReceiptTableItem(props) {
    const { data, count,onReload } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <tr>
            <td>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</td>
            <td>{data.id}</td>
            <td>{data.receipt_type}</td>
            <td>{data.username}</td>
            <td>{data.status_text}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-alert' to={`/warehouse-receipts/${data.id}`} >
                            <i className="fas fa-eye"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <Link className='text-alert' to={`/warehouse-receipts-edit/${data.id}`} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>

                    <Col xs="auto" sm="auto">
                        {data.status == 1 && <a className='text-danger' onClick={()=>{
                            confirmAlert({
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                                keyCodeForClose: [8, 32],
                                title: 'Xoá hoá đơn',
                                message: 'Bạn có chắc muốn xoá?',
                                buttons: [
                                    {
                                        label: 'Xoá',
                                        onClick: () => {
                                            dispatch(delWareHouseReceipt({id:data?.id,cb:({success,error})=>{
                                                showMessage(error)
                                                    if(success){
                                                        onReload()
                                                    }
                                            }}))
                                        }
                                    },
                                    {
                                        label: 'Huỷ',
                                        onClick: () => alert('Click No')
                                    }
                                ]
                            });
                        }} >
                            <i className="fas fa-trash"></i>
                        </a>}
                        {data.status == 0 && <a className='text-warning' onClick={()=>{
                                dispatch(restoreWareHouseReceipt({id:data?.id,cb:({success,error})=>{
                                    showMessage(error)
                                    if(success){
                                        onReload()
                                    }
                                }}))
                        }}><i className="fas fa-trash-restore"></i></a>}
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
WarehouseReceiptTableItem.defaultProps = {
    data: {},
    onReload: ()=>{}
}
WarehouseReceiptTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    onReload:PropTypes.func,
}

export default WarehouseReceiptTableItem;
