import PropTypes from "prop-types";
import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {groupCheckedChange} from "../../store/wareHouseReceipt/actions";
const GroupRow = ({id, value,childs,groupsChecked})=> {
	const dispatch = useDispatch();
	const [show,setShow] = useState(false)
	return (childs && childs?.length > 0) ? (<div className={"collapseRow"}>
		<div className={`collapseRowHeader ${(show) ? "active" :""}`} onClick={()=>{
			setShow(!show)
		}}>
			<div className={"collapseRowHeaderLabel  fontCollapse"} style={{color:(groupsChecked == id) ? "#556EE6" : "#0B1016"}}>{value}</div>
			<span role="img" aria-label="right" className="collapseArrow"><svg
				viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor"
				aria-hidden="true" ><path
				d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
		</div>
		{show && <div className={"collapseRowBody"}>
			{childs?.map(child=>{
				return <GroupRow groupsChecked={groupsChecked} key={child?.id} id={child?.id} value={child?.value} childs={null}/>
			})}
		</div>}
	</div> ) : 
  <div className={"collapseRowRaw fontCollapse"}>
    <label onClick={()=>{dispatch(groupCheckedChange({value: id}))}} style={{cursor:"pointer",color:(groupsChecked == id) ? "#556EE6" : "#0B1016"}}>{value}</label>
		</div>
}
export default GroupRow;

GroupRow.defaultProps = {
	childs: {},
	id: 0,
	value:""
}

GroupRow.propTypes = {
	childs: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	id:PropTypes.number,
	value:PropTypes.string,
	groupsChecked: PropTypes.number,
}
