import {call, put, takeLatest,select} from "redux-saga/effects";
import {
	GET_DETAIL,
	GET_LIST,
	GET_LIST_SUCCESS,
	GET_DETAIL_SUCCESS,
	GET_LIST_WARE_HOUSE,
	GET_LIST_WARE_HOUSE_SUCCESS,
	FILTER_PRODUCTS,
	FILTER_PRODUCTS_SUCCESS,
	SAVE_ITEM,
	saveWareHouseReceipt,
	DELETE_ITEM,
	GET_LIST_WARE_HOUSE_RECEIPT_TYPE,
	GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS,
	RESTORE_ITEM,
	VERIFY_RECEIPT,
	FILTER_CATEGORIES,
	GET_CATEGORIES,
	GET_ATTRIBUTES,
	GET_ATTRIBUTES_SUCCESS,
	SAVE_ADD_NEW_ATTRIBUTE,
	SAVE_UPDATE_NEW_ATTRIBUTE,
	GET_ATTRIBUTE_VALUES,
	SAVE_ADD_NEW_ATTRIBUTE_VAL,
	SAVE_UPDATE_NEW_ATTRIBUTE_VAL,
	GET_ATTRIBUTE_VALUES_SUCCESS, ON_SAVE_CHECKED_ATTRIBUTES, GET_ALL_ATTRIBUTES, GET_ALL_ATTRIBUTES_SUCCESS
} from "./actions";
import {
	confirmWarehouseReceipts,
	delWarehouseReceipts,
	filterProductByCode,
	getDetail,
	getList,
	getListAttributes,
	getListAttributeValues,
	getListCategories,
	getListWareHouse,
	getWareHouseReceiptTypes,
	restoreWarehouseReceipts,
	saveAttribute,
	saveAttributeVal,
	saveCheckedAttributes,
	saveWarehouseReceipts,
	updateAttribute,
	updateAttributeVal
} from "../../apis/warehouseReceipts";
import axios from "axios";
import {API_HOST_NAME} from "../../constants/SystemConstant";
import {GET_GROUPS_SUCCESS} from "../chat/actionTypes";
import {groupsCheckedSelector} from "./selector";
function* fetchWareHouseReceipts({payload}) {
	try {
		const response = yield call(getList,payload)//page, company_id
		yield put({
			type: GET_LIST_SUCCESS,
			payload: response?.data,
		})
	} catch (error) {

	}
}
function* fetchWareHouseReceiptDetail({payload}) {
	try {
		const response = yield call(getDetail,payload)
		yield put({
			type: GET_DETAIL_SUCCESS,
			payload: response?.data,
		})
	} catch (error) {
	}
}
function* fetchWareHouses({payload}){
	try {
		const response = yield call(getListWareHouse,payload?.selectedShop,payload?.page)//{id, callback, callbackErr}
		yield put({
			type: GET_LIST_WARE_HOUSE_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* fetchFilterProducts({payload}){
	try {
		const response = yield call(filterProductByCode,payload?.product_code,payload?.company_id)//{id, callback, callbackErr}
		yield put({
			type: FILTER_PRODUCTS_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* saveWareHouseReceiptSaga({payload}){
	try {
		const response = yield call(saveWarehouseReceipts,{postData: payload?.postData})//{id, callback, callbackErr}

		yield call(payload?.cb, {success: true, error: response?.data});
	} catch (error) {
		//console.log("response   aaa",error?.message)
		yield call(payload?.cb, {success: false, error});
	}
}
function* delWareHouseReceiptSaga({payload}){
	try {
		const response = yield call(delWarehouseReceipts,{id: payload?.id})//{id, callback, callbackErr}
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* fetchWareHouseReceiptTypesSaga({payload}){
	try {
		const response = yield call(getWareHouseReceiptTypes)//{id, callback, callbackErr}
		yield put({
			type: GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* restoreWareHouseReceiptSaga({payload}){
	try {
		const response = yield call(restoreWarehouseReceipts,{id: payload?.id})//{id, callback, callbackErr}
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* confirmHouseReceiptTypeSaga({payload}){
	try {
		const response = yield call(confirmWarehouseReceipts,{id: payload?.id})//{id, callback, callbackErr}
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* getCategoriesSaga({payload}){
	try {
		const response = yield call(getListCategories,payload)//{id, callback, callbackErr}
		yield put({
			type: GET_GROUPS_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* getAttributesSaga({payload}){
	try {
		const response = yield call(getListAttributes,payload)
		yield put({
			type: GET_ATTRIBUTES_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* getAllAttributesSaga({payload}){
	try {
		const response = yield call(getListAttributes,payload)
		yield put({
			type: GET_ALL_ATTRIBUTES_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}

function* saveAttributeSaga({payload}){
	try {
		const response = yield call(saveAttribute,{postData:{attribute_name: payload?.attrName,company_id: payload?.selectedShop}})
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* saveUpdateAttributeSaga({payload}){
	try {
		const response = yield call(updateAttribute,{postData:{attribute_name: payload?.attrName },id:payload?.id})
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}

function* saveAttributeValSaga({payload}){
	try {
		const response = yield call(saveAttributeVal,{attribute_id: payload?.attribute_id,value: payload?.value})
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* saveUpdateAttributeValSaga({payload}){
	try {
		const response = yield call(updateAttributeVal,{value: payload?.value,id:payload?.id})
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}
function* onSaveCheckedAttributesSaga({payload}){
	try {
		const response = yield call(saveCheckedAttributes,{category_id: payload?.category_id,attribute_ids: payload?.attribute_ids})
		yield call(payload?.cb, {success: true, error:response?.data});
	} catch (error) {
		yield call(payload?.cb, {success: false, error});
	}
}

function* getAttributeValuesSaga({payload}){
	try {
		const response = yield call(getListAttributeValues,payload)
		yield put({
			type: GET_ATTRIBUTE_VALUES_SUCCESS,
			payload: response,
		})
	} catch (error) {
	}
}
function* wareHouseReceiptSaga() {
	yield takeLatest(GET_LIST, fetchWareHouseReceipts)
	yield takeLatest(GET_DETAIL, fetchWareHouseReceiptDetail)
	yield takeLatest(GET_LIST_WARE_HOUSE, fetchWareHouses)
	yield takeLatest(FILTER_PRODUCTS, fetchFilterProducts)
	yield takeLatest(SAVE_ITEM, saveWareHouseReceiptSaga)
	yield takeLatest(DELETE_ITEM, delWareHouseReceiptSaga)
	yield takeLatest(GET_LIST_WARE_HOUSE_RECEIPT_TYPE, fetchWareHouseReceiptTypesSaga)
	yield takeLatest(RESTORE_ITEM, restoreWareHouseReceiptSaga)
	yield takeLatest(VERIFY_RECEIPT, confirmHouseReceiptTypeSaga)
	yield takeLatest(GET_CATEGORIES, getCategoriesSaga)
	yield takeLatest(GET_ATTRIBUTES, getAttributesSaga)
	yield takeLatest(GET_ALL_ATTRIBUTES, getAllAttributesSaga)
	yield takeLatest(SAVE_ADD_NEW_ATTRIBUTE, saveAttributeSaga)
	yield takeLatest(SAVE_UPDATE_NEW_ATTRIBUTE, saveUpdateAttributeSaga)

	yield takeLatest(SAVE_ADD_NEW_ATTRIBUTE_VAL, saveAttributeValSaga)
	yield takeLatest(SAVE_UPDATE_NEW_ATTRIBUTE_VAL, saveUpdateAttributeValSaga)

	yield takeLatest(GET_ATTRIBUTE_VALUES, getAttributeValuesSaga)
	yield takeLatest(ON_SAVE_CHECKED_ATTRIBUTES, onSaveCheckedAttributesSaga)
}
export default wareHouseReceiptSaga
