import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as categoryApi from '../../apis/categoryApi';

export const useFetchWebCategories = (selectedShop) => {
  const [webCategoriesTree, setWebCategoriesTree] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      fetchTreeWebCategory();
    }
  }, [selectedShop])

  const fetchTreeWebCategory = () => {
    let params = [
      { name: 'company_id', value: selectedShop },
    ]

    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, showMessage)
  }
  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategoriesTree(webCategories);
  }

  return webCategoriesTree;
};

export const useFetchWebCategoriesAsync = (selectedShop) => {
  const [webCategories, setWebCats] = useState([]);

  useEffect(() => {
    const fetchWebCats = async (selectedShop) => {
      if (selectedShop > 0) {
        let params = [{ name: 'company_id', value: selectedShop }];
        categoryApi
          .getTreeWebCategoryAsync(params)
          .then((res) => {
            let webCategories = res.data.data;
            setWebCats(webCategories);
          });
      }
    }
    fetchWebCats(selectedShop);
  }, [selectedShop]);

  return { webCategories };
};
