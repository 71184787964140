export const isTokenExpired = () => {
    let token = localStorage.getItem("token");
    
    if (!token) {
        return true
    }
    const tokenParse = parseJwt(token);
    const now = new Date().getTime();
    const expTime = tokenParse.exp;
    // exp time is second unit , now is mili-second unit

    if (expTime * 1000 > now)
        return false;
    else {
        removeToken();
        return true;
    }
}

const removeToken = () => {
    localStorage.removeItem("token")
}

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
const getRoleOfUser = () => {
    try {
        let user = JSON.parse(localStorage.getItem('authUser'))
        return user.role
    }
    catch {
        return ""
    }
}
export const isRole = (role) => {
    const userRole = getRoleOfUser()
    const userRoles = userRole.split(',')
    return userRoles.some(e => e == role)
}
export const isRoleIn = (roleArr = []) => {
    return roleArr.some((v,i) => isRole(v))
}
const getUserGroups = () => {
    try {
        let user = JSON.parse(localStorage.getItem('authUser'))
        return user.user_groups
    }
    catch {
        return []
    }
}
export const isUserInGroup = (groupsToCheck) => {
  const userGroups = getUserGroups()
  return userGroups.some(group => groupsToCheck.includes(group))
}
export const isSuperAdmin = () => {
    try {
        let user = JSON.parse(localStorage.getItem('authUser'))
        return user.is_admin == 1
    }
    catch {
        return false
    }
}
export const isWarehouseManager = () => {
    try {
        let user = JSON.parse(localStorage.getItem('authUser'))
        return user.is_warehouse_manager == 1
    }
    catch {
        return false
    }
}
