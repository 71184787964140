import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getListUser = (callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/users/groups/sale';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);
}

export const getList = (selectedShop, page, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/roles';
    if (!selectedShop) return;
    let params = [{
        name: 'company_id',
        value: selectedShop
    }]

    if (page) {
        params.push({
            name: 'page',
            value: page
        })
    }
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}
export const deleteRole = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/roles/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr);
}

export const get = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/roles/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}

export const getModule = (callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/modules';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}

export const getAction = (id,callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/modules/'+id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr);

}

export const create = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/roles';
    let token = localStorage.getItem("token")

    commonApis.POST(url, data, token, '', callback, callbackErr)

}

export const update = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/roles/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}