import React, { useEffect, useReducer, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom';
import * as brandApi from 'apis/brandApi';
import { PropTypes } from 'prop-types';
import PageContent from 'components/Common/PageContent';
import { Alert, Button, Form, notification, Space } from 'antd';
import { layout, validateMessages } from 'components/Common/Form/CommonProps';
import FormItemInput from 'components/Common/Form/FormItemInput';
import { formStateAction, formStateReducer, initFormState } from 'components/Common/Form/FormStateReducer';
import { useSelector } from 'react-redux';

const initialBrandState = {
  id: 0,
  brand_name: '',
}

function BrandFormPage(props) {

  const id = props.match.params?.id;
  console.log('id', id);
  const isNew = props.match.url.includes("create");
  console.log('isnew', isNew);
  const { state } = props.location;
  const [formState, dispatchFormState] = useReducer(formStateReducer, initFormState);
  const [form] = Form.useForm();
  const history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);

  useEffect(() => {    
    if (isNew) { return; }
    if (state) {
      const brandFields = {
        brand_name: state.brand_name,
      };
      form.setFieldsValue(brandFields);
    }
  }, [])

  const handleSubmit = (e) => {
    dispatchFormState({ type: formStateAction.SAVING });
    const data = {
      ...
      form.getFieldsValue(['brand_name']),
      company_id: selectedShop,
    };
    console.log('isnew', isNew);
    if (isNew) {
      brandApi.create(data, onCreateSuccess, onError)
    }
    else {
      brandApi.update(id, data, onUpdateSuccess, onError)
    }
  }

  const onCreateSuccess = (res) => {
    dispatchFormState({ type: formStateAction.LOAD_SUCCESS, payload: '' });
    notification['success']({ message: res.data.message });
    history.push('/brands');
  }

  const onUpdateSuccess = (res) => {
    dispatchFormState({ type: formStateAction.LOAD_SUCCESS, payload: '' });
    notification['success']({ message: res.data.message });
    history.push('/brands');
  }

  const onError = (err) => {
    dispatchFormState({ type: formStateAction.LOAD_FAILED, payload: '' });
    notification['error']({ message: err.message });
  }

  return (
    <PageContent title='Chi tiết thương hiệu'>
      <Form
        {...layout}
        layout="horizontal"
        form={form}
        name="brand-form"
        onFinish={handleSubmit}
        initialValues={initialBrandState}
        validateMessages={validateMessages}
        colon={false}
        className='ant-form-mb3'
        autoComplete="off">
        <FormItemInput label='Thương hiệu' name='brand_name' rules={[{ required: true }]} />
        <Form.Item {...layout}>
          <Space direction='vertical'>
            <Space>
              <Button type="primary" htmlType="submit" loading={formState.saving}>Lưu</Button>
              <Button htmlType="button" onClick={() => history.goBack()}>Quay lại</Button>
            </Space>
          </Space>
        </Form.Item>
      </Form>
    </PageContent>
  )
}
BrandFormPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(BrandFormPage);
