import React, { useEffect } from "react"
import './popup.css'
import PropTypes from 'prop-types'


const Popup = ({node, visible, x, y, triggerEdit, triggerDelete}) => {

  const handleDelete = () => {
    triggerDelete(node.value);
  }
  
  const handleEdit = () => {
    triggerEdit(node.value);
  }

  return (
    visible &&
    <ul className="popup" style={{ position:"fixed", left: `${x}px`, top: `${y}px`}}>
      <li className="popup-edit" onClick={handleEdit}>Sửa</li>
      <li className="popup-delete" onClick={handleDelete}>Xóa</li>
    </ul>
  );
}

Popup.propTypes = {
  node: PropTypes.object,
  visible: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  triggerEdit: PropTypes.func,
  triggerDelete: PropTypes.func,
}

export default Popup