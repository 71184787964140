import React from 'react'
import { Label } from 'reactstrap'
import { PropTypes } from 'prop-types'

WebOrderLabel.propTypes = {
  children: PropTypes.any,
}

function WebOrderLabel(props) {
  return (
    <Label md={12} className="col-form-label" style={{ maxWidth: '120px', minWidth: '120px' }}>
      {props.children}
    </Label>
  )
}

export default WebOrderLabel