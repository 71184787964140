import { selectedShopRoot } from "../../store/selectedShop/selector";
import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AttributeValues from "./Values";
import paginationFactory, {
  PaginationListStandalone, PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import TextFieldEditorUpdate from "./TextFieldEditorUpdate";
import {
  getAllAttributes,
  getAttributes, getAttributeValues,
  getGCategories, onCheckedAttribute, onResetSavedCheckedAttributes, onSaveCheckedAttributes,
  saveAddNewAttribute,
  saveUpdateAttribute
} from "../../store/wareHouseReceipt/actions";
import PropTypes from "prop-types";
import {
  allAttributesSelector,
  attributesSelector,
  checkedAttributesSelector,
  groupsCheckedSelector, selectedAttributeSelector
} from "../../store/wareHouseReceipt/selector";
import { Scrollbar } from "smooth-scrollbar-react";
import { showMessage } from "../../Utils/global";

const AttributeList = ({ attributes, selectedShop, catId, checkedAttributes, allAttributes, selectedAttribute }) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const [newAllAttributes, setNewAllAttributes] = useState([])
  useEffect(async () => {
    if (allAttributes?.length > 0) {
      let tmpAttrs = [];
      await allAttributes?.map((attr, index) => {
        let newAttr = { ...attr };
        newAttr.checked = (attributes?.length > 0 && attributes?.some(sattr => sattr?.attribute_id == attr?.attribute_id))
        newAttr.clicked = (selectedAttribute && selectedAttribute == attr?.attribute_id)
        tmpAttrs.push(newAttr)
      })
      setNewAllAttributes(tmpAttrs)
      setShowSaveBtn(false)
    }
  }, [attributes, allAttributes, selectedAttribute]);
  const [showSaveBtn, setShowSaveBtn] = useState(false)
  const columns = [{
    dataField: 'attribute_id',
    text: '',
    editable: false,
    sort: false,

    formatter: (cell) => {
      return <><a><i className="fas fa-angle-double-right"></i></a></>
    }, headerStyle: (colum, colIndex) => {
      return { width: '30px', textAlign: 'center' };
    }, events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        dispatch(getAttributeValues({ attribute_id: newAllAttributes[rowIndex]?.attribute_id }))
      }
    }
  }, {
    dataField: 'attribute_id',
    text: 'Hành động',
    editable: false,
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '60px', textAlign: 'center' };
    },
    formatter: (cell) => {
      return <>
        <input type={"checkbox"} value={cell} checked={newAllAttributes.find(item => item.attribute_id == cell)?.checked} onChange={e => {
          setNewAllAttributes(oldAttrs => {
            let newAttrs = [];
            oldAttrs.map(item => {
              if (item?.attribute_id !== cell) {
                newAttrs.push(item)
              } else {
                newAttrs.push({ ...item, checked: e.target.checked })
              }
            });
            return newAttrs;
          })
          setShowSaveBtn(true)

        }} />
      </>
    }
  }, {
    dataField: 'attribute_name',
    text: 'Tên thuộc tính',
    sort: false,
    editable: true,
    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      <TextFieldEditorUpdate {...editorProps} value={value} rowIndex={rowIndex} onUpdateServer={onUpdateRowVal} />
    ),
    formatter: (cell, row, rowIndex) => {
      return <><label style={{ cursor: "pointer", color: (newAllAttributes[rowIndex].clicked) ? "#556EE6" : "#0B1016" }}>{cell}</label></>
    }
  }];
  const defaultSorted = [{
    dataField: 'attribute_id',
    order: 'asc'
  }];
  const onUpdateRowVal = ({ value, rowIndex, cb }) => {
    dispatch(saveUpdateAttribute(
      {
        attrName: value,
        id: newAllAttributes[rowIndex].attribute_id,
        cb: ({ success, error }) => {
          showMessage({ message: (success) ? "Cập nhật thuộc tính thành công" : error?.message });
          cb();
          dispatch(getAllAttributes({ company_id: selectedShop, category_id: 0 }))
          dispatch(getAttributes({ company_id: selectedShop, category_id: catId }))
        }
      }))
  }
  const pageOptions = {
    sizePerPage: newAllAttributes.length,
    totalSize: newAllAttributes.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: allAttributes.length }
  ];
  const selectRow = {
    mode: 'checkbox'
  }
  const [showEdit, setShowEdit] = useState(false);
  const [attrName, setAttrName] = useState("")
  const toggleEdit = () => {
    setShowEdit(!showEdit)
    setAttrName("")
  }
  return (<PaginationProvider
    pagination={paginationFactory(pageOptions)}
    keyField='attribute_id'
    columns={columns}
    data={newAllAttributes}
  >
    {({ paginationProps, paginationTableProps }) => (
      <ToolkitProvider
        keyField='attribute_id'
        columns={columns}
        data={newAllAttributes}
        search
      >
        {toolkitProps => (
          <React.Fragment>
            {/*<Row className="mb-2">
							<Col xl="12">
								<div className="search-box me-2 mb-2 d-inline-block">
									<div className="position-relative">
										<SearchBar
											{...toolkitProps.searchProps}
										/>
										<i className="bx bx-search-alt search-icon" />
									</div>
								</div>
							</Col>
						</Row>*/}
            <Scrollbar
              className="custom-class"
              onScroll={() => {

              }}
              alwaysShowTracks={false}
              renderByPixels={false}
              damping={0.1}
              plugins={{
                overscroll: {
                  effect: "glow"
                }
              }}
            >
              <div className={" limitheight"} style={{ overflowX: "hidden" }}>
                <Row>
                  <Col xl="10" sm={9}>
                    <div className="table-responsive ">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={
                          "table align-middle table-nowrap table-rtl"
                        }
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        cellEdit={cellEditFactory({ mode: "dbclick" })}

                      />
                    </div>
                  </Col>
                  <Col xl="2" sm={3}>
                    <a onClick={toggleEdit}><img src={"img.png"} /></a>
                  </Col>
                </Row>
                {showSaveBtn && <Row>
                  <Col>
                    <Button color="primary" onClick={() => { //category_id: payload?.category_id,attribute_ids: payload?.attribute_ids
                      let newCheckedAttributes = [];
                      newAllAttributes?.map(nItem => {
                        if (nItem.checked) {
                          newCheckedAttributes.push(nItem.attribute_id)
                        }
                      })
                      dispatch(onSaveCheckedAttributes({
                        category_id: catId, attribute_ids: newCheckedAttributes, cb: ({ success, error }) => {
                          showMessage({ message: (success) ? "Lưu thuộc tính cho danh mục thành công" : error?.message })
                          if (success) {
                            dispatch(onResetSavedCheckedAttributes());
                            dispatch(getAllAttributes({ company_id: selectedShop, category_id: 0 }))
                            dispatch(getAttributes({ company_id: selectedShop, category_id: catId }))
                            setShowEdit(false)
                          }
                        }
                      }))
                    }}>Lưu</Button>{' '}
                  </Col>
                </Row>}
              </div>
            </Scrollbar>


            {/*<Row className="align-items-md-center mt-30">
							<Col className="inner-custom-pagination d-flex">
								<div className="d-inline">
									<SizePerPageDropdownStandalone
										{...paginationProps}
									/>
								</div>
								<div className="text-md-right ms-auto">
									<PaginationListStandalone
										{...paginationProps}
									/>
								</div>
							</Col>
						</Row>*/}
            <Modal isOpen={showEdit} toggle={toggleEdit} >
              <ModalBody>
                <input type={"text"} className={"form-control"} placeholder={"Nhập tên thuộc tính"} value={attrName} onChange={e => { setAttrName(e.target.value) }} />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => {
                  dispatch(saveAddNewAttribute({
                    attrName, selectedShop, cb: ({ success, error }) => {
                      showMessage({ message: (success) ? "Thêm thuộc tính thành công" : error?.message })
                      dispatch(getAllAttributes({ company_id: selectedShop, category_id: 0 }))
                      dispatch(getAttributes({ company_id: selectedShop, category_id: catId }))
                      if (success) setShowEdit(false)
                    }
                  }))
                }}>Lưu</Button>{' '}
                <Button color="secondary" onClick={toggleEdit}>Đóng</Button>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        )}
      </ToolkitProvider>
    )
    }</PaginationProvider>);
}

AttributeList.defaultProps = {
  attributes: [],
  allAttributes: [],
  selectedShop: 0,
  catId: 0,
  attributesChecked: [],
  checkedAttributes: [],

}

AttributeList.propTypes = {
  attributes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  allAttributes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedShop: PropTypes.number,
  catId: PropTypes.number,
  attributesChecked: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  checkedAttributes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedAttribute: PropTypes.number,
}
function mapStateToProps(state) {
  return {
    selectedShop: selectedShopRoot(state),
    attributes: attributesSelector(state),
    catId: groupsCheckedSelector(state),
    checkedAttributes: checkedAttributesSelector(state),
    allAttributes: allAttributesSelector(state),
    selectedAttribute: selectedAttributeSelector(state),
  };
}
const withReduce = connect(mapStateToProps);

export default compose(
  withReduce,
)(AttributeList);
