import ProductCategoryTableItem from 'components/TableItem/ProductCategoryTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import PropTypes from 'prop-types'
import { useQuery } from '../../Utils/UseQuery'

import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap"
import { Link } from 'react-router-dom';
import { GET, DELETE } from './../../apis/commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as categoryApi from './../../apis/categoryApi'
import { useSelector } from 'react-redux';
import ConfirmDelete from 'components/Common/ConfirmDelete';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function ListProductCategory(props) {

    let history = useHistory();
    const selectedShop = useSelector(state => state.SelectedShop);
    useEffect(() => {
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }, [props.location.search, selectedShop])
    const [data, setData] = useState([])
    const [moreData, setMoreData] = useState({})
    const [deleteId, setDeleteId] = useState(null);
    const fetchData = (page) => {
        categoryApi.getListCategory(selectedShop, page, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data.data)
        setMoreData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
        setMoreData({})
        setData([])
    }
    const onDeleteCat = (id) => {
        categoryApi.deleteCategory(id, deleteCatSuccess, deleteCatFail)
    }
    const deleteCatSuccess = (res) => {
        alert("Xóa thành công")
        setDeleteId(null)
        let page = useQuery(props.location.search).get('page');
        fetchData(page);
    }
    const deleteCatFail = (res) => {

        alert(getMessage(res.message))
        setDeleteId(null)
    }

    const showProductCatItem = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<ProductCategoryTableItem key={i} data={v} count={i + 1} deleteCallback={setDeleteId} />)
        })
    }
    const onChangePage = (page) => {
        let url = props.location.pathname + '?page=' + page;
        history.push(url);
    }
    return (
        <div className="page-content">
            <MetaTags>
            <title>Danh sách nhóm sản phẩm kho</title>
            </MetaTags>
            {deleteId ?
                <ConfirmDelete
                    confirmCallback={() => {
                        onDeleteCat(deleteId)
                    }}
                    cancelCallback={() => {
                        setDeleteId(null)
                    }}
                />
                : ''}

            <Card>
                <CardBody>
                    <CardTitle className="h4">Danh mục Nhóm sản phẩm </CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">

                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/product-categories/create"> <Button color='primary' className='px-3 rounded-pill'><i className="fas fa-plus mr-2"></i> Thêm mới</Button></Link>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Tên danh mục</th>
                                    <th>Danh mục cha</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showProductCatItem()}
                            </tbody>
                        </Table>
                    </div>

                    <MyPagination 
                        startPage={moreData.current_page ? moreData.current_page : 1} 
                        totalItemsCount={moreData.total ? moreData.total : 5} 
                        itemsCountPerPage={moreData.per_page ? moreData.per_page : 10}
                        callback={onChangePage} 
                    />
                </CardBody>
            </Card>
        </div>
    )
}
ListProductCategory.propTypes = {
    location: PropTypes.object,
}

export default withRouter(ListProductCategory);
