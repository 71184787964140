
import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getList = (page, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-export-types';
    let params = []
    if (page) {
        params.push({
            name: 'page',
            value: page
        })
    }
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}
export const deleteWarehouseExportType = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-export-types/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr);

}
export const get = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-export-types/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr); 

}
export const create = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-export-types';

    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)

}
export const update = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/warehouse-export-types/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}
export const getAllListExportType = (callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-warehouse-export-types/';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback, callbackErr); 

}