
import {storage_key} from '../constants/StorageKeyConstant';

const getStorageCache = (key) => {
  if (localStorage.getItem(key)) {
    const obj = JSON.parse(localStorage.getItem(key))
    return obj;
  }
  return false;
}

const setStorageCache = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
}

const getSessionStorageCache = (key) => {
  if (sessionStorage.getItem(key)) {
    const obj = JSON.parse(sessionStorage.getItem(key))
    return obj;
  }
  return false;
}

const setSessionStorageCache = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
}

const _cache_utils = {

  setCachedPaymentMethods: (data) => {
    setStorageCache(storage_key.PAYMENT_METHODS, data);
  },

  getCachedPaymentMethods: () => {
    return getStorageCache(storage_key.PAYMENT_METHODS)
  },

  setCachedSalemans: (data) => {
    setSessionStorageCache(storage_key.SALE_MANS, data);
  },

  getCachedSalemans: () => {
    return getSessionStorageCache(storage_key.SALE_MANS)
  },

  setCachedRoles: (selectedShop, data) => {
    const key = storage_key.ROLES + selectedShop.toString()
    setStorageCache(key, data);
  },

  getCachedRoles: (selectedShop) => {
    const key = storage_key.ROLES + selectedShop.toString()
    return getStorageCache(key)
  },

  setCachedUser: (data) => {
    setStorageCache(storage_key.USER, data);
  },

  getCachedUser: () => {
    return getStorageCache(storage_key.USER)
  },

  setCachedOrdermans: (data) => {
    setStorageCache(storage_key.ORDER_MANS, data);
  },

  getCachedOrdermans: () => {
    return getStorageCache(storage_key.ORDER_MANS)
  },

  setCachedCart: (data) => {
    setStorageCache(storage_key.CART, data);
  },

  getCachedCart: () => {
    return getStorageCache(storage_key.CART)
  },

  setCachedCartCustomer: (data) => {
    setStorageCache(storage_key.CART_CUSTOMER, data);
  },

  getCachedCartCustomer: () => {
    return getStorageCache(storage_key.CART_CUSTOMER)
  },
}

export { _cache_utils as CacheUtils }