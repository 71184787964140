// this is hidden config of reactjs
// - npm start => process.env.NODE_ENV === 'development'
// - npm run build => process.env.NODE_ENV === 'production'

export const API_HOST_NAME = (process.env.NODE_ENV === 'production') 
                                    ? 'https://api.24cara.net' // live 
                                    // : 'http://127.0.0.1:8000' // debug
                                    // : 'https://api24.ntfsoft.com' // dev remote
                                    : 'http://api24.local:801' // dev docker
                                    // : 'http://api24cara.test' // dev local
                                    // : 'https://api.24cara.net' // live test