import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import authStore from '../../../store/authStore';
import { useStore } from '../../../store/hooks';
//i18n
import { withTranslation } from "react-i18next";
const NotificationDropdown = observer((props) => { 
  const AuthStore = useStore(authStore);
  const selectedShop = useSelector(state => state.SelectedShop);
  const [menu, setMenu] = useState(false);
  const [listDataNoti, setListDataNoti] = useState([]);
  useEffect(() => {
    let data = localStorage.getItem('cart')
    if(data) {
      data = JSON.parse(data)
      setListDataNoti((data))
    }else {
      localStorage.setItem('cart', JSON.stringify([]));
    }
  }, [AuthStore.data])

  const toggle = () => {
    setMenu(!menu);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{listDataNoti.length > 0 ? listDataNoti.length : ""}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Thông báo")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  Xem tất cả
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {
              listDataNoti.map((item, i) => (
                <Link to="" className="text-reset notification-item" key={i}>
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        Sản phẩm vừa thêm vào hóa đơn : {item.product_code}
                      </h6>
                    </div>
                  </div>
                </Link>
              ))
            }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/orders/create">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("Tới trang tạo hóa đơn..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
})

NotificationDropdown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(NotificationDropdown)
