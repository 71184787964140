import React, { useEffect, useState, useCallback } from "react";
import * as baseProductApi from "../../apis/baseProductApi";
import * as brandApi from "../../apis/brandApi";
import { PropTypes } from 'prop-types'
import { MetaTags } from "react-meta-tags";
import { useFetchBrands } from "components/Hook/useFetchBrands";
import { useSelector } from "react-redux";
import useFetchCategories from "components/Hook/useFetchCategories";
import { useFetchWebCategories } from "components/Hook/useFetchWebCategories";
import { CacheUtils } from "Utils/CacheUtils";
import { Button, Card, Checkbox, Form, Input, Row, Select, TreeSelect, message } from "antd";
import { EyeOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import { showMessage } from "Utils/global";
import useFetchUnits from "components/Hook/useFetchUnits";
import AddBrandModal from "./AddBrandModal";
import ListVariant from "./ListVariant";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MyEditor extends ClassicEditor {
  static defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: String
  };
}

BaseProduct.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 3 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 21 } }
}

function BaseProduct(props) {
  let isView = props.match.url.includes("view");
  const baseProductId = props.match.params?.id;

  const selectedShop = useSelector(state => state.SelectedShop);
  const { brands, setBrands } = useFetchBrands(selectedShop);
  const categories = useFetchCategories(selectedShop);
  const webCategoriesTree = useFetchWebCategories(selectedShop);
  const units = useFetchUnits();
  const [baseProductForm] = Form.useForm();
  const [variantModalVisible, setVariantModalVisible] = useState(false);
  const [brandModalVisible, setBrandModalVisible] = useState(false);
  const [content, setContent] = useState("")
  const [rawContent, setRawContent] = useState("")

  const [webCatIds, setWebCatIds] = useState([]);
  const [formData, setFormData] = useState({
    product_id: "",
    company_id: "",
    product_code: "",
    product_web_name: "",
    unit_code: "",
    category_id: '',
    web_category_id: '',
    web_category_ids: [],
    brand_id: '',
    product_content: "",
  })

  // const initUser = () => {
  //   const userInfo = CacheUtils.getCachedUser();
  //   if (userInfo) {
  //     setUser(userInfo)
  //   }
  // }

  useEffect(() => {
    // initUser();// chưa dùng

    if (baseProductId > 0) {
      baseProductApi.get(
        baseProductId,
        fetchBaseProductSuccess,
        showMessage
      );
    }
  }, []);

  const fetchBaseProductSuccess = res => {
    const { data } = res.data;
    setFormData(data);
    if (data.web_category_ids?.length > 0) {
      let tmp = data.web_category_ids.split(',');
      let tmp2 = tmp.map(item => (parseInt(item)));
      console.log('tmp2 = ', tmp2)
      setWebCatIds(tmp2);
    }
  };

  const onFieldChanged = (fieldName, fieldValue) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: fieldValue,
    }))
  }

  const onCreateBrand = (values) => {
    const data = {
      'brand_name': values.brand_name,
      'company_id': selectedShop
    }
    brandApi.create(
      data,
      onCreateBrandSuccess,
      showMessage);
  }

  const onCreateBrandSuccess = (res) => {
    setBrandModalVisible(false);
    const newBrand = { value: res.data.data.id, label: res.data.data.brand_name }
    const newBrands = [newBrand].concat(brands);
    setBrands(newBrands);
    message.success('Đã thêm mới thương hiệu');
  }

  const onCloseBrandModal = () => {
    setBrandModalVisible(false);
  }

  const submitForm = () => {
    baseProductApi.update(
      baseProductId,
      formData,
      onUpdateSuccess,
      showMessage,
    );
  }

  const onUpdateSuccess = (res) => {
    message.info(res.data.message);
  }

  const handleCKEditorChange = (event, editor) => {
    const editorData = editor.getData();
    setTimeout(() => {
      console.log('text = ', editor.editing.view.document.getRoot());
      setRawContent(viewToPlainText(editor.editing.view.document.getRoot()));
    }, 200)
    setContent(editorData);
    onFieldChanged('product_content', editorData);
  }

  const copyContent = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(rawContent);
    message.info('Đã copy content sản phẩm');
  }

  const onChangeWebCategory = (newValue) => {
    console.log('newValue = ', newValue)
    if (newValue.length > 0) {
      onFieldChanged('web_category_id', newValue[0]);
    } else {
      onFieldChanged('web_category_id', '');
    }
    onFieldChanged('web_category_ids', newValue.join());
    setWebCatIds(newValue)
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Sản phẩm cơ sở</title>
      </MetaTags>
      <Form
        {...formItemLayout}
        labelAlign="left"
        layout="horizontal"
        form={baseProductForm}
        onFinish={(e) => submitForm(e)}>
        <Card title='SẢN PHẨM CƠ SỞ'>

          <Form.Item label='Code ảnh' className="mb-1">
            <Input required value={formData.product_code} disabled={isView}
              onChange={(e) => onFieldChanged('product_code', e.target.value)} />
          </Form.Item>

          <Form.Item label='Tên sản phẩm' className="mb-1">
            <Input required value={formData.product_web_name} disabled={isView}
              onChange={(e) => onFieldChanged('product_web_name', e.target.value)} />
          </Form.Item>

          <Form.Item label='Đơn vị tính' className="mb-1">
            <Select
              value={formData.unit_code}
              onChange={(value) => onFieldChanged('unit_code', value)}
              filterOption={true}
              disabled={isView}
              options={units} />
          </Form.Item>

          <Form.Item label='Nhóm sản phẩm kho' className="mb-1">
            <Select
              value={formData.category_id}
              onChange={(value) => onFieldChanged('category_id', value)}
              filterOption={true}
              disabled={true}
              options={categories} />
          </Form.Item>

          <Form.Item label='Nhóm sản phẩm web' className="mb-1">
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              value={webCatIds}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={webCategoriesTree}
              allowClear
              multiple
              treeDefaultExpandAll
              disabled={isView}
              onChange={onChangeWebCategory}
            />
          </Form.Item>

          <Form.Item label='Thương hiệu' className="mb-1">
            <TreeSelect
              showSearch
              treeNodeFilterProp='label'
              value={formData.brand_id}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={brands}
              allowClear
              treeDefaultExpandAll
              disabled={isView}
              style={{ width: 'calc(100% - 150px' }}
              onChange={(value) => onFieldChanged('brand_id', value)}
            />
            <Button type="primary" hidden={isView}
              icon={<PlusOutlined className="ant-fix-button-icon" />}
              onClick={() => { console.log('ok'); setBrandModalVisible(true) }}
              style={{ width: '140px', marginLeft: '10px' }}
            >
              Thương hiệu
            </Button>
          </Form.Item>

          <Form.Item label='Bán online' className="mb-1">
            <Checkbox checked={formData.active == 1} disabled={isView}
              onChange={(e) => onFieldChanged('active', e.target.checked ? 1 : 0)} />
          </Form.Item>

          <Form.Item label={<span>Content <a className='btn btn-sm btn-primary' onClick={copyContent}>copy</a></span>} className="mb-1">
            {/* <Input.TextArea required value={formData.product_content} disabled={isView} autoSize={true}
              onChange={(e) => onFieldChanged('product_content', e.target.value)} /> */}
              <CKEditor
                  editor={MyEditor}
                  data={formData.product_content}
                  onChange={handleCKEditorChange}
                />
          </Form.Item>

          <Row justify="end">
            <Button type="primary" htmlType="submit" hidden={isView}
              icon={<SaveOutlined className="ant-fix-button-icon" />}
              style={{ width: "120px", marginTop: 10, marginRight: 15 }}
            >
              Lưu lại
            </Button>
            <Button type="default"
              icon={<EyeOutlined className="ant-fix-button-icon" />}
              style={{ width: "120px", marginTop: 10, marginRight: 15 }}
              onClick={() => setVariantModalVisible(true)}
            >
              Biến thể
            </Button>
            <Button type="ghost" href="/base-products"
              icon={<RollbackOutlined className="ant-fix-button-icon" />}
              style={{ width: "120px", marginTop: 10 }}
            >
              Quay lại
            </Button>
          </Row>
        </Card>
      </Form>
      <AddBrandModal
        open={brandModalVisible}
        onCreate={onCreateBrand}
        onCancel={onCloseBrandModal} />
      <ListVariant
        visible={variantModalVisible}
        baseProduct={formData}
        onClose={() => setVariantModalVisible(false)} />
    </div>
  );
}
export default BaseProduct;