import { API_HOST_NAME } from "constants/SystemConstant"


export function debounce(func, wait, immediate) {
	var timeout
	return function () {
		var context = this,
			args = arguments
		var later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		if (immediate && !timeout) func.apply(context, args)
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

export function showMessage(e) {
	if(e?.message){
		alert(e.message);
	}
}

export function removeAccents(str) {
	return str.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/đ/g, 'd').replace(/Đ/g, 'D');
}


export function extractDatePart(dateTimeStr) {
  return dateTimeStr.includes(' ') ? dateTimeStr.substring(0, dateTimeStr.indexOf(' ')) : dateTimeStr
}
export function extractTimePart(dateTimeStr) {
  return dateTimeStr.includes(' ') ? dateTimeStr.substring(dateTimeStr.indexOf(' ') + 1) : '00:00:00'
}

export function generateCampaign(campaign) {
  return {
    ...campaign,
    from_date_part: extractDatePart(campaign.from_date),
    from_time_part: extractTimePart(campaign.from_date),
    to_date_part: extractDatePart(campaign.to_date),
    to_time_part: extractTimePart(campaign.to_date),
  }
}

export const fixImagePath = (imagePath) => {
  let image = imagePath ? imagePath : '/storage/no_image_100.png';
  image = image.startsWith('http') ? image : API_HOST_NAME + image;
  return image;
}

export const convertNumberToCurrency = (number) => {
  if ((number === null) || isNaN(number)) number = 0;
  let currency = number.toLocaleString(
      undefined, // leave undefined to use the browser's locale,
      // or use a string like 'en-US' to override it.
      {minimumFractionDigits: 0}
  );
  return currency;
}

export const formatMoney = number => {
  if (isNaN(number)) return 0;
  return new Intl.NumberFormat('vi-VN').format(number);
};
