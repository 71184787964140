import { CHANGE_FIRST_LOAD } from './actionTypes';
const INIT_STATE = false

const firstLoad = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_FIRST_LOAD:
            return action.payload;
        default:
            return state
    }
}
export default firstLoad;