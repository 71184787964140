import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle } from "reactstrap"
import * as systemConfig from '../../apis/systemConfig';
import MetaTags from 'react-meta-tags';


const SystemConfiguration = () => {
    
    const selectedShop = useSelector(state => state.SelectedShop);
    const [listForm, setListForm] = useState([])
    const fetchData = (selectedShop) => {
        if (selectedShop == 0) return;
        systemConfig.getList(selectedShop, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setListForm(res?.data?.data)
    }
    const fetchDataFail = (res) => {
        alert(res.message);
    }

    useEffect(() => {
        fetchData(selectedShop);
    }, [selectedShop])

    const updateDataForm = (data) => {
        const param_value = document.getElementById(data.param_code).value
        const params = {
            "param_code": data.param_code,
            "param_value": param_value,
            "company_id": selectedShop
        }
        systemConfig.updateForm(params, updateSuccess, fetchDataFail)
    }
    const updateSuccess = (res) => {
        alert(res.data.message);
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Cấu hình hệ thống</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="h4">Quản lý tham số hệ thống</CardTitle>

                    {
                        listForm ? listForm.map((v, i) => (
                            <div className="mt-3 row" key={i}>
                                <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                >
                                    {v.param_name}
                                </label>
                                <div className="col-md-12" style={{display: 'flex'}}>
                                    <input
                                        className="form-control"
                                        id={v.param_code}
                                        type={v.param_type}
                                        defaultValue={v.param_value}
                                        style={{ width: '400px', marginRight: '10px', height: '38px' }}
                                    />
                                    <Popconfirm
                                        placement="top"
                                        title={"Bạn có muốn cập nhật"}
                                        onConfirm={() => updateDataForm(v)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <button type="button" style={{height: '38px'}} className="btn btn-primary w-md">
                                            Lưu lại
                                        </button>
                                    </Popconfirm>
                                </div>
                            </div>
                        ))
                        : null
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default SystemConfiguration