
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, } from "reactstrap"
import PropTypes from 'prop-types'

function WarehouseReceiptTypeTableItem(props) {
    const { data, count,deleteCallback } = props;
    const onDelete = () => {
        deleteCallback(data.warehouse_receipt_type_id)
    }
    return (
        <tr>
            <td>{data.warehouse_receipt_type_id}</td>
            <td>{data.warehouse_receipt_type_name}</td>
            {/* <td>{data.description}</td> */}
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/warehouse-receipt-types/"+data.warehouse_receipt_type_id} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <a className='text-danger' onClick={onDelete} >
                            <i className="fas fa-trash"></i>
                        </a>
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
WarehouseReceiptTypeTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    deleteCallback: PropTypes.func
}

export default WarehouseReceiptTypeTableItem;
