import React from "react";
import PropTypes from "prop-types";

ShowHideComponent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
}

function ShowHideComponent(props) {


  return (
    <>
      {
        (props.show) ?
          <>
            {props.children}
          </>
          : null
      }
    </>
  );
}

export default ShowHideComponent
