import { UPDATE_ALL_SHOP } from './actionTypes';
const INIT_STATE = []

const allShops = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_ALL_SHOP:
            return action.payload;
        default:
            return state
    }
}
export default allShops;