import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { PropTypes } from 'prop-types';

Confirm.propTypes = {
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
    title:PropTypes.string,
    message:PropTypes.string,
}

Confirm.propTypes = {
    confirmCallback: null,
    cancelCallback: null,
    title:"",
    message:""
}

function Confirm({confirmCallback,cancelCallback,title,message}) {
    return (
        <SweetAlert
            title={title}
            warning
            showCancel
            confirmButtonText="Đồng ý"
            confirmBtnBsStyle="success"

            cancelBtnBsStyle="danger"
            cancelBtnText="Hủy"
            
            onConfirm={confirmCallback}
            onCancel={cancelCallback}
        >
            {message}
        </SweetAlert>
    )
}


export default Confirm