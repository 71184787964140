import React, { useEffect, useState } from "react";
import './print.css';
import './paper.css';
import * as billApi from "apis/billApi";
import bgimg1 from "assets/images/logo-season-print.png";
import { formatMoney, convertNumberToCurrency, showMessage } from 'Utils/global';
import { CacheUtils } from 'Utils/CacheUtils';
import { PropTypes } from 'prop-types'
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { PrinterOutlined, RollbackOutlined } from "@ant-design/icons";
// convertNumberToCurrency(parseInt(record.regular_price))

OrderSeasonPrintWithPrice.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

function OrderSeasonPrintWithPrice(props) {
  let history = useHistory();
  const orderId = props.match.params?.id;

  const [dataSaleMans, setDataSaleMans] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [form, setForm] = useState({
    customer_phone: "",
    customer_name: "",
    customer_address: "",
    bill_date: "",
    bill_date_vnd: "",
    is_ship: false,
    payment_code: '',
    bill_note: "",
    row_count: 0,
    total_amount: 0,
    total_discount: 0,
    total_final_amount_after_pre_paid: 0,
    total_final_amount: 0,
    pre_paid: 0,
    company_id: 0,
    saleman_id: 0,
    userinfo: {}
  });

  useEffect(() => {
    if (orderId > 0) {
      fetchDetailBill(orderId);
    }
  }, []);

  const fetchDetailBill = id => {
    billApi.getBillDetail(id, fetchDetailBillSuccess, fetchDetailBillFailure);
  };

  const fetchDetailBillSuccess = res => {
    const { data } = res.data;
    setSelectedProducts(data[0].details.map(item => { return { ...item, key: item.id } }));
    setForm({
      ...data[0],
      total_final_amount_after_pre_paid: formatMoney(parseInt(data[0].total_final_amount) - parseInt(data[0].pre_paid)),

    });    
  };

  const fetchDetailBillFailure = err => {
    showMessage(err.message);
  };
  

  const showListProduct = () => {
    if (selectedProducts.length === 0) {
      return <tr></tr>;
    } else {
      return selectedProducts.map((item, index) => {
        return (
          <tr key={index}>
            <td className="desc">{item.product_name}</td>
            <td>{formatMoney(item.qty)}</td>
            <td>{formatMoney(item.price)}</td>
            <td>{formatMoney(item.amount)}</td>
          </tr>
        );
      });
    }
  };

  return (
    <div className="print-body A5">
      <header className="clearfix">
        <div id="logo">
        </div>
        <h1><span style={{fontSize: '2.5em', letterSpacing: '-0.08em'}}>Season</span> <span style={{fontSize: '0.5em'}}>By 24</span></h1>
        <div id="customer" className="clearfix">
          <div><span>Tên khách: </span>{form.customer_name}</div>
          <div><span>Phone: </span>{form.customer_phone}</div>
          <div><span>Địa chỉ: </span>{form.customer_address}</div>
        </div>
        <div id="saleman">
          <div><span className="saleman-title">Ngày mua hàng: </span> <span className="saleman-data">{form.bill_date_vnd}</span></div>
          <div><span className="saleman-title">NV sale: </span> <span className="saleman-data">{form.userinfo.fullname}</span></div>
        </div>
      </header>
      <main>
        <table>
          <thead>
            <tr>
              <th className="desc">Tên sản phẩm</th>
              <th className="service">Số lượng</th>
              <th className="service">Đơn giá</th>
              <th className="service">Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {showListProduct()}
            <tr key={selectedProducts.length + 1} className="bill-summary">
              <td colSpan={3} className="qty" style={{fontWeight: 'bold'}}>Tổng thành tiền</td>
              <td className="qty">{formatMoney(form.total_amount)}</td>
            </tr>
            <tr key={selectedProducts.length + 2}>
              <td colSpan={3} className="qty" style={{fontWeight: 'bold'}}>Chiết khấu</td>
              <td className="qty">{formatMoney(form.total_discount)}</td>
            </tr>
            <tr key={selectedProducts.length + 3}>
              <td colSpan={3} className="qty" style={{fontWeight: 'bold'}}>Tổng tiền sau chiết khấu</td>
              <td className="qty">{formatMoney(form.total_final_amount)}</td>
            </tr>
          </tbody>
        </table>
      </main>

      <div className="footer-addr">
        Hotline - Viber - Zalo - Imess : 0926 37 2222 / 0966 50 6666
      </div>
      <Button type="primary"
        className="no-print"
        icon={<PrinterOutlined className="ant-fix-button-icon" />}
        style={{ width: "120px", marginTop: 10, marginRight: 25 }}
        onClick={() => window.print()}
      >
        In hóa đơn
      </Button>
      <Button type="ghost"
        className="no-print"
        icon={<RollbackOutlined className="ant-fix-button-icon" />}
        style={{ width: "120px", marginTop: 10, marginRight: 25 }}
        onClick={() => history.goBack()}
      >
        Quay lại
      </Button>
    </div>
  );
}

export default OrderSeasonPrintWithPrice;
