import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { Popconfirm } from "antd";
function WarehouseExportTableItem(props) {
  const { data, onDelete, onUndo } = props;
  return (
    <tr>
      <td>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</td>
      <td>{data.export_number}</td>
      <td>{data.export_type}</td>
      <td>{data.username}</td>
      <td>{data.status_text}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link className="text-success" to={`/warehouse-exports/` + data.id}>
              <i className="fas fa-eye"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
            {data.status_text == "Đã xóa" ? (
              <Popconfirm
                placement="top"
                title={"Bạn có muốn khôi phục lại"}
                onConfirm={() => onUndo(data.id)}
                okText="Yes"
                cancelText="No"
              >
              <a className="text-warning">
                <i className="fas fa-trash-restore" title="Khôi phục phiếu bị xóa"></i>
              </a>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="top"
                title={"Bạn có muốn xóa"}
                onConfirm={() => onDelete(data.id)}
                okText="Yes"
                cancelText="No"
              >
              <a className="text-danger">
                <i className="fas fa-trash"></i>
              </a>
              </Popconfirm>
            )}
          </Col>
          {/* {
            data.export_type == "Xuất trả hàng" ?
              <Col xs="auto" sm="auto">
                <Link className='text-alert' to={`/warehouse-exports/${data.id}`}>
                  <i className="fas fa-pen"></i>
                </Link>
              </Col>
              : null
          } */}
          {/* {
            data.export_type == "Xuất tạm" ?
              <Col xs="auto" sm="auto">
                <Link className='text-alert' to={`/warehouse-exports-temp/${data.id}`}>
                  <i className="fas fa-pen"></i>
                </Link>
              </Col>
              : null
          } */}
          <Col xs="auto" sm="auto">
            <Link className='text-alert' to={`/warehouse-exports-temp/${data.id}`}>
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
WarehouseExportTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  onDelete: PropTypes.func,
  onUndo: PropTypes.func,
};

export default WarehouseExportTableItem;
