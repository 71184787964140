import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Button, CardHeader, CardFooter } from 'reactstrap'
import * as moment from "moment";
import * as webOrderApi from '../../../apis/webOrderApi';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { convertNumberToCurrency, fixImagePath, showMessage } from 'Utils/global';
import WebOrderInfoPart from './Components/WebOrderInfoPart';
import WebOrderCustomerPart from './Components/WebOrderCustomerPart';
import { message, Image, Table } from 'antd';
import {Row as ARow, Col as ACol} from 'antd';
import ProducSelectionModal from './Components/ProducSelectionModal';
import { useSelector } from 'react-redux';
import { CacheUtils } from 'Utils/CacheUtils';
import { createContext } from 'react';
import authStore from 'store/authStore';
import { useStore } from 'store/hooks';

export const SelectWebOrderProductContext = createContext();

function WebOrder(props) {
  const AuthStore = useStore(authStore);
  let isView = props.match.url.includes('view');
  const selectedShop = useSelector(state => state.SelectedShop);
  const [webOrder, setWebOrder] = useState({
    customer_id: '',
    details: [],
    id: 0,
    order_date: '',
    order_number: '',
    phone: '',
    process_by: 0,
    process_date: '',
    process_status: 1,
    process_status_text: '',
    receipt_address: '',
    receipt_name: '',
    total_amount: 0,
    total_product: 0,
  });
  const [productSelectionModalVisible, setProductSelectionModalVisible] = useState(false);
  const [baseProductId, setBaseProductId] = useState(0);
  const [productSelectionData, setProductSelectionData] = useState([]);
  const [selectedProductQty, setSelectedProductQty] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  let history = useHistory();
  const [listCart, setListCart] = useState([]);

  useEffect(() => {
    initCart();
    const id = props.match.params?.id;
    if (id > 0) {
      fetchWebOrder(id);
    }
  }, []);

  const selectedProductColumns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'product_id',
      align: 'left',
      responsive: ["xs"],
      render: (text, record) => <ACol>
        <ARow>
          <ACol span={7}>ID: </ACol>
          <ACol>{record.product_id}</ACol>
        </ARow>        
        
        <ARow>
          <ACol span={7}>Mã sản phẩm: </ACol>
          <ACol>{record.product_code}</ACol>
        </ARow>
        
        <ARow>
          <ACol span={7}>Tên sản phẩm: </ACol>
          <ACol span={17}>{record.product_name}</ACol>
        </ARow>
        
        <ARow>
          <ACol span={7}>Kho: </ACol>
          <ACol>{record.warehouse_code}</ACol>
        </ARow>
        
        <ARow>
          <ACol span={7}>Giá thường: </ACol>
          <ACol>{convertNumberToCurrency(parseInt(record.price))}</ACol>
        </ARow>
        
        <ARow>
          <ACol span={7}>Giá bán: </ACol>
          <ACol>{convertNumberToCurrency(parseInt(record.sale_price))}</ACol>
        </ARow>
        
        <ARow>
          <a className='text-danger' onClick={() => handleRemoveProduct(record)}>
          Xóa <i className="fas fa-minus-circle"></i>
          </a>
        </ARow>
      </ACol>,
    },
    {
      title: 'ID',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
      width: 100,
      responsive: ["md"],
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      width: 150,
      responsive: ["md"],
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 250,
      responsive: ["md"],
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit_code',
      key: 'unit_code',
      width: 100,
      responsive: ["md"],
    },
    {
      title: 'Kho',
      dataIndex: 'warehouse_code',
      key: 'warehouse_code',
      width: 120,
      responsive: ["md"],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      key: 'price',
      width: 120,
      align: 'right',
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Giá bán',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: 120,
      align: 'right',
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Hành động',
      align: 'center',
      fixed: 'right',
      width: 120,
      responsive: ["md"],
      render: (key, record) => {
        return (
          <a className='text-danger' onClick={() => handleRemoveProduct(record)}>
            Xóa <i className="fas fa-minus-circle"></i>
          </a>
        );
      },
    },
  ];

  const webOrderProductColumns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'product_id',
      align: 'left',
      hidden: false,
      responsive: ["xs"],
      render: (text, record) => <ACol>
      <ARow>
        <ACol span={7}>Code ảnh: </ACol>
        <ACol>{record.base_product_code}</ACol>
      </ARow>        
      
      <ARow>
        <ACol span={7}>Mã sản phẩm: </ACol>
        <ACol>{record.base_product_name}</ACol>
      </ARow>
      
      <ARow>
        <ACol span={7}>ĐVT: </ACol>
        <ACol>{record.unit_code}</ACol>
      </ARow>
      
      <ARow>
        <ACol span={7}>Giá thường: </ACol>
        <ACol>{convertNumberToCurrency(parseInt(record.original_price))}</ACol>
      </ARow>
      
      <ARow>
        <ACol span={7}>Giá bán: </ACol>
        <ACol>{convertNumberToCurrency(parseInt(record.price))}</ACol>
      </ARow>
      
      <ARow>
        <ACol span={7}>Số lượng: </ACol>
        <ACol>{convertNumberToCurrency(parseInt(record.qty))}</ACol>
      </ARow>
      
      <ARow>
        <ACol span={7}>Thành tiền: </ACol>
        <ACol>{convertNumberToCurrency(parseInt(record.amount))}</ACol>
      </ARow>
      
      <ARow>
        <a className='text-alert' onClick={() => showSelectProductModal(record)}>
        Chọn<i className="fas fa-cart-plus"></i>
        </a>
      </ARow>
    </ACol>,
    },
    {
      title: 'Ảnh',
      dataIndex: 'thumb_file',
      key: 'thumb_file',
      width: '7%',
      hidden: false,
      responsive: ["md"],
      render: (thumb_file, record) => {
        const thumbFile = fixImagePath(record.thumb_file);
        const previewFile = fixImagePath(record.slide_file);
        return (
        <Image 
          width={64} 
          src={thumbFile} 
          preview={{src: previewFile}}
        />
        );
      }
    },
    {
      title: 'Code ảnh',
      dataIndex: 'base_product_code',
      key: 'base_product_code',
      align: 'center',
      width: 160,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Tên SP',
      dataIndex: 'base_product_name',
      key: 'base_product_name',
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit_code',
      key: 'unit_code',
      align: 'center',
      width: 70,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'original_price',
      key: 'original_price',
      width: 140,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      key: 'price',
      width: 140,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Số lượng',
      dataIndex: 'qty',
      key: 'qty',
      width: 95,
      align: 'right',
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Thành tiền',
      dataIndex: 'amount',
      key: 'amount',
      width: 140,
      align: 'right',
      hidden: false,
      responsive: ["md"],
      render: (text) => convertNumberToCurrency(parseInt(text)),
    },
    {
      title: 'Hành động',
      align: 'center',
      fixed: 'right',
      width: 120,
      hidden: isView,
      responsive: ["md"],
      render: (key, record) => {
        return (
          <a className='text-alert' onClick={() => showSelectProductModal(record)}>
          Chọn<i className="fas fa-cart-plus"></i>
          </a>
        );
      },
    },
  ].filter(e => e.hidden == false);

  const initCart = () => {
    const listItem = CacheUtils.getCachedCart()
    if (listItem) {
      setListCart(listItem);
    }
  }

  const fetchWebOrder = (id) => {
    webOrderApi.getWebOrder(
      id, 
      (res) => {
        let orderDate = moment(res.data.data.order_date);
        let orderDateStr = orderDate.isValid() ? orderDate.format("DD/MM/YYYY") : '';
        let processDate = moment(res.data.data.process_date);
        let processDateStr = processDate.isValid() ? processDate.format("DD/MM/YYYY") : '';
        setWebOrder({
          ...res.data.data, 
          order_date: orderDateStr, 
          process_date: processDateStr,
        });        
      }, 
      showMessage)
  }

  const onProductSelectionCancel = () => {
    setProductSelectionModalVisible(false);
  }

  const onProductSelected = (selectedProduct) => {
    let arr = [...selectedProducts];
    arr.push(selectedProduct);
    setSelectedProducts(arr);
  }

  const removeFromCart = (product) => {
    const data = listCart.filter(p => p.product_id !== product.product_id);    
    setListCart(data);
    CacheUtils.setCachedCart(data)
    AuthStore.data = data
    message.success('Xóa sản phẩm khỏi giỏ hàng thành công');
  }

  const handleRemoveProduct = product => {
    const arr = selectedProducts.filter(p => p.product_id !== product.product_id);
    removeFromCart(product);
    setSelectedProducts(arr);
  };

  const showSelectProductModal = (record) => {
    setProductSelectionData(record.inventories.map(item => {return {...item, key: item.product_id}}));
    setSelectedProductQty(record.qty);
    setBaseProductId(record.base_product_id)
    setProductSelectionModalVisible(true);
  }

  const createOrder = () => {
    CacheUtils.setCachedCartCustomer({
      'customer_phone': webOrder.phone,
      'customer_name': webOrder.receipt_name,
      'customer_address': webOrder.receipt_address,
      'web_order_id': webOrder.id,
    })
    history.push('/orders2/create');
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết đơn hàng Online</title>
      </MetaTags>
      <Card>
        <CardHeader className="h5 bg-transparent border-bottom text-uppercase">CHI TIẾT ĐƠN HÀNG ONLINE</CardHeader>
        <CardBody className='bg-gray'>
          <Row>
            <Col md={12} xl={6}>
              <WebOrderInfoPart webOrder={webOrder} setWebOrder={setWebOrder} isView={isView} />
            </Col>
            <Col md={12} xl={6}>
              <WebOrderCustomerPart webOrder={webOrder} />
            </Col>
          </Row>
          <Table 
            className="mytable mb-3"
            columns={webOrderProductColumns}
            dataSource={webOrder.details}
            rowKey={(record) => record.base_product_id}
            pagination={false}
            />
          <Table 
            className="mytable mb-3"
            columns={selectedProductColumns}
            dataSource={selectedProducts}
            rowKey={(record) => record.product_id}
            pagination={false}
            />
        </CardBody>
        <CardFooter className="h5 bg-transparent border-top">
          <Button color="secondary" className='mr-2' onClick={() => history.goBack()}>
            Quay lại
          </Button>
          {isView ? null :
            <Button color="primary" onClick={createOrder}>
              Tạo hóa đơn
            </Button>}
        </CardFooter>
      </Card>
      <SelectWebOrderProductContext.Provider value={{
        listCart: listCart, 
        setListCart: setListCart
      }}>
        <ProducSelectionModal
          companyId={selectedShop}
          baseProductId={baseProductId}
          products={productSelectionData}
          qty={selectedProductQty}
          visible={productSelectionModalVisible}
          selectNewProduct={false}
          onSelectProduct={onProductSelected}
          onCancel={onProductSelectionCancel} />
      </SelectWebOrderProductContext.Provider>
    </div>
  )
}

WebOrder.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}
export default WebOrder
