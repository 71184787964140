import React, { useEffect, useState } from 'react';
import * as webOrderApi from '../../../apis/webOrderApi';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Table } from 'antd';
import { showMessage } from 'Utils/global';
import { webOrderColumns } from './Columns/WebOrderColumns';

function ListWebOrder() {
  const [webOrders, setWebOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchWebOrders(1);
  }, []);

  const fetchWebOrders = (page) => {
    const params = [];
    params.push({ name: 'page', value: page });
    webOrderApi.getWebOrderList(params, onSuccess, showMessage);
  };

  const onSuccess = (res) => {
    let data = res.data.data.data;
    let orders = data.map(item => {return {...item, key: item.id}})
    setWebOrders(orders);
    setTotalPages(res.data.data.total);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách đơn hàng online</title>
      </MetaTags>
      <Card>
        <CardHeader className="h5 bg-transparent border-bottom text-uppercase">
          DANH SÁCH ĐƠN HÀNG ONLINE
        </CardHeader>
        <CardBody>
          <Table 
            className="mytable"
            columns={webOrderColumns}
            dataSource={webOrders}
            pagination={{
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                fetchWebOrders(page);
              },
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

ListWebOrder.propTypes = {
  location: PropTypes.object,
}
export default withRouter(ListWebOrder);
