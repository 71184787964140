import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import * as billApi from "../../apis/billApi";
import PreviewImg from "../../constants/component/PreviewImg";
import MetaTags from 'react-meta-tags';


function OrderView(props) {
  let params = useParams();
  let history = useHistory();
  const [dataPaymentMethods, setDataPaymentMethods] = useState([]);
  const [dataSaleMans, setDataSaleMans] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [productCode, setProductCode] = useState();
  const [form, setForm] = useState({
    bill_date: "",
    customer_phone: "",
    customer_name: "",
    customer_address: "",
    is_ship: 0,
    payment_code: "",
    bill_note: "",
    row_count: 0,
    total_amount: 0,
    total_discount: 0,
    total_final_amount: 0,
    pre_paid: null,
    company_id: null,
    saleman_id: null,
  });
  const popupRef = useRef();
  const selectedShop = useSelector(state => state.SelectedShop);

  const formatMoney = number => {
    if (isNaN(number)) return 0;
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  useEffect(() => {
    if (params.id) {
      fetchDetailBill(params.id);
    }
  }, [params.id]);

  const fetchDetailBill = id => {
    billApi.getBillDetail(id, fetchDetailBillSuccess, fetchDetailBillFailure);
  };

  const fetchDetailBillSuccess = res => {
    const { data } = res.data;
    setSelectedProducts(data[0].details);
    setForm(data[0]);
  };
  const fetchDetailBillFailure = err => {
    history.push("/orders");
  };

  const showListProduct = () => {
    if (selectedProducts.length === 0) {
      return <tr></tr>;
    } else {
      return selectedProducts.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.product_code}</td>
            <td><PreviewImg image_file={item.image_file} thumb_file={item.thumb_file} size={60} /></td>
            <td>{item.product_name}</td>
            <td>{item.warehouse_code}</td>
            <td>{item.unit_code}</td>
            <td>{item.qty}</td>
            <td>{formatMoney(item.price)}</td>
            <td>{formatMoney(item.amount)}</td>
            <td>{formatMoney(item.discount)}</td>
            <td>{formatMoney(item.final_amount)}</td>
          </tr>
        );
      });
    }
  };

  const fetchDataProductAvailableWarehouserSuccess = res => {
    const { data } = res.data;
    setProductAvailableWarehouser(data);
  };
  const fetchDataProductAvailableWarehouserFail = res => {
    alert("Không có dữ liệu");
  };
  useEffect(() => {
    billApi.getProductAvailableWarehouse(
      selectedShop,
      productCode,
      fetchDataProductAvailableWarehouserSuccess,
      fetchDataProductAvailableWarehouserFail
    );
  }, [productCode]);

  const fetchDataPaymentMethods = () => {
    billApi.getAllPaymentMethods(
      fetchDataPaymentMethodsSuccess,
      fetchDataPaymentMethodsFail
    );
  };
  const fetchDataPaymentMethodsSuccess = res => {
    const { data } = res.data;
    setDataPaymentMethods(data);
  };
  const fetchDataPaymentMethodsFail = res => {
    alert("Không có dữ liệu");
  };

  const fetchDataSaleMan = () => {
    billApi.getSaleMan(fetchDataSaleMansSuccess, fetchDataSaleManFail);
  };
  const fetchDataSaleMansSuccess = res => {
    const { data } = res.data;
    setDataSaleMans(data);
  };
  const fetchDataSaleManFail = res => {
    alert("Không có dữ liệu");
  };

  const fetchDataAllCustomer = phone => {
    setTimeout(function () {
      billApi.getAllCustomer(
        phone,
        fetchDataAllCustomersSuccess,
        fetchDataAllCustomerMethodsFail
      );
    }, 2000);
  };
  const fetchDataAllCustomersSuccess = res => {
    setDataCustomer([]);
    const { data } = res.data;
    setIsToggle(true);
    setDataCustomer(data);
  };
  const fetchDataAllCustomerMethodsFail = res => {
    alert("Không có dữ liệu");
  };

  const handleClickOutside = e => {
    if (popupRef.current && popupRef.current.contains(e.target)) {
      return;
    }
    setIsToggle(false);
    setDataCustomer([]);
  };

  useEffect(() => {
    if (isToggle) {
      document.addEventListener("click", handleClickOutside, false);
    } else {
      document.removeEventListener("click", handleClickOutside, false);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [isToggle]);

  const submitForm = e => {
    e.preventDefault();
  };
  useEffect(() => {
    fetchDataPaymentMethods();
    fetchDataSaleMan();
  }, []);

  return (
    <div className="page-content">
    <MetaTags>
      <title>Chi tiết đơn hàng</title>
    </MetaTags>
      <Form className="form" onSubmit={e => submitForm(e)}>
        <Card>
          <CardBody>
            <Card color="light">
              <CardBody>
                <div className="color-primary tag mb-3">THÔNG TIN HÓA ĐƠN </div>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div>Ngày</div>
                      <div>
                        <Input
                          disabled
                          type="date"
                          className="form-control"
                          value={form.bill_date}
                        />
                      </div>
                      <div>Số hóa đơn</div>
                      <div>
                        <Input
                          disabled
                          type="text"
                          className="form-control"
                          value={params.id}
                        />
                      </div>
                      <div>Số điện thoại:</div>
                      <div className="order-phone-customer">
                        <Input
                          type="text"
                          disabled
                          className="form-control"
                          value={form.customer_phone}
                          onChange={e => fetchDataAllCustomer(e.target.value)}
                        />
                        {isToggle && (
                          <div
                            className="order-popup-phone-customer"
                            ref={popupRef}
                          >
                            {showOptionCustom()}
                          </div>
                        )}
                      </div>
                      <div>Tên khách:</div>
                      <div>
                        <Input
                          disabled
                          type="text"
                          value={form.customer_name}
                          className="form-control"
                        />
                      </div>
                      <div>Địa chỉ:</div>
                      <div>
                        <Input
                          disabled
                          type="text"
                          value={form.customer_address}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-grid my-grid grid-fit-content-auto">
                      <div>Cần đặt ship:</div>
                      <div>
                        <div className="form-check form-switch">
                          <input
                            disabled
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch1"
                            checked={form.is_ship !== 0}
                          />
                        </div>
                      </div>
                      <div>Thanh toán:</div>
                      <div>
                        <Input type="select" className="form-control" disabled>
                          <option>
                            {dataPaymentMethods.length > 0
                              ? dataPaymentMethods.filter(
                                v => v.payment_code === form.payment_code
                              )[0]?.payment_desc
                              : ""}
                          </option>
                        </Input>
                      </div>
                      <div>Nhân viên:</div>
                      <div>
                        <Input type="select" className="form-control" disabled>
                          <option>
                            {dataSaleMans.length > 0
                              ? dataSaleMans.filter(
                                v => v.user_id === form.saleman_id
                              )[0]?.fullname
                              : ""}
                          </option>
                        </Input>
                      </div>
                      <div>Ghi chú:</div>
                      <div>
                        <Input
                          disabled
                          type="textarea"
                          className="form-control"
                          value={form.bill_note}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="table-responsive">
              <Table className="table-striped table-bill-detail mb-0 nowrap-table ">
                <thead>
                  <tr>
                    <th>Mã sản phẩm</th>
                    <th>Ảnh sp</th>
                    <th>Tên sản phẩm</th>
                    <th>Kho</th>
                    <th>Đơn vị</th>
                    <th>Số lượng</th>
                    <th>Đơn giá (vnđ)</th>
                    <th>Thành tiền (vnđ)</th>
                    <th>Chiết khấu</th>
                    <th>Tổng</th>
                  </tr>
                </thead>
                <tbody>{showListProduct()}</tbody>
              </Table>
            </div>

            <div className="mt-4">
              <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
                <div className="h4 mb-0">Tổng tiền:</div>
                <div className="body-1">{`${formatMoney(form.total_amount)} đ`}</div>
                <div className="h4 mb-0">Tổng chiết khấu:</div>
                <div className="body-1">{`${formatMoney(form.total_discount)} đ`}</div>
                <div className="h4 mb-0">Tiền sau chiết khấu:</div>
                <div className="body-1">{`${formatMoney(form.total_final_amount)} đ`}</div>
                <div className="h4 mb-0">Đã cọc:</div>
                <div className="body-1">{`${formatMoney(form.pre_paid) || 0} đ`}</div>
                <div className="h4 mb-0">Tiền cần thu:</div>
                <div className="h2 mb-0 color-primary">{`${formatMoney(form.total_final_amount - form.pre_paid)} đ`}</div>
                <div className=""></div>
                <div className="">
                  <Button
                    color="secondary"
                    className="px-4"
                    style={{ width: "120px" }}
                    onClick={() => history.goBack()}
                  >
                    Quay lại
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default OrderView;
