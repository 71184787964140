import React, { useState } from "react"
import { Row, Col, Label } from "reactstrap"
import { PropTypes } from 'prop-types';
import { TreeSelect } from 'antd';

FilterSelectTreeSingle.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func
}

function FilterSelectTreeSingle({ label, items, onChange }) {

  return (
    <Row xl={12} className="mb-2">
      <Label md={12} xl={2} className="col-form-label">{label}</Label>
      <Col md={12} xl={4}>
        <TreeSelect
          xl={12}
          showSearch
          treeNodeFilterProp='title'
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={items}
          allowClear
          treeDefaultExpandAll
          onChange={(value) => onChange(value)}
        />
      </Col>
    </Row>
  );
}

export default FilterSelectTreeSingle;