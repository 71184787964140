import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { PropTypes } from 'prop-types';
import { Link, useHistory, withRouter } from 'react-router-dom';
import * as warehouseReceiptTypeApi from '../../apis/warehouseReceiptTypeApi';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function WarehouseReceiptType(props) {
    const id = props.match.params?.id;
    const [data, setData] = useState({})
    let history = useHistory();
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [props.match.params])
    const fetchData = (id) => {
        warehouseReceiptTypeApi.get(id, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
    }
    const onChangeData = (value, attrName) => {
        data[attrName] = value;
        setData({ ...data })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (id) {
            warehouseReceiptTypeApi.update(id, data, onSubmitSuccess, onSubmitFail)
        }
        else {
            warehouseReceiptTypeApi.create(data, onSubmitSuccess, onSubmitFail)
        }
    }
    const onSubmitSuccess = (res) => {
        history.push("/warehouse-receipt-types")
    }
    const onSubmitFail = (res) => {
        alert(res.message)
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Chi tiết loại phiếu nhập</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">Danh mục loại phiếu nhập</CardTitle>
                    <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <Label htmlFor="Shop-name-Input">Tên loại phiếu nhập</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="warehouse-receipt-type-name-input"
                                placeholder="Nhập tên loại phiếu nhập"
                                value={data.warehouse_receipt_type_name}
                                onChange={e => onChangeData(e.target.value, 'warehouse_export_type_name')}
                            />
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} sm="auto">
                                    <button type="submit" className="btn btn-primary w-md">
                                        Lưu
                                    </button>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Link to="/warehouse-receipt-types" className="btn btn-secondary w-md">
                                        Hủy
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
WarehouseReceiptType.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
}

export default withRouter(WarehouseReceiptType);
