import {
	FILTER_CATEGORIES,
	FILTER_PRODUCTS_SUCCESS,
	GET_ALL_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTE_VALUES,
	GET_ATTRIBUTE_VALUES_SUCCESS,
	GET_ATTRIBUTES_SUCCESS,
	GET_DETAIL,
	GET_DETAIL_SUCCESS,
	GET_LIST,
	GET_LIST_SUCCESS,
	GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS,
	GET_LIST_WARE_HOUSE_SUCCESS,
	GROUP_CHECKED_CHANGE,
	ON_CHECKED_ATTRIBUTE, ON_RESET_SAVED_CHECKED_ATTRIBUTES,
	SET_SELECTED_ATTRIBUTE
} from "./actions";
import {GET_GROUPS_SUCCESS} from "../chat/actionTypes";
import {removeAccents} from "../../Utils/global";

const INIT_STATE = {
	wareHouses: {},
	wareHouseReceipts: {},
	wareHouseReceiptDetail: {},
	filterProducts:[],
	wareHouseReceiptTypes:[],
	error: {},
	categories:[],
	searchCategories: [],
	groupsChecked: 0,
	attributes:[],
	allAttributes: [],
	attributeValues:[],
	selectedAttribute: 0,
	checkedAttributes:[]
}
const wareHouseReceipts = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ON_RESET_SAVED_CHECKED_ATTRIBUTES:
			return {
				...state,
				checkedAttributes: [],
			}
		case ON_CHECKED_ATTRIBUTE:
			let checkedVals = state?.checkedAttributes;
			if(checkedVals && checkedVals?.some(val=>val == action.payload?.value)){
				checkedVals = checkedVals?.filter(val => val != action.payload?.value);
			}
			if(action.payload?.checked){
				checkedVals.push(action.payload?.value)
			}
			return {
				...state,
				checkedAttributes: checkedVals,
			}
		case GET_ATTRIBUTE_VALUES:
			console.log("GET_ATTRIBUTE_VALUES",action.payload)
			return {
				...state,
				selectedAttribute: action.payload?.attribute_id,
			}
		case GET_ATTRIBUTE_VALUES_SUCCESS:
			return {
				...state,
				attributeValues: action.payload?.data?.data,
			}
		case GET_ALL_ATTRIBUTES_SUCCESS:
			return {
				...state,
				allAttributes: action.payload?.data?.data,
			}
		case GET_ATTRIBUTES_SUCCESS:
			return {
				...state,
				attributes: action.payload?.data?.data,
			}

		case GROUP_CHECKED_CHANGE:

			return {
				...state,
				groupsChecked: action.payload?.value,
			}
		case GET_GROUPS_SUCCESS:
			return {
				...state,
				categories: action.payload?.data?.data,
				searchCategories: action.payload?.data?.data,
			}
		case FILTER_CATEGORIES:
			const keyword = action.payload?.keyword;
			if(keyword){
				const categories = state?.categories
				const keySlug = removeAccents(keyword).toLowerCase();
				const re = new RegExp(keySlug);
				const searchChilds = categories?.filter(cat=>{
					const slug = removeAccents(cat?.category_name).toLowerCase()
					console.log("Cat slug is ",slug)
					return re.test(slug)
				})
				let results = [];
				if(searchChilds){
					searchChilds?.map(child=> {
						if(child?.category_parent_id > 0){
							if(!results?.some(cat=>cat?.category_id == child?.category_parent_id)){
								results?.push(categories?.find(cat=>cat?.category_id == child?.category_parent_id))
							}

						}
						results.push(child)
					})
				}
				return {
					...state,
					searchCategories: results,
				}
			}else{
				return {
					...state,
					searchCategories: state?.categories,
				}
			}
		case GET_LIST_WARE_HOUSE_RECEIPT_TYPE_SUCCESS:
			return {
				...state,
				wareHouseReceiptTypes: action.payload?.data?.data,
			}
		case FILTER_PRODUCTS_SUCCESS:
			return {
				...state,
				filterProducts: action.payload?.data?.data,
			}
		case GET_LIST_WARE_HOUSE_SUCCESS:
			return {
				...state,
				wareHouses: action.payload?.data?.data,
			}
		case GET_LIST_SUCCESS:
			return {
				...state,
				wareHouseReceipts: action.payload?.data,
			}
		case GET_DETAIL_SUCCESS:
			return {
				...state,
				wareHouseReceiptDetail: action.payload,
			}
		default:
			return state
	}
}
export default wareHouseReceipts
