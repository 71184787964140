import React from "react"
import PropTypes from "prop-types"
import IconButtonWithConfirm from "./IconButtonWithConfirm"

const DeleteButtonWithConfirm = ({title, handleConfirm, id}) => {
  return (
    <IconButtonWithConfirm title={title} type="DELETE" handleConfirm={handleConfirm} id={id} />
  )
}

DeleteButtonWithConfirm.propTypes = {
  title: PropTypes.string,
  handleConfirm: PropTypes.func,
  id: PropTypes.any,
}

export default DeleteButtonWithConfirm
