import { useRef, useState } from "react";
import PropTypes from "prop-types";
import React, { Component } from 'react';

const TextFieldEditorUpdate = (props) => {
  const { value, onUpdate, rowIndex, onUpdateServer, ...rest } = props;
  const [tVal, setTVal] = useState(value);
  return (<div className={"d-flex"} style={{ direction: "ltr" }}>
    <input
      {...rest}
      className={"form-control"}
      key="textfield"
      type="text"
      value={tVal}
      onChange={e => setTVal(e.target.value)}
      onBlur={() => { }}
    />
    <button
      className="btn btn-default"
      onClick={() => {
        onUpdateServer({ value: tVal, rowIndex, cb: onUpdate })
      }}
      type={"button"}
    >
      Lưu
    </button></div>)
}

export default TextFieldEditorUpdate;
TextFieldEditorUpdate.propTypes = {
  onUpdate: PropTypes.func,
  onUpdateServer: PropTypes.func,
  value: PropTypes.string,
  rowIndex: PropTypes.number
}
