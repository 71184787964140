import { Image } from "antd";
import React, {useState} from "react";
import { API_HOST_NAME } from '../SystemConstant';
import { PropTypes } from "prop-types";
const PreviewImg = ({image_file, thumb_file, size}) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <img 
                src={thumb_file.startsWith('http') ? thumb_file : API_HOST_NAME + thumb_file} 
                style={{width: size+"px"}} 
                onClick={() => setVisible(true)}></img>
            <Image
                width={size}
                style={{ display: 'none' }}
                src={thumb_file.startsWith('http') ? thumb_file : API_HOST_NAME + thumb_file}
                preview={{
                    visible,
                    src: thumb_file.startsWith('http') ? thumb_file : API_HOST_NAME + thumb_file,
                    onVisibleChange: value => {
                        setVisible(value);
                    },
                }}
            />
      </>
    )
}
PreviewImg.propTypes = {
    image_file: PropTypes.string,
    thumb_file: PropTypes.string,
    size: PropTypes.number
};
export default PreviewImg;