import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getList = (selectedShop, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/system-params';
    let token = localStorage.getItem("token")
    let params = [
        {
            name: 'company_id',
            value: selectedShop 
        }
    ]
    commonApis.GET(url, token, params, callback, callbackErr);    
}
export const updateForm = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/system-params';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}
// 
