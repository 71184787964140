import React, { useReducer, useState, useEffect } from "react";
import MyPagination from "../../components/Common/MyPagination";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ListSaleCampaignTable from "./ListSaleCampaignTable";
import SaleCampaignModal from "./SaleCampaignModal";
import { SaleCampaignContext } from "./SaleCampaignContext";
import * as saleCampaignApi from "../../apis/saleCampaignApi";
import { generateCampaign, showMessage } from "Utils/global";
import LinkButton from "components/Common/LinkButton";
import ListSaleCampaignFilter from "./ListSaleCampaignFilter";

const initialSaleCampaigns = [];

const initialEditingCampaign = {
  id: 0,
  name: '',
  from_date: '',
  to_date: '',
  from_date_part: '',
  from_time_part: '00:00:00',
  to_date_part: '',
  to_time_part: '23:59:59'
};

const reducerSaleCampaigns = (currentSaleCampaigns, action) => {
  switch (action.type) {
    case 'POPULATE':
      const campaigns = action.payload.map((item) => {
        return generateCampaign(item)
      });
      return campaigns;
    case 'ADD':
      const newCampaign = generateCampaign(action.payload);
      return [newCampaign].concat(currentSaleCampaigns);
    case 'UPDATE':
      const objIndex = currentSaleCampaigns.findIndex(obj => obj.id === action.payload.id);
      if (objIndex === -1) {
        return currentSaleCampaigns;
      }
      const updatedCampaign = generateCampaign(action.payload);
      const updatedSaleCampaigns = [
        ...currentSaleCampaigns.slice(0, objIndex),
        updatedCampaign,
        ...currentSaleCampaigns.slice(objIndex + 1),
      ];
      return updatedSaleCampaigns;
    case 'DELETE':
      return currentSaleCampaigns.filter(el => el.id != action.payload.id);
    default:
      return currentSaleCampaigns;
  }
}

const reducerEditingCampaign = (currentEditingCampaign, action) => {
  switch (action.type) {
    case 'ASSIGN':
      return action.payload;
    case 'UPDATE':
      return { ...currentEditingCampaign, ...{ [action.payload.key]: action.payload.value } };
    case 'UNASSIGN':
      return initialEditingCampaign
    default:
      return currentEditingCampaign;
  }
}

function ListSaleCampaign(props) {

  const selectedShop = useSelector(state => state.SelectedShop)
  let history = useHistory();

  const [saleCampaigns, dispatchSaleCampaigns] = useReducer(reducerSaleCampaigns, initialSaleCampaigns);
  const [editingCampaign, dispatchEditingCampaign] = useReducer(reducerEditingCampaign, initialEditingCampaign);

  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState({});

  const onChangePage = (page) => {
    let queryStr = props.location.search.replaceAll('?', '');
    let queryArr = queryStr.split('&');
    let newQueryArr = queryArr.filter(query => !query.startsWith('page='));
    queryStr = newQueryArr.join('&')
    let url = props.location.pathname + "?page=" + page;
    if (queryStr != '') url += '&' + queryStr;
    history.push(url);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chiến dịch khuyến mại</title>
      </MetaTags>
      <Card>
        <CardHeader className="h5 bg-transparent border-bottom">
          CHIẾN DỊCH KHUYẾN MẠI
        </CardHeader>
        <CardBody>
          <SaleCampaignContext.Provider value={{
            campaigns: saleCampaigns, campaignsDispatch: dispatchSaleCampaigns,
            editingCampaign: editingCampaign, editingCampaignDispatch: dispatchEditingCampaign,
            modalVisible: modalVisible, setModalVisible: setModalVisible,
            pagination: pagination, setPagination: setPagination,
            selectedShop: selectedShop
          }}>
            <LinkButton
              buttonClass="mb-2"
              buttonText="Tạo chiến dịch mới"
              iconClass="fas fa-plus mr-2"
              link="/sale-campaigns/create"
              show={true}
            />
            <ListSaleCampaignFilter />
            <ListSaleCampaignTable/>

            <MyPagination
              startPage={pagination.current_page ? pagination.current_page : 1}
              totalItemsCount={pagination.total ? pagination.total : 5}
              itemsCountPerPage={pagination.per_page ? pagination.per_page : 10}
              callback={onChangePage}
            />
          </SaleCampaignContext.Provider>
        </CardBody>
      </Card>
    </div>
  );
}

ListSaleCampaign.propTypes = {
  location: PropTypes.object,
};
export default withRouter(ListSaleCampaign);
