import { Button, Col, Image, Row, Table } from 'antd';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import DeleteButtonWithConfirm from '../Button/DeleteButtonWithConfirm';
import { fixImagePath } from 'Utils/global';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  );
};

DraggableBodyRow.propTypes = {
  index: PropTypes.number,
  moveRow: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

const getColumns = () => [
  {
    title: 'STT ảnh',
    dataIndex: 'image_order',
    key: 'image_order',
    width: "100px",
    align: "center",
  },
  {
    title: 'Ảnh sản phẩm',
    dataIndex: 'thumb_file',
    key: 'thumb_file',
    width: "auto",
    align: "left",
    render: (thumb_file, record) => {
      const thumbFile = fixImagePath(record.thumb_file);
      const previewFile = fixImagePath(record.slide_file);
      return (
        <Image width={64} src={thumbFile} preview={{ src: previewFile }} />
      );
    }
  },
];

const DragableImageList = ({imageList, saveImagesOrder}) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [data],
  );

  const saveImageOrdersClick = () => {
    let orders = data.map((item, index) => ({image_file: item.image_file, image_order: index+1}));
    console.log('orders = ', orders);
    // saveImagesOrder(orders);
  }

  const initColumns = () => {
    const tableColumns = getColumns();
    setColumns(tableColumns);
  }

  useEffect(() => {
    console.log('jump into drag image list ', imageList)
    initColumns();
    if (imageList) {
      if (imageList.length > 1) {
        imageList.sort((a, b) => parseInt(a.image_order) - parseInt(b.image_order))
      } 
      setData(imageList);
    }
  }, [imageList])
  
  useEffect(() => {
    console.log('dragable image list data = ', data)
  }, [data])
  return (
    <DndProvider backend={HTML5Backend}>
      <h2 className='mt-3' style={{paddingLeft: "10px"}}>Kéo ảnh lên xuống để thay đổi thứ tự</h2>
      <Table
        rowKey={(record) => record.image_file}
        className="table mytable"
        columns={columns}
        dataSource={data}
        components={components}
        onRow={(_, index) => {
          const attr = {
            index,
            moveRow,
          };
          return attr;
        }}
        pagination={false}
      />
      <Button type='primary' style={{marginLeft: "10px"}} onClick={saveImageOrdersClick}>Lưu thứ tự ảnh</Button>
    </DndProvider>
  );
};

DragableImageList.propTypes = {
  imageList: PropTypes.array,
  saveImagesOrder: PropTypes.func,
};

export default DragableImageList;
