import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { message, Select, Row, Col, Form, Descriptions, Input, InputNumber } from 'antd';
import { convertNumberToCurrency, debounce, showMessage } from 'Utils/global';
import PropTypes from 'prop-types';
import * as wareHouseApi from "../../apis/wareHouseExportApi";
import * as billApi from "../../apis/billApi";

AddProductModal.propTypes = {
  companyId: PropTypes.number,
  visible: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

const noProductState = {
  product_id: 0,
  product_code: "",
  product_name: "",
  unit_code: "",
  warehouse_code: "",
  regular_price: 0,
  price: 0,
  sale_detail_id: 0,
  qty: 0,
  amount: 0,
  discount: 0,
  final_amount: 0,
  thumb_file: '',
  slide_file: '',
}

function AddProductModal({ companyId, visible, onSelect, onClose }) {

  const [productCodes, setProductCodes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(noProductState);
  const [avaiWarehouses, setAvaiWarehouses] = useState([]);
  const [priceInputVisible, setPriceInputVisible] = useState(false);

  useEffect(() => {
    handleQtyChanged();
  }, [selectedProduct.qty]);

  useEffect(() => {
    recalcAmount();
  }, [selectedProduct.discount, selectedProduct.price]);

  const onChangeProductCodes = (key, value) => {
    setSelectedProduct({
      ...selectedProduct,
      product_id: value.product_id,
      product_code: value.product_code,
      unit_code: value.unit_code,
      product_name: value.product_name,
      regular_price: value.regular_price,
      price: value.price,
      sale_detail_id: value.sale_detail_id,
      slide_file: value.slide_file,
      thumb_file: value.thumb_file,
      warehouse_code: "",
      amount: 0,
      discount: 0,
      final_amount: 0,
      qty: 0
    });

    setPriceInputVisible((value.price == 0) || (value.price == null))

    if (value) {
      billApi.getProductAvailableWarehouse(
        companyId,
        value.product_code,
        onGetProductAvailableWarehouseSuccess,
        onGetProductAvailableWarehouseFail
      );
    }
  };

  const onGetProductAvailableWarehouseSuccess = res => {
    const { data } = res.data;
    setAvaiWarehouses(data);
  };

  const onGetProductAvailableWarehouseFail = res => {
    setAvaiWarehouses([]);
    message.error(res.message);
  };

  const onSearchProductCodes = val => {
    getProductList(val, companyId);
    setSelectedProduct(noProductState);
  };

  const getProductList = useCallback(
    debounce((val, companyId) => {
      if (val) {
        const params = [
          { name: "product_code", value: val },
          { name: "company_id", value: companyId },
        ];
        wareHouseApi.getProductByCode(
          params,
          fetchDataProductSuccess,
          fetchDataProductFail
        );
      }
    }, 1000),
    []
  );

  const fetchDataProductSuccess = res => {
    setProductCodes(res.data.data);
  };

  const fetchDataProductFail = res => {
    setProductCodes([]);
  };

  const showAvaiWarehouses = () => {
    return avaiWarehouses.length == 0
    ? [{ label: 'Sản phẩm hết tồn', value: '-1' }]
    : avaiWarehouses.map((el) => {
      return { value: el.warehouse_code, label: 'Kho ' + el.warehouse_code + ' => Tồn: ' + el.qty }
    })
  }

  const handleQtyChanged = () => {
    if (selectedProduct.qty == 0) {
      setSelectedProduct({
        ...selectedProduct,
        amount: 0,
        final_amount: 0,
      });
      return;
    }
    if (!selectedProduct.warehouse_code) {
      alert('Vui lòng chọn kho trước khi nhập số lượng');
      setSelectedProduct({ ...selectedProduct, qty: 0 });
      return;
    }

    let warehouses = avaiWarehouses.filter(item => item.warehouse_code = selectedProduct.warehouse_code);
    if (warehouses.length == 0) {
      alert('Lỗi kho không chính xác');
      setSelectedProduct({ ...selectedProduct, qty: 0 });
      return;
    }

    let warehouseData = warehouses[0];
    if (selectedProduct.qty > warehouseData.qty) {
      alert('Không đủ tồn');
      setSelectedProduct({ ...selectedProduct, qty: 0 });
      return;
    }

    recalcAmount();
  };

  const recalcAmount = () => {
    let discount = parseInt(selectedProduct.discount);
    if (discount < 0) {
      discount = 0;
    }
    let amount = selectedProduct.qty * parseInt(selectedProduct.price);
    amount = amount < 0 ? 0 : amount;
    let final_amount = amount - discount;
    final_amount = final_amount < 0 ? 0 : final_amount;

    setSelectedProduct({
      ...selectedProduct,
      amount: amount,
      discount: discount,
      final_amount: final_amount,
    });
  };

  const handleChangeWarehouse = value => {
    setSelectedProduct({ ...selectedProduct, warehouse_code: value })
  }

  const setDiscount = value => {
    value = parseFloat(value.replace(/\D/g, ''));
    setSelectedProduct({ ...selectedProduct, discount: value })
  }

  const setPrice = value => {
    value = parseFloat(value.replace(/\D/g, ''));
    setSelectedProduct({ ...selectedProduct, price: value })
  }

  const showPrice = () => {
    return (
      priceInputVisible 
      ? <Input style={{ width: '50%' }} 
          value={selectedProduct.price ? convertNumberToCurrency(selectedProduct.price) : '0'} 
          onChange={(e) => setPrice(e.target.value)} />
      : convertNumberToCurrency(selectedProduct.price)
    )
  }

  const renderFormAddSingleProduct = () => {
    return (
      <Col>
        <Select
          showSearch
          placeholder="Nhập mã sản phẩm"
          onChange={onChangeProductCodes}
          onSearch={onSearchProductCodes}
          value={selectedProduct.product_code}
          filterOption={false}
          bordered={false}
          style={{ borderRadius: "0.25rem", border: "1px solid #ced4da", width: '100%', marginBottom: 5 }}
        >
          {productCodes.map(item => (
            <Select.Option
              key={item.product_id}
              product_code={item.product_code}
              regular_price={item.price}
              price={(item.sale_price && item.sale_price > 0) ? item.sale_price : item.price}
              sale_detail_id={item.sale_detail_id}
              product_name={item.product_name}
              unit_code={item.unit_code}
              product_id={item.product_id}
              thumb_file={item.first_image?.thumb_file}
              slide_file={item.first_image?.slide_file}
              value={item.product_code}
            >
              {item.product_code} - {item.product_name}
            </Select.Option>
          ))}
        </Select>

        <Descriptions bordered
          size="small"
          column={1}>
          <Descriptions.Item label='Mã SP'>
            {selectedProduct.product_code}
          </Descriptions.Item>

          <Descriptions.Item label='Tên SP'>
            {selectedProduct.product_name}
          </Descriptions.Item>

          <Descriptions.Item label='ĐVT'>
            {selectedProduct.unit_code}
          </Descriptions.Item>

          <Descriptions.Item label='Kho'>
            <Select
              key={selectedProduct.warehouse_code}
              style={{ width: '100%' }}
              disabled={avaiWarehouses.length == 0}
              value={selectedProduct.warehouse_code}
              options={showAvaiWarehouses()}
              onChange={(value) => handleChangeWarehouse(value)} />
          </Descriptions.Item>

          <Descriptions.Item label='Số lượng'>
            <InputNumber style={{ width: '50%' }} value={selectedProduct.qty} disabled={avaiWarehouses.length == 0}
              onChange={(value) => setSelectedProduct({ ...selectedProduct, qty: parseFloat(value) })} />
          </Descriptions.Item>

          <Descriptions.Item label='Giá'>
            {convertNumberToCurrency(selectedProduct.regular_price)}
          </Descriptions.Item>

          <Descriptions.Item label='Giá bán'>
            {showPrice()}
          </Descriptions.Item>

          <Descriptions.Item label='Thành tiền'>
            {convertNumberToCurrency(selectedProduct.amount)}
          </Descriptions.Item>

          <Descriptions.Item label='Chiết khấu'>
            <Input style={{ width: '50%' }} disabled={avaiWarehouses.length == 0}
              value={selectedProduct.discount ? convertNumberToCurrency(selectedProduct.discount) : 0} 
              onChange={(e) => setDiscount(e.target.value)} />
          </Descriptions.Item>

          <Descriptions.Item label='Tổng'>
            {convertNumberToCurrency(selectedProduct.final_amount)}
          </Descriptions.Item>

        </Descriptions>
      </Col>
    );
  }

  const handleClose = () => {
    setSelectedProduct(noProductState);
    onClose();
  }

  const handleSelectProduct = () => {
    if (selectedProduct.qty == 0) {
      alert('Bạn cần nhập số lượng trước khi thêm sản phẩm');
      return;
    }
    onSelect(selectedProduct);
    setSelectedProduct(noProductState);
  }

  return (
    <Modal isOpen={visible} toggle={handleClose} style={{ minWidth: 400 }}>
      <ModalHeader>
        Thêm sản phẩm vào hóa đơn
      </ModalHeader>
      <ModalBody>
        {renderFormAddSingleProduct()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button color="primary" onClick={handleSelectProduct} hidden={avaiWarehouses.length == 0}>
          Thêm sản phẩm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddProductModal;
