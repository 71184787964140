
const baseColumnProps = (
  title,
  dataIndex,
  width = "auto",
  align = "center",
  hidden = false,
  responsive = ["md"]
) => ({
  title: title,
  dataIndex: dataIndex,
  width: width,
  align: align,
  hidden: hidden,
  responsive: responsive,
});

export default baseColumnProps;