import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';


FullLoadedTableFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  close: PropTypes.func,
  dataIndex: PropTypes.string,
  dataName: PropTypes.string,
};


function FullLoadedTableFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex, dataName }) {
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex, dataName) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    // setSearchText('');
  };
  return (
    <div
      style={{ padding: 8, }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Tìm ${dataName}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, dataName)}
        style={{ marginBottom: 8, display: 'block', }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, dataName)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, }}
        >
          Tìm
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{ width: 90, }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false, });
            // setSearchText(selectedKeys[0]);
            // setSearchedColumn(dataIndex);
          }}
        >
          Lọc
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => { confirm({ closeDropdown: true, }); }}
        >
          Đóng
        </Button>
      </Space>
    </div>
  );
}

const getColumnSearchProps = (dataIndex, dataName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <FullLoadedTableFilter setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      close={close}
      dataIndex={dataIndex}
      dataName={dataName} />
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{ color: filtered ? '#1890ff' : undefined, }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    // if (visible && searchInput) {
    //   setTimeout(() => searchInput?.current?.select(), 200);
    // }
  },
  render: (text) => text
});

export default getColumnSearchProps;