import React from 'react';
import { Link } from 'react-router-dom';
import {Row,Col,} from "reactstrap"
import PropTypes from 'prop-types'


RoleTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    onDelete:PropTypes.func
}
RoleTableItem.defaultProps = {
    data:{},
    count:0,
    onDelete:null
}
function RoleTableItem(props) {
    const { data, count,onDelete } = props;

    const onDeleteItem = (e) =>{
        if(onDelete)onDelete(e,data.role_id);
    }
    return (
        <tr>
            <td>{data.role_name}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/roles/" + data.role_id}  >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <Link className='text-danger' onClick={(e) => {onDeleteItem(e)}}>
                            <i className="fas fa-trash"></i>
                        </Link>
                    </Col>
                </Row>
            </td>
        </tr>
    )

}

export default RoleTableItem;
