import moment from "moment";
import { PropTypes } from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import * as repayApi from "../../apis/repayApi";
import * as wareHouseApi from "../../apis/wareHouseApi";
import PreviewImg from "../../constants/component/PreviewImg";
import MetaTags from 'react-meta-tags';


function ReturnView(props) {
  const id = props.match.params?.id;
  let history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [data, setData] = useState({});
  const [wareHouseList, setWareHouseList] = useState([]);
  const [dataWareHouseEachProduct, setDataWareHouseEachProduct] = useState({});
  const [dataToggleRepayItem, setDataToggleRepayItem] = useState({});
  const [customer, setCustomer] = useState({});
  const formatMoney = number => {
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  useEffect(() => {
    if (selectedShop === 0) return;
    fetchDataWareHouses(selectedShop);
  }, [selectedShop]);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchDataWareHouses = selectedShop => {
    wareHouseApi.getAll(
      selectedShop,
      fetchDataWareHousesSuccess,
      fetchDataWareHousesFail
    );
  };

  const fetchDataWareHousesSuccess = res => {
    const { data } = res.data;
    setWareHouseList(data);
  };
  const fetchDataWareHousesFail = res => {
    alert(res.message);
  };



  const fetchData = id => {
    repayApi.getDetailRepay(id, fetchDataSuccess, fetchDataFail);
  };
  useEffect(() => {
  }, [customer])
  const fetchDataSuccess = res => {
    const { data } = res.data;
    const { customer } = data;
    setData(data)
    setCustomer(customer);
  };

  const fetchDataFail = res => {
    alert(res.message);
  };

  const caclTotalPrice = (quantity, price) => {
    return quantity * price;
  };

  const showListProduct = () => {
    if (!data) return <tr></tr>;
    if (data.details && data.details.length > 0) {
      return data.details.map((v, i) => {
        return (
          <tr key={i}>
            <td>{v.product_code}</td>
            <td><PreviewImg image_file={v.image_file} thumb_file={v.thumb_file} size={60} /></td>
            <td style={{ maxWidth: '200px', whiteSpace: 'normal' }}>{v.product_name || "N/A"}</td>
            <td>
              {wareHouseList.filter(
                _v => v.warehouse_code === _v.warehouse_code
              )[0]?.warehouse_name || "N/A"}
            </td>
            <td>{v.qty}</td>
            <td>{formatMoney(v.price)}</td>
            <td>{formatMoney(v.discount)}</td>
            <td>{formatMoney(v.reduce_amount)}</td>
            <td>{formatMoney(v.final_amount)}</td>
          </tr>
        );
      });
    }
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết trả lại</title>
      </MetaTags>
      <Card>
        <CardBody>
          <Card color="light">
            <CardBody>
              <div className="color-primary tag mb-3">THÔNG TIN TRẢ LẠI</div>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <div className="d-grid my-grid grid-fit-content-auto">
                    <div>Ngày HĐ</div>
                    <div>
                      {data.bill_date ? moment(data.bill_date).format("DD/MM/YYYY") : "N/A"}
                    </div>
                    <div>Số HĐ</div>
                    <div>
                      {data.bill_id}
                    </div>
                    <div>Số điện thoại:</div>
                    <div>{customer?.customer_phone ? customer?.customer_phone : "N/A"}</div>
                    <div>Tên khách:</div>
                    <div>{customer?.customer_name ? customer?.customer_name : "N/A"}</div>
                    <div>Địa chỉ:</div>
                    <div>{customer?.customer_address ? customer?.customer_address : "N/A"}</div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="d-grid my-grid grid-fit-content-auto">
                    <div>Ngày TL:</div>
                    <div>{moment(data.repay_date).format("DD/MM/YYYY")}</div>
                    <div>Số TL:</div>
                    <div>{data.id}</div>
                    <div>Người tạo phiếu:</div>
                    <div>{data?.userinfo?.fullname ? data.userinfo.fullname : "N/A"}</div>
                    <div>Ghi chú:</div>
                    <div>
                      <Input
                        value={data.repay_note}
                        disabled
                        type="textarea"
                        className="form-control"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="table-responsive">
            <Table className="table-striped mb-0 nowrap-table ">
              <thead>
                <tr>
                  <th>Mã sản phẩm</th>
                  <th>Ảnh sp</th>
                  <th>Tên sản phẩm</th>
                  <th>Kho</th>
                  <th>Số lượng</th>
                  <th>Thành tiền (vnđ)</th>
                  <th>Chiết khấu (vnđ)</th>
                  <th>Giảm trừ (vnđ)</th>
                  <th>Tổng (vnđ)</th>
                </tr>
              </thead>
              <tbody>{showListProduct()}</tbody>
            </Table>
          </div>

          <div className="mt-4">
            <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
              <div className="h4 mb-0">Tổng:</div>
              <div className="body-1">{data.total_amount ? formatMoney(parseInt(data.total_amount)) : 0} đ</div>
              <div className="h4 mb-0">Tổng chiết khấu:</div>
              <div className="body-1">{data.total_discount ? formatMoney(parseInt(data.total_discount)) : 0} đ</div>
              <div className="h4 mb-0">Tổng giảm trừ:</div>
              <div className="body-1">{data.total_reduce_amount ? formatMoney(parseInt(data.total_reduce_amount)) : 0} đ</div>
              <div className="h4 mb-0">Tổng sau chiết khấu:</div>
              <div className="h2 mb-0 color-primary">{data.total_final_amount ? formatMoney(parseInt(data.total_final_amount)) : 0} đ</div>


              <div className=""></div>
              <div className="">
                <Button
                  className="px-4 btn btn-secondary"
                  style={{ width: "120px" }}
                  onClick={() => history.goBack()}
                >
                  Quay lại
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

ReturnView.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(ReturnView);
