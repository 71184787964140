
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from "reactstrap"
import PropTypes from 'prop-types'
import { utils } from 'Utils/utils';
import * as moment from "moment";
import * as preOrderApi from '../../apis/preOrderApi';
import { isRoleIn, isUserInGroup } from "Utils/SecurityUtils";
import { showMessage } from 'Utils/global';

PreOrderTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  actionCallBack: PropTypes.func, 
  successCallback: PropTypes.func,
  isKeToan: PropTypes.bool,
}

PreOrderTableItem.defaultProps = {
  data: [],
  count: 0,
  actionCallBack: null
}

function PreOrderTableItem(props) {
  const { data, count, actionCallBack } = props;
  const [user, setUser] = useState({});
  const getUserName = () => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUser(obj);
    }
  }
  useEffect(() => {
    getUserName();
  }, [])

  const onAction = (type) => {
    actionCallBack(type, data.id);
  }

  const onSuccess = res => {
    alert(res.data.message);
    props.successCallback();
  };
  
  const onUnmarkProcessedInAccounting = () => {
    preOrderApi.setNotPrcessedAccountingStatus(data.id, onSuccess, showMessage);
  }
  
  const onMarkProcessedInAccounting = () => {
    preOrderApi.setPrcessedAccountingStatus(data.id, onSuccess, showMessage);
  }
  return (
    <tr>
      <td>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</td>
      <td>{data.order_number}</td>
      <td>{data.customer_name}</td>
      <td>{data.customer_phone}</td>
      <td>{data.saleman_username}</td>
      <td>{data.orderman_username}</td>
      <td>{utils.formatNumber(data.total_amount)}</td>
      <td>{data.process_status_text}</td>
      <td className={(props.isKeToan && (data?.accounting_status === 1)) ? 'accountant-checked' : ''}>{data.status_text}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          {data.status == 1 || isUserInGroup(['Admin', 'Sale', 'Order']) ?
            <Col xs="auto" sm="auto">
              <Link className='text-alert' to={"/preorders/" + data.id}>
                <i className="fas fa-pen"></i>
              </Link>
            </Col>
            : null
          }
          <Col xs="auto" sm="auto">
            <Link className='text-success' to={"/preorders/view/" + data.id} >
              <i className="fas fa-eye"></i>
            </Link>
          </Col>
          {
            data.process_status == 103 ?
              <Col xs="auto" sm="auto">
                <Link className='color-success' to={{
                  pathname: '/orders/create',
                  state: { detail: data }
                }} >
                  <i className="fas fa-plus-square"></i>
                </Link>
              </Col>
              : null
          }
          {data.status == 1 ?
            <Col xs="auto" sm="auto">
              <a className='text-danger' onClick={() => { onAction(1) }} >
                <i className="fas fa-trash"></i>
              </a>
            </Col>
            :
            <Col xs="auto" sm="auto">
              <a className='text-warning' onClick={() => { onAction(0) }}  >
                <i className="fas fa-trash-restore"></i>
              </a>
            </Col>
          }
          {isUserInGroup(['KeToan'])
            ? <Col xs="auto" sm="auto">
              {data.accounting_status === 1
                ? <a className="text-success" onClick={onUnmarkProcessedInAccounting}>
                  <i className="fas fa-unlink"></i>
                </a>
                : <a className="text-success" onClick={onMarkProcessedInAccounting}>
                  <i className="fas fa-link"></i>
                </a>
              }
            </Col>
            : null
          }

        </Row>
      </td>
    </tr>
  )

}


export default PreOrderTableItem;
