import React from "react"
import { Row, Col } from "reactstrap"
import { PropTypes } from 'prop-types';


ReportButtonContainer.propTypes = {
  children: PropTypes.any,
}

function ReportButtonContainer(props) {
  return (
    <Row xl={12} className="mb-2">
      <Col md={0} xl={2}></Col>
      <Col md={12} xl={10}>
        {props.children}
      </Col>
    </Row>
  );
}

export default ReportButtonContainer;