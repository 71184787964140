import * as commonApis from './commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';

export const auth = (username, password, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/auth/login"
  let data = {
    "username": username,
    "password": password
  }
  commonApis.POST(url, data, '', '', callback, callbackErr);
}

export const getList = (page, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/users"
  let params = [{
    name: 'page',
    value: page ? page : 1
  }]
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr);
}

export const getListUser = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/users";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const create = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/users";
  let token = localStorage.getItem("token")
  commonApis.POST(url, data, token, '', callback, callbackErr);
}

export const get = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/' + id;
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);
}

export const getWarehouses = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/warehouses/' + id;
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);
}

export const getGroups = (callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/groups';
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);
}

export const getGroupsOrder = (callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/groups/Order';
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);

}

export const update = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/' + id;
  let token = localStorage.getItem("token")
  commonApis.PUT(url, data, token, '', callback, callbackErr)

}

export const changePassword = (password, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/auth/change-my-pass';
  let token = localStorage.getItem("token")
  let data = {
    password: password
  }
  commonApis.POST(url, data, token, '', callback, callbackErr)

}

export const updateProfile = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/profile';
  let token = localStorage.getItem("token")
  commonApis.PUT(url, params, token, '', callback, callbackErr)
}

// lấy all nhân viên
export const getListStaff = (callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/groups/Sale';
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);

}

// get all nhan vien thu kho
export const getListStaffThuKho = (callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/groups/Kho';
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr);

}

export const deleteUser = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/' + id;
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, "", callback, callbackErr);
}

export const restoreUser = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/users/restore/' + id;
  let token = localStorage.getItem("token")
  commonApis.PUT(url, '', token, '', callback, callbackErr);
}

export const saveUserWarehouses = (body, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/users/warehouses';
    let token = localStorage.getItem("token")
    commonApis.PUT(url, body, token, [], callback, callbackErr)
}