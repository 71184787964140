import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getList = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/base-products";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const deleteImg = (id, filename, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/base-products/${id}/image`;
  if (!filename || !id) return;
  let param = [{
    name: "image_file",
    value: filename,
  }];
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, param, callback, callbackErr);
};

export const get = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/base-products/" + id;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const update = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/base-products/" + id;
  let token = localStorage.getItem("token");
  commonApis.PUT(url, data, token, "", callback, callbackErr);
};

export const uploadMultipleImg = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/base-products/" + id + "/images";
  let token = localStorage.getItem("token");
  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};
