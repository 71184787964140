import React from "react";
import { Form, Input, Modal } from 'antd';
import { PropTypes } from 'prop-types';

AddBrandModal.propTypes = {
  open: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
}

function AddBrandModal ({ open, onCreate, onCancel }) {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={open}
      title="Thêm thương hiệu mới"
      okText="Tạo"
      cancelText="Thoát"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="brand_name"
          label="Thương hiệu"
          rules={[
            {
              required: true,
              message: 'Chưa nhập tên thương hiệu',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddBrandModal;