import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { limitString } from "constants/function";

function BannerTableItem(props) {
  const { data, count, deleteCallback } = props;
  const onDelete = () => {
    deleteCallback(data.id);
  };
  const renderStatus = (status) => {
    return status === 1 ? "Hiển thị" : "Ẩn"
  }
  const renderImgOrVideo = (type, link) => {
    if (type === "video") {
      return <video muted
        autoPlay={false}
        loop className="video" style={{ width: '100px' }}>
        <source src={link} type="video/mp4" />
      </video>
    } else {
      return (
        <img src={link} style={{ width: "100px", height: 'auto' }} />
      )
    }
  }
  return (
    <tr>
      <td>{count}</td>
      <td>{limitString(data.title, 30)}</td>
      <td>{limitString(data.content, 50)}</td>
      <td>{renderImgOrVideo(data.banner_media_type, data.banner_media_link)}</td>
      <td>{renderStatus(data.status)}</td>
      <td>
        <Row className="d-flex align-items-center m-0">
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/banner/view/${data.id}`}>
              <i className="fas fa-eye"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
            <Link className="text-alert" to={`/banner/edit/${data.id}`}>
              <i className="fas fa-pen"></i>
            </Link>
          </Col>
          <Col xs="auto" sm="auto">
              <Link className='text-danger' onClick={onDelete} >
                  <i className="fas fa-trash"></i>
              </Link>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
BannerTableItem.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  deleteCallback: PropTypes.func,
  restoreCallback: PropTypes.func,
};

export default BannerTableItem;
