import ProductCategoryTableItem from 'components/TableItem/ProductCategoryTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import PropTypes from 'prop-types'
import { useQuery } from '../../Utils/UseQuery'

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap"
import { Link } from 'react-router-dom';
import { GET, DELETE } from '../../apis/commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as categoryApi from '../../apis/categoryApi'
import { useSelector } from 'react-redux';
import ConfirmDelete from 'components/Common/ConfirmDelete';
import { getMessage } from '../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { showMessage } from 'Utils/global';
import Popup from './Popup';
import LinkButton from 'components/Common/LinkButton';



function ListWebCategory(props) {
  let history = useHistory()
  const [popup, setPopup] = useState({
    node: null,
    visible: false,
    x: 0,
    y: 0
  });

  const [webCategories, setWebCategories] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const selectedShop = useSelector(state => state.SelectedShop);

  useEffect(() => {
    fetchTreeWebCategory()
  }, [selectedShop])

  const fetchTreeWebCategory = () => {

    if (!selectedShop) return;

    let params = [
      { name: 'company_id', value: selectedShop },
      { name: 'show_qty', value: 1 },
    ]

    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, showMessage)
  }
  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategories(webCategories);
  }

  const onDelete = (id) => {
    setDeleteId(id)
  }

  const onEdit = (id) => {
    history.push('/web-categories/' + id)
  }

  const deleteWebCategory = (id) => {
    setDeleteId(null);
    categoryApi.deleteWebCategory(id, deleteSuccess, showMessage)
  }

  const deleteSuccess = (res) => {
    alert(res.data.message);
    fetchTreeWebCategory()
  }

  const handleRightClick = ({ event, node }) => {
    event.stopPropagation();
    if (!popup.visible) {
      document.addEventListener(`click`, function onClickOutside(e) {
        if (e.target.className != 'popup-edit') { 
          // this check is importent for preventing mem leak
          // if no check: set state command will happend on unmounted component
          // in case of use select edit a category
          // that leads to redirect to edit page
          console.log('hide menu');
          setPopup({ ...popup, visible: false });
        }
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    setPopup({
      node,
      visible: true,
      x: event.clientX,
      y: event.clientY
    })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách nhóm sản phẩm web</title>
      </MetaTags>

      {
        deleteId ?
          <ConfirmDelete
            confirmCallback={() => { deleteWebCategory(deleteId); }}
            cancelCallback={() => { setDeleteId(null); }}
          />
          : null
      }

      <Card>
        <CardHeader className="h5 bg-transparent border-bottom text-uppercase">Nhóm sản phẩm web</CardHeader>
        <CardHeader className="bg-transparent border-bottom text-uppercase">
          <LinkButton show={true} buttonText='Thêm mới' link='/web-categories/create' />
        </CardHeader>
        <CardBody>
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            onRightClick={handleRightClick}
            treeData={webCategories}
          />
          <Popup node={popup.node} visible={popup.visible} x={popup.x} y={popup.y} 
            triggerDelete={onDelete}
            triggerEdit={onEdit} />
        </CardBody>
      </Card>
    </div >
  )
}
ListWebCategory.propTypes = {
  location: PropTypes.object,
}

export default withRouter(ListWebCategory);
