
import React, { useContext, useEffect, useState } from "react";
import * as saleCampaignApi from "../../apis/saleCampaignApi";
import { Row, Col, Button, Input, Label } from "reactstrap";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from 'Utils/UseQuery';
import { withRouter } from 'react-router-dom';
import { showMessage } from "Utils/global";
import { SaleCampaignContext } from "./SaleCampaignContext";

function ListSaleCampaignFilter(props) {

  const saleCampaignContext = useContext(SaleCampaignContext)
  const { campaignsDispatch, setPagination } = saleCampaignContext

  let history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [isShowSearch, setIsShowSearch] = useState(false);

  const FILTER_TYPE = {
    STRING: 0,
    CHECK: 1
  };

  const [filter, setFilter] = useState({
    name: "",
    from_date: "",
    to_date: "",
  });

  const filterTypes = {
    name: FILTER_TYPE.STRING,
    from_date: FILTER_TYPE.STRING,
    to_date: FILTER_TYPE.STRING,
  };

  const filterToQueryString = () => {

    let queryParams = [];

    Object.entries(filter).map(([key, value]) => {
      switch (filterTypes[key]) {
        case FILTER_TYPE.STRING:
          queryParams.push(key + '=' + encodeURIComponent(value));
          break;
        case FILTER_TYPE.CHECK:
          queryParams.push(key + '=' + (value ? '1' : '0'));
          break;
      }
    });

    queryParams.push('company_id=' + encodeURIComponent(selectedShop));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));

    let queryString = queryParams.join('&');
    return queryString;
  }

  useEffect(() => {

    let query = useQuery(props.location.search);

    let params = Object.keys(filter).reduce((acc, key) => {
      let value;
      switch (filterTypes[key]) {
        case FILTER_TYPE.STRING:
          value = (query.get(key) ?? '');
          break;
        case FILTER_TYPE.CHECK:
          value = (query.get(key) == '1');
          break;
      }
      return Object.assign({}, acc, {
        [key]: value
      });
    }, {})

    setFilter(params);
  }, []);

  const setFilterValue = (value, filterId) => {
    if (value === undefined)
      value = '';
    setFilter({ ...filter, [filterId]: value })
  }

  useEffect(() => {
    handleSearch();
  }, [props.location.search, selectedShop]);

  const handleSearch = () => {
    if (selectedShop === 0) return
    let queryParams = useQuery(props.location.search);
    const pageParam = initPageParam(queryParams);
    const filterParams = initFilterParams(queryParams);
    let params = filterParams.concat(pageParam);
    saleCampaignApi.getList(params, 
      (res) => {
        let campaigns = res.data.data.data;
        let page = res.data.data.current_page;
        let perPge = res.data.data.per_page;
        campaigns.forEach(function (row, index) {
          row.stt = (page - 1) * perPge + index + 1;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
        });
        campaignsDispatch({type: 'POPULATE', payload:campaigns});
        setPagination(res.data.data);
      },
      showMessage);
  };

  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString + '&t=' + Math.random().toString(36).slice(-6);
    history.push(url);
  }

  const handleResetFilter = () => {
    setFilter({
      name: "",
      from_date: "",
      to_date: "",
    });
  }

  const initPageParam = (queryParams) => {

    const params = [];
    let page = queryParams.get("page");

    if (page) {
      params.push({ name: "page", value: page });
    }

    return params;
  }

  const initFilterParams = (queryParams) => {
    const params = [];
    queryParams.forEach((value, key) => {
      params.push({ name: key, value: value });
    });
    params.push({ name: "company_id", value: queryParams.get("selectedShop") ?? selectedShop });
    return params;
  }

  const onClose = () => {
    setIsShowSearch(!isShowSearch);
  }

  return (
    <>
      {
        isShowSearch ?
          <>
            <Row className='align-items-end'>
              <Col xs={3}>
                <label className='body-2 font-weight-bold'> Tên </label>
                <Input type='text' 
                  className="form-control" 
                  value={filter.name} 
                  onChange={(e) => setFilterValue(e.target.value, 'name')} />
              </Col>
              <Col xs={2}>
                <label className='body-2 font-weight-bold'>Từ ngày</label>
                <Input type='date'
                  className="form-control"
                  value={filter.from_date}
                  onChange={(e) => setFilterValue(e.target.value, 'from_date')} />
              </Col>

              <Col xs={2}>
                <label className='body-2 font-weight-bold'>Đến ngày</label>
                <Input type='date'
                  className="form-control"
                  value={filter.to_date}
                  onChange={(e) => setFilterValue(e.target.value, 'to_date')} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg="auto">
                <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                  Tìm kiếm
                </Button>
                <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                  Reset tìm kiếm
                </Button>
              </Col>
            </Row>
          </>
          : null
      }
      <div className='tag color-primary font-weight-bold mt-3 mb-3' 
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
        onClick={onClose}>
        {
          isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
        }
        {
          isShowSearch ?
            <i className="bx bx bx-chevron-up ml-2" />
            : <i className="bx bx bx-chevron-down ml-2" />
        }
      </div>
    </>

  );
}

ListSaleCampaignFilter.propTypes = {
  location: PropTypes.object,
  populateProducts: PropTypes.func,
  updateQueryString: PropTypes.func,
};
export default withRouter(ListSaleCampaignFilter);
