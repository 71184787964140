import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { PropTypes } from 'prop-types';
import * as categoryApi from '../../apis/categoryApi'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { TreeSelect } from 'antd';
import { showMessage } from 'Utils/global';


function WebCategory(props) {
  let id = props.match.params?.id
  let history = useHistory()
  const selectedShop = useSelector(state => state.SelectedShop);
  const [data, setData] = useState({
    category_id: null,
    category_name: '',
    category_parent_id: null
  })
  const [webCategories, setWebCategories] = useState([]);

  useEffect(() => {
    fetchTreeWebCategory()
  }, [selectedShop])

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchTreeWebCategory = () => {

    if (!selectedShop) return;

    let params = [{ name: 'company_id', value: selectedShop }]
    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, showMessage)
  }

  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategories(webCategories);
  }

  const fetchData = () => {
    if (id) {
      categoryApi.getWebCategory(id, fetchDataSuccess, showMessage)
    }
  }
  const fetchDataSuccess = (res) => {
    setData({...res.data.data})
  }
  
  const onChangeName = (e) => {
    let name = e.target.value;
    setData({ ...data, "category_name": name });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    data.company_id = selectedShop
    if (id) {
      categoryApi.updateWebCategory(data.category_id, data, onSubmitSuccess, showMessage)
    }
    else {
      categoryApi.createWebCategory(data, onSubmitSuccess, showMessage)
    }
  }
  const onSubmitSuccess = (res) => {
    alert(res?.data?.message);
    history.push('/web-categories')
  }

  const onChangeWebCategory = (newValue) => {
    if (newValue === undefined) newValue = null
    if (newValue === data.category_id) newValue = null
    data['category_parent_id'] = newValue
    setData({ ...data })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết danh mục sản phẩm web</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Danh mục sản phẩm web</CardTitle>
          <Form onSubmit={onSubmit}>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Tên nhóm</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Tên Danh mục sản phẩm"
                value={data?.category_name}
                onChange={onChangeName}
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Danh mục cha</Label>
              <TreeSelect
                showSearch
                treeNodeFilterProp='title'
                style={{ width: '100%' }}
                value={data?.category_parent_id}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto', }}
                treeData={webCategories}
                placeholder="Chọn nhóm sp cha"
                allowClear
                treeDefaultExpandAll
                onChange={onChangeWebCategory}
              />
            </div>

            <div>
              <Row>
                <Col xs={12} sm="auto">
                  <button type="submit" className="btn btn-primary w-md">
                    Lưu
                  </button>
                </Col>
                <Col xs={12} sm="auto">
                  <Link to="/web-categories" className="btn btn-secondary w-md">
                    Hủy
                  </Link>
                </Col>
              </Row>

            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

WebCategory.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(WebCategory)
