import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

import * as reportsApi from 'apis/reportsApi';
import * as categoryApi from 'apis/categoryApi';
import { downloadFile } from "../../constants/function";
import PageTitle from "../../components/Common/PageTitle"
import FilterText from "./FilterComponent/FilterText";
import FilterFromToDate from "./FilterComponent/FilterFromToDate"
import ReportBody from "./ReportPartials/ReportBody";
import ReportButton from "./ReportPartials/ReportButton";
import ReportButtonContainer from "./ReportPartials/ReportButtonContainer";
import { useFetchBrands } from "components/Hook/useFetchBrands";
import FilterSelectTreeMulti from "./FilterComponent/FilterSelectTreeMulti";
import FilterSelectTreeSingle from "./FilterComponent/FilterSelectTreeSingle";
import { useSelector } from "react-redux";
import {useFetchWebCategoriesAsync} from "components/Hook/useFetchWebCategories";

const defaultFilterValues = {
  product_name: '',
  size: '',
  color: '',
  web_cat_ids: '',
  brand: 0,
  from_date: '',
  to_date: '',
};

function BcThoiGianTonKho() {

  const [dataHead, setDataHead] = useState([]);
  const [dataBody, setDataBody] = useState([]);

  const selectedCompany = useSelector(state => state.SelectedShop);
  const { webCategories } = useFetchWebCategoriesAsync(selectedCompany);
  const { brands } = useFetchBrands(selectedCompany);

  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  const changeFilterValue = (key, value) => {
    if (value === undefined)
      value = '';
    setFilterValues(prev => ({ ...prev, [key]: value }));
  };

  const initParams = () => {
    const params = [];
    for (const [key, value] of Object.entries(filterValues)) {
      params.push({ name: key, value: value });
    }
    params.push({ name: "company_id", value: selectedCompany });
    return params;
  }

  const renderTable = () => {
    if (selectedCompany === undefined) { alert("Chọn hệ thống cửa hàng"); return; }
    const params = initParams();
    reportsApi.getDataReportThoiGianTonKho(params, getDataView, showErr);
  }

  const getDataView = (res) => {
    setDataHead(res?.data?.data?.headers);
    setDataBody(res?.data?.data?.details);
  }

  const showErr = (res) => {
    alert(res.message);
  }

  const downloadReport = () => {
    if (selectedCompany === undefined) { alert("Chọn hệ thống cửa hàng"); return; }
    const params = initParams();
    reportsApi.downloadReportThoiGianTonKho(params, downloadExcel, showErr);
  }

  const downloadExcel = (res) => {
    downloadFile(res.data.data);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Thời gian tồn kho</title>
        </MetaTags>
        <Container fluid>
          <PageTitle title="Thời gian tồn kho" />
          <Card>
            <CardBody>

              <FilterText
                label="Mã/tên sản phẩm"
                onChange={(value) => changeFilterValue('product_name', value)} />

              <FilterText
                label="Size"
                onChange={(value) => changeFilterValue('size', value)} />

              <FilterText
                label="Màu"
                onChange={(value) => changeFilterValue('color', value)} />

              <FilterSelectTreeMulti
                label="Nhóm sản phẩm"
                items={webCategories}
                onChange={(value) => changeFilterValue('web_cat_ids', value)} />

              <FilterSelectTreeSingle
                label="Thương hiệu"
                items={brands}
                onChange={(value) => changeFilterValue('brand', value)} />

              <FilterFromToDate
                onChangeFromDate={(value) => changeFilterValue('from_date', value)}
                onChangeToDate={(value) => changeFilterValue('to_date', value)} />

              <ReportButtonContainer>
                <ReportButton
                  label='Xem báo cáo'
                  onClick={renderTable} />

                <ReportButton
                  label='Download báo cáo'
                  onClick={downloadReport} />
              </ReportButtonContainer>

            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <ReportBody
                dataHead={dataHead}
                dataBody={dataBody} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BcThoiGianTonKho
