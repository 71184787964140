export const orderStatusSale = [
    {
        "value": 109,
        "label": "Hoàn thành"
    },
    {
        "value": 201,
        "label": "Hủy - Hoàn cọc"
    },
    {
        "value": 202,
        "label": "Đã hoàn cọc"
    }
]

export const productDetailStatusSale = [
    {
        "value": 4,
        "label": "Đã giao khách"
    }
]

export const productDetailStatusAdmin = [
    {
        "value": 4,
        "label": "Đã giao khách"
    },
    {
        "value": 2,
        "label": "Đã mua - Đang về"
    },
    {
        "value": 3,
        "label": "Hàng đã về"
    }
]

export const productDetailStatusOrder = [
    {
        "value": 2,
        "label": "Đã mua - Đang về"
    },
    {
        "value": 3,
        "label": "Hàng đã về"
    }
]