
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
} from "reactstrap"
import PropTypes from 'prop-types'
import { Popconfirm } from 'antd';
import * as authApi from '../../apis/authApi'
import { showMessage } from 'Utils/global';

function UserTableItem(props) {
    const { data, count, setUserStatus } = props;

    const onRestore = id => {
      authApi.restoreUser(id, restoreSuccess, showMessage);
    };
    const restoreSuccess = res => {
      alert('Khôi phục thành công');
      setUserStatus(1, count - 1);
    };

    const onDelete = id => {
      authApi.deleteUser(id, deleteSuccess, showMessage);
    };
    const deleteSuccess = res => {
      alert('Xóa thành công');
      setUserStatus(0, count - 1);
    };

    return (
        <tr>
            <td scope="row">{count}</td>
            <td>{data.username}</td>
            <td>{data.fullname}</td>
            <td>{data.address}</td>
            <td>{data.phone}</td>
            <td>{data.email}</td>
            <td>{data.role}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/users/"+data.user_id} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    
                    <Col xs="auto" sm="auto">
                      {data.status == 0 ? (
                        <Popconfirm
                          placement="top"
                          title={"Bạn có muốn khôi phục lại user?"}
                          onConfirm={() => onRestore(data.user_id)}
                          okText="Đồng ý"
                          cancelText="Không"
                        >
                        <a className="text-warning">
                          <i className="fas fa-trash-restore" title="Khôi phục user bị xóa"></i>
                        </a>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          placement="top"
                          title={"Bạn có muốn xóa user?"}
                          onConfirm={() => onDelete(data.user_id)}
                          okText="Đồng ý"
                          cancelText="Không"
                        >
                        <a className="text-danger">
                          <i className="fas fa-trash" title="Xóa user"></i>
                        </a>
                        </Popconfirm>
                      )}
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
UserTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    setUserStatus: PropTypes.func,
}

export default UserTableItem;
