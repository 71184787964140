import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { API_HOST_NAME } from './../../constants/SystemConstant';
import "react-image-lightbox/style.css";
import { Image } from 'antd';
import * as moment from "moment";
import { utils } from '../../Utils/utils';

function ImagesStoreV2(props) {
  let data = props.data;
  let disabled = props.disabled;
  let addData = props.addData;
  let addImageCallback = props.addImageCallback;
  let deleteAddImgCallback = props.deleteAddImg;
  let deleteDataCallback = props.deleteImg;
  
  const onDeleteImg = (fileName) => {
    deleteDataCallback(fileName)
  }

  const deleteAddImg = (addImg) => {
    deleteAddImgCallback(addImg)
  }

  const onAddImage = (e) => {
    console.log(e.target.files);
    addImageCallback(e.target.files)
  }

  return (
    <div className='border-1 rounded border-grey border-solid'>
      <Row>
        <Col xs={12} md="auto">
          <div className='p-3 py-5'>
            <div className='text-center'>
              <label htmlFor='upload-image-for-store' className='d-block image-holder m-auto border-1 rounded border-dashed mb-3 border-primary cursor-pointer color-primary'>
                <div className='ratio-1x1 ratio'>
                  <div className='position-absolute center d-flex justify-content-center align-items-center'>+</div>
                </div>
              </label>
              <label htmlFor='upload-image-for-store' className='btn btn-primary '>
                <div className='d-flex align-items-center'>
                  <i className="fas fa-upload mr-2"></i>Chọn ảnh
                </div>
              </label>
            </div>
            <input type="file" accept="image/*" id="upload-image-for-store" onChange={onAddImage} multiple disabled={disabled} className='d-none' />
          </div>
        </Col>
      </Row>
      <Image.PreviewGroup>
        {
          data.map((v, i) => {
            let data = v.image_file.startsWith('http') ? v.image_file : API_HOST_NAME + v.image_file;
            let imageName = v.image_file.substring(v.image_file.lastIndexOf('/') + 1);
            return (
              <Row key={i + Math.random()} className="align-items-center mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete p-2 upload-image">

                <Image preview={{ mask: false }} className="col-auto avatar-m rounded bg-light" width={100} src={data} />
                {disabled ? '' : (
                  <div className='delete-img' onClick={() => onDeleteImg(v.image_file)}>
                    <i className="fas fa-times-circle"></i>
                  </div>
                )}
                <Col>
                  <a
                    href={data}
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted font-weight-bold"
                  >
                    {imageName}
                  </a>
                  <p className="mb-0">
                    <strong>
                      {
                        v.is_temp 
                      ? (<span className='text-warning'>Ảnh tạm</span>)
                      : (<span className='text-primary'>Ảnh chuẩn</span>)
                      }
                      </strong>
                  </p>
                  <p className="mb-0">
                  Người sửa cuối: {v.user}
                  </p>
                  <p className="mb-0">
                  Thời gian sửa: {moment(v.created_at).format('DD/MM/YYYY hh:mm:ss')}
                  </p>
                </Col>
              </Row>
            )
          })
        }
        {
          addData.map((v, i) => {
            let src = URL.createObjectURL(v)
            return (
              <Row
                key={i + Math.random()} className="align-items-center mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete p-2 upload-image">
                <Image preview={{ mask: false }} className="col-auto avatar-m rounded bg-light" width={100} src={src} />
                {disabled ? '' : (
                  <div className='delete-img' onClick={() => deleteAddImg(v)}>
                    <i className="fas fa-times-circle"></i>
                  </div>
                )}
                <Col>
                  <a
                    href={src}
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted font-weight-bold"
                  >
                    {v.name}
                  </a>
                  <p className="mb-0">
                    <strong>{utils.formatBytes(v.size)}</strong>
                  </p>
                  <p className="mb-0">
                    <strong>Ảnh mới chọn chưa lưu</strong>
                  </p>
                </Col>
              </Row>
            )
          })
        }
      </Image.PreviewGroup>
    </div>
  );
}

ImagesStoreV2.propTypes = {
  data: PropTypes.array,
  addData: PropTypes.array,
  addImageCallback: PropTypes.func,
  deleteAddImg: PropTypes.func,
  deleteImg: PropTypes.func,
  disabled: PropTypes.bool,
}
export default ImagesStoreV2;
