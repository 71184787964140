import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as unitApi from '../../apis/unitApi';

const useFetchUnits = () => {
  const [listUnit, setListUnit] = useState([]);  
  
  useEffect(() => {    
    unitApi.getAll(fetchUnitsSuccess, showMessage);
  }, [])

  const fetchUnitsSuccess = (res) => {
    setListUnit(res.data.data.map(item => { return { value: item.unit_code, title: item.unit_code } }))
  }

  return listUnit;
};

export default useFetchUnits;
