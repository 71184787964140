import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Table, TabContent, TabPane, Input } from 'reactstrap';
import { getMessage } from 'Utils/ErrMessageUtils';
import Select from "react-select"
import * as reportsApi from './../../apis/reportsApi';
import * as shopApi from './../../apis/shopApi';
import * as roleApi from './../../apis/roleApi';
import "flatpickr/dist/themes/material_blue.css"
import ReactApexChart from "react-apexcharts";
import * as chartApi from "./../../apis/chartApi";
import { downloadFile } from "../../constants/function";
import moment from 'moment';
import { API_HOST_NAME } from 'constants/SystemConstant';
import MetaTags from 'react-meta-tags';
import { inUserGroup } from 'helpers/function';
import { isUserInGroup } from 'Utils/SecurityUtils';


function WareHouseReport(props) {
  const [dataChart, setData] = useState(null);
  const [dataHead, setDataHead] = useState([]);
  const [dataBody, setDataBody] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setSelectedMulti] = useState();
  const [dataCompany, setDataCompany] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataShopNsp, setDataShopNsp] = useState();

  let curr = new Date();
  let toDay = curr.toISOString().substring(0, 10);
  curr.setMonth(curr.getMonth() - 1);
  let aMonthBefore = curr.toISOString().substring(0, 10);

  const [from_date, setFrom_date] = useState(aMonthBefore);
  const [to_date, setTo_date] = useState(toDay);
  const [code, setCode] = useState("");

  const [company_ids, setCompany_ids] = useState();
  const [dateRange, setDateRange] = useState();
  const [user, setUser] = useState();

  const [listStaff, setListStaff] = useState();
  
  const initUser = () => {
    const userInfo = CacheUtils.getCachedUser();
    if (userInfo) {
      setUser(userInfo)
    }
  }

  useEffect(() => {
    initUser();
  }, [])

  const handleMultiStaff = (value) => {
    setListStaff(value);
  }

  const handleMultiCompanyNsp = (value) => {
    setDataShopNsp(value);
  }
  const handleMultiCompanyDs = (value) => {
    setCompany_ids(value);
  }
  const handleRangeDate = (value) => {
    setDateRange(value);
  }

  const renderFormDsht = () => {
    let listCompany = []
    company_ids?.map(item => {
      listCompany.push(item.value)
    })
    if (listCompany.length > 0) {
      const params = [
        {
          name: "company_ids",
          value: listCompany.toString()
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportsDsht(params, getDataView, showErr)
    } else {
      return
    }
  }

  const downloadFileReportBanHang = () => {
    if (dataShopNsp) {
      const fromDate = from_date ? moment(from_date).format("YYYY-MM-DD") : "";
      const toDate = to_date ? moment(to_date).format("YYYY-MM-DD") : "";
      let url = API_HOST_NAME + `/api/reports/baocaobanhang?company_id=${dataShopNsp.value}&from_date=${fromDate}&to_date=${toDate}`;

      reportsApi.getDataReportBcbh(url, downloadExcel, showErr);
    } else {
      return
    }
  }

  const downloadFileReportTonKho = () => {
    if (dataShopNsp) {
      const params = [
        {
          name: "company_id",
          value: dataShopNsp.value
        },
        {
          name: "code",
          value: code
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.downloadReportNhapXuatTon(params, downloadExcel, showErr)
    } else {
      return
    }
  }

  const downloadExcel = (res) => {
    downloadFile(res.data.data);
  }

  const getDataDownloadView = (res) => {
  }

  const renderFormDsnv = () => {
    let listStaffArr = []
    listStaff?.map(item => {
      listStaffArr.push(item.value)
    })
    if (listStaffArr.length > 0) {
      const params = [
        {
          name: "user_id",
          value: isUserInGroup(['Admin']) ? listStaffArr.toString() : user?.user_id
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportsDsnv(params, getDataView, showErr)
    } else {
      return
    }
  }

  const renderFormDsnsp = () => {
    if (dataShopNsp) {
      const params = [
        {
          name: "company_ids",
          value: dataShopNsp.value
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportsDsnsp(params, getDataView, showErr)
    } else {
      return
    }
  }
  const renderFormDskho = () => {
    if (dataShopNsp) {
      const params = [
        {
          name: "company_ids",
          value: dataShopNsp.value
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportsDsKho(params, getDataView, showErr)
    } else {
      return
    }
  }
  const renderFormNxt = () => {
    if (code.length > 0) {
      const params = [
        {
          name: "company_id",
          value: dataShopNsp.value
        },
        {
          name: "code",
          value: code
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportsLichSuNhapXuat(params, getDataView, showErr)
    } else {
      return
    }
  }


  const renderFormTonKho = () => {
    if (dataShopNsp) {
      const params = [
        {
          name: "company_id",
          value: dataShopNsp.value
        },
        {
          name: "code",
          value: code
        },
        {
          name: "from_date",
          value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
        },
        {
          name: "to_date",
          value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
        }
      ]
      reportsApi.getDataReportNhapXuatTon(params, getDataView, showErr)
    } else {
      return
    }
  }

  const renderFormTabDsht = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={true}
                  onChange={handleMultiCompanyDs}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormDsht()}
        >
          Tìm kiếm
        </button>
      </>
    )
  }
  const renderFormTabNxt = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={false}
                  onChange={handleMultiCompanyNsp}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Mã sản phẩm</div>
              <div className=' grid-info-item'>
                <Input type='text'
                  onChange={(e) => setCode(e.target.value)}
                  className="form-control">
                </Input>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormNxt()}
        >
          Tạo báo cáo
        </button>
      </>
    )
  }

  const renderFormTabTonKho = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={false}
                  onChange={handleMultiCompanyNsp}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Mã sản phẩm</div>
              <div className=' grid-info-item'>
                <Input type='text'
                  onChange={(e) => setCode(e.target.value)}
                  className="form-control">
                </Input>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormTonKho()}
        >
          Xem báo cáo
        </button>

        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => downloadFileReportTonKho()}
        >
          Download báo cáo
        </button>
      </>
    )
  }

  const renderFormTabNv = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Mã nhân viên</div>
              <div className=' grid-info-item'>
                {
                  isUserInGroup(['Admin']) ?
                    <Select
                      value={selectedMulti}
                      isMulti={true}
                      onChange={handleMultiStaff}
                      options={dataUser}
                      classNamePrefix="select2-selection"
                    />
                    :
                    <Input type='select'
                      className="form-control">
                      <option>{user?.username}</option>
                    </Input>
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormDsnv()}
        >
          Tìm kiếm
        </button>
      </>
    )
  }
  const renderFormTabDsnsp = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={false}
                  onChange={handleMultiCompanyNsp}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormDsnsp()}
        >
          Tìm kiếm
        </button>
      </>
    )
  }
  const renderFormTabDskho = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={false}
                  onChange={handleMultiCompanyNsp}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => renderFormDskho()}
        >
          Tìm kiếm
        </button>
      </>
    )
  }
  const renderFormTabBcbh = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={false}
                  onChange={handleMultiCompanyNsp}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => downloadFileReportBanHang()}
        >
          Download
        </button>
      </>
    )
  }
  const renderFormChart = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <div className='grid-info-container' style={{ display: 'inline' }}>
              <div className='body-2 font-weight-bold grid-info-item mb-1'>Hệ thống cửa hàng</div>
              <div className=' grid-info-item'>
                <Select
                  value={selectedMulti}
                  isMulti={true}
                  onChange={handleMultiCompanyDs}
                  options={dataCompany}
                  classNamePrefix="select2-selection"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Từ ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setFrom_date(e.target.value)}
              defaultValue={aMonthBefore}
            >
            </Input>
          </Col>
          <Col xs={3}>
            <label className='body-2 font-weight-bold'>
              Đến ngày
            </label>
            <Input type='date'
              className="form-control"
              onChange={(e) => setTo_date(e.target.value)}
              defaultValue={toDay}
            >
            </Input>
          </Col>
        </Row>
        <button
          className={`btn btn-primary mt-2`}
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => getChartDsTheoNgay()}
        >
          Tìm kiếm
        </button>
      </>
    )
  }
  const renderChart = () => {
    if (dataChart) {
      return (
        <Row>
          <Col xs={6}>
            <ReactApexChart
              options={dataChart.options}
              series={dataChart.series}
              type="area"
              height="350"
              className="apex-charts"
            />
          </Col>
        </Row>
      )
    }
  }
  const getChartDsTheoNgay = () => {
    const params = [
      {
        name: "company_ids",
        value: "1"
      },
      {
        name: "from_date",
        value: from_date ? moment(from_date).format("YYYY-MM-DD") : ""
      },
      {
        name: "to_date",
        value: to_date ? moment(to_date).format("YYYY-MM-DD") : ""
      },
    ]
    chartApi.getChartDsTheoNgay(params, getDataSuccess, alertFail);
  }
  const getDataSuccess = (res) => {
    let params = {
      series: res.data.data.series,
      options: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#556ee6", "#34c38f", "lightblue"],
        xaxis: {
          categories: res.data.data.date,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      },
    }
    setData(params);
  }
  const alertFail = (res) => {
    alert(res.message);
  }
  useEffect(() => {
    getDataTalbe();
    getDataCompany();
    getListUser();
  }, []);

  const getListUser = () => {
    roleApi.getListUser(getDataUser, showErr)
  }

  const getDataUser = (res) => {
    setDataUser(convertLabelValueUser(res.data.data));
  }
  const convertLabelValueUser = (data) => {
    if (data.length > 0) {
      return data.map(item => {
        return {
          label: item.username,
          value: item.user_id
        }
      })
    }
  }
  const convertLabelValueShop = (data) => {
    if (data.length > 0) {
      return data.map(item => {
        return {
          label: item.company_name,
          value: item.company_id
        }
      })
    }
  }
  const getDataCompany = () => {
    shopApi.getAllShop(getDataShop, showErr);
  }

  const getDataShop = (res) => {
    setDataCompany(convertLabelValueShop(res.data.data));
  }

  const getDataTalbe = () => {
  }
  const getDataView = (res) => {
    setDataHead(res?.data?.data?.headers);
    setDataBody(res?.data?.data?.details);
  }
  const showErr = (res) => {
    alert(getMessage(res.message));
  }
  const generateData = () => {
    if (dataHead.length > 0) {
      const data = Object.keys(dataHead).map(key => dataHead[key]).reduce((old, item) => (
        { ...old, ...item }
      ), {})
      const newData = Object.keys(data).reduce((result, currentKey) => {
        if (typeof data[currentKey] === 'string' || data[currentKey] instanceof String) {
          const elementToPush = generateElement(currentKey, data[currentKey]);
          result.push(elementToPush);
        }
        return result;
      }, []);
      return newData;
    }
  }
  const generateElement = (key, value) => {
    return (
      <th key={key}>{value}</th>
    );
  }

  const formatCash = (str) => {
    if (str && !isNaN(str)) {
      return str.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return str
    }
  }
  const generateDataBody = (data) => {
    if (data) {
      const newData = Object.keys(data).reduce((result, currentKey) => {
        const elementToPush = generateElementBody(currentKey, data[currentKey]);
        result.push(elementToPush);
        return result;
      }, []);
      return newData;
    }
  }
  const generateElementBody = (key, value) => {
    return (
      <td key={key}>{formatCash(value)}</td>
    );
  }

  const renderDataAllBody = () => {
    if (dataBody.length > 0) {
      return dataBody.map((item, index) => {
        return (
          <tr key={index}>
            {generateDataBody(item)}
          </tr>
        )
      })
    }
  }

  const resetForm = (status) => {
    setActiveTab(status)
    setDataHead([]);
    setDataBody([]);
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Báo cáo</title>
      </MetaTags>
      <h4 className='mb-4'>
        Báo cáo
      </h4>
      <Card>
        <CardBody>
          <Card color='light'>
            <CardBody>
              <button
                className={`btn ${activeTab === "1" ? 'btn-primary' : ""}`}
                onClick={() => {
                  resetForm("1")
                }}
                type="button"
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo doanh số theo hệ thống
              </button>
              <button
                className={`btn ${activeTab === "2" ? 'btn-primary' : ""}`}
                onClick={() => {
                  resetForm("2")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo doanh số theo nhân viên
              </button>
              <button
                className={`btn ${activeTab === "3" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("3")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo doanh số theo nhóm sản phẩm
              </button>
              <button
                className={`btn ${activeTab === "4" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("4")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo doanh số
              </button>
              <button
                className={`btn ${activeTab === "5" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("5")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo bán hàng
              </button>
              <button
                className={`btn ${activeTab === "6" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("6")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo nhập xuất tồn
              </button>
              <button
                className={`btn ${activeTab === "7" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("7")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo doanh số theo kho
              </button>
              <button
                className={`btn ${activeTab === "8" ? 'btn-primary' : ""}`}
                type="button"
                onClick={() => {
                  resetForm("8")
                }}
                style={{ cursor: "pointer", borderRadius: '0' }}
              >
                Báo cáo tồn kho
              </button>
            </CardBody>
            <TabContent activeTab={activeTab} className="p-3 text-muted mb-3">
              <TabPane tabId="1">
                {
                  renderFormTabDsht()
                }
              </TabPane>
              <TabPane tabId="2">
                {
                  renderFormTabNv()
                }
              </TabPane>
              <TabPane tabId="3">
                {
                  renderFormTabDsnsp()
                }
              </TabPane>
              <TabPane tabId="4">
                {
                  renderFormChart()
                }
              </TabPane>
              <TabPane tabId="5">
                {
                  renderFormTabBcbh()
                }
              </TabPane>
              <TabPane tabId="6">
                {
                  renderFormTabNxt()
                }
              </TabPane>
              <TabPane tabId="7">
                {
                  renderFormTabDskho()
                }
              </TabPane>
              <TabPane tabId="8">
                {
                  renderFormTabTonKho()
                }
              </TabPane>
            </TabContent>
          </Card>
          {
            activeTab === "4" ?
              renderChart()
              :
              <Table>
                <thead className="table-light">
                  <tr>
                    {
                      generateData()
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    renderDataAllBody()
                  }
                </tbody>
              </Table>
          }
        </CardBody>
      </Card>
    </div>
  )
}

export default WareHouseReport;
