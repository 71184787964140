import { CHANGE_FIRST_LOAD } from './actionTypes';


export const changeFirstLoadToTrue = () => ({
    type: CHANGE_FIRST_LOAD,
    payload: true
})

export const changeFirstLoadToFalse = () => ({
    type: CHANGE_FIRST_LOAD,
    payload: false
})
