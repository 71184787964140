import dayjs from 'dayjs';

/**
 * From filters: {"title": ["sample 1", "sample 2"], * "pub_date": ["2023-12-14"]}
 * To queryParams: [{name: title, value: 'sample 1'}, {name: title, value: 'sample 2'}, {name: pub_date, value: '2023-12-14'}]
 * title[]=sample 1&title[]=sample 2&pub_date=2023-12-14
 * @param {object} filters of ant design table
 * @returns {array} a query params used in API call
 */
export const filtersToAPIQueryParams = (filters) => {
  let queryParams = [];
  Object.keys(filters).forEach(function (k) {
    if (filters[k].length > 1) {
      let isDate = true;      
      filters[k].every(val => { isDate = dayjs.isDayjs(val); return isDate; });
      if (isDate) {
        let dateArr = filters[k].map(val => { return val.format('YYYY-MM-DD') });
        queryParams.push({ name: k, value: dateArr });
      } else {
        queryParams.push({ name: k, value: filters[k] });
      }
    } else {
      filters[k].forEach(val => { queryParams.push({ name: k, value: val }) });
    }
  });
  return queryParams;
}

/**
 * From url: title=sample 1&title=sample 2&pub_date=4579 
 * To filters of antd table: {"title": ["sample 1", "sample 2"], * "pub_date": ["2023-12-14"]}
 * @param {string} url of current page
 * @returns {object} filters
 */
export const urlToFilters = (url) => {
  let filters = {};
  const getUrl = new URL(url);
  let params = new URLSearchParams(getUrl.search);
  for (const [key, value] of params.entries()) {
    let val = dayjs(value, 'YYYY-MM-DD', true).isValid() ? dayjs(value, 'YYYY-MM-DD') : value;
    if (filters.hasOwnProperty(key)) {
      filters[key].push(val);
    } else {
      filters = { ...filters, ...{ [key]: [val] } };
    }
  }
  return filters;
}

/**
 * From filters: {"title": ["sample 1", "sample 2"], * "pub_date": ["2023-12-14"]}
 * To query string of page: title=sample 1&title=sample 2&pub_date=2023-12-14
 * @param {*} url of current page
 * @returns a query string
 */
export const filtersToUrl = (filters) => {
  let queryParams = [];
  Object.keys(filters).forEach(function (k) {
    filters[k]?.forEach(val => {
      let encodeValue;
      if (dayjs.isDayjs(val)) {
        encodeValue = val.format('YYYY-MM-DD')
      } else {
        encodeValue = encodeURI(val);
      }
      queryParams.push(`${k}=${encodeValue}`)
    });
  });
  return queryParams.join("&");
}

/**
 * From url: title=sample 1&title=sample 2&pub_date=4579&page=2
 * To current page:  2
 * @param {string} url of current page
 * @returns {number} current page
 */
export const urlToCurrentPage = (url) => {
  const getUrl = new URL(url);
  let params = new URLSearchParams(getUrl.search);
  for (const [key, value] of params.entries()) {
    if (key === 'page') {
      return value;
    }
  }
  return 1;
}