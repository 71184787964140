import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap"
import PropTypes from 'prop-types';
import { MetaTags } from 'react-meta-tags';
import "antd/dist/antd.css";

PageContent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

function PageContent(props) {
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.title} | 24Cara</title>
      </MetaTags>
      <Card>
        <CardHeader className="h3 bg-transparent border-bottom">
          {props.title}
        </CardHeader>
        <CardBody className='pt-1 pb-1 mt-2'>
          {props.children}
        </CardBody>
      </Card>
    </div>
  );
}

export default PageContent;