
import { selectedShopRoot } from "../../store/selectedShop/selector";
import React, { Component, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import TextFieldEditorUpdate from "./TextFieldEditorUpdate";
import {
  getAttributeValues,
  saveAddNewAttributeVal, saveUpdateAttributeVal
} from "../../store/wareHouseReceipt/actions";
import PropTypes from "prop-types";
import {
  attributesSelector,
  attributeValuesSelector,
  selectedAttributeSelector
} from "../../store/wareHouseReceipt/selector";
import { showMessage } from "../../Utils/global";

const AttributeValues = ({ attributeValues, selectedShop, selectedAttribute }) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const columns = [{
    dataField: 'value',
    text: 'Giá trị thuộc tính',
    sort: false,
    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      <TextFieldEditorUpdate {...editorProps} value={value} rowIndex={rowIndex} onUpdateServer={onUpdateRowVal} />
    )
  }];
  const defaultSorted = [{
    dataField: 'value_id',
    order: 'asc'
  }];
  const onUpdateRowVal = ({ value, rowIndex, cb }) => {
    dispatch(saveUpdateAttributeVal(
      {
        value,
        id: attributeValues[rowIndex].value_id,
        cb: ({ success, error }) => {
          showMessage({ message: (success) ? "Cập nhật giá trị thuộc tính thành công" : error?.message });
          cb();
          dispatch(getAttributeValues({ attribute_id: selectedAttribute }))
        }
      }))
  }
  const pageOptions = {
    sizePerPage: attributeValues.length,
    totalSize: attributeValues.length,
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: attributeValues.length }
  ];
  const selectRow = {
    mode: 'checkbox'
  }
  const [showEdit, setShowEdit] = useState(false);
  const [attrValName, setAttrValName] = useState("")
  const toggleEdit = () => {
    setShowEdit(!showEdit)
    setAttrValName("")
  }
  return (<PaginationProvider
    pagination={paginationFactory(pageOptions)}
    keyField='id'
    columns={columns}
    data={attributeValues}
  >
    {({ paginationProps, paginationTableProps }) => (
      <ToolkitProvider
        keyField='value_id'
        columns={columns}
        data={attributeValues}
        search
      >
        {toolkitProps => (
          <React.Fragment>
            <Row>
              <Col xl="10" sm={9}>
                <div className="table-responsive limitheight">
                  <BootstrapTable
                    keyField={"value_id"}
                    responsive
                    bordered={false}
                    striped={true}
                    defaultSorted={defaultSorted}
                    classes={
                      "table align-middle table-nowrap table-rtl"
                    }
                    headerWrapperClasses={"thead-light"}
                    {...toolkitProps.baseProps}
                    {...paginationTableProps}
                    cellEdit={cellEditFactory({ mode: "dbclick" })}
                  />
                </div>
              </Col>
              <Col xl="2" sm={3}>
                <a onClick={toggleEdit}><img src={"img.png"} /></a>
              </Col>
            </Row>

            <Modal isOpen={showEdit} toggle={toggleEdit} >
              <ModalBody>
                <input type={"text"} className={"form-control"} placeholder={"Nhập giá trị thuộc tính"} value={attrValName} onChange={e => { setAttrValName(e.target.value) }} />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => {
                  dispatch(saveAddNewAttributeVal({
                    value: attrValName, attribute_id: selectedAttribute, cb: ({ success, error }) => {
                      showMessage({ message: (success) ? "Thêm giá trị thuộc tính thành công" : error?.message })
                      dispatch(getAttributeValues({ attribute_id: selectedAttribute }))
                      if (success) setShowEdit(false)
                    }
                  }))
                }}>Lưu</Button>{' '}
                <Button color="secondary" onClick={toggleEdit}>Đóng</Button>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        )}
      </ToolkitProvider>
    )
    }</PaginationProvider>);
}
AttributeValues.defaultProps = {
  attributeValues: {},
  selectedShop: 0,
  selectedAttribute: 0
}

AttributeValues.propTypes = {
  attributeValues: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedShop: PropTypes.number,
  selectedAttribute: PropTypes.number,
}
function mapStateToProps(state) {
  return {
    selectedShop: selectedShopRoot(state),
    attributeValues: attributeValuesSelector(state),
    selectedAttribute: selectedAttributeSelector(state),
  };
}
const withReduce = connect(mapStateToProps);

export default compose(
  withReduce,
)(AttributeValues);
