import React, { useState, useEffect, useCallback } from "react";
import { CardBody, Table } from "reactstrap";
import { Card } from "reactstrap";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import { Input } from "reactstrap";
import "antd/dist/antd.css";
import { Popconfirm, Select, Switch } from "antd";
import "./warehouse_export.scss";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { useQuery } from "../../Utils/UseQuery";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";
import * as wareHouseApi from "./../../apis/wareHouseExportApi";
import * as authApi from "./../../apis/authApi";
import { getMessage } from "Utils/ErrMessageUtils";
import { showMessage } from "Utils/global";
const { Option } = Select;
import MetaTags from 'react-meta-tags';

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function WareHouseExport(props) {
  let history = useHistory();
  let id = props.match.params?.id;
  const [selectedGroup, setSelectedMulti2] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const selectedShop = useSelector(state => state.SelectedShop);
  const [moreData, setMoreData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [availble, setAvailble] = useState([]);
  const [productCode, setProductCode] = useState([]);
  const [qty, setQty] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [keySearchAvailble, setKeySearchAvailble] = useState("");
  const [itemProduct, setItemProduct] = useState({
    product_code: "",
    product_name: "",
    qty: "",
    warehouse_code: "",
    unit_code: "",
    product_id: "",
  });
  const [user, setUser] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [listUser, setListUser] = useState([]);
  const [userName, setUserName] = useState([]);
  const [listExportType, setListExportType] = useState([]);
  const [exportType, setExportType] = useState([]);
  const [listItem, setListItem] = useState([]);
  const [receiptWarehouseCode, setReceiptWarehouseCode] = useState("");
  const [exportNote, setExportNote] = useState("");
  const [dataAllWarehouses, setDataAllWarehouses] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [dataView, setDataView] = useState();
  const [temporaryExit, setTemporaryExit] = useState(false);
  const [listProductCode, setListProductCode] = useState("");

  useEffect(() => {
    if (selectedShop == 0) return
    getWarehouseExportType();
    if (id !== "create") {
      setIsDetail(true);
      getDetailWare(id);
    } else {
      getUserName();
      getAllWarehouses();
      getAllStaff();
    }
  }, [id, selectedShop]);

  const getAllStaff = () => {
    authApi.getListStaff(getDataSuccess, alertFail);
  };
  const getDataSuccess = res => {
    setListUser(res.data.data);
  };
  const alertFail = res => {
    alert(res.message);
  };

  const getDetailWare = id => {
    wareHouseApi.getDetailWare(id, getDataView, showErr);
  };
  const getDataView = res => {
    setDataView(res.data.data);
  };
  const showErr = res => {
    alert(getMessage(res.message));
  };
  const getWarehouseExportType = () => {
    wareHouseApi.getWarehouseExportType(
      fetchDataWarehouseExportTypeSuccess,
      fetchDataWarehouseExportTypeFail
    );
  };

  const fetchDataWarehouseExportTypeSuccess = res => {
    setListExportType(res.data.data);
  };
  const fetchDataWarehouseExportTypeFail = res => { };

  const getAllWarehouses = () => {
    if (selectedShop === 0) return
    wareHouseApi.getAllWarehouses(
      selectedShop,
      fetchDataAllWarehousesSuccess,
      fetchDataAllWarehousesFail
    );
  };

  const fetchDataAllWarehousesSuccess = res => {
    setDataAllWarehouses(res.data.data);
  };

  const fetchDataAllWarehousesFail = res => { };

  const onChangeProductCode = (key, values) => {
    const item = {
      product_code: values.value,
      product_name: values.productName,
      unit_code: values.unitCode,
      product_id: values.productId,
    };
    setItemProduct({ ...itemProduct, ...item });
    const params = [
      {
        name: "product_code",
        value: values.value,
      },
      {
        name: "company_id",
        value: selectedShop,
      },
    ];
    wareHouseApi.getAvailableWareHouse(
      params,
      fetchDataAvailbleSuccess,
      fetchDataAvailbleFail
    );
  };

  const getProductList = useCallback(
    debounce((val, selectedShop) => {
      if (val) {
        const params = [
          {
            name: "product_code",
            value: val,
          },
          {
            name: "company_id",
            value: selectedShop,
          },
        ];
        wareHouseApi.getProductByCode(
          params,
          fetchDataProductSuccess,
          fetchDataProductFail
        );
      }
    }, 1000),
    []
  );

  const onSearchProductCode = val => {
    getProductList(val, selectedShop);
  };

  const fetchDataProductSuccess = res => {
    setProductCode(res.data.data);
  };

  const fetchDataProductFail = res => {
    setProductCode([]);
  };

  const onChangeAvailble = (key, values) => {
    const item = {
      warehouse_code: values.value,
      qty: values.qty,
    };
    setItemProduct({ ...itemProduct, ...item });
  };

  const handleChangeQty = value => {
    setQty(parseInt(value));
  };
  const onChangeUser = (key, values) => {
    setNameUser(values.name);
    setUser(values.id);
  };

  const onSearchAvailble = val => {
  };

  const fetchDataAvailbleSuccess = res => {
    setAvailble(res.data.data);
  };

  const fetchDataAvailbleFail = res => {
    setAvailble([]);
  };

  const getUserName = () => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserName(obj?.username);
    }
  };

  const guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };

  const resetItemProduct = () => {
    setItemProduct({
      product_code: "",
      product_name: "",
      qty: "",
      warehouse_code: "",
      unit_code: "",
    });
    setProductCode([]);
    setAvailble([]);
    setQty("");
  };

  const onAddItem = () => {
    if (qty == "" || qty > itemProduct.qty) {
      alert(`Số lượng ko được vượt quá ${itemProduct.qty}`);
      return;
    } else if (
      itemProduct.product_code == "" ||
      itemProduct.warehouse_code == ""
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    } else if (
      temporaryExit && user == ""
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return
    } else {
      const item = {
        ...itemProduct,
        qty: qty,
        receiver_id: user,
        receiver_fullname: nameUser,
        id: guidGenerator(),
      };
      setListItem([...listItem, item]);
      resetItemProduct();
    }
  };

  const handleChangeExportType = value => {
    setExportType(parseInt(value));
    setTemporaryExit(value == "3" ? true : false);
  };

  const handleChangeReceipt = value => {
    setReceiptWarehouseCode(value);
  };

  const handleChangeExportNote = value => {
    setExportNote(value);
  };
  const handleChangeProductCode = value => {
    setListProductCode(value);
  };
  const handleDelete = id => {
    const newListItem = listItem.filter(item => item.id !== id);
    setListItem(newListItem);
  };
  const handleSubmitAddData = () => {
    const details = listItem.map(item => {
      return {
        product_code: item.product_code,
        product_name: item.product_name,
        qty: item.qty,
        warehouse_code: item.warehouse_code,
        unit_code: item.unit_code ? item.unit_code : "",
        product_id: item.product_id,
        receiver_id: exportType === 3 ? item.receiver_id : null
      };
    });
    const params = {
      export_date: moment(date).format("YYYY-MM-DD"),
      export_type: exportType,
      receipt_warehouse_code: exportType === 1 || exportType === 3 ? receiptWarehouseCode : "",
      export_note: exportNote,
      row_count: listItem.length,
      company_id: selectedShop,
      details: details,
    };
    wareHouseApi.warehouseExports(params, addDataSuccess, addDataFail);
  };
  const addDataSuccess = res => {
    backUrl();
  };
  const backUrl = () => {
    history.push("/warehouse-exports");
  };
  const addDataFail = res => {
    alert(res.message);
  };
  const handleChangeStatus = (id, status) => {
    // status : 1 == true ? false
    const newStatus = status == 1 ? 2 : 1;
    wareHouseApi.changeStatus(id, newStatus, handleChangeStatusSuccess, showErr);
  }
  const handleChangeStatusSuccess = (res) => {
    getDetailWare(props.match.params?.id);
  }
  const showListProduct = () => {
    const dataList = isDetail ? dataView && dataView[0]?.details : listItem;
    if (dataList && dataList.length === 0) {
      return <tr></tr>;
    }
    return (
      dataList &&
      dataList.map((v, i) => {
        return (
          <tr key={i}>
            <td>{v.product_code}</td>
            <td>{v.product_name}</td>
            <td>{v.warehouse_code}</td>
            <td>{v.qty}</td>
            {dataView && dataView[0]?.export_type === 4 || temporaryExit ? (
              <td>{v.receiver_fullname}</td>
            ) : null}
            {dataView && dataView[0]?.export_type === 4 ? (
              <td>
                {
                  <Popconfirm
                    placement="top"
                    title={"Bạn có muốn cập nhật trạng thái"}
                    onConfirm={() => handleChangeStatus(v.id, v.status)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Switch size="small" checked={v.status === 1 ? true : false} />
                  </Popconfirm>
                }
              </td>
            ) : null}
            {!isDetail ? (
              <td>
                <a
                  href="#"
                  className="color-danger"
                  onClick={() => handleDelete(v.id)}
                >
                  <i className="fas fa-minus-circle"></i>
                </a>
              </td>
            ) : null}
          </tr>
        );
      })
    );
  };
  const renderProductCode = () => {
    const newItem = [];
    dataView[0].details.map(item => {
      newItem.push(item.product_code);
    });
    return newItem.toString();
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết phiếu xuất</title>
      </MetaTags>
      {!isDetail ? (
        <Card>
          <CardBody>
            <Card color="light">
              <CardBody>
                <div className="tag color-primary font-weight-bold mb-3">
                  THÔNG TIN PHIẾU XUẤT
                </div>
                <Row>
                  <Col xs={12} lg={8}>
                    <div className="grid-info-container">
                      <div className="body-2 font-weight-bold grid-info-item wd-70">
                        Ngày
                      </div>
                      <div className="grid-info-item">
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={date}
                          disabled={true}
                        />
                      </div>
                      <div className="body-2 font-weight-bold grid-info-item wd-70">
                        Nhân viên:
                      </div>
                      <div className=" grid-info-item">
                        <Input type="select" className="form-control">
                          <option>{userName}</option>
                        </Input>
                      </div>
                      <div className="body-2 font-weight-bold grid-info-item wd-70">
                        Loại:
                      </div>
                      <div className=" grid-info-item">
                        <Input
                          type="select"
                          className="form-control"
                          onChange={e => handleChangeExportType(e.target.value)}
                        >
                          <option value="">Chọn loại</option>
                          {listExportType.map(item => (
                            <option
                              key={item.warehouse_export_type_id}
                              value={item.warehouse_export_type_id}
                            >
                              {item.warehouse_export_type_name}
                            </option>
                          ))}
                        </Input>
                      </div>
                      {exportType === 1 || exportType === 3 ? (
                        <>
                          <div className="body-2 font-weight-bold grid-info-item wd-70">
                            Kho nhập:
                          </div>
                          <div className=" grid-info-item">
                            <Input
                              type="select"
                              className="form-control"
                              onChange={e =>
                                handleChangeReceipt(e.target.value)
                              }
                            >
                              <option value="">Chọn kho nhập</option>
                              {dataAllWarehouses.map(item => (
                                <option
                                  key={item.warehouse_code}
                                  value={item.warehouse_code}
                                >
                                  {item.warehouse_name}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </>
                      ) : null}
                      {exportType !== 1 && exportType !== 3 ? (
                        <>
                          <div className="body-2 font-weight-bold grid-info-item wd-70"></div>
                          <div className=" grid-info-item"></div>
                        </>
                      ) : null}
                      <div className="body-2 font-weight-bold grid-info-item wd-70">
                        Ghi chú:
                      </div>
                      <div className="grid-info-item">
                        <Input
                          type="textarea"
                          className="form-control"
                          onChange={e => handleChangeExportNote(e.target.value)}
                        ></Input>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="table-responsive">
              <Table className="table-striped mb-0 nowrap-table">
                <thead>
                  <tr>
                    <th>Mã sản phẩm</th>
                    <th>Tên sản phẩm</th>
                    <th>Kho</th>
                    <th>Số lượng</th>
                    {temporaryExit ? <th>Nv Sale</th> : null}
                    <th>{exportType == "4" ? 'Tick trả hàng' : 'Thao tác'}</th>
                  </tr>
                </thead>
                <tbody>
                  {showListProduct()}
                  <tr>
                    <td>
                      <Select
                        showSearch
                        placeholder="Nhập mã sản phẩm"
                        onChange={onChangeProductCode}
                        onSearch={onSearchProductCode}
                        filterOption={false}
                        value={itemProduct.product_code}
                        style={{ width: "300px" }}
                      >
                        {productCode.map(item => (
                          <Option
                            key={item.product_id}
                            price={item.price}
                            productName={item.product_name}
                            value={item.product_code}
                            unitCode={item.unit_code}
                            productId={item.product_id}
                          >
                            {item.product_code} - {item.product_name}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>{itemProduct.product_name}</td>
                    <td>
                      <Select
                        showSearch
                        placeholder="Nhập kho"
                        onChange={onChangeAvailble}
                        onSearch={onSearchAvailble}
                        filterOption={false}
                        style={{ width: "300px" }}
                        value={itemProduct.warehouse_code}
                      >
                        {availble.map(item => (
                          <Option
                            key={item.warehouse_code}
                            value={item.warehouse_code}
                            qty={item.qty}
                          >
                            {item.warehouse_code} - {item.qty}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <Input
                        value={qty}
                        type="text"
                        bsSize="sm"
                        onChange={e => handleChangeQty(e.target.value)}
                      />
                    </td>
                    {temporaryExit ? (
                      <td>
                        <Select
                          placeholder="Chọn NV Sale"
                          onChange={onChangeUser}
                          filterOption={false}
                          style={{ width: "120px" }}
                        >
                          <Option id="" name="">Chọn NV Sale</Option>
                          {listUser.map((item, i) => (
                            <Option
                              key={i}
                              id={item.user_id}
                              name={item.fullname}
                            >
                              {item.fullname}
                            </Option>
                          ))}
                        </Select>
                      </td>
                    ) : null}
                    <td>
                      <span className="color-success" onClick={onAddItem}>
                        <i className="fas fa-plus-square"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="mt-4">
              <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
                <div className="">
                  <Button
                    color="success"
                    className="px-4"
                    style={{ width: "120px" }}
                    disabled={listItem.length === 0 ? true : false}
                    onClick={handleSubmitAddData}
                  >
                    Lưu lại
                  </Button>
                </div>
                <div className="">
                  <Button
                    color="danger"
                    className="px-4"
                    style={{ width: "120px" }}
                    onClick={backUrl}
                  >
                    Hủy
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody>
            <Card color="light">
              <CardBody>
                <div className="tag color-primary font-weight-bold mb-3">
                  THÔNG TIN PHIẾU XUẤT
                </div>
                <Row>
                  <Col xs={12} lg={8}>
                    <div className="grid-info-container">
                      <div className="body-2 font-weight-bold grid-info-item">
                        Ngày
                      </div>
                      <div className="grid-info-item">
                        <input
                          type="date"
                          className="form-control"
                          value={dataView && moment(new Date(dataView[0]?.export_date)).format("YYYY-MM-DD")}
                          disabled={true}
                        />
                      </div>

                      <div className="body-2 font-weight-bold grid-info-item">
                        Số PX
                      </div>
                      <div className="grid-info-item">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={dataView && dataView[0]?.id}
                          disabled={true}
                        />
                      </div>

                      <div className="body-2 font-weight-bold grid-info-item">
                        Loại:
                      </div>
                      <div className=" grid-info-item">
                        <Input
                          type="select"
                          className="form-control"
                          value={dataView && dataView[0]?.export_type}
                          disabled={true}
                        >
                          {listExportType.map(item => (
                            <option
                              key={item.warehouse_export_type_id}
                              value={item.warehouse_export_type_id}
                            >
                              {item.warehouse_export_type_name}
                            </option>
                          ))}
                        </Input>
                      </div>
                      {dataView && dataView[0]?.export_type === 1 || dataView && dataView[0]?.export_type === 3 ? (
                        <>
                          <div className="body-2 font-weight-bold grid-info-item">
                            Kho nhập:
                          </div>
                          <div className=" grid-info-item">
                            <Input
                              type="select"
                              className="form-control"
                              disabled={true}
                            >
                              <option value="">
                                {dataView && dataView[0].receipt_warehouse_code}
                              </option>
                            </Input>
                          </div>
                        </>
                      ) : null}
                      <div className="body-2 font-weight-bold grid-info-item">
                        Nhân viên:
                      </div>
                      <div className=" grid-info-item">
                        <Input
                          type="select"
                          className="form-control"
                          disabled={true}
                        >
                          <option>
                            {dataView && dataView[0].userinfo.username}
                          </option>
                        </Input>
                      </div>
                      <div className="body-2 font-weight-bold grid-info-item">
                        Ghi chú:
                      </div>
                      <div className="grid-info-item">
                        <Input
                          type="textarea"
                          className="form-control"
                          disabled={true}
                          value={dataView && dataView[0].export_note}
                        ></Input>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="table-responsive">
              <Table className="table-striped mb-0 nowrap-table ">
                <thead>
                  <tr>
                    <th>Mã sản phẩm</th>
                    <th>Tên sản phẩm</th>
                    <th>Kho</th>
                    <th>Số lượng</th>
                    {dataView && dataView[0]?.export_type === 4 ? (
                      <th>Nv Sale</th>
                    ) : null}
                    {!isDetail || dataView && dataView[0]?.export_type === 4 ? <th>Tick trả hàng</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {showListProduct()}
                  {
                    (dataView && dataView[0]?.details && dataView[0]?.details.length > 0) ?
                      <tr>
                        <td colSpan={3} style={{ textAlign: "right" }}>Tổng số lượng sản phẩm</td>
                        <td>{dataView && dataView[0].total_qty}</td>
                      </tr> : null
                  }
                </tbody>
              </Table>
            </div>
            <div className="mt-4">
              <div className="d-grid my-grid grid-fit-content-auto justify-content-end px-2 text-right">
                <div className="">
                  <Button
                    color="secondary"
                    className="px-4"
                    style={{ width: "120px" }}
                    onClick={() => history.goBack()}
                  >
                    Quay lại
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
}

WareHouseExport.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(WareHouseExport);
