import { number } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Alert,
} from "reactstrap"
import PropTypes from 'prop-types'
function ProductCategoryTableItem(props) {
    const { data, count, deleteCallback } = props;
    let parentName = data.parent_cat?.category_name ? data.parent_cat?.category_name: ''
    let editLink = '/product-categories/'+data.category_id
    const onDelete = () => {
        deleteCallback(data.category_id)
    }
    return (
        <tr>
            <th scope="row">{count}</th>
            <td>{data.category_name}</td>
            <td>{parentName}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={editLink} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <a className='text-danger' onClick={onDelete} >
                            <i className="fas fa-trash"></i>
                        </a>
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
ProductCategoryTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    deleteCallback: PropTypes.func
}

export default ProductCategoryTableItem;
