import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getSaleman = ( callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/auth/role/sale';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);    
}

export const getPaymentMethod = ( callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-payment-methods';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);    
}

export const getOrderMan = ( callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/auth/role/order';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);    
}

export const getAllUnits = ( callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/all-units';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);    
}

//lấy ds trạng thái xử lý
export const getAllProcess = ( callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/orders/status';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);    
}
