import React, { useEffect, useState } from "react";
import * as baseProductApi from "../../apis/baseProductApi";
import { fixImagePath, showMessage } from 'Utils/global';
import { Card, Table, Row, Col, Image } from 'antd';
import MetaTags from 'react-meta-tags';
import { PropTypes } from 'prop-types'
import { withRouter, Link } from "react-router-dom";
import ListVariant from "./ListVariant";
import PartialLoadedTable from "components/Common/Table/PartialLoadedTable";
import { getBaseProductColumns } from "./ListBaseProductColumns";

ListBaseProduct.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

function ListBaseProduct(props) {
  const [baseProducts, setBaseProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState();
  const [variantModalVisible, setVariantModalVisible] = useState(false);

  // useEffect(() => {
  //   if (selectedShop > 0) {
  //     fetchBaseProducts(1);
  //   }
  // }, [selectedShop]);

  // const fetchBaseProducts = (page, pageSize) => {
  //   const params = [];
  //   params.push({ name: 'page', value: page });
  //   params.push({ name: 'per_page', value: pageSize });
  //   params.push({ name: 'company_id', value: selectedShop });
  //   baseProductApi.getList(params, onSuccess, showMessage);
  // }

  // const onSuccess = (res) => {
  //   let data = res.data.data.data;
  //   let fromIndex = res.data.data.from;
  //   let lbp = data.map((item, index) => { 
  //     return { ...item, 
  //       key: item.product_id, 
  //       stt: fromIndex + index } 
  //   })
  //   setBaseProducts(lbp);
  //   setTotalPages(res.data.data.total);
  // }

  const onCloseVariantModal = () => {
    setVariantModalVisible(false);
  }

  const onShowVariantModal = (baseProduct) => {
    setSelectedProduct(baseProduct);
    setVariantModalVisible(true);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách sản phẩm cơ sở</title>
      </MetaTags>
      <Card title='DANH SÁCH SẢN PHẨM CƠ SỞ'>
        
        <PartialLoadedTable 
          getColumns={getBaseProductColumns} 
          paginationTitle="sản phẩm"
          callbacks={{
            getData: baseProductApi.getList,
            needCompanyId: true,
            idField: 'product_id',
            onShowVariantModal: onShowVariantModal
          }}  />
        {/* <Table className="mytable"
          size="small"
          dataSource={baseProducts}
          columns={baseProductColumns}
          pagination={{
            defaultPageSize: 10,
            total: totalPages,
            locale: { items_per_page: "sản phẩm" },
            onChange: (page, items_per_page) => {
              fetchBaseProducts(page, items_per_page);
            },
          }} /> */}
      </Card>
      <ListVariant
        visible={variantModalVisible}
        baseProduct={selectedProduct}
        onClose={onCloseVariantModal} />
    </div>
  );
}

export default withRouter(ListBaseProduct);
