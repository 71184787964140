import CustomerTableItem from 'components/TableItem/CustomerTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import * as customerApi from '../../apis/customerApi';
import MetaTags from 'react-meta-tags';


import {
    Table,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Input
} from "reactstrap"

import { Link, useHistory, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from 'Utils/UseQuery';
import { getMessage } from './../../Utils/ErrMessageUtils';

function ListCustomer(props) {
    const [data, setData] = useState([])
    const [additionalData, setAdditionalData] = useState({})
    let history = useHistory();

    const [isShowSearch, setIsShowSearch] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [filterAddress, setFilterAddress] = useState("");
    const [filterPhone, setFilterPhone] = useState("");

    const filterToQueryString = () => {
        let queryParams = [];

        queryParams.push('name=' + encodeURIComponent(filterName));
        queryParams.push('address=' + encodeURIComponent(filterAddress));
        queryParams.push('phone=' + encodeURIComponent(filterPhone));

        let rdn = Math.floor(Math.random() * 10) + 1;
        queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));

        let queryString = queryParams.join('&');
        return queryString;
    }

    useEffect(() => {
        let query = useQuery(props.location.search)

        setFilterName(query.get('name') ?? '')
        setFilterAddress(query.get('address') ?? '')
        setFilterPhone(query.get('phone') ?? '')
    }, []);

    useEffect(() => {
        handleSearch();
    }, [props.location.search]);
    
    const handleOnSearch = () => {
        let url = props.location.pathname;
        let queryString = filterToQueryString()
        url = url + '?' + queryString
        history.push(url);
    }
    
    const handleSearch = () => {
        const params = [];
        let queryParams = useQuery(props.location.search)
        let page = queryParams.get("page");
        if (page) {
        params.push({name: "page", value: page});
        } 
        params.push({name: "name", value: queryParams.get("name")})
        params.push({name: "address", value: queryParams.get("address")})
        params.push({name: "phone", value: queryParams.get("phone")})
        
        customerApi.getListCustomer(params, fetchDataSuccess, fetchDataFail);
    }

    const handleResetFilter = () => {
        setFilterName("");
        setFilterAddress("");
        setFilterPhone("");
    }

    const fetchDataSuccess = res => {
        setAdditionalData(res.data.data)
        setData(res.data.data.data);
    }

    const fetchDataFail = res => {
        alert(getMessage(res.message))
    }

    const onChangePage = (page) => {
        let url = props.location.pathname + "?page=" + page;
        let queryString = filterToQueryString()
        url = url + '&' + queryString
        history.push(url);
    }

    const showCustomers = () => {
        if (data.length === 0) {
            return (<tr></tr>);
        }
        return data.map((v, i) => {
            return (<CustomerTableItem key={v.phone} data={v} count={i + 1} />)
        })
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Danh sách khách hàng</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="h4">Danh mục khách hàng</CardTitle>
                    <Row className="justify-content-between">
                        <Col xs="auto" sm="auto">
                        </Col>
                        <Col xs="auto" sm="auto">
                            <Link to="/customers/create">
                                <Button color='primary' className='px-3 rounded-pill'>
                                    <i className="fas fa-plus mr-2"></i> Thêm mới
                                </Button>
                            </Link>
                        </Col>
                    </Row>
            {
                isShowSearch ? 
                <Row className='align-items-end'>
                    <Col xs={2}>
                    <label className='body-2 font-weight-bold'>
                        Tìm theo tên khách
                    </label>
                    <Input 
                        type='text'
                        className="form-control"
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                    <label className='body-2 font-weight-bold'>
                        Tìm theo địa chỉ
                    </label>
                    <Input 
                        type='text'
                        className="form-control"
                        value={filterAddress}
                        onChange={(e) => setFilterAddress(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                    <label className='body-2 font-weight-bold'>
                        Tìm theo SĐT
                    </label>
                    <Input 
                        type='text'
                        className="form-control"
                        value={filterPhone}
                        onChange={(e) => setFilterPhone(e.target.value)} />
                    </Col>
                    <Col xs={12} lg="auto">
                        <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                        Tìm kiếm
                        </Button>
                        <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                        Reset tìm kiếm
                        </Button>
                    </Col>
                    </Row>
                    : null
                }
                <div className='tag color-primary font-weight-bold mt-3' 
                    style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} onClick={() => setIsShowSearch(!isShowSearch)}>
                    {
                    isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
                    } 
                    {
                    isShowSearch ? 
                    <i className="bx bx bx-chevron-up ml-2" />
                    : <i className="bx bx bx-chevron-down ml-2" />
                    }
                </div>
                    <div className="table-responsive">
                        <Table className="table mb-3">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tên khách</th>
                                    <th>Địa chỉ</th>
                                    <th>Số điện thoại</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showCustomers()}
                            </tbody>
                        </Table>
                    </div>
                    
                    <MyPagination 
                        startPage={additionalData.current_page ? additionalData.current_page : 1} 
                        totalItemsCount={additionalData.total ? additionalData.total : 5} 
                        itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
                        callback={onChangePage}                             
                        />

                </CardBody>
            </Card>
        </div>
    )
}

ListCustomer.propTypes = {
    location: PropTypes.object,
}
export default withRouter(ListCustomer);
