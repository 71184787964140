import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Switch } from 'antd';
import * as contactApi from "../../../apis/contactApi";
import PreviewImg from "../../../constants/component/PreviewImg";
import "./style.css"
import { API_HOST_NAME } from "constants/SystemConstant";
import MetaTags from 'react-meta-tags';


const optionGroup2 = [
  { label: "Mustard", value: "Mustard" },
  { label: "Ketchup", value: "Ketchup" },
  { label: "Relish", value: "Relish" }
]
function AddFooter(props) {
  let params = useParams();
  let history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const popupRef = useRef();
  const [form, setForm] = useState({});
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [menu_order, setMenu_order] = useState("");
  const [dataFooter, setDataFooter] = useState([])
  const [selectedParent, setSelectdParent] = useState({})
  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = () => {
    contactApi.getListFooter(fetchDataSuccess, fetchDataFail);
  };
  const fetchDataSuccess = res => {
    const { data } = res.data;
    const listFooter = data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
    setSelectdParent(listFooter[0])
    setDataFooter(listFooter)
  };
  const fetchDataFail = res => {
    alert("Không có dữ liệu");
  };
  const selectedShop = useSelector(state => state.SelectedShop);
  const routeBack = () => {
    history.push("/footer")
  }
  const handleSubmitReservations = (values) => {
    const params = {
      ...values,
      parent_id: selectedParent.value
    }
    contactApi.addFooter(params, addSuccess, addFailure);
  };
  
  const addSuccess = res => {
    alert(res.data.message);
    routeBack()
  };

  const addFailure = err => {
    alert(err.message);
  };

  const handleMulti3 = (val) => {
    setSelectdParent(val)
  }

  return (
    <div className="page-content">
      <MetaTags>
          <title>CMS | Thêm menu footer</title>
      </MetaTags>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: (name) || "",
          link: (link) || "",
          menu_order: (menu_order) || ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            "Tên không được để trống"
          ),
          link: Yup.string().required(
            "Đường dẫn không được để trống"
          ),
          menu_order: Yup.string().required(
            "Số thứ tự không được để trống"
          ),
        })}
        onSubmit={values => {
          handleSubmitReservations(values);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Thêm mới banner </CardTitle> */}
                <Card color="light">
                  <CardBody>
                    <div className="color-primary tag mb-3">THÊM MỚI FOOTER</div>
                    <Row>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Tên</div>
                          <div>
                            <Field
                              name="name"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Menu cha</div>
                          <div>
                            <Select
                              value={selectedParent}
                              isMulti={false}
                              onChange={handleMulti3}
                              options={dataFooter}
                              classNamePrefix="select2-selection"
                              closeMenuOnSelect={true}
                              placeholder="Chọn menu cha"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Link</div>
                          <div>
                            <Field
                              name="link"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.link && touched.link
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="link"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Thứ tự hiển thị</div>
                          <div>
                            <Field
                              name="menu_order"
                              label=""
                              type="number"
                              className={
                                "form-control" +
                                (errors.menu_order && touched.menu_order
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="menu_order"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form"></div>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              style={{ width: "120px" }}
                            >
                              Lưu lại
                            </Button>{" "}
                            <Button color="secondary" onClick={routeBack}>
                              Quay lại
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4}>

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default AddFooter;
