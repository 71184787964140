import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getListBanner = (callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/banners";
    let token = localStorage.getItem("token");
    commonApis.GET(url, token, [], callback, callbackErr);
};

export const getDetail = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/banners/' + id;
    let params = []
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}
export const addBanner = (postData, callback, callbackErr) => {
    const params = []
	let url = API_HOST_NAME + '/api/banners';
	let token = localStorage.getItem("token")
	return commonApis.POSTV2(url, postData, token, params, callback, callbackErr)
}

export const uploadImg = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/banners/image';
    let token = localStorage.getItem("token")

    commonApis.POST_FILE(url, data, token, '', callback, callbackErr)
}

export const updateBanner = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/banners/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}

export const deleteBanner = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/banners/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}
