import PreOrderTableItem from 'components/TableItem/PreOrderTableItem';
import React, { useEffect, useState } from 'react';
import MyPagination from '../../components/Common/MyPagination';
import { Table, Card, CardBody, CardTitle, Row, Col, Button, Input } from "reactstrap"
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from 'Utils/UseQuery';
import * as preOrderApi from '../../apis/preOrderApi';
import * as authApi from "../../apis/authApi";
import * as utilsApi from "../../apis/utilsApi"
import Confirm from 'components/Common/Confirm';
import AlertSimple from 'components/Common/AlertSimple';
import { utils } from 'Utils/utils';
import "flatpickr/dist/themes/material_blue.css"
import moment from "moment";
import MetaTags from 'react-meta-tags';
import { isRoleIn, isUserInGroup } from '../../Utils/SecurityUtils';


ListPreOrder.propTypes = {
  location: PropTypes.object,
}

function ListPreOrder(props) {
  const [data, setData] = useState([])
  const [additionalData, setAdditionalData] = useState({})
  const selectedShop = useSelector(state => state.SelectedShop);
  const [deleteId, setDeleteId] = useState(null);
  const [type, setType] = useState(0);
  const [reload, setReload] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [showMessage, setShowMessage] = useState({
    state: false,
    message: "",
    title: "",
    typeShow: 'success'
  });

  const [order_number, setOrder_number] = useState("");

  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");

  const [user, setUser] = useState("");
  const [order_user, setOrder_user] = useState("");

  const [customer_name, setCustomer_name] = useState("");
  const [customer_phone, setCustomer_phone] = useState("");

  const [amount_from, setAmount_from] = useState("");
  const [amount_to, setAmount_to] = useState("");

  const [process_status, setProcess_status] = useState("");
  const [status, setStatus] = useState("");

  const [listUser, setListUser] = useState([]);
  const [listUserOrder, setListUserOrder] = useState([]);
  const [listProcess, setListProcess] = useState([]);

  let history = useHistory();

  const filterToQueryString = () => {
    let queryParams = [];

    let fromDate = moment(from_date, "YYYY-MM-DD", true);
    if (fromDate.isValid())
      queryParams.push('from_date=' + fromDate.format("YYYY-MM-DD"));

    let toDate = moment(to_date, "YYYY-MM-DD", true);
    if (toDate.isValid())
      queryParams.push('to_date=' + toDate.format("YYYY-MM-DD"));

    if (Boolean(order_number) && (!isNaN(order_number)))
      queryParams.push('order_number=' + encodeURIComponent(order_number));

    queryParams.push('sale_user=' + encodeURIComponent(user));
    queryParams.push('order_user=' + encodeURIComponent(order_user));
    queryParams.push('customer_name=' + encodeURIComponent(customer_name));
    queryParams.push('customer_phone=' + encodeURIComponent(customer_phone ? customer_phone : ""));
    queryParams.push('amount_from=' + encodeURIComponent(amount_from));
    queryParams.push('amount_to=' + encodeURIComponent(amount_to));
    queryParams.push('status=' + encodeURIComponent(status));
    queryParams.push('process_status=' + encodeURIComponent(process_status));
    queryParams.push('company_id=' + encodeURIComponent(selectedShop));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));
    let queryString = queryParams.join('&');
    return queryString;
  }

  const onChangePage = (page) => {
    let url = props.location.pathname + "?page=" + page;
    let queryString = filterToQueryString()
    url = url + '&' + queryString
    history.push(url);
  }

  useEffect(() => {
    handleSearch()
    getListSaleMan();
    getListOrderMan();
    getAllProcessStatus();
  }, [props.location.search, selectedShop, reload]);

  useEffect(() => {
    let query = useQuery(props.location.search)
    let fromDate = moment(query.get('from_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setFrom_date(fromDate)

    let toDate = moment(query.get('to_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setTo_date(toDate)

    let orderNumber = query.get('order_number')
    setOrder_number(orderNumber)

    setUser(query.get('sale_user') ?? '')
    setOrder_user(query.get('order_user') ?? '')
    setCustomer_name(query.get('customer_name') ?? '')
    setCustomer_phone(query.get('customer_phone') ?? '')
    setAmount_from(query.get('amount_from') ?? '')
    setAmount_to(query.get('amount_to') ?? '')
    setStatus(query.get('status') ?? '')
    setProcess_status(query.get('process_status') ?? '')
  }, []);

  const getAllProcessStatus = () => {
    utilsApi.getAllProcess(onProcessSuccess, alertFail);
  }
  const onProcessSuccess = (res) => {
    setListProcess(res.data.data);
  }
  const getListOrderMan = () => {
    authApi.getGroupsOrder(getDataOrderSuccess, alertFail);
  }
  const getDataOrderSuccess = (res) => {
    setListUserOrder(res.data.data);
  }
  const getListSaleMan = () => {
    authApi.getListStaff(getDataSuccess, alertFail);
  }
  const getDataSuccess = (res) => {
    setListUser(res.data.data);
  }
  const alertFail = (res) => {
    alert(res.message);
  }
  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString
    history.push(url);
  }
  const handleSearch = () => {

    if (selectedShop === 0) return

    const params = [];
    let queryParams = useQuery(props.location.search)
    let page = queryParams.get("page");

    if (page) {
      params.push({ name: "page", value: page });
    }

    params.push({ name: "order_number", value: queryParams.get("order_number") });
    params.push({ name: "customer_name", value: queryParams.get("customer_name") });
    params.push({ name: "customer_phone", value: queryParams.get("customer_phone") });
    params.push({ name: "sale_user", value: queryParams.get("sale_user") });
    params.push({ name: "order_user", value: queryParams.get("order_user") });
    params.push({ name: "from_date", value: queryParams.get("from_date") });
    params.push({ name: "to_date", value: queryParams.get("to_date") });
    params.push({ name: "amount_from", value: queryParams.get("amount_from") });
    params.push({ name: "amount_to", value: queryParams.get("amount_to") });
    params.push({ name: "process_status", value: queryParams.get("process_status") });
    params.push({ name: "status", value: queryParams.get("status") });
    params.push({ name: "company_id", value: queryParams.get("selectedShop") ?? selectedShop });

    preOrderApi.getList(params, populateData, errorHandler);
  }

  const populateData = (data) => {
    setData(data.data.data.data);
    setAdditionalData(data.data.data);
  }

  const errorHandler = (res) => {
    setShowMessage({
      state: true,
      message: res.message,
      title: "Thất bại",
      typeShow: 'danger'
    });
  }

  const handleResetFilter = () => {
    setOrder_number("");
    setFrom_date("");
    setTo_date("");
    setUser("");
    setOrder_user("");
    setCustomer_name("");
    setCustomer_phone("");
    setAmount_from("");
    setAmount_to("");
    setStatus("");
    setProcess_status("");
  }

  const showPreOrderItems = () => {
    try {
      if (utils.isEmpty(data) || data.length === 0) {
        return (<tr></tr>);
      }
      return data.map((v, i) => {
        return (
          <PreOrderTableItem
            key={i}
            data={v}
            count={i + 1}
            actionCallBack={onActionCallback}
            successCallback={handleSearch}
            isKeToan={isUserInGroup(['KeToan'])}
          />
        )
      });
    } catch (ex) {
      return (<tr></tr>);
    }

  }

  const onActionCallback = (type, id) => {
    setType(type);
    setDeleteId(id);
  }

  const onConfirm = () => {
    setDeleteId(null);
    if (type == 1) {
      preOrderApi.deleteOrder(deleteId, confirmSuccess, errorHandler)
    } else {
      preOrderApi.restoreOrder(deleteId, confirmSuccess, errorHandler)
    }
  }

  const confirmSuccess = (res) => {
    setReload(!reload);
    setShowMessage({ state: true, message: "", title: type == 1 ? "Xóa thành công" : "Khôi phục thành công", typeShow: 'success' });

  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách lệnh đặt hàng</title>
      </MetaTags>
      {deleteId ?
        <Confirm
          title={type == 1 ? "Bạn chắc chắn muốn xóa ?" : "Bạn muốn khôi phục lại ?"}
          message="Bạn sẽ không thu hồi được quyết định này"
          confirmCallback={() => {
            onConfirm();
          }}
          cancelCallback={() => {
            setDeleteId(null)
          }}
        />
        : null
      }

      {showMessage.state ?
        <AlertSimple
          message={showMessage.message}
          type={showMessage.typeShow}
          title={showMessage.title}
          confirmCallback={() => {
            setShowMessage({
              state: false
            });
          }}
        />
        : null
      }


      <Card>
        <CardBody>
          <Card color='light'>
            <CardBody>
              <Row className="justify-content-between">
                <Col xs="auto" sm="auto"><CardTitle className="h4">Danh sách lệnh đặt hàng</CardTitle></Col>
                <Col xs="auto" sm="auto">
                  <Link to="/preorders/create">
                    {" "}
                    <Button color="primary" className="px-3 rounded-pill">
                      <i className="fas fa-plus mr-2"></i> Thêm mới
                    </Button>
                  </Link>
                </Col>
              </Row>
              {
                isShowSearch ?
                  <Row className='align-items-end'>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Số lệnh đặt hàng
                      </label>
                      <Input
                        type='text'
                        className="form-control"
                        value={order_number}
                        onChange={(e) => setOrder_number(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Từ ngày
                      </label>
                      <Input
                        type='date'
                        className="form-control"
                        value={from_date}
                        onChange={(e) => setFrom_date(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Đến ngày
                      </label>
                      <Input
                        type='date'
                        className="form-control"
                        value={to_date}
                        onChange={(e) => setTo_date(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo NV Sale
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                      >
                        <option value={""}>Chọn NV sale</option>
                        {
                          listUser.map((item, i) => (
                            <option value={item.user_id} key={i}>{item.fullname} - {item.username}</option>
                          ))
                        }
                      </Input>
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo NV order
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={order_user}
                        onChange={(e) => setOrder_user(e.target.value)}
                      >
                        <option value={""}>Chọn NV order</option>
                        {
                          listUserOrder.map((item, i) => (
                            <option value={item.user_id} key={i}>{item.fullname} - {item.username}</option>
                          ))
                        }
                      </Input>
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo khách
                      </label>
                      <Input
                        type='text'
                        className="form-control"
                        value={customer_name}
                        onChange={(e) => setCustomer_name(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo SĐT
                      </label>
                      <Input
                        type='number'
                        className="form-control"
                        value={customer_phone}
                        placeholder="Gõ đầy đủ số phone"
                        onChange={(e) => setCustomer_phone(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Số tiền từ
                      </label>
                      <Input
                        type='number'
                        className="form-control"
                        value={amount_from}
                        onChange={(e) => setAmount_from(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Số tiền đến
                      </label>
                      <Input
                        type='number'
                        className="form-control"
                        value={amount_to}
                        onChange={(e) => setAmount_to(e.target.value)} />
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo trạng thái
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value={""}>Chọn trạng thái</option>
                        <option value={0}>Đã xóa</option>
                        <option value={1}>Bình thường</option>
                      </Input>
                    </Col>
                    <Col xs={2}>
                      <label className='body-2 font-weight-bold'>
                        Tìm theo bước xử lý
                      </label>
                      <Input
                        type='select'
                        className="form-control"
                        value={process_status}
                        onChange={(e) => setProcess_status(e.target.value)}
                      >
                        <option value={""}>Chọn các bước</option>
                        {
                          listProcess.map((item, i) => (
                            <option value={item.status_id} key={i}>{item.status_text}</option>
                          ))
                        }
                      </Input>
                    </Col>
                    <Col xs={12} lg="auto">
                      <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                        Tìm kiếm
                      </Button>
                      <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                        Reset tìm kiếm
                      </Button>
                    </Col>
                  </Row>
                  : null
              }
              <div className='tag color-primary font-weight-bold mt-3' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => setIsShowSearch(!isShowSearch)}>
                {
                  isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
                }
                {
                  isShowSearch ?
                    <i className="bx bx bx-chevron-up ml-2" />
                    : <i className="bx bx bx-chevron-down ml-2" />
                }
              </div>
            </CardBody>
          </Card>

          <div className="table-responsive">
            <Table className="table mb-3">
              <thead>
                <tr>
                  <th>Ngày</th>
                  <th>Số</th>
                  <th>Khách</th>
                  <th>Phone</th>
                  <th>NV sale</th>
                  <th>NV order</th>
                  <th>Tổng</th>
                  <th>Bước xử lý</th>
                  <th>Tình trạng</th>
                  <th>Hành Động</th>
                </tr>
              </thead>
              <tbody>
                {showPreOrderItems()}
              </tbody>
            </Table>
          </div>
          <MyPagination
            startPage={additionalData.current_page ? additionalData.current_page : 1}
            totalItemsCount={additionalData.total ? additionalData.total : 5}
            itemsCountPerPage={additionalData.per_page ? additionalData.per_page : 10}
            callback={onChangePage}
          />

        </CardBody>
      </Card>
    </div>
  )
}

export default ListPreOrder;
