
const _utils = {

  formatDate: (time) => {
    let todayTime = new Date(time);
    var month = "0" + (todayTime.getMonth() + 1);
    var day = ("0" + (todayTime.getDate()));
    var year = (todayTime.getFullYear());

    var result = day.slice(day.length - 2) + "/" + month.slice(month.length - 2) + "/" + year;
    return result;
  },

  isEmpty: (value) => {
    let result = false;
    if (value == null || value == "null" || value === "" || value == undefined || !value || undefined === value) result = true;
    return result;
  },

  isEmptyDate: (value) => {
    let result = false;
    if (value == null || value == "null" || value == "" || value == -2209014000000) result = true;
    return result;
  },

  formatNumber: (value) => {
    return value.toLocaleString(("en-US").replace(/\,([^\,]*)$/, "." + '$1'));
  },

  formatMoney: (value) => {
    return value.toLocaleString(("en-US").replace(/\,([^\,]*)$/, "." + '$1')) + " đ";
  },

  moneyToNumber: (value) => {
    if (value == '') return 0;    
    value = value.replaceAll(',', '');
    value = parseInt(value.replace(/\D/g, ''));
    return value;
  },

  checkValidImage: (file) => {
    if (!file) return;

    let size = (file.size) / (1024 * 1024);
    let type = (file.type).toUpperCase();
    let type_img = type.substring(type.indexOf("/") + 1);

    return (size <= 2 && (type_img === "JPEG" || type_img === "PNG"))
  },

  createUUID: () => {
    return "TMP_ID" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
  },

  dateDefault: () => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    return curr.toISOString().substr(0, 10);
  },

  formatBytes: (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
}

export { _utils as utils }