import React from "react"
import PropTypes from "prop-types"
import { Form, Input } from "antd"

const FormItemInput = props => {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={props.rules}
    >
      <Input autoComplete="off" />
    </Form.Item>
  )
}

FormItemInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.array,
}

export default FormItemInput
