import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getListRequest = (callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/callbacks";
    let token = localStorage.getItem("token");
    commonApis.GET(url, token, [], callback, callbackErr);
};

export const getListFeedback = (callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/feedbacks";
    let token = localStorage.getItem("token");
    commonApis.GET(url, token, [], callback, callbackErr);
};
export const getDetailFeedback = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/feedbacks/' + id;
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, [], callback, callbackErr);
}

export const getListContact = (callback, callbackErr) => {
    let url = API_HOST_NAME + "/api/shop-addresses";
    let token = localStorage.getItem("token");
    commonApis.GET(url, token, [], callback, callbackErr);
};

export const getDetailContact = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/shop-addresses/' + id;
    let params = []
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}

export const updateContact = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/shop-addresses/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}
export const addContact = (postData, callback, callbackErr) => {
    const params = []
	let url = API_HOST_NAME + '/api/shop-addresses';
	let token = localStorage.getItem("token")
	return commonApis.POST(url, postData, token, params, callback, callbackErr)
}

// footer
export const getListFooter = (callback, callbackErr) => {
    const params = []
    let url = API_HOST_NAME + "/api/footer-menus";
    let token = localStorage.getItem("token");
    commonApis.GET(url, token, params, callback, callbackErr);
};

export const addFooter = (postData, callback, callbackErr) => {
    const params = []
	let url = API_HOST_NAME + '/api/footer-menus';
	let token = localStorage.getItem("token")
	return commonApis.POST(url, postData, token, params, callback, callbackErr)
}

export const getDetailFooter = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/footer-menus/' + id;
    let params = []
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback, callbackErr);
}

export const updateFooter = (id, data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/footer-menus/' + id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)

}

export const deleteFooter = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + `/api/footer-menus/${id}`;
    if (!id) return;
    let token = localStorage.getItem("token");
    commonApis.DELETE(url, "", token, "", callback, callbackErr);
  };
