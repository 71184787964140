import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getListRepay = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const getListFilter = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/filters";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const deleteRepay = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/" + id;
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, "", callback, callbackErr);
};

export const getDetailRepay = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/" + id;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const getDetailRepayForCreate = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/get-repayable-products/" + id;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const restoreRepay = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/restore/" + id;
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const postCreateNewRepay = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays";
  let token = localStorage.getItem("token");
  commonApis.POST(url, data, token, "", callback, callbackErr);
};

export const setPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/" + id + "/set-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};

export const setNotPrcessedAccountingStatus = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/repays/" + id + "/set-not-processed-accounting";
  let token = localStorage.getItem("token");
  commonApis.PUT(url, "", token, "", callback, callbackErr);
};
