
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
} from "reactstrap"
import PropTypes from 'prop-types'

function ShopTableItem(props) {
    const { data, count } = props;
    return (
        <tr>
            <td>{data.company_id}</td>
            <td>{data.company_name}</td>
            <td>{data.company_address}</td>
            <td>{data.company_phone}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/shops/"+data.company_id} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    {/* <Col xs="auto" sm="auto">
                        <Link className='text-danger' to="/#" >
                            <i className="fas fa-trash"></i>
                        </Link>
                    </Col> */}
                </Row>
            </td>
        </tr>
    )

}
ShopTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number
}

export default ShopTableItem;
