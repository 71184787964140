import React, { useState } from 'react';
import PropTypes from 'prop-types'
import Pagination from "react-js-pagination";

function MyPagination(props) {
    const { startPage, callback, totalItemsCount, itemsCountPerPage } = props
    //const [curPage, setCurPage] = useState(startPage ? startPage : 1);
    const onChangePage = (page) => {
        if(callback) {
            callback(page)
        }
        //setCurPage(page);
    }
    return (
        <>
            <Pagination
                activeClass="active"
                innerClass='pagination-rounded justify-content-end pagination'
                activePage={startPage}
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={itemsCountPerPage}
                pageRangeDisplayed={5}
                onChange={onChangePage}
                itemClass="page-item"
                linkClass="page-link"
                hideFirstLastPages={false}
            />
        </>
    );
}
MyPagination.propTypes = {
    startPage: PropTypes.number,
    callback: PropTypes.func,
    totalItemsCount: PropTypes.number,
    itemsCountPerPage: PropTypes.number
}

export default MyPagination;
