import React from 'react'
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'

import { PropTypes } from 'prop-types';
import WebOrderLabel from './WebOrderLabel';
import { Select, message } from 'antd';
import * as webOrderApi from 'apis/webOrderApi';
import moment from 'moment';

WebOrderInfoPart.propTypes = {
  webOrder: PropTypes.object,
  setWebOrder: PropTypes.func,
  isView: PropTypes.bool,
}

const webOrderStatus = [
  {label: 'Hủy', value: 0},
  {label: 'Đang xử lý', value: 1},
  {label: 'Hoàn thành', value: 2},
]

function WebOrderInfoPart({webOrder, setWebOrder, isView}) {

  const onChangeProcessStatus = value => {    
    webOrderApi.updateWebOrderProcessStatus(
      webOrder.id,
      {'process_status': value},
      (res) => {
        message.success(res.data.message); 
        setWebOrder(prevState => ({
          ...prevState, 
            process_date: moment(new Date()).format("DD/MM/YYYY"),
            process_status: value,
        }))
      },
      (err) => {message.error(err.message)}
    )
  }
  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">Thông tin đơn hàng</CardHeader>
      <CardBody>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Số đơn</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="text"
              className="form-control"
              value={webOrder.order_number}
              disabled={true}
            />
          </Col>
        </Row>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Ngày đặt hàng</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="text"
              className="form-control"
              value={webOrder.order_date}
              disabled={true}
            />
          </Col>
        </Row>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Ngày xử lý</WebOrderLabel>
          <Col md={12} xl={9}>
            <Input
              type="text"
              className="form-control"
              value={webOrder.process_date ?? ''}
              disabled={true}
            />
          </Col>
        </Row>
        <Row xl={12} className="mb-2">
          <WebOrderLabel>Tình trạng</WebOrderLabel>
          <Col md={12} xl={9}>
            <Select value={webOrder.process_status} disabled={isView}
              options={webOrderStatus}
              style={{minWidth: '100%'}}
              onChange={onChangeProcessStatus} />
            {/* <Input
              type="text"
              className="form-control"
              value={webOrder.process_status_text}
              disabled={true}
            /> */}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default WebOrderInfoPart