import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row, Button } from 'reactstrap'
import * as customerApi from '../../apis/customerApi';
import { PropTypes } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { getMessage } from './../../Utils/ErrMessageUtils';
import MetaTags from 'react-meta-tags';


function Customer(props) {
    const id = props.match.params?.id;
    const [data, setData] = useState({})
    let history = useHistory();
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [props.match.params])
    const fetchData = (id) => {
        customerApi.get(id, fetchDataSuccess, fetchDataFail)
    }
    const fetchDataSuccess = (res) => {
        setData(res.data.data)
    }
    const fetchDataFail = (res) => {
        alert(getMessage(res.message))
    }
    const onChangeData = (value, attrName) => {
        data[attrName] = value;
        setData({ ...data })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (id) {
            customerApi.update(id, data, onSubmitSuccess, onSubmitFail)
        }
        else {
            customerApi.create(data, onSubmitSuccess, onSubmitFail)
        }
    }
    const onSubmitSuccess = (res) => {
        history.push("/customers")
    }
    const onSubmitFail = (res) => {
        alert(getMessage(res.message))
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>Thông tin khách hàng</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">Danh mục khách hàng</CardTitle>
                    <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <Label htmlFor="Customer-name-Input">Tên khách hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="customer-name-input"
                                placeholder="Nhập tên khách hàng"
                                value={data.customer_name}
                                onChange={e => onChangeData(e.target.value, 'customer_name')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="Customer-address-Input">Địa chỉ khách hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="customer-address-input"
                                placeholder="Nhập địa chỉ khách hàng"
                                value={data.customer_address}
                                onChange={e => onChangeData(e.target.value, 'customer_address')}
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="Customer-phone-Input">Số điện thoại khách hàng</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="customer-phone-input"
                                placeholder="Nhập số điện thoại khách hàng"
                                value={data.customer_phone}
                                onChange={e => onChangeData(e.target.value, 'customer_phone')}
                            />
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} sm="auto">
                                    <button type="submit" className="btn btn-primary w-md">
                                        Lưu
                                    </button>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Button
                                        className="btn btn-secondary w-md"
                                        style={{ width: "120px" }}
                                        onClick={() => history.goBack()}
                                    >
                                    Quay lại
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

Customer.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
}
export default Customer
