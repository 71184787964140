
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
} from "reactstrap"
import PropTypes from 'prop-types'

function WarehouseTableItem(props) {
    const { data, count,deleteCallback } = props;
    const onDelete = (e) => {
        deleteCallback(data.warehouse_code)
    }
    return (
        <tr>
            <td>{data.warehouse_code}</td>
            <td>{data.warehouse_name}</td>
            <td>{data.warehouse_address}</td>
            <td>{data.warehouse_phone}</td>
            <td>
                <Row className="d-flex align-items-center m-0">
                    <Col xs="auto" sm="auto">
                        <Link className='text-success' to={"/warehouses/" + data.warehouse_code} >
                            <i className="fas fa-pen"></i>
                        </Link>
                    </Col>
                    <Col xs="auto" sm="auto">
                        <a className='text-danger' onClick={onDelete}>
                            <i className="fas fa-trash"></i>
                        </a>
                    </Col>
                </Row>
            </td>
        </tr>
    )

}
WarehouseTableItem.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
    deleteCallback: PropTypes.func
}

export default WarehouseTableItem;
