
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, } from "reactstrap"
import { message, Popconfirm } from 'antd';
import * as saleCampaignApi from "apis/saleCampaignApi";
import { showMessage } from 'Utils/global';
import { Td, Tr } from 'react-super-responsive-table';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { SaleCampaignContext } from './SaleCampaignContext';
import { isUserInGroup } from '../../Utils/SecurityUtils';

function ListSaleCampaignTableItem() {

  const saleCampaignContext = useContext(SaleCampaignContext)
  const { campaigns, editingCampaignDispatch, campaignsDispatch, setModalVisible } = saleCampaignContext

  const onDelete = id => {
    saleCampaignApi.destroy(id, deleteSuccess, showMessage);
  };

  const onStart = id => {
    saleCampaignApi.start(id, deleteSuccess, showMessage);
  };

  const onStop = id => {
    saleCampaignApi.stop(id, deleteSuccess, showMessage);
  };

  const deleteSuccess = res => {
    campaignsDispatch({ type: 'UPDATE', payload: res.data.data });
    message.success(res.data.message);
  };

  const onRestore = id => {
    saleCampaignApi.restore(id, restoreSuccess, showMessage);
  };

  const restoreSuccess = res => {
    campaignsDispatch({ type: 'UPDATE', payload: res.data.data });
    message.success(res.data.message);
  };

  return (
    <>
      {campaigns?.map((data, i) => {
        return (
          <Tr key={data.id}>
            <Td>{data.stt}</Td>
            <Td>{data.name}</Td>
            <Td>{data.from_date}</Td>
            <Td>{data.to_date}</Td>
            <Td>{data.status_text}</Td>
            <Td>
              <Row className="d-flex align-items-center m-0">
                <Col xs="auto" sm="auto">
                  <Link className="text-success" to={"/sale-campaigns/view/" + data.id}>
                    <i className="fas fa-eye"></i>
                  </Link>
                </Col>
                {data.status != 0 && isUserInGroup(['Admin', 'Kho', 'KeToan'])
                  ?
                  <Col xs="auto" sm="auto">
                    <Link className='text-alert' to={"/sale-campaigns/" + data.id}>
                      <i className="fas fa-pen"></i>
                    </Link>
                  </Col>
                  : null
                }
                {data.status == 1 && isUserInGroup(['Admin', 'Kho', 'KeToan'])
                  ?
                  <Col xs="auto" sm="auto">
                    <Popconfirm
                      placement="top"
                      title={"Bạn có muốn dừng chiến dịch khuyến mại?"}
                      onConfirm={() => onStop(data.id)}
                      okText="Tạm dừng"
                      cancelText="Không"
                    >
                      <a className="text-info"><i className="fas fa-pause" title='Tạm dừng'></i></a>
                    </Popconfirm>
                  </Col>
                  : null
                }
                {data.status == 2 && isUserInGroup(['Admin', 'Kho', 'KeToan'])
                  ?
                  <Col xs="auto" sm="auto">
                    <Popconfirm
                      placement="top"
                      title={"Bạn có muốn chạy chiến dịch khuyến mại?"}
                      onConfirm={() => onStart(data.id)}
                      okText="Bắt đầu chạy"
                      cancelText="Không"
                    >
                      <a className="text-success"><i className="fas fa-play" title='Chạy'></i></a>
                    </Popconfirm>
                  </Col>
                  : null
                }
                {isUserInGroup(['Admin', 'Kho', 'KeToan']) ? (data.status != 0
                  ?
                  <Col xs="auto" sm="auto">
                    <Popconfirm
                      placement="top"
                      title={"Bạn có muốn xóa chiến dịch khuyến mại?"}
                      onConfirm={() => onDelete(data.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a className="text-danger"><i className="fas fa-trash"></i></a>
                    </Popconfirm>
                  </Col>
                  :
                  <Col xs="auto" sm="auto">
                    <Popconfirm
                      placement="top"
                      title={"Bạn có muốn khôi phục chiến dịch khuyến mại?"}
                      onConfirm={() => onRestore(data.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a className="text-warning"><i className="fas fa-trash-restore"></i></a>
                    </Popconfirm>
                  </Col>
        ) : null}
              </Row>
            </Td>
          </Tr>
        )
      })
      }
    </>

  )
}

export default ListSaleCampaignTableItem;
