import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "react-autosuggestions";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Switch } from 'antd';
import * as bannerApi from "../../../apis/bannerApi";
import PreviewImg from "../../../constants/component/PreviewImg";
import "./style.css"
import { API_HOST_NAME } from "constants/SystemConstant";
import MetaTags from 'react-meta-tags';


function AddBanner(props) {
  let params = useParams();
  let history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const popupRef = useRef();
  const [form, setForm] = useState({});
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [banner_link, setBanner_link] = useState("")
  const [banner_media_link, setBanner_media_link] = useState("");
  const [button_title, setButton_title] = useState("");
  const [button_link, setButton_link] = useState("");
  const [banner_order, setBanner_order] = useState("");
  const [selectedTypeBanner, setSelectedTypeBanner] = useState({ label: "Ảnh", value: "image" })
  const [isShowBanner, setIsShowBanner] = useState(true)

  const selectedShop = useSelector(state => state.SelectedShop);

  const handleSubmitReservations = (values) => {
    const params = {
      ...values,
      content: content,
      status: isShowBanner ? 1 : 0,
      banner_media_type: selectedTypeBanner.value
    }
    bannerApi.addBanner(params, addBannerlSuccess, addBannerFailure);
  };
  const addBannerlSuccess = res => {
    history.push("/banner");
  };
  const addBannerFailure = err => {
    alert(err.data);
  };

  const optionGroup = [
    { label: "Ảnh", value: "image" },
    { label: "Video", value: "video" }
  ]

  const handleSelectGroup = (selectedGroup) => {
    setSelectedTypeBanner(selectedGroup)
  }
  const onChange = (checked) => {
    setIsShowBanner(checked)
  };

  const uploadImgSuccess = (res) => {
    const {data} = res.data
    setBanner_media_link(data)
  }
  const uploadFail = (res) => {
    alert(res.data)
  }
  const onUpload = (e) => {
    let result = new FormData();
    result.append('banner', e.target.files[0]);
    bannerApi.uploadImg(result, uploadImgSuccess, uploadFail)
  }
  const routeBack = () => {
    history.push("/banner")
  }
  return (
    <div className="page-content">
      <MetaTags>
          <title>CMS | Thêm banner</title>
      </MetaTags>
      <Formik
        enableReinitialize={true}
        initialValues={{
          header: (header) || "",
          title: (title) || "",
          banner_link: (banner_link) || "",
          banner_media_link: (banner_media_link) || "",
          button_title: (button_title) || "",
          button_link: (button_link) || "",
          banner_order: (banner_order) || ""
        }}
        validationSchema={Yup.object().shape({
          header: Yup.string().required(
            "Header không được để trống"
          ),
          title: Yup.string().required(
            "Tiêu đề không được để trống"
          ),
          banner_link: Yup.string().required(
            "Banner link không được để trống"
          ),
          banner_media_link: Yup.string().required(
            "Link ảnh/Video không được để trống"
          ),
          button_title: Yup.string().required(
            "Tiêu đề nút không được để trống"
          ),
          button_link: Yup.string().required(
            "Link nút không được để trống"
          ),
          banner_order: Yup.string().required(
            "Số thứ tự không được để trống"
          )
        })}
        onSubmit={values => {
          handleSubmitReservations(values);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Thêm mới banner </CardTitle> */}
                <Card color="light">
                  <CardBody>
                    <div className="color-primary tag mb-3">CHỈNH SỬA HÓA ĐƠN </div>
                    <Row>
                      <Col xs={12} md={6} lg={4}>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Header</div>
                          <div>
                            <Field
                              name="header"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.header && touched.header
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="header"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Tiêu đề</div>
                          <div>
                            <Field
                              name="title"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.title && touched.title
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Nội dung</div>
                          <div>
                            <textarea
                              onChange={(e) => setContent(e.target.value)}
                              name="content"
                              className="form-control"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Link banner</div>
                          <div>
                            <Field
                              name="banner_link"
                              label=""
                              className={
                                "form-control" +
                                (errors.banner_link && touched.banner_link
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="banner_link"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        {
                          selectedTypeBanner.value === "image" &&
                          <div className="d-grid my-grid grid-fit-content-auto">
                            <div className="title-form">Upload ảnh</div>
                            <div className="box-upload">
                              <input type="file" id="upload_img" onChange={(e) => onUpload(e)}></input>
                              {
                                banner_media_link.length > 0 && <img src={banner_media_link} id="img_preview"></img>
                              }
                              <div className="box-icon-upload">
                                <i className="bx bx-upload"></i>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Loại banner</div>
                          <div>
                            <Select
                              value={selectedTypeBanner}
                              onChange={handleSelectGroup}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Link ảnh/Video</div>
                          <div>
                            <Field
                              name="banner_media_link"
                              label=""
                              type="text"
                              disabled={selectedTypeBanner.value === "image" ? true : false}
                              className={
                                "form-control" +
                                (errors.banner_media_link && touched.banner_media_link
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="banner_media_link"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Tên nút</div>
                          <div>
                            <Field
                              name="button_title"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.button_title && touched.button_title
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="button_title"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Link nút</div>
                          <div>
                            <Field
                              name="button_link"
                              label=""
                              type="text"
                              className={
                                "form-control" +
                                (errors.button_link && touched.button_link
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="button_link"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Thứ tự hiển thị</div>
                          <div>
                            <Field
                              name="banner_order"
                              label=""
                              type="number"
                              className={
                                "form-control" +
                                (errors.banner_order && touched.banner_order
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="banner_order"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form">Hiển thị/Ẩn</div>
                          <div>
                            <Switch defaultChecked={isShowBanner} onChange={onChange} />
                          </div>
                        </div>
                        <div className="d-grid my-grid grid-fit-content-auto">
                          <div className="title-form"></div>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              style={{ width: "120px" }}
                            >
                              Lưu lại
                            </Button>{" "}
                            <Button color="secondary" onClick={routeBack}>
                              Quay lại
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default AddBanner;
